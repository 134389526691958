import ax from 'axios';

import * as utils from '../utils/utils';

function urlParams(obj) {
  return Object.keys(obj)
    .filter(key => obj[key] != null)
    .map(key => {
      if (obj[key] instanceof Array) {
        return `${key}=` + obj[key].join(`&${key}=`);
      }

      return `${key}=${obj[key]}`;
    })
    .join('&');
}

class Pos {
  _getHeaders() {
    let headers = {
      'Content-Type': 'application/json',
    };

    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);

      if (user.token) {
        headers.Authorization = user.token;
      }
    }

    return headers;
  }

  async getRentalStatusSummary() {
    let resp = await ax.get('/api/v1/rental-card/status/summary', {
      headers: this._getHeaders(),
    });

    return resp.data.content;
  }

  async resetFailureInvoices(params) {
    await ax.post(
      `/api/v1/invoice/upload-failures/reset?${urlParams(
        utils.snakelize(params)
      )}`,
      {},
      {
        headers: this._getHeaders(),
      }
    );
  }

  async findRentalGroups(params) {
    let resp = await ax.get(
      `/api/v1/rental-group?${urlParams(utils.snakelize(params))}`,
      {
        headers: this._getHeaders(),
      }
    );

    return resp.data;
  }

  async addRentalGroup(params) {
    let resp = await ax.post(`/api/v1/rental-group`, params, {
      headers: this._getHeaders(),
    });

    return resp.data;
  }

  async updateRentalGroup(id, params) {
    let resp = await ax.put(`/api/v1/rental-group/${id}`, params, {
      headers: this._getHeaders(),
    });

    return resp.data;
  }

  async deleteRentalGroups(ids) {
    let resp = await ax.delete(`/api/v1/rental-group`, {
      headers: this._getHeaders(),
      data: {
        ids,
      },
    });

    return resp.data;
  }

  async findDepts(params) {
    let resp = await ax.get(
      `/api/v1/dept?${urlParams(utils.snakelize(params))}`,
      {
        headers: this._getHeaders(),
      }
    );

    return resp.data;
  }

  async addDept(params) {
    let resp = await ax.post(`/api/v1/dept`, params, {
      headers: this._getHeaders(),
    });

    return resp.data;
  }

  async updateDept(id, params) {
    let resp = await ax.put(`/api/v1/dept/${id}`, params, {
      headers: this._getHeaders(),
    });

    return resp.data;
  }

  async deleteDepts(ids) {
    let resp = await ax.delete(`/api/v1/dept`, {
      headers: this._getHeaders(),
      data: {
        ids,
      },
    });

    return resp.data;
  }
}

const posv2 = new Pos();

export default posv2;
