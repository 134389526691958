import React from 'react';
import {
  Layout,
  Spin,
  Tag,
  List,
  Typography,
  Select,
  Button,
  Drawer,
  Form,
  message,
  Input,
  DatePicker,
  Switch,
} from 'antd';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import pos from 'pos-api';
import { dayMapper } from '../../../utils/mapper';

const localizer = momentLocalizer(moment);

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const DAY = 24 * 60 * 60 * 1000;

const colors = {
  normal: '#4962EA',
  holiday: '#FF9800',
  spHoliday: '#FF9800',
};

const messages = {
  allDay: '整天',
  previous: '上一頁',
  next: '下一頁',
  today: '今日',
  month: '月',
  week: '周',
  day: '日',
  agenda: '應辦事項',
  date: '日期',
  time: '時間',
  event: '事件',
  showMore: total => `+ 更多... (${total})`,
};

const DayDrawer = Form.create()(
  class extends React.Component {
    upload = async values => {
      values.date = values.date.toDate();
      let d = dayMapper.toEntity(values);
      d = await pos.calendar.spDay.upsertSpDay(d);

      return d;
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(d => {
              hide();
              message.info('上傳成功');
              this.props.actions.add(dayMapper.toView(d));

              if (this.props.onClose) {
                this.props.onClose();
              }
            })
            .catch(err => {
              hide();
              message.error('上傳失敗');
              if (this.props.onClose) {
                this.props.onClose();
              }
            });
        } else {
          console.log(err);
        }
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;

      return (
        <Drawer {...this.props}>
          <Form>
            <Form.Item label='名稱'>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter day name' }],
                initialValue: '特別假日',
              })(<Input placeholder='Please enter app name' />)}
            </Form.Item>

            <Form.Item label='日期'>
              {getFieldDecorator('date', {
                rules: [{ required: true, message: 'Please enter date' }],
                initialValue: moment(),
              })(<DatePicker></DatePicker>)}
            </Form.Item>

            <Form.Item label='類型'>
              {getFieldDecorator('holidayCategory', {
                rules: [
                  { required: true, message: 'Please enter holiday category' },
                ],
                initialValue: '特別假日',
              })(<Input placeholder='Please enter app name' />)}
            </Form.Item>

            <Form.Item label='假日?'>
              {getFieldDecorator('isHoliday', {
                rules: [{ required: true, message: 'Please enter category' }],
                initialValue: true,
                valuePropName: 'checked',
              })(<Switch></Switch>)}
            </Form.Item>

            <Form.Item label='描述'>
              {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Please enter app name' }],
                initialValue: '特別假日',
              })(<Input placeholder='Please enter description' />)}
            </Form.Item>
          </Form>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class SystemCalendar extends React.Component {
  state = {
    evts: null,
    loaded: false,
    date: new Date(),
    currentType: 'normal',
    days: {},
    drawer: {
      title: '',
      visible: false,
    },
  };

  daysToEvents = days => {
    return days.map(d => {
      let title = d.name && d.name.length > 0 ? d.name : d.holidayCategory;
      return {
        title: title,
        start: d.date,
        end: new Date(d.date.getTime() + DAY),
        allDay: true,
        bgColor: d.isHoliday ? colors.holiday : colors.normal,
        color: '#fff',
      };
    });
  };

  fetchCalendar = async (year, month) => {
    this.setState({
      loaded: false,
    });

    let calendar = await pos.calendar.fetchCalendar(year, month);

    let days = calendar.days;
    days = dayMapper.toView(days);
    let evts = this.daysToEvents(days);

    let normalDays = days.filter(d => !d.isHoliday);
    let holidays = days.filter(d => d.isHoliday);
    let spHolidays = calendar.sp_days;
    spHolidays = dayMapper.toView(spHolidays);
    spHolidays = spHolidays.filter(d => d.isHoliday);

    this.setState({
      loaded: true,
      evts,
      days: {
        all: days,
        normal: normalDays,
        holiday: holidays,
        spHoliday: spHolidays,
      },
    });
  };

  componentDidMount() {
    let now = new Date();
    let year = Number(now.getFullYear());
    let month = Number(now.getMonth()) + 1;
    this.setState({
      year,
      month,
    });

    this.fetchCalendar(year);
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: event.bgColor,
      color: event.color,
      display: 'block',
    };
    return {
      style: style,
    };
  };

  onTypeSwitchChange = v => {
    this.setState({
      currentType: v,
    });
  };

  upsertSpDay = day => {};

  onDrawerClose = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = false;
      return {
        drawer,
      };
    });
  };

  drawerActoins = {
    add: d => {
      this.setState(prev => {
        let days = Object.assign({}, prev.days);
        let spDays = days.spHoliday;
        let idx = -1;
        for (let i = 0; i < spDays.length; i++) {
          if (d.id === spDays[i].id) {
            idx = i;
            break;
          }
        }

        if (idx >= 0) {
          spDays[idx] = d;
        } else {
          spDays.push(d);
        }

        return {
          days,
        };
      });
    },
  };

  onAddBtn = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = true;
      return {
        drawer,
      };
    });
  };

  render() {
    return (
      <Layout>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            background: '#fff',
            minHeight: 720,
          }}
        >
          {this.state.loaded ? (
            <div>
              <Calendar
                messages={messages}
                localizer={localizer}
                events={this.state.evts}
                startAccessor='start'
                endAccessor='end'
                style={{ height: 600 }}
                eventPropGetter={this.eventStyleGetter}
                date={this.state.date}
                onNavigate={(date, v, action) => {
                  let month = Number(date.getMonth()) + 1;
                  let year = Number(date.getFullYear());
                  let old = this.state.date;
                  let oldYear = Number(old.getFullYear());
                  if (oldYear !== year) {
                    this.fetchCalendar(year);
                  }

                  this.setState({
                    year,
                    month,
                    date,
                  });
                }}
              />
            </div>
          ) : (
            <Spin></Spin>
          )}
        </Content>

        <Sider
          width={260}
          style={{
            background: '#fff',
            boxShadow: '2px 2px 5px #ccc',
            margin: '24px 12px 24px 8px',
            padding: 12,
          }}
        >
          <div style={{ padding: '6px 0px 12px 0px' }}>
            <Tag color={colors.normal}>平日</Tag>
            <Tag color={colors.holiday}>假日</Tag>
            <Tag color={colors.spHoliday}>特別假日</Tag>
          </div>

          <div style={{ padding: 12, boxShadow: '0px 5px 5px -5px #ccc' }}>
            <Select
              style={{ width: '100%' }}
              value={this.state.currentType}
              onChange={this.onTypeSwitchChange}
            >
              <Option value={'normal'}>平日</Option>
              <Option value={'holiday'}>假日</Option>
              <Option value={'spHoliday'}>特別假日</Option>
            </Select>
          </div>

          <List
            loading={!this.state.loaded}
            style={{ height: 550, overflowY: 'auto' }}
            dataSource={this.state.days[this.state.currentType]}
            renderItem={item => (
              <List.Item>
                <span>{item.date.toLocaleDateString()}</span>
                <span style={{ marginLeft: 12 }}>
                  {item.name || item.holidayCategory}
                </span>
              </List.Item>
            )}
          />

          <div
            style={{
              position: 'absolute',
              width: 'calc(100% - 24px)',
              bottom: 0,
              padding: 12,
              boxShadow: '0px -5px 5px -5px #ccc',
            }}
          >
            <Button
              style={{ width: '100%', margin: 'auto' }}
              type='dashed'
              icon='add'
              disabled={this.state.currentType !== 'spHoliday'}
              onClick={this.onAddBtn}
            >
              新增
            </Button>
          </div>
        </Sider>

        <DayDrawer
          width={600}
          title={this.state.drawer.title}
          placement='right'
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawer.visible}
          actions={this.drawerActoins}
        ></DayDrawer>
      </Layout>
    );
  }
}

export default SystemCalendar;
