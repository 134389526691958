import React, { useState, useEffect, useRef } from 'react';

import {
  Table,
  Typography,
  Button,
  Layout,
  Upload,
  Icon,
  message,
  Input,
} from 'antd';

import { Link } from 'react-router-dom';
import pos from 'pos-api';

const { Search } = Input;

const { Content } = Layout;

function retrieveDiscountDescription(discount) {
  let data = discount;

  if (discount.type === 'all') {
    return '全部折抵';
  } else if (discount.type === 'hourly') {
    let messages = [];

    let hours;

    if (data.default_hours != null) {
      hours = data.default_hours;
    }

    if (!hours) return '';

    return `折抵${hours}小時`;
  } else if (discount.type === 'amount') {
    if (data.default_amount) {
      return `折抵${discount.amount}元`;
    }
  }

  return '';
}

const columns = [
  {
    title: '名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '車號',
    dataIndex: 'plate',
    key: 'plate',
    render: (plate, record) => {
      let identities = record.identities;
      if (!identities) return '';

      let plates = identities.filter(idt =>
        ['plate_car', 'plate_moto'].includes(idt.type)
      );

      return plates.map(p => p.content).join(',');
    },
  },
  {
    title: '優惠折扣',
    dataIndex: 'discounts',
    key: 'discounts',
    render: (discounts, record) => {
      if (!discounts) return '';

      return discounts.map(dis => dis.name).join(',');
    },
  },
  {
    title: '動作',
    dataIndex: 'action',
    key: 'action',
    render: (act, record) => {
      return (
        <span>
          <Link to={`/dashboard/manage/customer/${record.id}`}>
            <Button type='link' icon='edit'></Button>
          </Link>
        </span>
      );
    },
  },
];

const Customers = props => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 100,
  });

  const [customers, setCustomers] = useState([]);

  const searchRef = useRef();

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (pagination.current < 1) return;

    async function init() {
      let resp = await pos.customer.readCustomers({
        pageSize: pagination.pageSize,
        page: pagination.current - 1,
        identity: searchText,
      });

      setCustomers(resp.content);

      setPagination({
        current: pagination.current,
        pageSize: Number(resp.pagination.page_size),
        total: resp.pagination.total_elements,
      });
    }

    init();
  }, [pagination.current]);

  useEffect(() => {
    setPagination({
      ...pagination,
      current: 0,
    });

    // hack
    setTimeout(() => {
      setPagination({
        ...pagination,
        current: 1,
      });
    }, 50);
  }, [searchText]);

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  const handleImport = async file => {
    let hide = message.loading('處理中...');
    try {
      await pos.customer.importCustomers(file);
    } catch (err) {
      hide();
      console.log(err);
      message.error('處理失敗');
      return;
    }

    hide();
    message.success('處理成功');
  };

  const onSearch = text => {
    console.log('log');
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      setSearchText(text);
    }, 250);
  };

  return (
    <div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ float: 'left' }}>
            <Link to='/dashboard/manage/customer/0'>
              <Button type='link' icon='plus'>
                新增顧客
              </Button>
            </Link>

            <Upload
              {...{
                accept: '.csv',
                beforeUpload: file => {
                  handleImport(file);

                  return false;
                },
                fileList: [],
              }}
            >
              <Button type='link'>
                <Icon type='import' />
                匯入
              </Button>
            </Upload>
          </div>

          <div style={{ float: 'right' }}>
            <Search
              placeholder={'請輸入車牌號碼'}
              onSearch={onSearch}
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                onSearch(value);
              }}
              style={{ width: 200 }}
            />
          </div>
        </Content>
      </div>

      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        <Table
          columns={columns}
          dataSource={customers}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Content>
    </div>
  );
};

export default Customers;
