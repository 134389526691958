import React, { useState, useEffect } from 'react';
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Select,
  Button,
  Spin,
  message,
  Switch,
  Col,
  Row,
  Menu,
  Icon,
  List,
  TimePicker,
} from 'antd';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SliderRail, Handle, Track, Tick } from './components';
import ColorPicker from '../../../component/ColorPicker';
import uuid from 'uuid/v4';

import moment from 'moment';

import './ServiceForm.less';
import { serviceMapper, categoryMapper } from '../../../utils/mapper';
import pos from 'pos-api';
import * as util from '../../../utils/utils';
import routes from '../../../Routes';
import { connect } from 'react-redux';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const BasicSection = props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const onTypeChange = type => {
    props.onTypeChange && props.onTypeChange(type);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label='名稱'
              htmlFor='name'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your service name!',
                  },
                ],
                initialValue: props.service.name,
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label='服務類型'
              htmlFor='type'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('type', {
                rules: [
                  {
                    required: true,
                    message: 'Please select your service type!',
                  },
                ],
                initialValue: props.service.type,
              })(
                <Select onChange={onTypeChange}>
                  <Option value='charge_by_time'>計時收費服務</Option>
                  <Option value='park_rental'>停車月租服務</Option>
                  <Option value='park_slot'>車位計費服務</Option>
                  <Option value='not_use_electric_station'>
                    未使用充電樁加收
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label='預設服務'
              htmlFor='type'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('isDefault', {
                initialValue: props.service.isDefault,
                valuePropName: 'checked',
              })(<Switch></Switch>)}
            </Form.Item>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

const OtherSection = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const data = props.service.data;

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Form.Item
          label='啟用入場緩衝'
          htmlFor='enableEnterBufferTime'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableEnterBufferTime', {
            initialValue: !!data.enableEnterBufferTime,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableEnterBufferTime') ? (
          <Form.Item
            label='入場緩衝時間(分鐘)'
            htmlFor='enterBufferTime'
            {...formItemLayout}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('data.enterBufferTime', {
              initialValue:
                data && data.enterBufferTime ? data.enterBufferTime : 10,
            })(<InputNumber></InputNumber>)}
          </Form.Item>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用出場緩衝'
          htmlFor='enableExitBufferTime'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableExitBufferTime', {
            initialValue: !!data.enableExitBufferTime,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        <Form.Item
          label='出場緩衝時間(分鐘)'
          htmlFor='exitBufferTime'
          {...formItemLayout}
          style={{
            marginBottom: 0,
            display: getFieldValue('data.enableExitBufferTime')
              ? 'block'
              : 'none',
          }}
        >
          {' '}
          {/*在席connector需要知道前一次出場緩衝時間*/}
          {getFieldDecorator('data.exitBufferTime', {
            initialValue:
              data && data.exitBufferTime ? data.exitBufferTime : 10,
          })(<InputNumber></InputNumber>)}
        </Form.Item>

        <Form.Item
          label='星期五視為假日'
          htmlFor='fridayAsHoliday'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.fridayAsHoliday', {
            initialValue: !!data.fridayAsHoliday,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        <Form.Item
          label='啟用日收費上限'
          htmlFor='dayAmountLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableDayAmountLimit', {
            initialValue: !!data.enableDayAmountLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableDayAmountLimit') ? (
          <div>
            <Form.Item
              label='平日收費上限(元)'
              htmlFor='dayAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.dayAmountLimit', {
                initialValue:
                  data && data.dayAmountLimit ? data.dayAmountLimit : 100,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='假日收費上限(元)'
              htmlFor='holidayAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.holidayAmountLimit', {
                initialValue:
                  data && data.holidayAmountLimit
                    ? data.holidayAmountLimit
                    : 100,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用累積時數收費上限'
          htmlFor='eanbleAccumulateHoursAmountLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableAccumulateHoursAmountLimit', {
            initialValue: !!data.enableAccumulateHoursAmountLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableAccumulateHoursAmountLimit') ? (
          <div>
            <div>
              <div>平日</div>
              <Form.Item
                label='時數:'
                htmlFor='accumulateLimits.normal.hours'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  'data.accumulateHoursAmountLimits.normal.hours',
                  {
                    initialValue: data?.accumulateHoursAmountLimits?.normal
                      ?.hours
                      ? data?.accumulateHoursAmountLimits?.normal?.hours
                      : 24,
                  }
                )(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <Form.Item
                label='金額:'
                htmlFor='accumulateLimits.normal.amount'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  'data.accumulateHoursAmountLimits.normal.amount',
                  {
                    initialValue: data?.accumulateHoursAmountLimits?.normal
                      ?.amount
                      ? data?.accumulateHoursAmountLimits?.normal?.amount
                      : 180,
                  }
                )(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <div>假日</div>
              <Form.Item
                label='時數:'
                htmlFor='accumulateLimits.holiday.hours'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  'data.accumulateHoursAmountLimits.holiday.hours',
                  {
                    initialValue: data?.accumulateHoursAmountLimits?.holiday
                      ?.hours
                      ? data?.accumulateHoursAmountLimits?.holiday?.hours
                      : 24,
                  }
                )(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <Form.Item
                label='金額:'
                htmlFor='accumulateLimits.holiday.amount'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  'data.accumulateHoursAmountLimits.holiday.amount',
                  {
                    initialValue: data?.accumulateHoursAmountLimits?.holiday
                      ?.amount
                      ? data?.accumulateHoursAmountLimits?.holiday?.amount
                      : 180,
                  }
                )(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <div>特別假日</div>
              <Form.Item
                label='時數:'
                htmlFor='accumulateLimits.sp_holiday.hours'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  'data.accumulateHoursAmountLimits.sp_holiday.hours',
                  {
                    initialValue: data?.accumulateHoursAmountLimits?.sp_holiday
                      ?.hours
                      ? data?.accumulateHoursAmountLimits?.sp_holiday?.hours
                      : 24,
                  }
                )(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <Form.Item
                label='金額:'
                htmlFor='accumulateLimits.sp_holiday.amount'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  'data.accumulateHoursAmountLimits.sp_holiday.amount',
                  {
                    initialValue: data?.accumulateHoursAmountLimits?.sp_holiday
                      ?.amount
                      ? data?.accumulateHoursAmountLimits?.sp_holiday?.amount
                      : 180,
                  }
                )(<InputNumber width={150}></InputNumber>)}
              </Form.Item>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用計價容忍時間(秒)'
          htmlFor='enableTolerateTime'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableTolerateTime', {
            initialValue: !!data.enableTolerateTime,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableTolerateTime') ? (
          <div>
            <Form.Item
              label='計價容忍時間(秒)'
              htmlFor='tolerateTime'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.tolerateTime', {
                initialValue:
                  data && data.tolerateTime ? data.tolerateTime : 60,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用最低收費額'
          htmlFor='enableBelowLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableBelowLimit', {
            initialValue: !!data.enableBelowLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableBelowLimit') ? (
          <div>
            <div>
              <div>平日</div>
              <Form.Item
                label='時數:'
                htmlFor='belowLimitTime'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.belowLimitSettings.normal.time', {
                  initialValue: data?.belowLimitSettings?.normal?.time ?? 60,
                })(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <Form.Item
                label='金額:'
                htmlFor='belowLimitAmount'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.belowLimitSettings.normal.amount', {
                  initialValue: data?.belowLimitSettings?.normal?.amount ?? 60,
                })(<InputNumber width={150}></InputNumber>)}
              </Form.Item>
            </div>

            <div>
              <div>假日</div>
              <Form.Item
                label='時數:'
                htmlFor='belowLimitTime'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.belowLimitSettings.holiday.time', {
                  initialValue: data?.belowLimitSettings?.holiday?.time ?? 60,
                })(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <Form.Item
                label='金額:'
                htmlFor='belowLimitAmount'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.belowLimitSettings.holiday.amount', {
                  initialValue: data?.belowLimitSettings?.holiday?.amount ?? 60,
                })(<InputNumber width={150}></InputNumber>)}
              </Form.Item>
            </div>

            <div>
              <div>特別假日</div>
              <Form.Item
                label='時數:'
                htmlFor='belowLimitTime'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.belowLimitSettings.spHoliday.time', {
                  initialValue: data?.belowLimitSettings?.spHoliday?.time ?? 60,
                })(<InputNumber width={150}></InputNumber>)}
              </Form.Item>

              <Form.Item
                label='金額:'
                htmlFor='belowLimitAmount'
                {...formItemLayout}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.belowLimitSettings.spHoliday.amount', {
                  initialValue:
                    data?.belowLimitSettings?.spHoliday?.amount ?? 60,
                })(<InputNumber width={150}></InputNumber>)}
              </Form.Item>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Content>
    </div>
  );
};

const START = -8 * 60 * 60;
const DAY = 24 * 60 * 60;
const domain = [START, START + DAY - 1];
const TICKS = [0.0, 0.2, 0.4, 0.6, 0.8, 1.0].map(rate => {
  return START + rate * (DAY - 1);
});

class DaysFeeSetting extends React.Component {
  constructor(props) {
    super(props);
  }

  transformDataToValues(data) {
    let start = data[0].startTime.getTime() / 1000;
    let values = [start];
    for (let i = 0; i < data.length; i++) {
      let v = data[i].endTime.getTime() / 1000;
      values.push(v);
    }

    // values.pop();
    // values.push(START + DAY - 1);
    return values;
  }

  initState = data => {
    data = [...data];
    data.forEach(d => {
      d.id = uuid();
    });
    let values = this.transformDataToValues(data);
    return {
      values: [...values],
      update: [...values],
      currentNav: '1',
      data: data,
      disableDrag: false,
    };
  };

  state = {
    ...this.initState(this.props.data),
  };

  onNavigate = e => {
    this.setState({
      currentNav: e.key,
    });
  };

  onSliderUpdate = update => {
    this.setState({ update });
    this.onChange(update);
  };

  onSliderChange = values => {
    this.setState({ values });
    this.onChange(values);
  };

  onChange = values => {
    let data = [];
    for (let i = 0; i < values.length - 1; i++) {
      let c = values[i];
      let n = values[i + 1];
      data.push({
        startTime: new Date(c * 1000),
        endTime: new Date(n * 1000),
        color: this.state.data[i].color,
        id: this.state.data[i].id,
        price: this.state.data[i].price,
        timeUnit: this.state.data[i].timeUnit,
        chargeInterval: this.state.data[i].chargeInterval,
        selectMaxPrice: this.state.data[i].selectMaxPrice,
        maxPrice: this.state.data[i].maxPrice,
      });
    }

    if (this.props.onChange) {
      this.props.onChange(data);
    }

    this.setState({ data });
  };

  onTimePickerChange = (time, idx, type) => {
    let data = [...this.state.data];
    let section = data[idx];
    if (type === 'start') {
      if (idx === 0 && data.length <= 1) return;
      let preIdx = idx - 1;

      if (idx <= 0) {
        preIdx = data.length - 1;
      }

      let prev = data[preIdx];
      section.startTime = time.toDate();

      prev.endTime = time.toDate();
    } else if (type === 'end') {
      if (idx >= data.length - 1 && data.length <= 1) return;

      let nextIdx = idx + 1;

      if (nextIdx >= data.length) {
        nextIdx = 0;
      }

      let d = time.toDate();

      let next = data[nextIdx];
      section.endTime = time.toDate();

      if (idx < data.length - 1) {
        next.startTime = time.toDate();
      } else {
        if (d.getTime() < section.startTime.getTime()) {
          next.startTime = time.toDate();
        } else {
          next.startTime = new Date(START * 1000);
          section.endTime = new Date((START + DAY - 1) * 1000);
        }
      }
    }

    console.log(data);

    let values = this.transformDataToValues(data);

    this.setState({ data, values, update: [...values] });
  };

  onColorPickerChange = (color, idx) => {
    this.setState(prev => {
      let next = [...prev.data];
      let s = next[idx];
      s.color = color;
      return {
        data: next,
      };
    });
  };

  addNewSection = () => {
    let data = [...this.state.data];
    let last = data[data.length - 1];
    let values = this.state.values;
    let v1 = values[values.length - 2];
    let v2 = values[values.length - 1];
    let mid = (v2 - v1) / 2;
    let st = last.startTime.getTime() + mid * 1000;
    let nextLast = {
      id: data.length + '',
      endTime: last.endTime,
      startTime: new Date(st),
      color: '#ff0000',
      price: 50,
      timeUnit: 'hr',
      chargeInterval: 1,
      selectMaxPrice: 'no',
      maxPrice: 0,
    };
    last.endTime = new Date(st);
    data.push(nextLast);
    values = this.transformDataToValues(data);
    if (this.props.onChange) {
      this.props.onChange(data);
    }
    this.setState({ data, values, update: [...values] });
  };

  onDragEnd = result => {
    let { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }

    let data = [...this.state.data];
    let s = data[source.index];
    let d = data[destination.index];
    // swap
    if (source.index > destination.index) {
      let t = s;
      s = d;
      d = t;

      t = source;
      source = destination;
      destination = t;
    }
    // swap color
    let tcolor = s.color;
    s.color = d.color;
    d.color = tcolor;
    // swap time slot
    let sslot = s.endTime.getTime() - s.startTime.getTime();
    let dslot = d.endTime.getTime() - d.startTime.getTime();
    s.endTime = new Date(s.startTime.getTime() + dslot);
    let snext = data[source.index + 1];
    snext.startTime = new Date(s.startTime.getTime() + dslot);
    if (destination.index === data.length - 1) {
      d.startTime = new Date(d.endTime.getTime() - sslot);
      let dpre = data[destination.index - 1];
      dpre.endTime = new Date(d.endTime.getTime() - sslot);
    } else {
      d.endTime = new Date(d.startTime.getTime() + sslot);
      let dnext = data[destination.index + 1];
      dnext.startTime = new Date(d.startTime.getTime() + sslot);
    }

    this.updateData(data);
  };

  updateData = data => {
    data.forEach(d => {
      d.id = uuid();
    });
    let values = this.transformDataToValues(data);
    if (this.props.onChange) {
      this.props.onChange(data);
    }
    this.setState({
      data,
      values,
      update: [...values],
    });
  };

  changeData = (idx, key, value) => {
    let data = [...this.state.data];

    data[idx][key] = value;
    this.setState({
      data,
    });

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  };

  onPriceChange = (idx, price) => {
    this.changeData(idx, 'price', price);
  };

  onTimeUnitChange = (idx, unit) => {
    this.changeData(idx, 'timeUnit', unit);
  };

  onChargeIntervalChange = (idx, interval) => {
    this.changeData(idx, 'chargeInterval', interval);
  };

  onMaxPriceSelectChange = (idx, value) => {
    this.changeData(idx, 'selectMaxPrice', value);
  };
  onMaxPriceChange = (idx, value) => {
    this.changeData(idx, 'maxPrice', value);
  };

  onDelete = idx => {
    if (this.state.length <= 1) {
      message.error('只剩一筆時段，不可刪除');
      return;
    }

    if (this.state.data.length === 2) {
      this.updateData([
        {
          ...this.state.data[0],
          startTime: new Date(START * 1000),
          endTime: new Date((START + DAY - 1) * 1000),
        },
      ]);

      return;
    }

    let data = [...this.state.data];
    if (idx === 0) {
      let next = data[1];
      next.startTime = data[idx].startTime;
    } else if (idx === data.length - 1) {
      let pre = data[idx - 1];
      pre.endTime = data[idx].endTime;
    } else {
      let pre = data[idx - 1];
      let next = data[idx + 1];
      pre.endTime = next.startTime;
    }

    data.splice(idx, 1);

    this.updateData(data);
  };

  componentWillReceiveProps(props) {
    if (props.data !== this.props.data) {
      this.setState({
        ...this.initState(props.data),
      });
    }
  }

  render() {
    const sliderStyle = {
      position: 'relative',
      width: '80%',
      margin: 'auto',
    };

    return (
      <div style={{ width: '100%', marginTop: 70 }}>
        <div style={{ width: '100%', height: 20 }}>
          <Slider
            mode={1}
            step={5}
            domain={domain}
            rootStyle={sliderStyle}
            onUpdate={this.onSliderUpdate}
            onChange={this.onSliderChange}
            values={this.state.values}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => {
                return (
                  <div className='slider-handles'>
                    {handles.map((handle, idx) => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={
                          idx === 0 || idx === handles.length - 1
                            ? () => {}
                            : getHandleProps
                        }
                      />
                    ))}
                  </div>
                );
              }}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => {
                return (
                  <div className='slider-tracks'>
                    {tracks.map(({ id, source, target }, idx) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                        color={this.state.data[idx].color}
                      />
                    ))}
                  </div>
                );
              }}
            </Tracks>
            <Ticks values={TICKS}>
              {({ ticks }) => (
                <div className='slider-ticks'>
                  {ticks.map(tick => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>

        <div style={{ width: '90%', margin: '60px auto 0px auto' }}>
          <Title level={3}>時段</Title>
          <div style={{ borderBottom: '1px solid #ccc' }}></div>
          <DragDropContext
            onBeforeDragStart={this.onBeforeDragStart}
            onDragStart={this.onDragStart}
            onDragUpdate={this.onDragUpdate}
            onDragEnd={this.onDragEnd}
          >
            <Droppable
              droppableId={uuid()}
              type='PERSON'
              isDropDisabled={false}
            >
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List
                    size='large'
                    itemLayout='horizontal'
                    dataSource={this.state.data}
                    style={{ maxHeight: 340, overflowY: 'auto', marginTop: 12 }}
                    renderItem={(period, idx) => (
                      <Draggable
                        draggableId={period.id}
                        index={idx}
                        isDragDisabled={this.state.disableDrag}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <List.Item style={{ fontSize: 16 }}>
                              <span>{`時段${idx + 1}:`}</span>
                              <TimePicker
                                defaultValue={moment(period.startTime)}
                                value={moment(period.startTime)}
                                style={{ marginLeft: 12 }}
                                onChange={time =>
                                  this.onTimePickerChange(time, idx, 'start')
                                }
                              />
                              <span style={{ margin: '0px 12px' }}>~</span>
                              <TimePicker
                                defaultValue={moment(period.endTime)}
                                value={moment(period.endTime)}
                                onChange={time =>
                                  this.onTimePickerChange(time, idx, 'end')
                                }
                              />
                              <InputNumber
                                defaultValue={period.price}
                                formatter={value =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                                style={{ marginLeft: 36, width: 78 }}
                                onChange={v => this.onPriceChange(idx, v)}
                                key={`${period.id}-price`}
                              />
                              <span style={{ marginLeft: 4 }}>/</span>
                              <InputNumber
                                min={1}
                                defaultValue={period.chargeInterval}
                                style={{ marginLeft: 4, width: 56 }}
                                onChange={v =>
                                  this.onChargeIntervalChange(idx, v)
                                }
                                key={`${period.id}-charge-interval`}
                              />

                              <Select
                                defaultValue={period.timeUnit || 'no'}
                                style={{ marginLeft: 12, width: 76 }}
                                onSelect={v => this.onTimeUnitChange(idx, v)}
                                key={`${period.id}-time-unit`}
                              >
                                <Option value='hr'>小時</Option>
                                <Option value='m'>分鐘</Option>
                              </Select>

                              <Select
                                defaultValue={period.selectMaxPrice}
                                style={{ marginLeft: 12, width: 76 }}
                                onSelect={v =>
                                  this.onMaxPriceSelectChange(idx, v)
                                }
                                key={`${period.id}-max-price-select`}
                              >
                                <Option value='no'>無上限</Option>
                                <Option value='yes'>上限</Option>
                              </Select>

                              {period.selectMaxPrice == 'yes' ? (
                                <InputNumber
                                  min={1}
                                  defaultValue={period.maxPrice}
                                  style={{ marginLeft: 4, width: 56 }}
                                  onChange={v => this.onMaxPriceChange(idx, v)}
                                  key={`${period.id}-max-price`}
                                />
                              ) : (
                                <div></div>
                              )}

                              <ColorPicker
                                style={{ marginLeft: 32, float: 'right' }}
                                color={period.color}
                                key={`${period.id}-color-picker`}
                                onChange={color =>
                                  this.onColorPickerChange(color, idx)
                                }
                                onOpen={() => {
                                  this.setState({
                                    disableDrag: true,
                                  });
                                }}
                                onClose={() => {
                                  this.setState({
                                    disableDrag: false,
                                  });
                                }}
                              ></ColorPicker>
                              <Icon
                                type='swap'
                                style={{
                                  transform: 'rotate(90deg)',
                                  fontSize: 28,
                                  color: '#ccc',
                                  marginLeft: 18,
                                }}
                              />

                              <Button
                                key={`${period.id}-delete`}
                                type='link'
                                icon='delete'
                                style={{ marginLeft: 32 }}
                                onClick={() => this.onDelete(idx)}
                              ></Button>
                            </List.Item>
                          </div>
                        )}
                      </Draggable>
                    )}
                  >
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Button
            type='dashed'
            onClick={this.addNewSection}
            style={{ width: '100%', marginTop: 12 }}
          >
            <Icon type='plus' /> 新增時段
          </Button>
        </div>
      </div>
    );
  }
}

class FeeSetting extends React.Component {
  state = {
    dayType: 'normal',
    periods: this.props.periods,
  };

  componentWillReceiveProps(props) {
    if (props.periods) {
      this.setState({
        periods: Object.assign({}, props.periods),
      });
    }
  }

  onDayTypeChange = evt => {
    this.setState({
      dayType: evt.key,
      periods: Object.assign({}, this.state.periods),
    });
  };

  render() {
    return (
      <div>
        <Menu
          onClick={this.onNavigate}
          selectedKeys={[this.state.dayType]}
          mode='horizontal'
          onSelect={this.onDayTypeChange}
        >
          <Menu.Item key='normal'>
            <span>平日</span>
          </Menu.Item>
          <Menu.Item key='holiday'>
            <span>假日</span>
          </Menu.Item>
          <Menu.Item key='spHoliday'>
            <span>特別假日</span>
          </Menu.Item>
        </Menu>

        <DaysFeeSetting
          data={this.state.periods[this.state.dayType]}
          onChange={periods => {
            this.state.periods[this.state.dayType] = [...periods];
            if (this.props.onChange) {
              this.props.onChange(this.state.periods);
            }
          }}
        ></DaysFeeSetting>
      </div>
    );
  }
}

const FeeSettingSection = props => {
  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <FeeSetting
          periods={props.periods}
          onChange={props.onChange}
        ></FeeSetting>
      </Content>
    </div>
  );
};

const ParkSlotOtherSection = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const data = props.service.data;

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Form.Item
          label='啟用入車緩衝'
          htmlFor='enableEnterBufferTime'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableEnterBufferTime', {
            initialValue: !!data.enableEnterBufferTime,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableEnterBufferTime') ? (
          <Form.Item
            label='入車緩衝時間(分鐘)'
            htmlFor='enterBufferTime'
            {...formItemLayout}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('data.enterBufferTime', {
              initialValue:
                data && data.enterBufferTime ? data.enterBufferTime : 10,
            })(<InputNumber></InputNumber>)}
          </Form.Item>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='車種'
          htmlFor='parkSlotType'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.parkSlotType', {
            initialValue: data && data.parkSlotType ? data.parkSlotType : '',
          })(
            <Select>
              <Option value='all'>全部</Option>
              <Option value='normal'>一般</Option>
              <Option value='electric'>電動</Option>
              <Option value='disabled'>身障</Option>
              <Option value='parent-child'>婦幼</Option>
              <Option value='motorbike'>機車</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label='星期五視為假日'
          htmlFor='fridayAsHoliday'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.fridayAsHoliday', {
            initialValue: !!data.fridayAsHoliday,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        <Form.Item
          label='啟用日收費上限'
          htmlFor='dayAmountLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableDayAmountLimit', {
            initialValue: !!data.enableDayAmountLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableDayAmountLimit') ? (
          <div>
            <Form.Item
              label='平日收費上限(元)'
              htmlFor='dayAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.dayAmountLimit', {
                initialValue:
                  data && data.dayAmountLimit ? data.dayAmountLimit : 100,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='假日收費上限(元)'
              htmlFor='holidayAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.holidayAmountLimit', {
                initialValue:
                  data && data.holidayAmountLimit
                    ? data.holidayAmountLimit
                    : 100,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用累積時數收費上限'
          htmlFor='eanbleAccumulateHoursAmountLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableAccumulateHoursAmountLimit', {
            initialValue: !!data.enableAccumulateHoursAmountLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableAccumulateHoursAmountLimit') ? (
          <div>
            <Form.Item
              label='時數:'
              htmlFor='accumulateHours'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.accumulateHours', {
                initialValue:
                  data && data.accumulateHours ? data.accumulateHours : 24,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='金額:'
              htmlFor='accumulateHoursAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.accumulateHoursAmountLimit', {
                initialValue:
                  data && data.accumulateHoursAmountLimit
                    ? data.accumulateHoursAmountLimit
                    : 180,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用最低收費額'
          htmlFor='enableBelowLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableBelowLimit', {
            initialValue: !!data.enableBelowLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableBelowLimit') ? (
          <div>
            <Form.Item
              label='時數:'
              htmlFor='belowLimitTime'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.belowLimitTime', {
                initialValue:
                  data && data.belowLimitTime ? data.belowLimitTime : 60,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='金額:'
              htmlFor='belowLimitAmount'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.belowLimitAmount', {
                initialValue:
                  data && data.belowLimitAmount ? data.belowLimitAmount : 60,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}
      </Content>
    </div>
  );
};

const NotUseElectricStationOtherSection = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const data = props.service.data;

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Form.Item
          label='啟用入車緩衝'
          htmlFor='enableEnterBufferTime'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableEnterBufferTime', {
            initialValue: !!data.enableEnterBufferTime,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableEnterBufferTime') ? (
          <Form.Item
            label='入車緩衝時間(分鐘)'
            htmlFor='enterBufferTime'
            {...formItemLayout}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('data.enterBufferTime', {
              initialValue:
                data && data.enterBufferTime ? data.enterBufferTime : 10,
            })(<InputNumber></InputNumber>)}
          </Form.Item>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='車種'
          htmlFor='parkSlotType'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.parkSlotType', {
            initialValue: data && data.parkSlotType ? data.parkSlotType : '',
          })(
            <Select>
              <Option value='all'>全部</Option>
              <Option value='normal'>一般</Option>
              <Option value='electric'>電動</Option>
              <Option value='disabled'>身障</Option>
              <Option value='parent-child'>婦幼</Option>
              <Option value='motorbike'>機車</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label='星期五視為假日'
          htmlFor='fridayAsHoliday'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.fridayAsHoliday', {
            initialValue: !!data.fridayAsHoliday,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        <Form.Item
          label='啟用日收費上限'
          htmlFor='dayAmountLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableDayAmountLimit', {
            initialValue: !!data.enableDayAmountLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableDayAmountLimit') ? (
          <div>
            <Form.Item
              label='平日收費上限(元)'
              htmlFor='dayAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.dayAmountLimit', {
                initialValue:
                  data && data.dayAmountLimit ? data.dayAmountLimit : 100,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='假日收費上限(元)'
              htmlFor='holidayAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.holidayAmountLimit', {
                initialValue:
                  data && data.holidayAmountLimit
                    ? data.holidayAmountLimit
                    : 100,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用累積時數收費上限'
          htmlFor='eanbleAccumulateHoursAmountLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableAccumulateHoursAmountLimit', {
            initialValue: !!data.enableAccumulateHoursAmountLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableAccumulateHoursAmountLimit') ? (
          <div>
            <Form.Item
              label='時數:'
              htmlFor='accumulateHours'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.accumulateHours', {
                initialValue:
                  data && data.accumulateHours ? data.accumulateHours : 24,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='金額:'
              htmlFor='accumulateHoursAmountLimit'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.accumulateHoursAmountLimit', {
                initialValue:
                  data && data.accumulateHoursAmountLimit
                    ? data.accumulateHoursAmountLimit
                    : 180,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='啟用最低收費額'
          htmlFor='enableBelowLimit'
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.enableBelowLimit', {
            initialValue: !!data.enableBelowLimit,
            valuePropName: 'checked',
          })(<Switch></Switch>)}
        </Form.Item>

        {getFieldValue('data.enableBelowLimit') ? (
          <div>
            <Form.Item
              label='時數:'
              htmlFor='belowLimitTime'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.belowLimitTime', {
                initialValue:
                  data && data.belowLimitTime ? data.belowLimitTime : 60,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='金額:'
              htmlFor='belowLimitAmount'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.belowLimitAmount', {
                initialValue:
                  data && data.belowLimitAmount ? data.belowLimitAmount : 60,
              })(<InputNumber width={150}></InputNumber>)}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}
      </Content>
    </div>
  );
};

function processChageByTime(service) {
  if (!service.data) return;
  if (!service.data.periods) return;

  const transformPeriods = periods => {
    periods.forEach((p, idx) => {
      periods[idx] = {
        startTime: new Date(p.startTime.getTime() + START * 1000),
        endTime: new Date(p.endTime.getTime() + START * 1000),
        color: p.color,
        timeUnit: p.timeUnit,
        chargeInterval: p.chargeInterval,
        price: p.price,
        enableMaxPrice: p.enableMaxPrice,
        maxPrice: p.maxPrice,
      };
    });
  };

  let periods = service.data.periods;

  let types = ['normal', 'holiday', 'spHoliday'];
  types.forEach(t => {
    if (periods[t] instanceof Array) {
      transformPeriods(periods[t]);
    }
  });
}

function genDefaultPeriods() {
  let periods = {};
  let types = ['normal', 'holiday', 'spHoliday'];
  types.forEach(t => {
    periods[t] = [
      {
        startTime: new Date(START * 1000),
        endTime: new Date((START + DAY - 1) * 1000),
        id: uuid(),
        color: '#ff0000',
        timeUnit: 'hr',
        chargeInterval: 1,
        price: 50,
        selectMaxPrice: 'no',
        maxPrice: 100,
      },
    ];
  });

  return periods;
}

const ParkRentalContentSection = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const { service } = props;

  const { system } = props;

  const [periodKeys, setPeriodKeys] = useState([]);

  const [spaceKeys, setSpaceKeys] = useState([]);

  const [loading, setLoading] = useState(true);

  const [parkSpaces, setParkSpaces] = useState([]);

  const [spaceRegulations, setSpaceRegulations] = useState([]);

  const [spacePeriodKeys, setSpacePeriodKeys] = useState({});

  let dayOptionsType = 'week';

  if (system.PARK_RENTAL_USER?.RENTAL_PERIOD_CALENDAR_TYPE === 'gov_calendar') {
    dayOptionsType = 'dayType';
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const addNewPeriod = () => {
    let key = uuid();
    let next = [...periodKeys, key];

    setPeriodKeys(next);
  };

  const removePeriod = key => {
    let next = periodKeys.filter(_key => _key !== key);

    setPeriodKeys(next);
  };

  const updatePeriod = (periodKey, key, value) => {
    let field = getFieldValue(`periods.${periodKey}`);
    field[key] = value;

    let periods = {};
    periods[periodKey] = field;

    setFieldsValue({
      periods: periods,
    });
  };

  const initPeriods = periods => {
    if (!periods) return;

    let fieldPeriods = {};

    periods.forEach(p => {
      fieldPeriods[uuid()] = p;
    });

    setPeriodKeys(Object.keys(fieldPeriods));

    setTimeout(() => {
      setFieldsValue({
        periods: fieldPeriods,
      });
    }, 250);
  };

  const initParkSpaces = parkSpaces => {
    if (!(parkSpaces instanceof Array)) {
      return;
    }

    let fieldParkSpaces = {};

    parkSpaces.forEach(s => {
      let key = uuid();

      fieldParkSpaces[key] = s.id;
    });

    setSpaceKeys(Object.keys(fieldParkSpaces));

    setTimeout(() => {
      setFieldsValue({
        parkSpaces: fieldParkSpaces,
      });
    }, 250);
  };

  const addNewSpacePeriod = spaceKey => {
    let key = uuid();
    let next = {
      ...spacePeriodKeys,
    };

    next[spaceKey] = [...next[spaceKey], key];

    setSpacePeriodKeys(next);
  };

  const removeSpacePeriod = (spaceKey, key) => {
    let next = spacePeriodKeys[spaceKey].filter(_key => _key !== key);

    setSpacePeriodKeys(next);
  };

  const updateSpacePeriod = (spaceKey, periodKey, key, value) => {
    let regulations = getFieldValue(`spaceRegulations`);

    let next = {
      ...regulations,
    };

    next[spaceKey].periods[periodKey][key] = value;

    setFieldsValue({
      spaceRegulations: next,
    });
  };

  const initParkSpaceDefines = async data => {
    let pks = await pos.parkSpace.readParkSpaces();

    if (!pks.content) return;

    let parkSpaces = pks.content;

    let spaceRegulations = data.spaceRegulations || {};

    let regulationMap = {};

    let spacePeriodKeys = {};

    parkSpaces.forEach(s => {
      let r = spaceRegulations[s.id];

      let periodKeys = [];
      let periods = {};

      let vr = {
        type: 'period',
        periods: {},
        hours: 4,
      };

      if (r) {
        if (r.periods) {
          r.periods.forEach(p => {
            let key = uuid();

            periods[key] = p;

            periodKeys.push(key);
          });
        }

        vr = {
          ...r,
          periods,
        };
      }

      console.log(vr);

      regulationMap[s.id] = vr;

      spacePeriodKeys[s.id] = periodKeys;
    });

    setSpacePeriodKeys(spacePeriodKeys);

    setParkSpaces(parkSpaces);

    setSpaceRegulations(regulationMap);

    setTimeout(() => {
      setFieldsValue({
        spaceRegulations: regulationMap,
      });
    }, 250);
  };

  const removeParkSpace = key => {
    setSpaceKeys(spaceKeys.filter(_key => _key !== key));
  };

  const addParkSpace = () => {
    let key = uuid();

    let nextSpacePeriodKeys = {
      ...spacePeriodKeys,
    };

    nextSpacePeriodKeys[key] = [];

    setSpacePeriodKeys(nextSpacePeriodKeys);

    setSpaceKeys([...spaceKeys, key]);
  };

  useEffect(() => {
    if (!service) return;

    let data = service.data;
    if (!data) return;
    let periods = data.periods;
    if (!periods) return;

    initPeriods(periods);

    let parkSpaces = service.parkSpaces;

    initParkSpaces(parkSpaces);

    initParkSpaceDefines(data).then(() => {
      setLoading(false);
    });
  }, [service]);

  if (loading) {
    return <div className='content-wrapper'></div>;
  }

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Form.Item label='價格' htmlFor='price' {...formItemLayout}>
          {getFieldDecorator('data.price', {
            initialValue:
              service.data && service.data.price >= 0
                ? service.data.price
                : 100,
          })(<InputNumber size='large'></InputNumber>)}
        </Form.Item>

        <Form.Item label='月數' htmlFor='months' {...formItemLayout}>
          {getFieldDecorator('data.months', {
            initialValue:
              service.data && service.data.months >= 1
                ? service.data.months
                : 1,
          })(<InputNumber size='large'></InputNumber>)}
        </Form.Item>

        <Form.Item
          label='月租起始日'
          htmlFor='startDayOfMonth'
          {...formItemLayout}
        >
          {getFieldDecorator('data.startDayOfMonth', {
            initialValue:
              service.data && service.data.startDayOfMonth >= 1
                ? service.data.startDayOfMonth
                : 0,
          })(<InputNumber size='large'></InputNumber>)}
        </Form.Item>

        <Form.Item label='車種' htmlFor='carType' {...formItemLayout}>
          {getFieldDecorator('data.carType', {
            initialValue:
              service.data && service.data.carType ? service.data.carType : 'C',
          })(
            <Select>
              <Option value='C'>汽車</Option>
              <Option value='M'>機車</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label='是否可販售' style={{ marginBottom: 0 }}>
          {getFieldDecorator('enableSale', {
            rules: [],
            valuePropName: 'checked',
            initialValue:
              service && service.enableSale != null ? service.enableSale : true,
          })(<Switch />)}
        </Form.Item>

        <Form.Item label='啟用分區管理' style={{ marginBottom: 0 }}>
          {getFieldDecorator('data.enableParkSpaceManage', {
            rules: [],
            valuePropName: 'checked',
            initialValue:
              service.data && service.data.enableParkSpaceManage != null
                ? service.data.enableParkSpaceManage
                : false,
          })(<Switch />)}
        </Form.Item>

        <Form.Item label='是否入系統帳' style={{ marginBottom: 0 }}>
          {getFieldDecorator('enableCredited', {
            rules: [],
            valuePropName: 'checked',
            initialValue:
              service && service.enableCredited != null
                ? service.enableCredited
                : true,
          })(<Switch />)}
        </Form.Item>

        <Form.Item label='啟用不計位' style={{ marginBottom: 0 }}>
          {getFieldDecorator('data.isNotCountSpace', {
            rules: [],
            valuePropName: 'checked',
            initialValue:
              service.data && service.data.isNotCountSpace != null
                ? service.data.isNotCountSpace
                : false,
          })(<Switch />)}
        </Form.Item>

        {getFieldValue('data.enableParkSpaceManage') ? (
          <div>
            {spaceKeys.map((key, idx) => {
              return (
                <div key={key}>
                  <div>
                    {getFieldDecorator(`parkSpaces.${key}`, {
                      rules: [],
                      initialValue: 1,
                    })(
                      <Select style={{ width: 160, marginRight: 8 }}>
                        {parkSpaces.map(pk => {
                          return (
                            <Option key={pk.id} value={pk.id}>
                              {pk.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}

                    {
                      <Icon
                        className='dynamic-delete-button'
                        type='minus-circle-o'
                        onClick={() => removeParkSpace(key)}
                      />
                    }
                  </div>
                </div>
              );
            })}
            <Button
              type='dashed'
              style={{ width: 160, marginTop: 12 }}
              onClick={addParkSpace}
            >
              <Icon type='plus' /> 新增區域
            </Button>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='管制方式'
          htmlFor='carRegulationType'
          {...formItemLayout}
        >
          {getFieldDecorator('data.carRegulationType', {
            initialValue:
              service.data && service.data.carRegulationType
                ? service.data.carRegulationType
                : 'simple',
          })(
            <Select>
              <Option value='simple'>簡單</Option>
              <Option value='parking-space'>分區管制</Option>
            </Select>
          )}
        </Form.Item>

        {getFieldValue('data.carRegulationType') === 'simple' ? (
          <div>
            <Form.Item label='月租時段' style={{ marginBottom: 0 }}>
              <div style={{ width: '100%', margin: 'auto' }}>
                {periodKeys.map(key => (
                  <div
                    key={`time-block-${key}`}
                    className='time-block'
                    style={{ display: 'flex' }}
                  >
                    <span className='time-block-header'>時段:</span>

                    <div
                      className='time-block-content'
                      style={{ display: 'flex' }}
                    >
                      {dayOptionsType === 'dayType' ? (
                        <div>
                          {getFieldDecorator(`periods.${key}.dayType`, {
                            rules: [],
                            initialValue: `all`,
                          })(
                            <Select style={{ width: 120 }}>
                              <Option value='all'>全部</Option>
                              <Option value='workday'>平日</Option>
                              <Option value='holiday'>假日</Option>
                              <Option value='sp_holiday'>特別假日</Option>
                            </Select>
                          )}
                        </div>
                      ) : (
                        <div>
                          {getFieldDecorator(`periods.${key}.startDay`, {
                            rules: [],
                            initialValue: `1`,
                          })(
                            <Select style={{ width: 120 }}>
                              <Option value='1'>星期一</Option>
                              <Option value='2'>星期二</Option>
                              <Option value='3'>星期三</Option>
                              <Option value='4'>星期四</Option>
                              <Option value='5'>星期五</Option>
                              <Option value='6'>星期六</Option>
                              <Option value='7'>星期日</Option>
                            </Select>
                          )}

                          <span
                            style={{
                              display: 'inline-block',
                              width: '24px',
                              textAlign: 'center',
                            }}
                          >
                            -
                          </span>

                          {getFieldDecorator(`periods.${key}.endDay`, {
                            rules: [],
                            initialValue: `7`,
                          })(
                            <Select style={{ width: 120 }}>
                              <Option value='1'>星期一</Option>
                              <Option value='2'>星期二</Option>
                              <Option value='3'>星期三</Option>
                              <Option value='4'>星期四</Option>
                              <Option value='5'>星期五</Option>
                              <Option value='6'>星期六</Option>
                              <Option value='7'>星期日</Option>
                            </Select>
                          )}
                        </div>
                      )}

                      <span
                        style={{
                          display: 'inline-block',
                          width: '24px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                      </span>

                      <Form.Item
                        help='開始時間'
                        style={{ display: 'inline-block' }}
                        htmlFor='startTime'
                      >
                        {getFieldDecorator(`periods.${key}.startTime`, {
                          rules: [],
                          initialValue: moment('00:00', 'HH:mm'),
                        })(
                          <TimePicker
                            format='HH:mm'
                            onChange={v => updatePeriod(key, 'startTime', v)}
                          />
                        )}
                      </Form.Item>

                      <span
                        style={{
                          display: 'inline-block',
                          width: '24px',
                          textAlign: 'center',
                        }}
                      >
                        -
                      </span>

                      <Form.Item
                        help='結束時間'
                        style={{ display: 'inline-block' }}
                        htmlFor='endTime'
                      >
                        {getFieldDecorator(`periods.${key}.endTime`, {
                          rules: [],
                          initialValue: moment('23:59', 'HH:mm'),
                        })(
                          <TimePicker
                            format='HH:mm'
                            onChange={v => updatePeriod(key, 'endTime', v)}
                          />
                        )}
                      </Form.Item>

                      <Button
                        key={`periods-${key}-delete`}
                        type='link'
                        icon='delete'
                        style={{ marginLeft: 32 }}
                        onClick={() => removePeriod(key)}
                      ></Button>
                    </div>
                  </div>
                ))}

                <Button
                  type='dashed'
                  style={{ width: '100%', marginTop: 12 }}
                  onClick={addNewPeriod}
                >
                  <Icon type='plus' /> 新增時段
                </Button>
              </div>
            </Form.Item>
          </div>
        ) : getFieldValue('data.carRegulationType') === 'parking-space' ? (
          <div>
            <div>
              {parkSpaces.map((pk, idx) => {
                return (
                  <div key={idx}>
                    <Title>{pk.name}</Title>

                    <Form.Item
                      label='區域管制模式'
                      htmlFor={`spaceRegulations.${pk.id}.type`}
                      {...formItemLayout}
                    >
                      {getFieldDecorator(`spaceRegulations.${pk.id}.type`, {
                        initialValue:
                          spaceRegulations[pk.id] &&
                          spaceRegulations[pk.id].type
                            ? spaceRegulations[pk.id].type
                            : 'period',
                      })(
                        <Select>
                          <Option value='period'>時段管制</Option>
                          <Option value='hourly'>時數管制</Option>
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item
                      label='入場管制模式'
                      htmlFor={`spaceRegulations.${pk.id}.allowEntranceType`}
                      {...formItemLayout}
                    >
                      {getFieldDecorator(
                        `spaceRegulations.${pk.id}.allowEntranceType`,
                        {
                          initialValue:
                            spaceRegulations[pk.id] &&
                            spaceRegulations[pk.id].allowEntranceType
                              ? spaceRegulations[pk.id].allowEntranceType
                              : 'period',
                        }
                      )(
                        <Select>
                          <Option value='period'>時段可進</Option>
                          <Option value='all-time'>全時可進</Option>
                          <Option value='invalid'>不可進</Option>
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item label='啟用不計位' style={{ marginBottom: 0 }}>
                      {getFieldDecorator(
                        `spaceRegulations.${pk.id}.isNotCountSpace`,
                        {
                          rules: [],
                          valuePropName: 'checked',
                          initialValue:
                            spaceRegulations[pk.id] &&
                            spaceRegulations[pk.id].isNotCountSpace
                              ? spaceRegulations[pk.id].isNotCountSpace
                              : false,
                        }
                      )(<Switch />)}
                    </Form.Item>

                    {getFieldValue(`spaceRegulations.${pk.id}`) == null ||
                    getFieldValue(`spaceRegulations.${pk.id}`).type ===
                      'period' ? (
                      <div>
                        <Form.Item label='月租時段' style={{ marginBottom: 0 }}>
                          <div style={{ width: '100%', margin: 'auto' }}>
                            {spacePeriodKeys[pk.id].map(periodKey => (
                              <div
                                key={`spaceRegulations-${pk.id}-time-block-${periodKey}`}
                                className='time-block'
                              >
                                <span className='time-block-header'>時段:</span>

                                <div className='time-block-content'>
                                  {getFieldDecorator(
                                    `spaceRegulations.${pk.id}.periods.${periodKey}.startDay`,
                                    {
                                      rules: [],
                                      initialValue: `1`,
                                    }
                                  )(
                                    <Select style={{ width: 120 }}>
                                      <Option value='1'>星期一</Option>
                                      <Option value='2'>星期二</Option>
                                      <Option value='3'>星期三</Option>
                                      <Option value='4'>星期四</Option>
                                      <Option value='5'>星期五</Option>
                                      <Option value='6'>星期六</Option>
                                      <Option value='7'>星期日</Option>
                                    </Select>
                                  )}

                                  <span
                                    style={{
                                      display: 'inline-block',
                                      width: '24px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    -
                                  </span>

                                  {getFieldDecorator(
                                    `spaceRegulations.${pk.id}.periods.${periodKey}.endDay`,
                                    {
                                      rules: [],
                                      initialValue: `7`,
                                    }
                                  )(
                                    <Select style={{ width: 120 }}>
                                      <Option value='1'>星期一</Option>
                                      <Option value='2'>星期二</Option>
                                      <Option value='3'>星期三</Option>
                                      <Option value='4'>星期四</Option>
                                      <Option value='5'>星期五</Option>
                                      <Option value='6'>星期六</Option>
                                      <Option value='7'>星期日</Option>
                                    </Select>
                                  )}
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      width: '24px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {' '}
                                  </span>

                                  <Form.Item
                                    help='開始時間'
                                    style={{ display: 'inline-block' }}
                                    htmlFor='startTime'
                                  >
                                    {getFieldDecorator(
                                      `spaceRegulations.${pk.id}.periods.${periodKey}.startTime`,
                                      {
                                        rules: [],
                                        initialValue: moment('00:00', 'HH:mm'),
                                      }
                                    )(
                                      <TimePicker
                                        format='HH:mm'
                                        onChange={v =>
                                          updateSpacePeriod(
                                            pk.id,
                                            periodKey,
                                            'startTime',
                                            v
                                          )
                                        }
                                      />
                                    )}
                                  </Form.Item>

                                  <span
                                    style={{
                                      display: 'inline-block',
                                      width: '24px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    -
                                  </span>

                                  <Form.Item
                                    help='結束時間'
                                    style={{ display: 'inline-block' }}
                                    htmlFor='endTime'
                                  >
                                    {getFieldDecorator(
                                      `spaceRegulations.${pk.id}.periods.${periodKey}.endTime`,
                                      {
                                        rules: [],
                                        initialValue: moment('23:59', 'HH:mm'),
                                      }
                                    )(
                                      <TimePicker
                                        format='HH:mm'
                                        onChange={v =>
                                          updateSpacePeriod(
                                            pk.id,
                                            periodKey,
                                            'endTime',
                                            v
                                          )
                                        }
                                      />
                                    )}
                                  </Form.Item>

                                  <Button
                                    key={`spaceRegulations-${pk.id}-periods-${periodKey}-delete`}
                                    type='link'
                                    icon='delete'
                                    style={{ marginLeft: 32 }}
                                    onClick={() =>
                                      removeSpacePeriod(pk.id, periodKey)
                                    }
                                  ></Button>
                                </div>
                              </div>
                            ))}

                            <Button
                              type='dashed'
                              style={{ width: '100%', marginTop: 12 }}
                              onClick={() => addNewSpacePeriod(pk.id)}
                            >
                              <Icon type='plus' /> 新增時段
                            </Button>
                          </div>
                        </Form.Item>
                      </div>
                    ) : getFieldValue(`spaceRegulations.${pk.id}`)?.type ===
                      'hourly' ? (
                      <div>
                        <Form.Item
                          label='時數(小時)'
                          htmlFor={`spaceRegulations.${pk.id}.hours`}
                          {...formItemLayout}
                        >
                          {getFieldDecorator(
                            `spaceRegulations.${pk.id}.hours`,
                            {
                              initialValue:
                                spaceRegulations && spaceRegulations[pk.id]
                                  ? spaceRegulations[pk.id].hours || 4
                                  : 4,
                            }
                          )(<InputNumber size='large'></InputNumber>)}
                        </Form.Item>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Content>
    </div>
  );
};

class ItemForm extends React.Component {
  state = {
    periods: {
      normal: [],
      holiday: [],
      spHoliday: [],
    },
  };

  fetchService = async () => {
    let service = await pos.service.fetchService(this.props.match.params.id);
    service = serviceMapper.toView(service);

    if (service.type === 'charge_by_time') {
      processChageByTime(service);

      if (!service.data) {
        service.data = {};
      }

      if (service.data.periods) {
        Object.keys(service.data.periods).forEach(key => {
          let ps = service.data.periods[key];

          ps.forEach(p => {
            p.selectMaxPrice = p.enableMaxPrice ? 'yes' : 'no';
          });
        });
      }

      if (service.data && service.data.periods) {
        this.periods = service.data.periods;
      } else {
        this.periods = genDefaultPeriods();
      }

      this.setState({
        service: service,
        dayType: 'normal',
      });
    } else if (service.type === 'park_rental') {
      let data = service.data;
      data.periods = data.periods.map(p => {
        return {
          dayType: p.day_type,
          startDay: p.start_day + '',
          endDay: p.end_day + '',
          startTime: moment(new Date(p.start_time)),
          endTime: moment(new Date(p.end_time)),
        };
      });

      if (data.spaceRegulations) {
        let temp = {};
        Object.keys(data.spaceRegulations).forEach(key => {
          let sr = data.spaceRegulations[key];
          if (sr.periods) {
            sr.periods = sr.periods.map(p => {
              return {
                startDay: p.start_day + '',
                endDay: p.end_day + '',
                startTime: moment(new Date(p.start_time)),
                endTime: moment(new Date(p.end_time)),
              };
            });
          }

          temp[key] = util.camelize(sr);
        });

        data.spaceRegulations = temp;
      }

      console.log(service);

      this.setState({
        service: service,
      });
    } else if (service.type === 'park_slot') {
      processChageByTime(service);

      if (!service.data) {
        service.data = {};
      }

      if (service.data && service.data.periods) {
        this.periods = service.data.periods;
      } else {
        this.periods = genDefaultPeriods();
      }

      this.setState({
        service: service,
        dayType: 'normal',
      });
    } else if (service.type === 'not_use_electric_station') {
      processChageByTime(service);

      if (!service.data) {
        service.data = {};
      }

      if (service.data && service.data.periods) {
        this.periods = service.data.periods;
      } else {
        this.periods = genDefaultPeriods();
      }

      this.setState({
        service: service,
        dayType: 'normal',
      });
    }
  };

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id !== '0') {
      this.fetchService();
      this.setState({
        op: 'edit',
      });
    } else {
      this.periods = genDefaultPeriods();

      this.setState({
        op: 'add',
        service: {
          name: 'default',
          type: 'charge_by_time',
          isDefault: false,
          data: {},
        },
        dayType: 'normal',
      });
    }
  }

  upload = async values => {
    let service = values;

    let hide = message.loading('處理中...');
    try {
      if (this.state.op === 'edit') {
        service.id = this.props.match.params.id;
        await pos.service.updateService(serviceMapper.toEntity(service));
      } else {
        service = await pos.service.addService(serviceMapper.toEntity(service));
      }

      hide();
      message.success('儲存成功');
      this.props.history.push(`${routes.service.layout}${routes.service.path}`);
    } catch (err) {
      message.error('修改失敗');
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values);

      if (!err) {
        if (this.state.service.type === 'charge_by_time') {
          values.data.periods = {};
          let types = ['normal', 'holiday', 'spHoliday'];
          types.forEach(t => {
            values.data.periods[t] = this.periods[t].map(p => {
              return {
                stime: p.startTime.getTime() - START * 1000,
                etime: p.endTime.getTime() - START * 1000,
                color: p.color,
                time_unit: p.timeUnit,
                charge_interval: p.chargeInterval,
                price: p.price,
                enable_max_price: p.selectMaxPrice === 'yes',
                max_price: p.maxPrice,
              };
            });
          });
          values.data.periods = util.snakelize(values.data.periods);
          values.data = util.snakelize(values.data);
        } else if (this.state.service.type === 'park_rental') {
          if (!values.data) values.data = {};

          if (!values.data.periods) {
            values.data.periods = [];
          }

          if (!values.periods) {
            values.periods = [];
          }

          values.data.periods = Object.keys(values.periods).map(key => {
            let p = values.periods[key];
            return {
              day_type: p.dayType,
              start_day: Number(p.startDay),
              end_day: Number(p.endDay),
              start_time: p.startTime.toDate().toISOString(),
              end_time: p.endTime.toDate().toISOString(),
            };
          });

          let spaceRegulations = values.spaceRegulations;

          if (spaceRegulations) {
            let dataRegulations = {};

            spaceRegulations.forEach((sr, idx) => {
              if (!sr) return;

              if (sr.type === 'period') {
                sr.periods = Object.keys(sr.periods).map(key => {
                  let p = sr.periods[key];
                  return {
                    day_type: p.dayType,
                    start_day: Number(p.startDay),
                    end_day: Number(p.endDay),
                    start_time: p.startTime.toDate().toISOString(),
                    end_time: p.endTime.toDate().toISOString(),
                  };
                });
              }

              dataRegulations[idx] = util.snakelize(sr);
            });

            values.data.space_regulations = dataRegulations;
          }

          if (values.parkSpaces) {
            values.park_space_ids = Object.values(values.parkSpaces);
          }

          delete values.parkSpaces;

          values.data = util.snakelize(values.data);

          delete values.periods;
        } else if (this.state.service.type === 'park_slot') {
          values.data.periods = {};
          let types = ['normal', 'holiday', 'spHoliday'];
          types.forEach(t => {
            values.data.periods[t] = this.periods[t].map(p => {
              return {
                stime: p.startTime.getTime() - START * 1000,
                etime: p.endTime.getTime() - START * 1000,
                color: p.color,
                time_unit: p.timeUnit,
                charge_interval: p.chargeInterval,
                price: p.price,
              };
            });
          });
          values.data.periods = util.snakelize(values.data.periods);
          values.data = util.snakelize(values.data);
        } else if (this.state.service.type === 'not_use_electric_station') {
          values.data.periods = {};
          let types = ['normal', 'holiday', 'spHoliday'];
          types.forEach(t => {
            values.data.periods[t] = this.periods[t].map(p => {
              return {
                stime: p.startTime.getTime() - START * 1000,
                etime: p.endTime.getTime() - START * 1000,
                color: p.color,
                time_unit: p.timeUnit,
                charge_interval: p.chargeInterval,
                price: p.price,
              };
            });
          });
          values.data.periods = util.snakelize(values.data.periods);
          values.data = util.snakelize(values.data);
        }

        this.upload(values);
      } else {
        console.log(err);
      }
    });
  };

  hangleTypeChange = type => {
    if (type === 'charge_by_time') {
      this.periods = genDefaultPeriods();

      this.setState({
        service: {
          name: 'default',
          type: type,
          isDefault: false,
          data: {},
        },
        dayType: 'normal',
      });
    } else if (type === 'park_rental') {
      this.setState({
        service: {
          name: 'default',
          type: type,
          isDefault: false,
          data: {
            periods: [],
          },
        },
      });
    } else if (type === 'park_slot') {
      this.periods = genDefaultPeriods();

      this.setState({
        service: {
          name: 'default',
          type: type,
          isDefault: false,
          data: {},
        },
        dayType: 'normal',
      });
    } else if (type === 'not_use_electric_station') {
      this.periods = genDefaultPeriods();

      this.setState({
        service: {
          name: 'default',
          type: type,
          isDefault: false,
          data: {},
        },
        dayType: 'normal',
      });
    }
  };

  render() {
    if (!this.state.service) {
      return <Spin></Spin>;
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const infoProps = {
      service: this.state.service,
    };

    return (
      <Form {...formItemLayout} className='form'>
        <BasicSection
          {...infoProps}
          {...this.props}
          onTypeChange={this.hangleTypeChange}
        ></BasicSection>
        {this.state.service.type === 'charge_by_time' ? (
          <div>
            <FeeSettingSection
              periods={this.periods}
              {...this.props}
              onChange={periods => {
                this.periods = Object.assign({}, periods);
              }}
            ></FeeSettingSection>
            <OtherSection {...infoProps} {...this.props}></OtherSection>
          </div>
        ) : this.state.service.type === 'park_rental' ? (
          <div>
            <ParkRentalContentSection
              {...this.props}
              service={this.state.service}
              op={this.state.op}
            ></ParkRentalContentSection>
          </div>
        ) : this.state.service.type === 'park_slot' ? (
          <div>
            <FeeSettingSection
              periods={this.periods}
              {...this.props}
              onChange={periods => {
                this.periods = Object.assign({}, periods);
              }}
            ></FeeSettingSection>
            <ParkSlotOtherSection
              {...infoProps}
              {...this.props}
            ></ParkSlotOtherSection>
          </div>
        ) : this.state.service.type === 'not_use_electric_station' ? (
          <div>
            <FeeSettingSection
              periods={this.periods}
              {...this.props}
              onChange={periods => {
                this.periods = Object.assign({}, periods);
              }}
            ></FeeSettingSection>
            <NotUseElectricStationOtherSection
              {...infoProps}
              {...this.props}
            ></NotUseElectricStationOtherSection>
          </div>
        ) : (
          <div></div>
        )}

        <div
          style={{
            padding: '20px 30px',
          }}
        >
          <Button
            type='primary'
            size='large'
            block
            style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
            onClick={this.handleSubmit}
          >
            確認
          </Button>

          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>

          <Button
            type='danger'
            size='large'
            block
            style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
          >
            取消
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedItemForm = Form.create({ name: 'service_form' })(ItemForm);

function mapProps(s) {
  return {
    system: s.system,
  };
}

export default connect(mapProps)(WrappedItemForm);
