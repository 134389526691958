import React from 'react';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
  Modal,
} from 'antd';
import { Drawer, Form, Input, Switch, InputNumber } from 'antd';

import pos from 'pos-api';
import { appMapper } from '../../utils/mapper';
import * as util from '../../utils/utils';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;

const columns = [
  {
    title: '應用名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '類型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '操作',
    key: 'action',
    render: (text, record) => (
      <span>
        <Button type='link' icon='edit' onClick={record.onEdit}></Button>
        <Divider type='vertical' />
        <Button type='link' icon='delete' onClick={record.onDelete}></Button>
      </span>
    ),
  },
];

const CategoryDrawer = Form.create()(
  class extends React.Component {
    upload = async values => {
      let c = values;
      if (this.props.app) {
        c.id = this.props.app.id;
        c = appMapper.toEntity(c);
        c = await pos.app.updateApp(c);
      } else {
        c = appMapper.toEntity(c);
        c = await pos.app.addApp(c);
      }

      return c;
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(c => {
              hide();
              message.info('上傳成功');
              if (this.props.app) {
                if (this.props.actions && this.props.actions.replace) {
                  this.props.actions.replace(appMapper.toView(c));
                }
              } else {
                if (this.props.actions && this.props.actions.add) {
                  this.props.actions.add(appMapper.toView(c));
                }
              }

              if (this.props.onClose) {
                this.props.onClose();
              }
            })
            .catch(err => {
              hide();
              message.error('上傳失敗');
              if (this.props.onClose) {
                this.props.onClose();
              }
            });
        } else {
          console.log(err);
        }
      });
    };

    componentDidUpdate(prevProps) {
      if (this.props.app !== prevProps.app) {
        const { setFieldsValue } = this.props.form;

        if (this.props.app) {
          const { app } = this.props;
          let data = {};
          if (app.data) {
            data = {
              ...app.data,
            };
          }

          if (data.apsDiscountMinutes == null) {
            data.apsDiscountMinutes = 0;
          }

          setFieldsValue({
            name: app.name,
            type: app.type,
            data,
          });
        } else {
          setFieldsValue({
            name: null,
            type: 'normal',
          });
        }
      }
    }

    render() {
      const { getFieldDecorator, getFieldValue } = this.props.form;

      return (
        <Drawer {...this.props}>
          <Form>
            <Form.Item label='名稱'>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter app name' }],
                initialValue: this.props.app ? this.props.app.name : null,
              })(<Input placeholder='Please enter app name' />)}
            </Form.Item>

            <Form.Item label='類型'>
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please enter app name' }],
                initialValue: this.props.app ? this.props.app.type : 'order',
              })(
                <Select>
                  <Option value='order'>點餐型應用</Option>
                  <Option value='normal'>一般型應用</Option>
                  <Option value='admin'>主控台應用</Option>
                  <Option value='aps'>自動繳費機應用</Option>
                  <Option value='edge_face_detector'>人臉檢測器</Option>
                </Select>
              )}
            </Form.Item>

            {['order', 'aps'].includes(getFieldValue('type')) ? (
              <div>
                <Form.Item label='機號'>
                  {getFieldDecorator('data.apsNumber', {
                    rules: [{ required: false, message: '請輸入機號' }],
                    initialValue:
                      this.props.app && this.props.app.data
                        ? this.props.app.data.apsNumber
                        : null,
                  })(<Input placeholder='請輸入機號' />)}
                </Form.Item>

                <Form.Item label='發票key'>
                  {getFieldDecorator('data.invoiceKey', {
                    rules: [{ required: false, message: '請輸入發票key' }],
                    initialValue:
                      this.props.app && this.props.app.data
                        ? this.props.app.data.invoiceKey
                        : null,
                  })(<Input placeholder='請輸入發票key' />)}
                </Form.Item>

                <Form.Item label='監聽目標'>
                  {getFieldDecorator('data.listenTargets', {
                    rules: [{ required: false, message: '監聽目標' }],
                    initialValue:
                      this.props.app && this.props.app.data
                        ? this.props.app.data.listenTargets
                        : null,
                  })(<Input placeholder='監聽目標' />)}
                </Form.Item>

                <Form.Item label='自動結帳'>
                  {getFieldDecorator('data.autoCheckout', {
                    rules: [{ required: false, message: '自動結帳' }],
                    valuePropName: 'checked',
                    initialValue:
                      this.props.app && this.props.app.data
                        ? !!this.props.app.data.autoCheckout
                        : false,
                  })(<Switch />)}
                </Form.Item>

                <Form.Item label='機台優惠(分鐘)'>
                  {getFieldDecorator('data.apsDiscountMinutes', {
                    rules: [{ required: false, message: '機台優惠(分鐘)' }],
                    initialValue:
                      this.props.app && this.props.app.data
                        ? this.props.app.data.apsDiscountMinutes
                        : 0,
                  })(<InputNumber width={150} />)}
                </Form.Item>
              </div>
            ) : (
              <div></div>
            )}

            {['edge_face_detector'].includes(getFieldValue('type')) ? (
              <div>
                <Form.Item label='設備位置(IP)'>
                  {getFieldDecorator('data.ip', {
                    rules: [{ required: false, message: '請輸入IP位置' }],
                    initialValue:
                      this.props.app && this.props.app.data
                        ? this.props.app.data.ip
                        : null,
                  })(<Input placeholder='請輸入IP位置' />)}
                </Form.Item>

                <Form.Item label='請輸入裝置密碼'>
                  {getFieldDecorator('data.password', {
                    rules: [{ required: false, message: '請輸入裝置密碼' }],
                    initialValue:
                      this.props.app && this.props.app.data
                        ? this.props.app.data.password
                        : null,
                  })(<Input placeholder='請輸入裝置密碼' />)}
                </Form.Item>
              </div>
            ) : (
              <div></div>
            )}

            {this.props.app ? (
              <div>
                <Text>Token: {this.props.app.accessToken}</Text>
                <br></br>
                <Text>Key: {this.props.app.accessKey}</Text>
              </div>
            ) : (
              <div></div>
            )}
          </Form>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class Categorys extends React.Component {
  state = {
    drawer: {
      title: '',
      visible: false,
    },
  };

  fetchApps = async () => {
    let apps = await pos.app.fetchApps({
      pageSize: 100,
    });
    apps = appMapper.toView(apps);
    apps.forEach((app, idx) => {
      app.key = app.id;
      app.onEdit = this.onEditBtn(app);
      app.onDelete = this.onDeleteBtn(app);
    });

    this.setState({
      apps,
    });
  };

  onDrawerClose = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = false;
      return {
        drawer,
      };
    });
  };

  showDrawer = title => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = true;
      return {
        drawer,
      };
    });
  };

  onAddBtn = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '新增';
      return {
        drawer,
        current: null,
      };
    });

    this.showDrawer();
  };

  onEditBtn = app => () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '修改';
      return {
        drawer,
        current: app,
      };
    });

    this.showDrawer();
  };

  deleteApp = async app => {
    await pos.app.deleteApp(app.id);
  };

  onDeleteBtn = app => () => {
    let that = this;

    confirm({
      title: `刪除[${app.name}]?`,
      content: ``,
      okText: '確定',
      cancelText: '取消',
      onOk() {
        let hide = message.loading('處理中...');
        that
          .deleteApp(app)
          .then(() => {
            hide();
            message.info('刪除成功');
            that.setState(prev => {
              return {
                apps: prev.apps.filter(c => c !== app),
              };
            });
          })
          .catch(err => {
            hide();
            message.error('刪除失敗');
          });
      },
      onCancel() {},
    });
  };

  drawerActoins = {
    add: c => {
      this.setState(prev => {
        c.key = c.id;
        c.onEdit = this.onEditBtn(c);
        c.onDelete = this.onDeleteBtn(c);
        return {
          apps: [...prev.apps, c],
        };
      });
    },
    replace: c => {
      this.setState(prev => {
        let cs = prev.apps;
        let idx = -1;
        for (let i = 0; i < cs.length; i++) {
          if (cs[i].id === c.id) {
            idx = i;
            break;
          }
        }

        if (idx >= 0) {
          c.key = c.id;
          c.onEdit = this.onEditBtn(c);
          c.onDelete = this.onDeleteBtn(c);
          cs.splice(idx, 1, c);
          let next = [...cs];
          return {
            apps: next,
          };
        }

        return {};
      });
    },
  };

  componentDidMount() {
    this.fetchApps({
      pageSize: 200,
    });
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <Button type='link' icon='plus' onClick={this.onAddBtn}>
            新增應用
          </Button>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          {this.state.apps ? (
            <Table columns={columns} dataSource={this.state.apps} />
          ) : (
            <Table columns={columns} loading />
          )}
        </Content>

        <CategoryDrawer
          width={600}
          title={this.state.drawer.title}
          placement='right'
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawer.visible}
          app={this.state.current}
          actions={this.drawerActoins}
        ></CategoryDrawer>
      </Content>
    );
  }
}

export default Categorys;
