import React from 'react';
import {
  Layout,
  Table,
  Button,
  Icon,
  Typography,
  DatePicker,
  InputNumber,
  Switch,
} from 'antd';
import { Input, Select } from 'antd';
import Highlighter from 'react-highlight-words';

import convertCase from 'js-convert-case';

import pos from 'pos-api';
import { cashMapper } from '../../../utils/mapper';
import moment from 'moment';

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const columns = [
  {
    title: '時間戳記',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: 250,
    render: (text, record) => <span>{record.timestamp.toLocaleString()}</span>,
    sorter: (a, b) => a.timestamp.getTime() - b.timestamp.getTime(),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '序號',
    dataIndex: 'id',
    key: 'id',
    width: 150,
  },
  {
    title: '摘要',
    dataIndex: 'comment',
    key: 'comment',
    width: 250,
  },
  {
    title: '錢幣變動',
    children: [
      {
        title: '1元',
        dataIndex: 'c1',
        key: 'c1',
        width: 100,
      },
      {
        title: '5元',
        dataIndex: 'c5',
        key: 'c5',
        width: 100,
      },
      {
        title: '10元',
        dataIndex: 'c10',
        key: 'c10',
        width: 100,
      },
      {
        title: '50元',
        dataIndex: 'c50',
        key: 'c50',
        width: 100,
      },
      {
        title: '100元',
        dataIndex: 'c100',
        key: 'c100',
        width: 100,
      },
      {
        title: '500元',
        dataIndex: 'c500',
        key: 'c500',
        width: 100,
      },
      {
        title: '1000元',
        dataIndex: 'c1000',
        key: 'c1000',
        width: 100,
      },
    ],
  },
  {
    title: '錢幣結餘',
    children: [
      {
        title: '1元',
        dataIndex: 'e1',
        key: 'e1',
        width: 100,
      },
      {
        title: '5元',
        dataIndex: 'e5',
        key: 'e5',
        width: 100,
      },
      {
        title: '10元',
        dataIndex: 'e10',
        key: 'e10',
        width: 100,
      },
      {
        title: '50元',
        dataIndex: 'e50',
        key: 'e50',
        width: 100,
      },
      {
        title: '100元',
        dataIndex: 'e100',
        key: 'e100',
        width: 100,
      },
      {
        title: '500元',
        dataIndex: 'e500',
        key: 'e500',
        width: 100,
      },
      {
        title: '1000元',
        dataIndex: 'e1000',
        key: 'e1000',
        width: 100,
      },
    ],
  },
  {
    title: '異動',
    dataIndex: 'change_balance',
    key: 'change_balance',
    render: (text, record) => {
      let coinValues = [1, 5, 10, 50, 100, 500, 1000];
      let balance = 0;
      coinValues.forEach(v => {
        balance += v * record[`c${v}`];
      });

      return <span>{balance}</span>;
    },
    width: 150,
  },
  {
    title: '餘額',
    dataIndex: 'balance',
    key: 'balance',
    render: (text, record) => {
      let coinValues = [1, 5, 10, 50, 100, 500, 1000];
      let balance = 0;
      coinValues.forEach(v => {
        balance += v * record[`e${v}`];
      });

      return <span>{balance}</span>;
    },
    width: 150,
  },
];

const DEFAULT_PAGE_SIZE = 100;

class Cashs extends React.Component {
  constructor(props) {
    super(props);
    this.pageTimer = null;
  }

  state = {
    searchText: '',
    startTime: null,
    endTime: null,
    amounts: {
      salesAmount: 0,
      totalAmount: 0,
      taxAmount: 0,
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      current: 1,
    },
    loading: true,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      filters: filters,
      sorter: sorter,
    });

    this.fetchCashs({
      pagination: pager,
      sorter,
      filters,
    });

    this.fetchLastCash(this.state.appId);
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  fetchCashs = async opts => {
    this.setState({
      loading: true,
    });

    opts = opts || {
      pagination: this.state.pagination,
      sorter: {},
    };

    if (!opts.pagination) opts.pagination = this.state.pagination;
    if (!opts.sorter) opts.sorter = {};
    if (!opts.filters) opts.filters = {};
    if (!opts.startTime) opts.startTime = this.state.startTime;
    if (!opts.endTime) opts.endTime = this.state.endTime;
    if (!opts.appId) opts.appId = this.state.appId;
    if (opts.onlyProblem == null) opts.onlyProblem = this.state.onlyProblem;

    let { content, pagination } = await pos.cash.fetchCashs({
      pageSize: opts.pagination.pageSize,
      page: opts.pagination.current - 1,
      stime: opts.startTime,
      etime: opts.endTime,
      sortBy: opts.sorter.field
        ? convertCase.toSnakeCase(opts.sorter.field)
        : null,
      sortDirection: opts.sorter.order === 'descend' ? 'desc' : 'asc',
      appId: opts.appId,
      onlyProblem: opts.onlyProblem,
    });

    let invs = cashMapper.toView(content);
    invs.forEach((g, idx) => {
      g.key = g.id;
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      cashs: invs,
      loading: false,
      pagination: pager,
    });
  };

  fetchLastCash = async appId => {
    let cash = await pos.cash.fetchLastCash(appId);
    this.setState({
      currentCash: cash,
    });
  };

  onFooter = record => {
    let coinValues = [1, 5, 10, 50, 100, 500, 1000];
    let last = this.state.currentCash;
    if (!last) {
      return <div></div>;
    }
    let sum = 0;
    coinValues.forEach(v => {
      sum += v * last[`e${v}`];
    });

    return (
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <div style={{ fontWeight: 'bold' }}>
          當前餘額:
          {coinValues.map((v, idx) => (
            <span key={idx}>
              {v !== 1 ? <span>+</span> : <span></span>}
              <Text
                style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}
              >
                {v}元
              </Text>
              x
              <Text
                style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}
              >
                {last[`e${v}`]}
              </Text>
            </span>
          ))}
          =
          <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
            {sum}元
          </Text>
        </div>
      </div>
    );
  };

  handleTimeChange = (field, value) => {
    this.setState({
      [field]: value,
    });

    let timeRange = {
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };

    timeRange[field] = value.toDate();

    this.fetchCashs({
      pagination: this.state.pagination,
      filters: this.state.filters,
      sorter: this.state.sorter,
      ...timeRange,
    });

    this.fetchLastCash(this.state.appId);
  };

  handleAppChange = value => {
    this.setState({
      appId: value,
    });
    this.fetchCashs({
      ...this.state,
      appId: value,
    });

    this.fetchLastCash(value);
  };

  init = async () => {
    let apps = await pos.app.fetchApps();
    if (apps.length <= 0) {
      this.setState({
        loading: false,
      });
      return;
    }

    this.setState({
      apps: apps,
      appId: apps[0].id,
    });

    let appid = apps[0].id;
    this.fetchLastCash(this.state.appId);
    await this.fetchCashs({
      appId: appid,
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: 4,
              display: 'flex',
            }}
          >
            {this.state.apps ? (
              <div
                style={{
                  flex: 1,
                }}
              >
                應用:
                {
                  <Select
                    defaultValue={this.state.apps[0].id}
                    style={{ marginLeft: 12, width: 120 }}
                    onChange={this.handleAppChange}
                  >
                    {this.state.apps.map(app => (
                      <Option key={app.id} value={app.id}>
                        {app.name}
                      </Option>
                    ))}
                  </Select>
                }
              </div>
            ) : (
              <div></div>
            )}
            <div
              style={{
                flex: 4,
              }}
            >
              搜尋範圍:
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                placeholder='start'
                onChange={value => this.handleTimeChange('startTime', value)}
                style={{ marginRight: 12, marginLeft: 12 }}
              ></DatePicker>
              <DatePicker
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                placeholder='end'
                onChange={value => this.handleTimeChange('endTime', value)}
                style={{ marginRight: 12 }}
              ></DatePicker>
              濾出問題帳:
              <Switch
                onChange={value => {
                  this.setState({
                    onlyProblem: value,
                  });

                  this.fetchCashs({
                    ...this.state,
                    onlyProblem: value,
                  });
                }}
                style={{ marginLeft: 12 }}
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            單頁筆數:
            <InputNumber
              style={{ marginLeft: 12 }}
              min={1}
              defaultValue={DEFAULT_PAGE_SIZE}
              onChange={value => {
                if (this.state.pagination.pageSize === value) {
                  return;
                }

                // debounce
                if (this.pageTimer) {
                  clearTimeout(this.pageTimer);
                }

                this.pageTimer = setTimeout(() => {
                  let pager = {
                    ...this.state.pagination,
                  };
                  pager.pageSize = value;
                  pager.current = 1;
                  this.setState({
                    pagination: pager,
                  });

                  this.fetchCashs({
                    pagination: pager,
                  });
                }, 250);
              }}
            />
          </div>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.cashs}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            footer={this.onFooter}
            scroll={{ y: 520, x: 1000 }}
          />
        </Content>
      </Content>
    );
  }
}

export default Cashs;
