// import { Layout, Table, Divider, Button, message, Select, Typography, Modal, Avatar, DatePicker } from 'antd';
// import { Form, Input } from 'antd';
// import {generatePath} from 'react-router-dom';
// import {rentalUserMapper} from '../../../utils/mapper';
// import routes from '../../../Routes';
import {
  Avatar,
  Button,
  DatePicker,
  Input,
  Layout,
  Modal,
  Select,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import pos from 'pos-api';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import Viewer from 'react-viewer';
import { getOS } from '../../../utils/utils';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const { confirm } = Modal;

const columns = [
  {
    title: '進場時間',
    dataIndex: 'ctime',
    key: 'ctime',
    render: ctime => {
      if (!ctime) {
        return 'unknown';
      } else {
        return new Date(ctime).toLocaleString('zh-TW', { hour12: false });
      }
    },
  },
  {
    title: '出場時間',
    dataIndex: 'etime',
    key: 'etime',
    render: etime => {
      if (!etime) {
        return '尚未出場';
      } else {
        return new Date(etime).toLocaleString('zh-TW', { hour12: false });
      }
    },
  },
  {
    title: '審核狀態',
    dataIndex: 'mark',
    key: 'mark',
    render: mark => {
      switch (mark) {
        case 'N':
          return '未審核';
        case 'C':
          return '已審核';
        default:
          return '未審核';
      }
    },
  },
];

const ParkRecords = props => {
  const { searchText, startTime, endTime, searchStatus, sortBy, membership } =
    props;

  const [viewerVisiable, setViewerVisible] = useState(false);
  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 12,
    current: 1,
    total: 100,
  });

  // START 無痕模式
  const [deletable, setDeletable] = useState(false);

  // 要在初始化時執行
  const setDeletableFromSystem = async () => {
    const system = await pos.system.fetchSystem();
    const ENABLE = system?.PARKING_RECORD_DELETABLE?.ENABLE || false;
    setDeletable(ENABLE);
  };

  const handleDelete = async (item, event) => {
    try {
      if (deletable) {
        if (
          window.confirm(`車牌 ${item.name} 的該筆停車記錄，確認是否要刪除？`)
        ) {
          await pos.item.deleteItem(item);
          await fetchItems(pagination); // 接續更新頁面
        }
      } else {
        console.log('PARKING_RECORD_DELETABLE not available');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  // END 無痕模式

  const getColumns = () => {
    // 無痕模式
    const incognitoMode = {
      title: '無痕模式',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => {
        return (
          <div style={{ width: '100%' }}>
            <Button
              id={`record_${text.toString()}`}
              onClick={e => {
                handleDelete(record, e);
              }}
            >
              刪除
            </Button>
          </div>
        );
      },
    };

    // 額外的客製欄位
    let extraColumns = [];

    // 判斷是否要增加客製的欄位
    if (deletable) {
      extraColumns.push(incognitoMode);
    }

    return [
      {
        title: '入場圖片',
        dataIndex: 'cover',
        key: 'cover',
        render: (text, record) => {
          let img;

          if (record.picture) {
            img = `${record.picture.base_url}/rw320.jpg?_=${record.picture.mtime}`;
          }

          const onImgClick = () => {
            let img = `${record.picture.base_url}/origin.jpg?_=${record.picture.mtime}`;
            setImages([{ src: img, alt: '' }]);
            setViewerVisible(true);
          };

          if (img) {
            return (
              <div
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={onImgClick}
              >
                <img
                  src={img}
                  alt='user-cover'
                  style={{
                    height: 40,
                    borderRadius: 4,
                    marginLeft: 24,
                    display: 'block',
                  }}
                ></img>
              </div>
            );
          }

          return (
            <div style={{ width: '100%' }}>
              <Avatar
                shape='square'
                style={{
                  marginLeft: 24,
                  width: 40,
                  height: 40,
                  backgroundColor: '#48D049',
                  fontSize: 16,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {record.name[0] + ''}
              </Avatar>
            </div>
          );
        },
      },
      {
        title: '出場圖片',
        dataIndex: 'exits',
        key: 'events',
        render: (text, record) => {
          let { events } = record;

          let exitEvent =
            events && events.find(event => event.name === 'park_exit');

          if (!exitEvent || !exitEvent.image_uploaded) {
            return '無出場圖片';
          }

          let img = `/api/v1/item/${record.id}/event/${exitEvent.id}/image/rw320.jpg`;

          const onImgClick = () => {
            let img = `/api/v1/item/${record.id}/event/${exitEvent.id}/image/origin.jpg`;
            setImages([{ src: img, alt: '' }]);
            setViewerVisible(true);
          };

          if (img) {
            return (
              <div
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={onImgClick}
              >
                <img
                  src={img}
                  alt='user-cover'
                  style={{
                    height: 40,
                    borderRadius: 4,
                    marginLeft: 24,
                    display: 'block',
                  }}
                ></img>
              </div>
            );
          }

          return (
            <div style={{ width: '100%' }}>
              <Avatar
                shape='square'
                style={{
                  marginLeft: 24,
                  width: 40,
                  height: 40,
                  backgroundColor: '#48D049',
                  fontSize: 16,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {record.name[0] + ''}
              </Avatar>
            </div>
          );
        },
      },
      {
        title: '車牌',
        dataIndex: 'name',
        key: 'name',
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText || '']}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      },
      ...columns,
      ...extraColumns,
    ];
  };

  const fetchItems = async pagination => {
    setLoading(true);

    let opts = {
      sortDirection: 'desc',
      pageSize: pagination.pageSize,
      page: pagination.current - 1,
      type: 'park_service',
      stime: startTime ? startTime.toISOString() : null,
      etime: endTime ? endTime.toISOString() : null,
      sortBy: sortBy,
      ...membership,
    };
    // membership {
    //   membershipType?: 'rental' | 'one_time',
    //   isStrictlyRental?: boolean,
    //   rentalServiceId?: number,
    // }

    if (searchText && searchText !== '') {
      opts.searchText = searchText;
      opts.searchBy = 'name';
    }

    if (searchStatus !== 'all') {
      opts.status = searchStatus;
    }

    let resp = await pos.item.fetchItems(opts);

    let items = resp.content;

    setItems(items);

    let nextPagination = {
      ...pagination,
    };

    nextPagination.total = resp.pagination.total_elements;
    setPagination(nextPagination);

    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchItems(pagination);
  };

  const init = async pagination => {
    fetchItems(pagination);
    await setDeletableFromSystem();
  };

  useEffect(() => {
    init(pagination);
  }, []);

  useEffect(() => {
    let nextPagination = {
      ...pagination,
    };

    nextPagination.current = 1;

    fetchItems(nextPagination);
  }, [searchText, startTime, endTime, searchStatus, sortBy, membership]);

  return (
    <div>
      <Viewer
        visible={viewerVisiable}
        onClose={() => {
          setViewerVisible(false);
        }}
        images={images}
      />

      <Table
        loading={loading}
        columns={getColumns()}
        dataSource={items}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

const Page = props => {
  const [searchText, setSearchText] = useState('');
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [searchStatus, setSearchStatus] = useState('all');
  const [exportCsv, setExportCsv] = useState(false);
  const [sortBy, setSortBy] = useState();
  const searchTimeoutRef = useRef();

  const onSearch = text => {
    clearTimeout(searchTimeoutRef.current);

    searchTimeoutRef.current = setTimeout(() => {
      setSearchText(text);
    }, 250);
  };

  const onSearchStatusChange = status => {
    setSearchStatus(status);
  };

  const onSortByChange = sortBy => {
    setSortBy(sortBy);
  };

  const onSearchTimeChange = (type, time) => {
    let d = null;

    if (time) {
      d = time.toDate();
    }

    if (type === 'startTime') {
      setStartTime(d);
    } else {
      setEndTime(d);
    }
  };

  const download = (filename, text) => {
    //text = text.replace(/\n/g, '\r\n');
    var element = document.createElement('a');

    if (getOS() === 'Windows') {
      let blob = new Blob(['\ufeff', text], { type: 'text/csv;charset=utf-8' });
      element.setAttribute('href', window.URL.createObjectURL(blob));
    } else {
      element.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
      );
    }

    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const exportParkRecords = async () => {
    try {
      setExportCsv(true);
      //set opts
      const opts = {
        sortDirection: 'desc',
        pageSize: 10,
        page: 0,
        type: 'park_service',
        stime: startTime ? startTime.toISOString() : null,
        etime: endTime ? endTime.toISOString() : null,
        ...membership,
      };

      if (searchText && searchText !== '') {
        opts.searchText = searchText;
        opts.searchBy = 'name';
      }

      if (searchStatus !== 'all') {
        opts.status = searchStatus;
      }

      //sync park records
      const { pagination: syncPagination } = await pos.item.fetchItems(opts);

      const totalElements = syncPagination.total_elements;
      const pageSize = 5000;
      const totalPages = Math.floor(totalElements / pageSize + 1);
      const pagesToFetch = Array.from(Array(totalPages).keys()); // [0,1,2,3,4] if totalPages is 5

      //fetch
      const fetched = await Promise.all(
        pagesToFetch.map(page =>
          pos.item.fetchItems({
            ...opts,
            pageSize,
            page,
          })
        )
      );

      const columns = '車牌,進場時間,出場時間,審核狀態\n';
      const result = fetched.reduce((data, currentResolve) => {
        currentResolve.content.forEach(item => {
          let { name, ctime, etime, mark } = item;
          ctime = !ctime
            ? 'unknown'
            : moment(ctime).format('YYYY-MM-DD HH:mm:ss');
          etime = !etime
            ? '尚未出場'
            : moment(etime).format('YYYY-MM-DD HH:mm:ss');
          mark = mark === 'C' ? '已審核' : '未審核';
          //車牌(name)若為純數字，Excel會視作數字忽略開頭0（"08861" -> 8861）
          //加入"="" """避免此問題（參考 https://github.com/react-csv/react-csv/issues/128）
          data += `"=""${name}""",${ctime},${etime},${mark}\n`;
        });
        return data;
      }, columns);

      download('停車紀錄.csv', result);
    } catch (error) {
      console.log(error);
    } finally {
      setExportCsv(false);
    }
  };

  // START 身份別
  const [parkingOption, setParkingOption] = useState(null);
  const onParkingOptionsChange = parkingType => {
    const parkingValue = parseInt(parkingType);
    setParkingOption(parkingValue);
  };
  const [rentals, setRentals] = useState(null);
  const additionalOptions = [
    { id: -1, name: '臨停' },
    { id: -2, name: '月租' },
  ];
  useEffect(() => {
    const fetchRentals = async () => {
      try {
        const response = await pos.service.fetchServices({
          page: 0,
          page_size: 1000,
          type: 'park_rental',
        });
        setRentals(response.content);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchRentals();
  }, []);

  const [membership, setMembership] = useState({});
  useEffect(() => {
    if (isNaN(parkingOption)) {
      setMembership({});
    }
    if (parkingOption > 0) {
      setMembership({
        membershipType: 'rental',
        //isStrictlyRental: true,
        rentalServiceId: parkingOption,
      });
    }
    if (parkingOption === -1) {
      setMembership({
        membershipType: 'one_time',
        //isStrictlyRental: true,
      });
    }
    if (parkingOption === -2) {
      setMembership({
        membershipType: 'rental',
        //isStrictlyRental: true,
      });
    }
  }, [parkingOption]);
  // END 身份別

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 80,
        }}
      >
        <div
          style={{
            flex: 4,
          }}
        >
          搜尋範圍:
          <DatePicker
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            placeholder='start'
            onChange={value => onSearchTimeChange('startTime', value)}
            style={{ marginRight: 12, marginLeft: 12 }}
          ></DatePicker>
          <DatePicker
            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
            placeholder='end'
            onChange={value => onSearchTimeChange('endTime', value)}
            style={{ marginRight: 12 }}
          ></DatePicker>
          車輛狀態:
          <Select
            defaultValue='all'
            onChange={onSearchStatusChange}
            style={{ width: 180, marginLeft: 12, marginRight: 12 }}
          >
            <Option value='all'>全部</Option>
            <Option value='free'>免費</Option>
            <Option value='serving'>未付款</Option>
            <Option value='checkouted'>已付款</Option>
            <Option value='confirm'>已出場</Option>
            <Option value='no_pay_exit'>未繳費離場</Option>
            <Option value='archived'>封存</Option>
          </Select>
          排序:
          <Select
            defaultValue='id'
            onChange={onSortByChange}
            style={{ width: 180, marginLeft: 12, marginRight: 12 }}
          >
            <Option value='id'>預設</Option>
            <Option value='etime'>出場時間</Option>
          </Select>
          身份別:
          <Select
            defaultValue=''
            onChange={onParkingOptionsChange}
            style={{ width: 180, marginLeft: 12, marginRight: 12 }}
          >
            <Option value=''>全部</Option>
            {additionalOptions.map(option => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
            {rentals &&
              rentals.map(option => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
          </Select>
          <Button
            disabled={exportCsv}
            onClick={() => {
              exportParkRecords();
            }}
            style={{ marginLeft: 12 }}
          >
            匯出CSV
          </Button>
          <div style={{ float: 'right' }}>
            <Search
              placeholder={'請輸入車牌號碼'}
              onSearch={() => {}}
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                onSearch(value);
              }}
              style={{ width: 200, float: 'right', marginLeft: 14 }}
            />
          </div>
        </div>
      </Content>

      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        <ParkRecords
          startTime={startTime}
          endTime={endTime}
          searchText={searchText}
          searchStatus={searchStatus}
          sortBy={sortBy}
          membership={membership}
        />
      </Content>
    </Content>
  );
};

export default Page;
