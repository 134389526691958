import React from 'react';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Icon,
  Typography,
} from 'antd';
import { Drawer, Form, Input, Select } from 'antd';

import uuid from 'uuid/v4';

import pos from 'pos-api';
import { ingredientMapper } from '../../utils/mapper';

import './Ingredient.less';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;

const columns = [
  {
    title: '配料名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '配料簡稱',
    dataIndex: 'abbreviation',
    key: 'abbreviation',
  },
  {
    title: '配料內容',
    dataIndex: 'options',
    key: 'options',
    render: (text, record) => (
      <div>
        {record.options.content.map((opt, idx) => (
          <span style={{ marginRight: 8 }} key={idx}>
            <Icon type={opt.value ? 'check-square' : 'minus-square'}></Icon>
            <Text>{opt.name}</Text>
          </span>
        ))}
      </div>
    ),
  },
  {
    title: '操作',
    key: 'action',
    render: (text, record) => (
      <span>
        <Button type='link' icon='edit' onClick={record.onEdit}></Button>
        <Divider type='vertical' />
        <Button type='link' icon='delete' onClick={record.onDelete}></Button>
      </span>
    ),
  },
];

class Options extends React.Component {
  constructor(props) {
    super(props);
    if (props.ingredient) {
      let opts = props.ingredient.options.content;
      if (opts && opts.length > 0) {
        this.ikeys = opts.map(opt => uuid());
        for (let i = 0; i < opts.length; i++) {
          if (opts[i].value) {
            this.state.defaultOption = this.ikeys[i];
            break;
          }
        }
      }
    }
  }

  state = {
    ingredient: this.props.ingredient,
  };

  componentWillReceiveProps(props) {
    const { getFieldValue } = props.form;
    const keys = getFieldValue('keys');
    if (
      props.ingredient &&
      this.props.ingredient !== props.ingredient &&
      keys
    ) {
      let opts = props.ingredient.options.content;
      if (opts && opts.length > 0) {
        const { setFieldsValue } = props.form;
        let inkeys = [];
        let inopts = {};

        for (let i = 0; i < opts.length; i++) {
          let k = uuid();
          inkeys.push(k);
          inopts[k] = opts[i].name;
        }

        let defaultOption;
        for (let i = 0; i < opts.length; i++) {
          if (opts[i].value) {
            defaultOption = inkeys[i];
            break;
          }
        }

        this.setState({
          ingredient: props.ingredient,
        });

        setFieldsValue({
          keys: inkeys,
          options: inopts,
          defaultOption: defaultOption,
        });
      }
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } =
      this.props.form;
    getFieldDecorator('keys', { initialValue: this.ikeys || [] });
    const keys = getFieldValue('keys');
    return (
      <Form.Item label='選項'>
        {keys.map((key, idx) => (
          <div key={idx}>
            {getFieldDecorator(`options.${key}`, {
              rules: [],
              initialValue:
                this.state.ingredient &&
                this.state.ingredient.options.content[idx]
                  ? this.state.ingredient.options.content[idx].name || ''
                  : '',
            })(
              <Input
                placeholder='Please enter option name'
                style={{ width: 160, marginRight: 8 }}
              />
            )}

            {
              <Icon
                className='dynamic-delete-button'
                type='minus-circle-o'
                onClick={() => {
                  const keys = getFieldValue('keys');
                  setFieldsValue({
                    keys: keys.filter(k => key !== k),
                  });
                }}
              />
            }
          </div>
        ))}

        <Button
          icon='plus'
          type='dashed'
          style={{ width: '60%' }}
          onClick={() => {
            const keys = getFieldValue('keys');
            let next = [...keys, uuid()];
            setFieldsValue({
              keys: next,
            });
          }}
        ></Button>

        <Form.Item label='預設選項'>
          {getFieldDecorator(`defaultOption`, {
            rules: [],
            initialValue: this.state.defaultOption,
          })(
            <Select>
              {getFieldValue('keys').map((k, idx) => (
                <Option key={idx} value={k}>
                  {getFieldValue('options')[k]}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form.Item>
    );
  }
}

const IngredientDrawer = Form.create()(
  class extends React.Component {
    mapToIng = values => {
      let ing = {};
      ing.name = values.name;
      ing.abbreviation = values.abbreviation || values.name;
      ing.options = {
        type: 'radio',
      };
      let optsContent = values.keys.map(k => {
        return {
          name: values.options[k],
          value: k === values.defaultOption,
        };
      });
      ing.options.content = optsContent;
      return ing;
    };

    upload = async values => {
      let ing = this.mapToIng(values);
      if (this.props.ingredient) {
        ing.id = this.props.ingredient.id;
        ing = ingredientMapper.toEntity(ing);
        ing = await pos.ingredient.updateIngredient(ing);
      } else {
        ing = ingredientMapper.toEntity(ing);
        ing = await pos.ingredient.addIngredient(ing);
      }

      return ing;
    };

    componentWillReceiveProps(props) {
      const { setFieldsValue } = this.props.form;
      if (props.ingredient && this.props.ingredient !== props.ingredient) {
        setFieldsValue({
          name: props.ingredient.name,
          abbreviation: props.ingredient.abbreviation || props.ingredient.name,
        });
      }
    }

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(ing => {
              hide();
              message.info('上傳成功');
              if (this.props.ingredient) {
                if (this.props.actions && this.props.actions.replace) {
                  this.props.actions.replace(ingredientMapper.toView(ing));
                }
              } else {
                if (this.props.actions && this.props.actions.add) {
                  this.props.actions.add(ingredientMapper.toView(ing));
                }
              }

              if (this.props.onClose) {
                this.props.onClose();
              }
            })
            .catch(err => {
              hide();
              message.error('上傳失敗');
              if (this.props.onClose) {
                this.props.onClose();
              }
            });
        } else {
          console.log(err);
        }
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;

      return (
        <Drawer {...this.props}>
          <Form>
            <Form.Item label='名稱'>
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Please enter category name' },
                ],
                initialValue: this.props.ingredient
                  ? this.props.ingredient.name
                  : null,
              })(<Input placeholder='Please enter ingredient name' />)}
            </Form.Item>

            <Form.Item label='簡稱'>
              {getFieldDecorator('abbreviation', {
                rules: [],
                initialValue: this.props.ingredient
                  ? this.props.ingredient.abbreviation ||
                    this.props.ingredient.name
                  : null,
              })(<Input placeholder='Please enter ingredient abbreviation' />)}
            </Form.Item>

            <Options
              form={this.props.form}
              ingredient={this.props.ingredient}
            ></Options>
          </Form>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class Ingredients extends React.Component {
  state = {
    drawer: {
      title: '',
      visible: false,
    },
    pagination: {
      pageSize: 8,
      current: 1,
    },
    loading: true,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchIngredients({
      pagination: pager,
    });
  };

  fetchIngredients = async opts => {
    this.setState({
      loading: true,
    });
    opts = opts || {
      pagination: this.state.pagination,
    };

    let { content, pagination } = await pos.ingredient.fetchIngredients({
      pageSize: opts.pagination.pageSize,
      page: opts.pagination.current - 1,
    });

    let ings = content;
    ings = ingredientMapper.toView(ings);
    ings.forEach((g, idx) => {
      g.key = g.id;
      g.onEdit = this.onEditBtn(g);
      g.onDelete = this.onDeleteBtn(g);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      ingredients: ings,
      pagination: pager,
      loading: false,
    });
  };

  onDrawerClose = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = false;
      return {
        drawer,
      };
    });
  };

  showDrawer = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = true;
      return {
        drawer,
      };
    });
  };

  onAddBtn = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '新增';
      return {
        drawer,
        current: null,
      };
    });

    this.showDrawer();
  };

  onEditBtn = ingredient => () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '修改';
      return {
        drawer,
        current: ingredient,
      };
    });

    this.showDrawer();
  };

  deleteIngredient = async ingredient => {
    await pos.ingredient.deleteIngredient(ingredient.id);
  };

  onDeleteBtn = ingredient => () => {
    let hide = message.loading('處理中...');
    this.deleteIngredient(ingredient)
      .then(() => {
        hide();
        message.info('刪除成功');
        this.setState(prev => {
          return {
            ingredients: prev.ingredients.filter(ing => ing !== ingredient),
          };
        });
      })
      .catch(err => {
        hide();
        message.error('刪除失敗');
      });
  };

  drawerActoins = {
    add: ing => {
      this.setState(prev => {
        ing.key = ing.id;
        ing.onEdit = this.onEditBtn(ing);
        ing.onDelete = this.onDeleteBtn(ing);
        return {
          ingredients: [...prev.ingredients, ing],
        };
      });
    },
    replace: ing => {
      this.setState(prev => {
        let ings = prev.ingredients;
        let idx = -1;
        for (let i = 0; i < ings.length; i++) {
          if (ings[i].id === ing.id) {
            idx = i;
            break;
          }
        }

        if (idx >= 0) {
          ing.key = ing.id;
          ing.onEdit = this.onEditBtn(ing);
          ing.onDelete = this.onDeleteBtn(ing);
          ings.splice(idx, 1, ing);
          let next = [...ings];
          return {
            ingredients: next,
          };
        }

        return {};
      });
    },
  };

  componentDidMount() {
    this.fetchIngredients();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <Button type='link' icon='plus' onClick={this.onAddBtn}>
            新增配料
          </Button>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.ingredients}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>

        <IngredientDrawer
          width={600}
          title={this.state.drawer.title}
          placement='right'
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawer.visible}
          ingredient={this.state.current}
          actions={this.drawerActoins}
        ></IngredientDrawer>
      </Content>
    );
  }
}

export default Ingredients;
