import React from 'react';
import {
  Layout,
  Form,
  Input,
  Spin,
  Button,
  message,
  Typography,
  Checkbox,
} from 'antd';

import ImageUploader from '../../../component/ImageUploader';

import { systemInfoMapper } from '../../../utils/mapper';

import * as util from '../../../utils/utils';

import pos from 'pos-api';

const { Content } = Layout;
const { Title } = Typography;

const InfoForm = Form.create()(
  class extends React.Component {
    state = {
      loaded: false,
      logoImage: null,
      logoImageChange: false,
    };

    init = async () => {
      console.log(this.props);
      let invoice = await pos.system.fetchSystemField('invoice');
      invoice = systemInfoMapper.toView(invoice);

      let logoImage;
      if (invoice && invoice.logoImage) {
        logoImage = `${process.env.PUBLIC_URL}/api/v1/image/${invoice.logoImage}/origin.jpg?_=${invoice.logoImageUpdateTime}`;
      }

      this.setState({
        loaded: true,
        invoice,
        logoImage: logoImage,
      });
    };

    upload = async values => {
      let id;

      if (this.state.logoImageChange) {
        let blob = util.dataURItoBlob(values.logoImage);
        let file = new File([blob], 'image.png');

        if (this.state.invoice && this.state.invoice.logoImage) {
          id = Number(this.state.invoice.logoImage);
          await pos.image.updateImage(id, file, { resizeMethod: 'fill' });
        } else {
          let im = await pos.image.uploadImage(file, { resizeMethod: 'fill' });

          id = im.id;
        }

        values['logoImageUpdateTime'] = new Date();
        values['logoImage'] = id;
      } else {
        if (this.state.invoice && this.state.invoice.logoImage) {
          values.logoImage = Number(this.state.invoice.logoImage);
        } else {
          delete values.logoImage;
        }
      }

      let body = systemInfoMapper.toEntity(values);

      await pos.system.updateSystemField('invoice', body);
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(c => {
              hide();
              message.info('成功');
            })
            .catch(err => {
              hide();
              message.error('失敗');
            });
        } else {
          message.error(err);
        }
      });
    };

    componentDidMount() {
      this.init();
    }

    changeLogoImage(url) {
      this.setState({
        logoImage: url,
        logoImageChange: true,
      });
    }

    render() {
      const { getFieldDecorator, setFieldsValue } = this.props.form;

      if (!this.state.loaded) {
        return <Spin></Spin>;
      }

      const disabled = !this.props.privilege.includes('u');

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      };

      return (
        <Form>
          <Form.Item label='Logo名稱'>
            {getFieldDecorator('logoName', {
              rules: [{ required: false, message: 'Please enter logo name' }],
              initialValue: this.state.invoice
                ? this.state.invoice.logoName
                : '',
            })(
              <Input placeholder='Please enter logo name' disabled={disabled} />
            )}
          </Form.Item>

          <div>
            <Form.Item htmlFor='logoImage' {...formItemLayout} label='Logo圖片'>
              {getFieldDecorator('logoImage', {
                rules: [],
              })(
                <ImageUploader
                  height={100}
                  borderWidth={'auto'}
                  cropperEnable={false}
                  url={this.state.logoImage}
                  onChange={url => {
                    setFieldsValue({
                      logoImage: url,
                    });

                    if (this.changeLogoImage) {
                      this.changeLogoImage(url);
                    }
                  }}
                ></ImageUploader>
              )}
            </Form.Item>
          </div>

          <Form.Item>
            {getFieldDecorator('isUseLogoImage', {
              valuePropName: 'checked',
              initialValue: this.state.invoice
                ? this.state.invoice.isUseLogoImage
                : false,
            })(<Checkbox>使用logo圖片</Checkbox>)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('enable', {
              valuePropName: 'checked',
              initialValue: this.state.invoice
                ? this.state.invoice.enable
                : false,
            })(<Checkbox>開立發票</Checkbox>)}
          </Form.Item>

          <div
            style={{
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
            }}
          >
            {disabled ? (
              <div></div>
            ) : (
              <Button type='primary' onClick={this.onSubmit}>
                確認
              </Button>
            )}
          </div>
        </Form>
      );
    }
  }
);

const Info = props => {
  return (
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
        minHeight: 280,
      }}
    >
      <InfoForm privilege={props.userRoutePrivilege}></InfoForm>
    </Content>
  );
};

export default Info;
