export default {
  overview: {},
  items: {},
  orderItems: {},
  category: {},
  ingredients: {},
  user: {},
  app: {},
  service: {},
  member: {},
  carWithDiscount: {},
  manage: {
    children: {
      car: {},
      rentalUsers: {},
      carMonitor: {},
    },
  },
  report: {
    children: {
      invoice: {},
      order: {},
      cash: {},
    },
  },
  store: {},
  system: {
    children: {
      log: {},
      calendar: {},
      info: {},
      invoice: {},
    },
  },

  // below are not display in dashboard menu

  itemForm: {},
  serviceForm: {},
  rentalUserForm: {},
};
