/* eslint-disable jsx-a11y/anchor-is-valid */
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Icon,
  Input,
  Layout,
  List,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  TimePicker,
  Typography,
  Upload,
  message,
} from 'antd';
import moment from 'moment';
import pos from 'pos-api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import Viewer from 'react-viewer';
import { itemMapper } from '../../../utils/mapper';
import stomp from '../../../utils/stomp';
import * as utils from '../../../utils/utils';
import { getOS } from '../../../utils/utils';

const { Content, Sider } = Layout;
const { Paragraph } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

const carMarkMap = {
  N: '待審核',
  C: '已審核',
};

/**
 * 檢查有沒有rentalInfo或是有rentalUsers, 且rentalUsers長度不為0,
 * 其中一項符合表示當前或是月租戶或是過期月租戶(有月租紀錄)
 * */
function checkRecordedRentalUser(car) {
  const hasRentalInfo = !!car.rentalInfo;
  const hasRentalUsers =
    Array.isArray(car.rentalUsers) && car.rentalUsers.length > 0;
  return hasRentalInfo || hasRentalUsers;
}

/**
 * 給定起始跟結束時間, 判斷當下是否在這個範圍內
 */
function isNowInRange(start, end) {
  if (!start || !end) return false;
  const now = new Date();
  const startDateTime = new Date(start);
  const endDateTime = new Date(end);
  return now >= startDateTime && now <= endDateTime;
}

/**
 * 檢查rentalInfo跟rentalUsers是否有一項沒過期, 如果都過期或是根本就沒有月租紀錄的話會回傳false
 */
function checkRentalRecordInRange(car) {
  const rentalInfoInRange = isNowInRange(
    car.rentalInfo?.start_time,
    car.rentalInfo?.end_time
  );
  const rentalUsersDataInRange = !!car.rentalUsers?.some(user =>
    isNowInRange(user?.stime, user?.etime)
  );
  return rentalInfoInRange || rentalUsersDataInRange;
}

const CarAvater = props => {
  let url320, url640, url960;
  if (props.image) {
    url320 = props.image;
    url640 = props.image.replace(/rw\d{3}/g, 'rw640');
    url960 = props.image.replace(/rw\d{3}/g, 'rw960');
  }
  return (
    <div {...props} style={{ cursor: 'pointer', width: '100%' }}>
      {props.image ? (
        <picture>
          <source media='(min-width: 1440px)' srcSet={url960} />
          <source media='(min-width: 1000px)' srcSet={url640} />
          <source srcSet={url320} />
          <img
            src={url320}
            alt='car'
            style={{ width: '100%', height: '100%', borderRadius: 6 }}
          />
        </picture>
      ) : (
        <Avatar
          shape='square'
          style={{
            width: 320,
            height: 240,
            margin: '0 auto',
            backgroundColor: '#48D049',
            fontSize: 52,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.name}
        </Avatar>
      )}
    </div>
  );
};

const ChargeCalculator = Form.create()(props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const { car } = props;

  const [carId, setCarId] = useState(props.car.id);

  const [amounts, setAmounts] = useState({
    amount: 0,
    discount: 0,
    total_amount: 0,
  });

  useEffect(() => {
    if (props.car && carId !== props.car.id) {
      setCarId(props.car.id);
      setFieldsValue({
        startTime: car.createTime ? moment(car.createTime) : moment(),
        plate: car.name,
        endTime: moment(),
      });
    }
  }, [car]);

  const calc = async endTime => {
    return await pos.item.fetchItem(car.id, {
      test: true,
      testParkEndTime: endTime.toISOString(),
    });
  };

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        calc(values.endTime.toDate())
          .then(c => {
            console.log(c);
            setAmounts(c.price);
          })
          .catch(err => {
            message.error('失敗');
          });
      } else {
        console.log(err);
      }
    });
  };

  return (
    <div>
      <Form>
        <Form.Item label='車牌'>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '請輸入車牌號碼' }],
            initialValue: props.car ? props.car.name : null,
            value: props.car ? props.car.name : null,
          })(
            <Input placeholder='請輸入車牌號碼' size='large' disabled={true} />
          )}
        </Form.Item>

        <Form.Item label='入場時間'>
          {getFieldDecorator('startTime', {
            rules: [{ required: true, message: '請輸入進場時間' }],
            initialValue: props.car
              ? moment(props.car.createTime ? props.car.createTime : new Date())
              : moment(),
            value: props.car
              ? moment(props.car.createTime ? props.car.createTime : new Date())
              : moment(),
          })(
            <DatePicker
              showTime={true}
              format={'YYYY-MM-DD HH:mm:ss'}
              size='large'
              disabled={true}
            ></DatePicker>
          )}
        </Form.Item>

        <Form.Item label='出場時間'>
          {getFieldDecorator('endTime', {
            rules: [{ required: true, message: '請輸入出場時間' }],
            initialValue: props.car
              ? moment(props.car.endTime ? props.car.endTime : new Date())
              : moment(),
          })(
            <DatePicker
              showTime={true}
              format={'YYYY-MM-DD HH:mm:ss'}
              size='large'
              disabled={false}
            ></DatePicker>
          )}
        </Form.Item>

        <Typography>試算結果:</Typography>

        <Typography>總金額: {amounts.total_amount}</Typography>
        <Typography>折扣額: {amounts.discount}</Typography>
        <Typography>收費額: {amounts.amount}</Typography>
      </Form>

      <div>
        <Button type='primary' onClick={onSubmit} size='large'>
          開始試算
        </Button>
      </div>
    </div>
  );
});

const CarField = props => {
  const [viewerVisible, setViewerVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [rentalInfo, setRentalInfo] = useState('臨停');

  const [showCalcModal, setShowCalcModal] = useState(false);

  useEffect(() => {
    let { car } = props;

    async function initCarInfo(car) {
      if (!car) return;

      let content = await pos.item.fetchItem(car.id, {
        calc: false,
        cache: true,
      });

      if (!content) return;

      let carInfo = itemMapper.toView(content, {
        imageType: 'rw320',
        autoSample: false,
      });

      if (carInfo.image) {
        setImages([{ src: `${carInfo.baseImageUrl}/origin.jpg`, alt: '' }]);
      } else {
        setImages([]);
      }

      const hasRentalRecord = checkRecordedRentalUser(car);
      const isRentalInRange = checkRentalRecordInRange(car);

      // 月租戶, 過期月租戶與臨停
      const currentRantalUser = hasRentalRecord && isRentalInRange;
      const outdatedRentalUser = hasRentalRecord && !isRentalInRange;
      const notRentalUser = !hasRentalRecord;

      if (currentRantalUser) setRentalInfo(carInfo.rentalInfo?.name ?? '未知');
      else if (outdatedRentalUser)
        setRentalInfo(`${carInfo.rentalInfo?.name ?? '未知'}(月租已過期)`);
      else setRentalInfo('臨停');
    }

    initCarInfo(car);
  }, [props.car]);

  if (!props.car) return <div></div>;

  const confirmCar = async car => {
    let nextCar = {
      ...car,
    };

    nextCar.mark = 'C';
    delete nextCar.status;

    await pos.item.updateItem(nextCar);

    if (props.refresh) {
      props.refresh();
    }
  };

  return (
    <>
      <Row
        type='flex'
        align='middle'
        style={{ height: '100%' }}
        gutter={[20, 0]}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <div
            style={{
              fontSize: 26,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CarAvater
              name={props.car.name}
              image={props.car.image}
              onClick={() => {
                if (props.car.image) {
                  setViewerVisible(true);
                }
              }}
            ></CarAvater>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <div
            style={{
              fontSize: 26,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Paragraph style={{ marginTop: 22 }}>
              車牌號碼: {props.car.name}
            </Paragraph>

            <Paragraph>
              入場時間:{' '}
              {moment(props.car.createTime).format('YYYY-MM-DD HH:mm:ss')}
            </Paragraph>
            <Paragraph>
              繳費時間:{' '}
              {props.car.paymentTime
                ? moment(props.car.paymentTime).format('YYYY-MM-DD HH:mm:ss')
                : '未繳費'}
            </Paragraph>

            <Paragraph>身分: {rentalInfo}</Paragraph>

            {props.car.carTowerNumber && (
              <Paragraph>
                車塔: {props.car.carTowerNumber.substring(0, 2)}, 座席:{' '}
                {props.car.carTowerNumber.substring(2)}
              </Paragraph>
            )}

            <Paragraph style={{ color: '#cf1322' }}>
              繳費狀態:{' '}
              {props.car.status ? statusMap[props.car.status] : '未審核'}
            </Paragraph>

            {props.car.discounts && props.car.discounts.length > 0 ? (
              <Paragraph style={{ color: '#cf1322' }}>
                折扣:
                {props.car.discounts.map(d => d.name).join(',')}
              </Paragraph>
            ) : (
              <div></div>
            )}

            <Paragraph style={{ color: '#cf1322' }}>
              <span>
                審核狀態:{' '}
                {props.car.mark ? carMarkMap[props.car.mark] : '未審核'}
              </span>

              {props.car.mark !== 'C' ? (
                <span style={{ marginLeft: 12 }}>
                  <Button
                    type='primary'
                    shape='circle'
                    icon='check'
                    onClick={() => {
                      let car = itemMapper.toEntity(props.car);

                      confirmCar(car);
                    }}
                  />
                </span>
              ) : (
                <span></span>
              )}
            </Paragraph>

            <Paragraph>
              <Button
                onClick={() => {
                  setShowCalcModal(true);
                }}
                size='large'
              >
                試算
              </Button>
            </Paragraph>
          </div>
        </Col>
      </Row>

      <Viewer
        visible={viewerVisible}
        onClose={() => {
          setViewerVisible(false);
        }}
        images={images}
      />

      {props.car ? (
        <Modal
          title='試算'
          visible={showCalcModal}
          onOk={() => {
            setShowCalcModal(false);
          }}
          onCancel={() => {
            setShowCalcModal(false);
          }}
          okText='確定'
          cancelText='取消'
        >
          <ChargeCalculator car={props.car} />
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
};

const useToolBarState = () => {
  const [searchText, setSearchText] = useState('');

  return {
    setSearchText,
    searchText,
  };
};

const ToolBar = props => {
  const { setSearchText, searchText } = useToolBarState();

  const externalOnSearchChange = utils.doWhenExist(props.onSearchChange);
  const onEdit = utils.doWhenExist(props.onEdit);
  const onAdd = utils.doWhenExist(props.onAdd);
  const onDelete = utils.doWhenExist(props.onDelete);
  const onUnknownNotifitionChange = utils.doWhenExist(
    props.onUnknownNotifitionChange
  );

  useEffect(() => {
    externalOnSearchChange(searchText);
  }, [searchText]);

  const download = (filename, text) => {
    //text = text.replace(/\n/g, '\r\n');
    var element = document.createElement('a');

    if (getOS() === 'Windows') {
      let blob = new Blob(['\ufeff', text], { type: 'text/csv;charset=utf-8' });
      element.setAttribute('href', window.URL.createObjectURL(blob));
    } else {
      element.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
      );
    }

    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleExport = async () => {
    let opts = {
      page: 0,
      page_size: 3000,
      sort_direction: 'asc',
      type: 'park_service',
      status: ['free', 'serving', 'checkouted'],
    };

    let resp = await pos.item.fetchItems(opts);

    let cars = resp.content;

    let exportCars = cars.map(car => {
      return {
        plate: car.name,
        car_type: car.car_type,
        ctime: car.ctime,
        park_space: car.park_space_id,
      };
    });

    let data = '車號,車種,入場時間,停車場\n';
    if (exportCars.length > 0) {
      data +=
        exportCars
          .map(
            c =>
              `${c.plate},${c.car_type},${c.ctime},${
                c.park_space_id != null ? c.park_space_id : ''
              }`
          )
          .join('\n') + '\n';
    }

    download('場內車資料.csv', data);
  };

  const handleImport = async data => {
    let rows = data.split('\n');

    if (rows.length <= 1) {
      return;
    }

    for (let i = 1; i < rows.length; i++) {
      let rc = rows[i];

      let rowData = rc.split(',');

      if (rowData.length < 4) {
        continue;
      }

      let parkSpaceId = null;

      if (rowData[3] !== '') {
        parkSpaceId = Number(rowData[3]);
      }

      let c = {
        name: rowData[0],
        type: 'park_service',
        car_type: rowData[1],
        ctime: rowData[2],
        park_space_id: parkSpaceId,
      };

      await pos.item.addItem(c);
    }

    message.info('完成');
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          flex: 2,
        }}
      >
        <span
          style={{
            marginLeft: 20,
            marginRight: 12,
            fontSize: 20,
            fontWeight: 'bold',
          }}
        >
          請輸入車牌號碼:
        </span>
        <Search
          size='large'
          placeholder='請輸入車牌號碼'
          onSearch={text => setSearchText(text.toUpperCase())}
          onChange={evt => {
            let value = evt.target.value;
            if (value == null) return;

            setSearchText(value.toUpperCase());
          }}
          style={{ width: 200 }}
        />

        {/* <span style={{marginLeft: 20, marginRight: 6, fontSize: 20}}>未知車牌通知:</span>
        <Switch onChange={onUnknownNotifitionChange} checked={props.unknownNotifitionChecked} size='large'></Switch> */}
      </div>

      <div
        style={{
          flex: 2,
        }}
      >
        <div
          style={{
            float: 'right',
          }}
        >
          {props.privilege.includes('c') ? (
            <Button
              type='link'
              icon='plus'
              style={{ fontSize: 22, fontWeight: 'bold' }}
              onClick={onAdd}
            >
              新增
            </Button>
          ) : (
            <div></div>
          )}

          {props.privilege.includes('u') ? (
            <Button
              type='link'
              icon='edit'
              style={{ fontSize: 22, fontWeight: 'bold' }}
              onClick={onEdit}
            >
              修改
            </Button>
          ) : (
            <div></div>
          )}

          {props.privilege.includes('d') ? (
            <Button
              type='link'
              icon='delete'
              style={{ fontSize: 22, fontWeight: 'bold' }}
              onClick={onDelete}
            >
              刪除
            </Button>
          ) : (
            <div></div>
          )}

          {
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <a
                      onClick={() => {
                        handleExport();
                      }}
                    >
                      匯出
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <Upload
                      {...{
                        accept: '.csv',
                        beforeUpload: file => {
                          const reader = new FileReader();
                          reader.onload = async e => {
                            const text = e.target.result;
                            handleImport(text);
                          };
                          reader.readAsText(file);

                          return false;
                        },
                        fileList: [],
                      }}
                    >
                      <Button type='link'>
                        <Icon type='import' />
                        匯入
                      </Button>
                    </Upload>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className='ant-dropdown-link'
                onClick={e => e.preventDefault()}
              >
                其他 <Icon type='down' />
              </a>
            </Dropdown>
          }
        </div>
      </div>
    </div>
  );
};

const statusMap = {
  free: '免費',
  serving: '未付款',
  checkouted: '已付款',
  confirm: '已出場',
};

const useCarsNavigatorState = (state = {}) => {
  // carType: any of 'all'、'car'、'moto'、'unknown', 'rental', 'one_time'
  const { searchText, disable, carType } = state;

  const onCarChange = utils.doWhenExist(state.onCarChange);

  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState(state.cars || []);
  const [pagination, setPagination] = useState(
    state.pagination || {
      current: 1,
      total: 1000,
      pageSize: 1,
    }
  );
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (disable) return;

    if (state.action && state.action.type === 'add') {
      if (state.action.car) {
        updateCars([...cars, state.action.car], searchText);
        let nextPagin = { ...pagination };
        nextPagin.total += 1;
        nextPagin.current = Number(nextPagin.total);

        setPagination(nextPagin);
      }
    } else if (state.action && state.action.type === 'update') {
      if (state.action.car) {
        let idx = -1;
        for (let i = 0; i < cars.length; i++) {
          if (cars[i].id === state.action.car.id) {
            idx = i;
            break;
          }
        }

        if (idx < 0) return;
        let next = [...cars];
        next[idx] = state.action.car;
        updateCars(next, searchText);
        let nextPagin = { ...pagination };
        nextPagin.current = idx + 1;

        setPagination(nextPagin);
      }
    }
  }, [state.action]);

  // tracking component state
  const componentIsMounted = useRef(true);

  const syncItemTimeout = useRef(null);

  const fetchItems = async () => {
    let parkSpaceId = null;

    let backendParkSpaceId = localStorage.getItem('backend-park-space-id');

    if (backendParkSpaceId) {
      parkSpaceId = Number(backendParkSpaceId);
    }

    let opts = {
      page: 0,
      page_size: 2000,
      sort_direction: 'desc',
      type: 'park_service',
      status: ['free', 'serving', 'checkouted'],
      park_space_id: parkSpaceId,
    };

    if (carType === 'unknown') {
      opts.search_by = 'name';
      opts.search_text = '######';
    } else if (carType === 'moto' || carType === 'car') {
      opts.carType = carType === 'car' ? 'C' : 'M';
    }

    let resp = await pos.item.fetchItems(opts);

    let cars = itemMapper.toView(resp.content, {
      imageType: 'rw320',
      autoSample: false,
    });

    let pagin = resp.pagination;
    if (pagin.total_elements > 2000) {
      pagin.total_elements = 2000;
    }

    if (carType === 'one_time') {
      cars = cars.filter(car => {
        const hasRentalRecord = checkRecordedRentalUser(car);
        const isRentalInRange = checkRentalRecordInRange(car);
        const oneTimeUser = !hasRentalRecord || !isRentalInRange;
        return oneTimeUser;
      });
      pagin.total_elements = cars.length;
    } else if (carType === 'rental') {
      cars = cars.filter(car => {
        const hasRentalRecord = checkRecordedRentalUser(car);
        const isRentalInRange = checkRentalRecordInRange(car);
        const rentalUser = hasRentalRecord && isRentalInRange;
        return rentalUser;
      });
      pagin.total_elements = cars.length;
    }

    cars.forEach(c => {
      c.display = true;
    });

    return {
      cars,
      pagin,
    };
  };

  const nextSyncItem = refresh => {
    clearTimeout(syncItemTimeout.current);

    syncItemTimeout.current = setTimeout(() => {
      if (componentIsMounted.current) {
        setRefresh(!refresh);
      }
    }, 5000);
  };

  const init = async () => {
    setLoading(true);

    try {
      let { cars, pagin } = await fetchItems();

      updateCars(cars, searchText);

      let next = {
        ...pagination,
      };

      next.total = pagin.total_elements;

      if (next.current > cars.length) {
        next.current = cars.length;
      }

      onCarChange(cars[next.current - 1]);

      setPagination(next);
    } catch (err) {
      // ignore now
      console.log(err);
    }

    setLoading(false);

    nextSyncItem(refresh);
  };

  // initial
  useEffect(() => {
    init();
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const syncItems = (cars, pagination, refresh, searchText) => {
    const asyncSyncItems = async () => {
      let resp;
      try {
        resp = await fetchItems();
      } catch (err) {
        // do in next time
        nextSyncItem(refresh);
        return;
      }

      let currentCar = cars[Number(pagination.current) - 1];
      let idx = -1;

      if (currentCar) {
        for (let i = 0; i < resp.cars.length; i++) {
          let c = resp.cars[i];
          if (c.id === currentCar.id) {
            idx = i;
            break;
          }
        }
      }

      let nextPagin = {
        ...pagination,
      };

      // cannot find current car
      // find closet neighbor
      if (idx < 0) {
        if (nextPagin.current > resp.pagin.total_elements) {
          nextPagin.current = resp.pagin.total_elements;
        }
      } else {
        nextPagin.current = idx + 1;
      }

      if (nextPagin.current <= 0) {
        nextPagin.current = 1;
      }

      onCarChange(resp.cars[nextPagin.current - 1]);

      nextPagin.total = resp.pagin.total_elements;

      setPagination(nextPagin);

      updateCars(resp.cars, searchText);

      nextSyncItem(refresh);
    };

    asyncSyncItems();
  };

  useEffect(() => {
    // when nagivator is disable
    // don't to sync items
    if (!disable) {
      syncItems(cars, pagination, refresh, searchText);
    }
  }, [refresh, disable, state.refresh]);

  const onPageChange = page => {
    let next = {
      ...pagination,
    };

    next.current = page;

    onCarChange(cars[page - 1]);

    setPagination(next);
  };

  const updateCars = (cars, text) => {
    let next = [...cars];
    next.forEach(c => {
      if (c.name.includes(text)) {
        c.display = true;
      } else {
        c.display = false;
      }
    });

    setCars(next);
  };

  const forceRefresh = useCallback(() => {
    setRefresh(!refresh);
  }, [refresh]);

  useEffect(() => {
    updateCars(cars, searchText);
  }, [searchText]);

  return {
    onPageChange,
    cars,
    pagination,
    loading,
    setCars,
    refresh: forceRefresh,
  };
};

const CarsNavigator = props => {
  const { onPageChange, cars, pagination, loading, refresh } =
    useCarsNavigatorState(props);

  return (
    <Layout style={{ background: '#fff' }}>
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '12px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        {loading ? (
          <Spin></Spin>
        ) : cars.length > 0 ? (
          <CarField
            car={cars[Number(pagination.current) - 1]}
            refresh={refresh}
          ></CarField>
        ) : (
          <div></div>
        )}
        <div
          style={{
            minHeight: 42,
            position: 'absolute',
            right: 22,
            top: -2,
          }}
        >
          <span style={{ fontSize: 32, fontWeight: 'bold' }}>
            {pagination.current}
          </span>
          <span style={{ fontSize: 32, fontWeight: 'bold' }}>
            &nbsp;/&nbsp;
          </span>
          <span style={{ fontSize: 32, fontWeight: 'bold' }}>
            {pagination.total}
          </span>
          <Button
            size='large'
            onClick={() => {
              let next = { ...pagination };
              next.current -= 1;
              if (next.current < 1) {
                next.current = 1;
              }
              onPageChange(next.current);
            }}
            style={{
              marginLeft: 20,
              fontSize: 24,
              fontWeight: 'bold',
              borderRadius: 4,
              width: 48,
              height: 48,
            }}
          >
            &lt;
          </Button>

          <Button
            size='large'
            onClick={() => {
              let next = { ...pagination };
              next.current += 1;
              if (next.current > pagination.total) {
                next.current = pagination.total;
              }

              onPageChange(next.current);
            }}
            style={{
              marginLeft: 12,
              fontSize: 24,
              fontWeight: 'bold',
              borderRadius: 4,
              width: 48,
              height: 48,
            }}
          >
            &gt;
          </Button>
          {/*
            <Pagination
              simple
              size='large'
              pageSize={pagination.pageSize}
              current={pagination.current}
              total={pagination.total}
              onChange={onPageChange}></Pagination> */}
        </div>
      </Content>

      <Sider
        width={260}
        style={{
          background: '#fff',
          //boxShadow: '2px 2px 5px #ccc',
          margin: '0px 0px 16px 12px',
          padding: '8px 12px',
          borderLeft: '1px solid #ddd',
        }}
      >
        <div
          style={{
            padding: '0px 12px 12px 12px',
            boxShadow: '0px 5px 5px -5px #ccc',
          }}
        >
          <span style={{ fontSize: 22, fontWeight: 'bold' }}>車牌號碼</span>
        </div>

        <List
          loading={loading}
          style={{ height: 500, overflowY: 'auto' }}
          dataSource={cars}
          renderItem={(item, idx) => (
            <List.Item style={{ display: item.display ? 'block' : 'none' }}>
              <Button
                onClick={() => onPageChange(idx + 1)}
                size='large'
                style={{ fontWeight: 'bold' }}
              >
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[props.searchText]}
                  autoEscape
                  textToHighlight={item.name}
                />
              </Button>

              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#cf1322',
                  marginLeft: 6,
                }}
              >
                {item.status ? statusMap[item.status] : ''}
              </span>

              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#cf1322',
                  marginLeft: 6,
                }}
              >
                {item.mark === 'C' ? <Icon type='check' /> : ''}
              </span>

              {item.discounts && item.discounts.length > 0 ? (
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#cf1322',
                    marginLeft: 6,
                  }}
                >
                  ,{item.discounts[0].name}
                </span>
              ) : (
                <div></div>
              )}
            </List.Item>
          )}
        />
      </Sider>
    </Layout>
  );
};

const CarDrawer = Form.create()(props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const [carId, setCarId] = useState(props.car ? props.car.id : null);

  const [viewerVisible, setViewerVisible] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.car && carId !== props.car.id && props.op === 'edit') {
      setCarId(props.car.id);
      setFieldsValue({
        name: props.car.name,
        date: props.car
          ? moment(props.car.createTime ? props.car.createTime : new Date())
          : moment(),
        time: props.car
          ? moment(props.car.createTime ? props.car.createTime : new Date())
          : moment(),
        carType: props.car && props.car.carType ? props.car.carType : 'C',
        parkSpaceId:
          props.car && props.car.parkSpaceId
            ? props.car.parkSpaceId
            : props.parkSpaces.find(ps => ps.is_default)?.id ||
              props.parkSpaces[0]?.id ||
              null,
      });
    }
  }, [props.car]);

  useEffect(() => {
    if (props.op === 'add') {
      setCarId(null);
      setFieldsValue({
        name: null,
        date: moment(),
        time: moment(),
        carType: 'C',
        parkSpaceId:
          props.parkSpaces.find(ps => ps.is_default)?.id ||
          props.parkSpaces[0]?.id ||
          null,
      });
    }
  }, [props.visible]);

  const upload = async item => {
    let res;
    if (props.op === 'add') {
      if (item.park_space_id) {
        item.park_space_id = +item.park_space_id;
      }

      res = await pos.item.addItem(item);
    } else {
      res = await pos.item.updateItem(item);
    }

    return itemMapper.toView(res);
  };

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        let hide = message.loading('處理中...');

        let car = { ...props.car };

        car.name = values.name;
        car.createTime = new Date(
          `${values.date.format('YYYY-MM-DD')} ${values.time.format(
            'HH:mm:ss'
          )}`
        );
        car.type = 'park_service';
        car.carType = values.carType;
        car = itemMapper.toEntity(car);
        car.park_space_id = values.parkSpaceId || null;
        if (props.op === 'add') {
          car.manual_create = true;
        }
        upload(car)
          .then(c => {
            hide();
            message.info('上傳成功');

            if (props.onClose) {
              props.onClose();
            }

            if (props.actions && props.actions[props.op]) {
              props.actions[props.op](c);
            }
          })
          .catch(err => {
            hide();
            message.error('上傳失敗');
            if (props.onClose) {
              props.onClose();
            }
          });
      } else {
        console.log(err);
      }
    });
  };

  return (
    <Drawer {...props}>
      <Form>
        <Form.Item label='車牌'>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '請輸入車牌號碼' }],
            initialValue: props.car ? props.car.name : null,
            value: props.car ? props.car.name : null,
          })(<Input placeholder='請輸入車牌號碼' size='large' />)}
        </Form.Item>

        <Form.Item label='車種'>
          {getFieldDecorator('carType', {
            rules: [{ required: true, message: '請輸入車種' }],
            initialValue: props.car ? props.car.carType : 'C',
            value: props.car ? props.car.carType : 'C',
          })(
            <Select size='large'>
              <Option value='C'>汽車</Option>
              <Option value='M'>機車</Option>
              <Option value='B'>大客車</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label='入場日期'>
          {getFieldDecorator('date', {
            rules: [{ required: true, message: '請輸入進場日期' }],
            initialValue: props.car
              ? moment(props.car.createTime ? props.car.createTime : new Date())
              : moment(),
            value: props.car
              ? moment(props.car.createTime ? props.car.createTime : new Date())
              : moment(),
          })(<DatePicker size='large'></DatePicker>)}
        </Form.Item>

        <Form.Item label='入場時間'>
          {getFieldDecorator('time', {
            rules: [{ required: true, message: '請輸入進場時間' }],
            initialValue: props.car
              ? moment(props.car.createTime ? props.car.createTime : new Date())
              : moment(),
            value: props.car
              ? moment(props.car.createTime ? props.car.createTime : new Date())
              : moment(),
          })(<TimePicker format={'HHmm'} size='large'></TimePicker>)}
        </Form.Item>

        {props.parkSpaces.length ? (
          <Form.Item label='入場區域'>
            {getFieldDecorator('parkSpaceId', {
              rules: [{ required: true, message: '請輸入入場區域' }],
              // initialValue: +localStorage.getItem('backend-park-space-id') || props.parkSpaces.find((ps)=>ps.is_default)?.id || props.parkSpaces[0]?.id || null,
              initialValue: props.car
                ? +props.car.parkSpaceId
                : props.parkSpaces.find(ps => ps.is_default)?.id ||
                  props.parkSpaces[0]?.id ||
                  null,
              // value: +localStorage.getItem('backend-park-space-id') || props.parkSpaces.find((ps)=>ps.is_default)?.id || props.parkSpaces[0]?.id || null
              value: props.car
                ? +props.car.parkSpaceId
                : props.parkSpaces.find(ps => ps.is_default)?.id ||
                  props.parkSpaces[0]?.id ||
                  null,
            })(
              <Select
                size='large'
                onChange={value => {
                  // localStorage.setItem('backend-park-space-id', value)
                }}
              >
                {props.parkSpaces.map(ps => (
                  <Option key={ps.id} value={ps.id}>
                    {ps.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}

        {props.car && props.car.image ? (
          <div>
            <div>圖片</div>

            <div
              style={{
                marginTop: 12,
                borderRadius: 6,
                border: '1px 1px #aaa solid',
                width: 300,
                height: 200,
                cursor: 'pointer',
              }}
              onClick={() => {
                setImages([
                  { src: `${props.car.baseImageUrl}/origin.jpg`, alt: '' },
                ]);

                setViewerVisible(true);
              }}
            >
              <img
                src={props.car.image}
                alt='car'
                style={{ height: '100%', width: '100%' }}
              ></img>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Form>

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={props.onClose} style={{ marginRight: 8 }} size='large'>
          取消
        </Button>
        <Button type='primary' onClick={onSubmit} size='large'>
          提交
        </Button>
      </div>

      <Viewer
        visible={viewerVisible}
        onClose={() => {
          setViewerVisible(false);
        }}
        images={images}
      />
    </Drawer>
  );
});

const Car = props => {
  const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState('one_time');
  const [drawer, setDrawer] = useState({
    title: '修改',
    visible: false,
  });
  const [car, setCar] = useState({});
  const [notifiCar, setNotifiCar] = useState(null);
  const [needRefresh, setNeedFresh] = useState(false);

  const [navAction, setNavAction] = useState({});
  const [unknownNotifitionChecked, setUnknownNotifitionChecked] =
    useState(false);

  const [inhibitCarChange, setInhibitCarChange] = useState(false);
  const [parkSpaces, setParkSpaces] = useState([]);

  const fetchCarRef = useRef();

  const onDrawerClose = () => {
    setInhibitCarChange(false);
    let next = { ...drawer };
    next.visible = false;
    setDrawer(next);
    setNotifiCar(null);
  };

  const tabs = [
    {
      name: '臨停',
      key: 'one_time',
    },
    {
      name: '月租',
      key: 'rental',
    },
    {
      name: '全部',
      key: 'all',
    },
    {
      name: '汽車',
      key: 'car',
    },
    {
      name: '機車',
      key: 'moto',
    },
    {
      name: '未知車牌',
      key: 'unknown',
    },
  ];

  const onTabChange = value => {
    if (value !== currentTab) {
      setCurrentTab(value);
    }
  };

  const showDrawer = (op, opts) => {
    let next = { ...drawer };
    next.visible = true;
    next.op = op;
    if (op === 'add') {
      next.title = '新增';
    } else {
      next.title = '修改';
    }

    if (opts && opts.title) {
      next.title = opts.title;
    }

    setDrawer(next);
  };

  const onEdit = opts => {
    setInhibitCarChange(true);
    showDrawer('edit', opts);
  };

  const onAdd = () => {
    setInhibitCarChange(true);
    showDrawer('add');
  };

  const deleteCar = async car => {
    let nextCar = { ...car };

    nextCar = itemMapper.toEntity(car);

    nextCar.etime = new Date();
    nextCar.status = 'confirm';
    nextCar.manual_leave = true;

    await pos.item.updateItem(nextCar);
  };

  const onDelete = () => {
    confirm({
      title: `刪除車號[${car.name}]?`,
      content: ``,
      okText: '確定',
      cancelText: '取消',
      onOk() {
        let hide = message.loading('處理中...');
        deleteCar(car)
          .then(() => {
            hide();
            message.info('刪除成功');

            setNeedFresh(!needRefresh);
          })
          .catch(err => {
            hide();
            message.error('刪除失敗');
          });
      },
      onCancel() {},
    });
  };

  const onCarChange = car => {
    function change() {
      if (fetchCarRef.current) {
        clearTimeout(fetchCarRef.current);
      }

      // debounce
      fetchCarRef.current = setTimeout(() => {
        if (inhibitCarChange) {
          return;
        }

        setCar(car);
      }, 250);
    }

    change();
  };

  const drawerActions = {
    add: item => {
      setNavAction({
        type: 'add',
        car: item,
      });
      setInhibitCarChange(false);
      setNeedFresh(!needRefresh);
    },
    edit: item => {
      setNavAction({
        type: 'update',
        car: item,
      });
      setInhibitCarChange(false);
      setNeedFresh(!needRefresh);
    },
    onClose: () => {
      setInhibitCarChange(false);
    },
  };

  useEffect(() => {
    let sub;

    const subscribe = async () => {
      try {
        sub = await stomp.asyncSubscribe(
          '/exchange/pos/item.park_service.unknown',
          message => {
            // use parse magic to retrieve message body
            // I don't know how it work. but it do will
            let car = JSON.parse(JSON.stringify(message))['body'];
            if (!car) return;

            car = JSON.parse(car);
            // waiting anpr process
            setTimeout(() => {
              let id = car.id;
              const run = async () => {
                let car = await pos.item.fetchItem(id);
                car = itemMapper.toView(car);
                setNotifiCar(car);
              };
              run();
            }, 1000);
          }
        );
      } catch (err) {
        console.error(err);
      }
    };

    subscribe();

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (notifiCar == null) return;
    if (drawer.visible) return;
    if (!unknownNotifitionChecked) return;
    onEdit({ title: '未知車牌' });
  }, [notifiCar]);

  useEffect(() => {
    const getParkSpaces = async () => {
      const { content } = await pos.parkSpace.readParkSpaces();
      setParkSpaces(content);
    };
    getParkSpaces();
  }, []);

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 80,
        }}
      >
        <ToolBar
          onSearchChange={setSearchText}
          onEdit={onEdit}
          onAdd={onAdd}
          onDelete={onDelete}
          unknownNotifitionChecked={unknownNotifitionChecked}
          onUnknownNotifitionChange={value => {
            setUnknownNotifitionChecked(value);
          }}
          privilege={props.userRoutePrivilege}
        ></ToolBar>
      </Content>

      <Content
        style={{
          margin: '4px 0px 16px 0px',
          padding: '12px 24px',
          background: '#fff',
        }}
      >
        <Tabs
          defaultActiveKey={tabs[0].key}
          onChange={onTabChange}
          size='large'
          style={{ fontSize: 18 }}
        >
          {tabs.map(t => (
            <TabPane tab={t.name} key={t.key}>
              <CarsNavigator
                searchText={searchText}
                disable={t.key !== currentTab}
                carType={t.key}
                onCarChange={onCarChange}
                refresh={needRefresh}
                action={navAction}
              ></CarsNavigator>
            </TabPane>
          ))}
        </Tabs>
      </Content>

      <CarDrawer
        width={600}
        title={drawer.title}
        placement='right'
        closable={false}
        onClose={onDrawerClose}
        visible={drawer.visible}
        op={drawer.op}
        car={drawer.op === 'add' ? null : notifiCar || car}
        actions={drawerActions}
        parkSpaces={parkSpaces}
      ></CarDrawer>
    </Content>
  );
};

export default Car;
