import React from 'react';

import { Breadcrumb } from 'antd';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const Nav = props => {
  if (!props.nav || props.nav.length <= 0) {
    return <div></div>;
  }

  return (
    <div style={props.style}>
      <Breadcrumb separator='>' style={{ fontSize: 16 }}>
        {props.nav.map((a, idx) => (
          <Breadcrumb.Item key={idx}>
            <Link to={a.path}>{a.name}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    nav: state.breadnav,
  };
}

export default connect(mapStateToProps)(Nav);
