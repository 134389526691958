import React, { useEffect, useState, useRef } from 'react';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
  Modal,
  Avatar,
  DatePicker,
} from 'antd';
import { Form, Input } from 'antd';

import { generatePath } from 'react-router-dom';
import moment from 'moment';

import pos from 'pos-api';
import { rentalUserMapper } from '../../../utils/mapper';

import routes from '../../../Routes';

import Highlighter from 'react-highlight-words';
import Viewer from 'react-viewer';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const { confirm } = Modal;

const columns = [
  {
    title: '名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '時間',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: timestamp => {
      if (!timestamp) {
        return 'unknown';
      } else {
        return new Date(timestamp).toLocaleString();
      }
    },
  },
  {
    title: '溫度',
    dataIndex: 'temperature',
    key: 'temperature',
  },
  {
    title: '口罩',
    dataIndex: 'mask',
    key: 'mask',
    render: mask => {
      if (mask) return '有';
      return '無';
    },
  },
];

const DetectorRecords = props => {
  const { searchText, startTime, endTime, detectorId } = props;

  const [viewerVisiable, setViewerVisible] = useState(false);
  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 12,
    current: 1,
    total: 100,
  });

  const getColumns = () => {
    return columns;
  };

  const fetchDetectHistory = async pagination => {
    if (!detectorId) {
      return;
    }

    setLoading(true);

    let opts = {
      sortDirection: 'desc',
      pageSize: 1000,
      page: 0,
      startTime: startTime ? startTime.toISOString() : null,
      endTime: endTime ? endTime.toISOString() : null,
    };

    console.log(opts);

    if (searchText && searchText !== '') {
      opts.searchText = searchText;
      opts.searchBy = 'name';
    }

    let resp = await pos.edgeFaceDetector.fetchDetectHistory(detectorId, opts);

    let history = resp;

    history.forEach((h, idx) => {
      h.key = idx;
    });

    setHistory(history);

    let nextPagination = {
      ...pagination,
    };

    nextPagination.total = history.length;
    setPagination(nextPagination);

    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDetectHistory(pagination);
  };

  const init = async pagination => {
    fetchDetectHistory(pagination);
  };

  useEffect(() => {
    init(pagination);
  }, []);

  useEffect(() => {
    fetchDetectHistory(pagination);
  }, [searchText, startTime, endTime, detectorId]);

  return (
    <div>
      <Viewer
        visible={viewerVisiable}
        onClose={() => {
          setViewerVisible(false);
        }}
        images={images}
      />

      <Table
        loading={loading}
        columns={getColumns()}
        dataSource={history}
        onChange={handleTableChange}
        scroll={{ y: 460 }}
      />
    </div>
  );
};

const Page = props => {
  const [searchText, setSearchText] = useState('');
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [detector, setDetector] = useState({
    id: 0,
  });
  const [detectors, setDetectors] = useState([]);
  const searchTimeoutRef = useRef();

  const onSearch = text => {
    clearTimeout(searchTimeoutRef.current);

    searchTimeoutRef.current = setTimeout(() => {
      setSearchText(text);
    }, 250);
  };

  const onSearchTimeChange = (type, time) => {
    let d = null;

    if (time) {
      d = time.toDate();
    }

    if (type === 'startTime') {
      setStartTime(d);
    } else {
      setEndTime(d);
    }
  };

  const init = async () => {
    let apps = await pos.app.fetchApps({
      pageSize: 2000,
    });

    let detectors = apps.filter(app => {
      return app.type === 'edge_face_detector';
    });

    setDetectors(detectors);

    if (detectors.length > 0) {
      setDetector(detectors[0]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 80,
        }}
      >
        <div
          style={{
            flex: 4,
          }}
        >
          檢測裝置:
          <Select
            defaultValue={detector.id + ''}
            value={detector.id + ''}
            style={{ width: 150, marginLeft: 12 }}
            onChange={value => {
              let id = Number(value);

              let detector;
              for (let i = 0; i < detectors.length; i++) {
                if (detectors[i].id === id) {
                  detector = detectors[i];
                  break;
                }
              }

              if (detector) {
                setDetector(detector);
              }
            }}
          >
            {detectors.map(d => {
              return (
                <Option key={detector.id} value={d.id + ''}>
                  {d.name}
                </Option>
              );
            })}
          </Select>
          <div style={{ float: 'right' }}>
            搜尋範圍:
            <DatePicker
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              placeholder='start'
              onChange={value => onSearchTimeChange('startTime', value)}
              style={{ marginRight: 12, marginLeft: 12 }}
            ></DatePicker>
            <DatePicker
              showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
              placeholder='end'
              onChange={value => onSearchTimeChange('endTime', value)}
            ></DatePicker>
          </div>
        </div>
      </Content>

      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        <DetectorRecords
          detectorId={detector.id}
          startTime={startTime}
          endTime={endTime}
          searchText={searchText}
        ></DetectorRecords>
      </Content>
    </Content>
  );
};

export default Page;
