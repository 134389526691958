import {
  Button,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Layout,
  Select,
  Table,
  Typography,
} from 'antd';
import convertCase from 'js-convert-case';
import moment from 'moment';
import pos from 'pos-api';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { orderMapper } from '../../../utils/mapper';
import { getOS, paymentTypeMap } from '../../../utils/utils';
// import iconv from 'iconv-lite'

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const orderStatusMap = {
  placed: '未完成',
  approved: '完成',
  delivered: '完成',
  refund: '退款',
};

const columns = [
  {
    title: '項目',
    dataIndex: 'items',
    key: 'itemName',
    width: 200,
    render: (text, record) => {
      let itemName = '';
      if (record.rentalCards && record.rentalCards.length > 0) {
        let rentalCards = record.rentalCards;

        itemName = rentalCards.map(card => card.name).join(',');

        return <span>{itemName}</span>;
      }

      if (record && record.items && record.items.length > 0) {
        let items = record.items;

        let itypes = items.map(item => item.type);

        if (itypes.includes('park_service')) {
          itemName = '臨停';
        } else {
          itemName = '一般';
        }
      }

      return <span>{itemName}</span>;
    },
  },
  {
    title: '車牌號',
    dataIndex: 'items',
    key: 'plate',
    width: 200,
    render: (text, record) => {
      let itemName = '';
      if (record.rentalCards && record.rentalCards.length > 0) {
        let rentalCards = record.rentalCards;

        itemName = rentalCards.map(card => card.plate).join(',');

        return <span>{itemName}</span>;
      }

      if (record && record.items && record.items.length > 0) {
        let items = record.items;
        itemName = items
          .filter(item => item.type !== 'park_rental_card')
          .map(item => item.name)
          .join(',');

        if (itemName.length > 14) {
          itemName = itemName.substr(0, 11) + '...';
        }
      }

      return <span>{itemName}</span>;
    },
  },
  {
    title: '發票號',
    dataIndex: 'invoices',
    key: 'invoiceNumbers',
    width: 200,
    render: (text, record) => {
      let numbers = '';
      if (record && record.invoices && record.invoices.length > 0) {
        let invoices = record.invoices;

        if (invoices.length > 1) {
          numbers = invoices
            .map(iv => {
              if (iv.void) {
                return iv.ivNumber + '(作廢)';
              }

              return iv.ivNumber;
            })
            .join(',');
        } else {
          numbers = invoices[0].ivNumber;

          if (invoices[0].void) {
            numbers += '(作廢)';
          }
        }
      }

      return <span>{numbers}</span>;
    },
  },
  {
    title: '汽機車',
    dataIndex: 'items[0]',
    key: 'car_type',
    width: 200,
    render: (text, record) => {
      const parkServiceCarType = record.items?.[0]?.carType;
      const rentalCarType = record.items?.[0]?.data?.car_type;
      return parkServiceCarType || rentalCarType || '';
    },
  },
  {
    title: '交易時間',
    dataIndex: 'ctime',
    key: 'ctime',
    width: 250,
    render: (text, record) => (
      <span>{moment(record.ctime).format('YYYY-MM-DD HH:mm:ss')}</span>
    ),
    sorter: (a, b) => a.ctime.getTime() - b.ctime.getTime(),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '入場時間',
    dataIndex: 'enterTime',
    key: 'enterTime',
    width: 250,
    render: (text, record) => {
      let enterTime = '';

      if (record && record.items && record.items.length > 0) {
        let items = record.items;
        let parks = items.filter(item => item.type === 'park_service');

        if (parks.length > 0) {
          enterTime = new Date(parks[0].ctime);
        }
      }

      return (
        <span>
          {enterTime === ''
            ? ''
            : moment(enterTime).format('YYYY-MM-DD HH:mm:ss')}
        </span>
      );
    },
  },
  {
    title: '離場時間',
    dataIndex: 'exitTime',
    key: 'exitTime',
    width: 250,
    render: (text, record) => {
      let exitTime = '';

      if (record && record.items && record.items.length > 0) {
        let items = record.items;
        let parks = items.filter(item => item.type === 'park_service');

        if (parks.length > 0) {
          exitTime = new Date(parks[0].etime);
        }
      }

      return (
        <span>
          {exitTime === ''
            ? ''
            : moment(exitTime).format('YYYY-MM-DD HH:mm:ss')}
        </span>
      );
    },
  },
  {
    title: '停車時長',
    dataIndex: 'gapTime',
    key: 'gapTime',
    width: 250,
    render: (text, record) => {
      let gapTime = '';

      if (record && record.items && record.items.length > 0) {
        let items = record.items;
        let parks = items.filter(item => item.type === 'park_service');

        if (parks.length > 0 && record.ctime) {
          let gap =
            new Date(record.ctime).getTime() -
            new Date(parks[0].ctime).getTime();

          gap = Math.floor(gap / (60 * 1000));

          let minutes = Math.floor(gap % 60);

          let hr = Math.floor(Math.floor(gap / 60) % 24);

          let day = Math.floor(gap / (24 * 60));

          if (day > 0) {
            gapTime += `${day}天`;
          }

          if (hr > 0) {
            gapTime += `${hr}時`;
          }

          gapTime += `${minutes}分`;
        }
      }

      return <span>{gapTime}</span>;
    },
  },
  {
    title: '機台號',
    dataIndex: 'app',
    key: 'app',
    width: 200,
    render: (text, record) => {
      let appName = '';
      if (record.app) {
        appName = record.app.name;
      }

      return <span>{appName}</span>;
    },
  },
  {
    title: '折扣項目',
    dataIndex: 'discounts',
    key: 'discounts',
    width: 200,
    render: (text, record) => {
      let discounts = record.discounts;

      if (!discounts || discounts.length <= 0) {
        return <span></span>;
      }

      let disNames = discounts.map(dis => dis.name).join(',');

      return <span>{disNames}</span>;
    },
  },
  {
    title: '交易狀態',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (text, record) => {
      if (orderStatusMap[text]) {
        text = orderStatusMap[text];
      }

      return <span>{text}</span>;
    },
  },

  {
    title: '銷售額',
    dataIndex: 'amounts.amount',
    key: 'amounts.amount',
    render: (text, record) => <span>{text ? text : '0'}</span>,
    width: 150,
  },

  {
    title: '折抵金額',
    dataIndex: 'amounts.discount',
    key: 'amounts.discount',
    width: 150,
    render: (text, record) => {
      const total = record.amounts.totalAmount;
      const discount = record.amounts.discount;
      return discount > total ? total : discount;
    },
  },
  {
    title: '總金額',
    dataIndex: 'amounts.totalAmount',
    key: 'amounts.totalAmount',
    width: 120,
  },
  {
    title: '支付方式',
    dataIndex: 'payments',
    key: 'paymentType',
    width: 150,
    render: (text, record) => {
      let payments = record.payments;

      if (payments && payments.length > 0) {
        for (let i = 0; i < payments.length; i++) {
          let type = payments[i].type;

          if (type && paymentTypeMap[type]) {
            return paymentTypeMap[type];
          }
        }
      }

      return '';
    },
  },
];

const DEFAULT_PAGE_SIZE = 100;

class orders extends React.Component {
  constructor(props) {
    super(props);
    this.pageTimer = null;
  }

  state = {
    searchText: '',
    startTime: moment(moment().format('YYYY-MM') + '-01', 'YYYY-MM-DD'),
    endTime: null,
    apps: [
      {
        id: 'all',
        name: '全部',
      },
    ],
    appId: 'all',
    searchIvNumber: null,
    searchItemName: null,
    enableCredited: 'all',
    amounts: {
      salesAmount: 0,
      totalAmount: 0,
      taxAmount: 0,
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      current: 1,
    },
    paymentType: 'all',
    category: [
      {
        id: 'all',
        name: '全部',
      },
      {
        id: 'parking',
        name: '臨停',
      },
    ],
    categoryType: 'all',
    status: 'complete',
    amountType: 'all',
    loading: true,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      filters: filters,
      sorter: sorter,
    });

    this.fetchOrders({
      pagination: pager,
      sorter,
      filters,
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  fetchOrders = async opts => {
    this.setState({
      loading: true,
    });

    opts = opts || {
      pagination: this.state.pagination,
      sorter: {},
    };

    if (!opts.sorter) opts.sorter = {};
    if (!opts.filters) opts.filters = {};
    if (!opts.startTime) opts.startTime = this.state.startTime;
    if (!opts.endTime) opts.endTime = this.state.endTime;
    if (!opts.appId) opts.appId = this.state.appId;
    if (!opts.searchIvNumber) opts.searchIvNumber = this.state.searchIvNumber;
    if (!opts.searchItemName) opts.searchItemName = this.state.searchItemName;
    if (!opts.enableCredited) opts.enableCredited = this.state.enableCredited;
    if (!opts.paymentType) opts.paymentType = this.state.paymentType;
    if (!opts.categoryType) opts.categoryType = this.state.categoryType;
    if (!opts.status) opts.status = this.state.status;
    if (!opts.amountType) opts.amountType = this.state.amountType;

    let appId;
    if (opts.appId !== 'all') {
      appId = Number(opts.appId);
    }

    let paymentType = opts.paymentType;
    if (paymentType === 'all') {
      paymentType = null;
    }

    let amountType = opts.amountType;
    if (opts.amountType === 'all') {
      amountType = null;
    }

    let enableCredited = opts.enableCredited;
    if (opts.enableCredited === 'all') {
      enableCredited = null;
    }

    let categoryType = opts.categoryType;
    let type, itemName;
    if (categoryType === 'parking') {
      type = 'park_service';
    } else if (categoryType !== 'all') {
      type = 'park_rental_card';
      itemName = categoryType;
    }

    let status = ['approved', 'delivered'];
    if (opts.status === 'uncomplete') {
      status = ['placed'];
    } else if (opts.status === 'refund') {
      status = ['refund'];
    }

    let { content, pagination, amounts } = await pos.order.fetchOrders({
      pageSize: opts.pagination.pageSize,
      page: opts.pagination.current - 1,
      startTime: opts.startTime ? opts.startTime.toISOString() : undefined,
      endTime: opts.endTime ? opts.endTime.toISOString() : undefined,
      status: status,
      sortBy: opts.sorter.field
        ? convertCase.toSnakeCase(opts.sorter.field)
        : null,
      sortDirection: opts.sorter.order === 'descend' ? 'desc' : 'asc',
      appId,
      ivNumber: opts.searchIvNumber,
      plate: opts.searchItemName,
      enableCredited,
      paymentType,
      itemType: type,
      amountType,
      itemName,
    });

    let orders = orderMapper.toView(content);
    orders.forEach((g, idx) => {
      g.key = g.id;
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;

    this.setState({
      orders: orders,
      loading: false,
      amounts: {
        salesAmount: amounts.amount,
        totalAmount: amounts.total_amount,
      },
      pagination: pager,
    });
  };

  onFooter = record => {
    return (
      <div>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總筆數: {this.state.pagination.total}
            </Text>
          </div>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總金額:{' '}
              {`$ ${this.state.amounts.salesAmount}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  handleTimeChange = (field, value) => {
    this.setState({
      [field]: value,
    });

    let timeRange = {
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };

    timeRange[field] = value.toDate();

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      ...timeRange,
    });
  };

  init = async () => {
    let apps = await pos.app.fetchApps({
      pageSize: 100,
      type: ['aps', 'online_sell_rental_card', 'admin'],
    });

    if (apps) {
      let _apps = apps.map(ap => {
        return {
          id: ap.id + '',
          name: ap.name,
        };
      });

      _apps = [
        {
          id: 'all',
          name: '全部',
        },
        ..._apps,
      ];

      this.setState({
        apps: _apps,
      });
    }

    let resp = await pos.item.fetchItems({
      type: 'park_rental_card',
      pageSize: 100,
    });

    let cards = resp.content;

    if (cards && cards.length > 0) {
      this.setState({
        category: [
          {
            id: 'all',
            name: '全部',
          },
          {
            id: 'parking',
            name: '臨停',
          },
          ...cards.map(c => {
            return {
              id: c.name,
              name: c.name,
            };
          }),
        ],
      });
    }

    await this.fetchOrders();
  };

  handleAppIdChange = appId => {
    this.setState({
      appId: appId,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId,
    });
  };

  handlePaymentTypeChange = paymentType => {
    this.setState({
      paymentType: paymentType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      paymentType,
    });
  };

  handleSearchIvNumberChange = ivNumber => {
    if (this.searchIvNumberTimer) {
      clearTimeout(this.searchIvNumberTimer);
    }

    this.searchIvNumberTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        searchIvNumber: ivNumber,
      });

      this.fetchOrders({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        searchIvNumber: ivNumber,
      });
    }, 200);
  };

  handleSearchItemNameChange = itemName => {
    if (this.searchItemNameTimer) {
      clearTimeout(this.searchItemNameTimer);
    }

    this.searchItemNameTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        searchItemName: itemName,
      });

      this.fetchOrders({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        searchItemName: itemName,
      });
    }, 200);
  };

  handleCategoryTypeChange = categoryType => {
    this.setState({
      categoryType: categoryType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      categoryType,
    });
  };

  handleAmountTypeChange = amountType => {
    this.setState({
      amountType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      amountType,
    });
  };

  handleEnableCreditedChange = enableCredited => {
    this.setState({
      enableCredited,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      enableCredited,
    });
  };

  handleExport = async () => {
    let appId;
    if (this.state.appId !== 'all') {
      appId = Number(this.state.appId);
    }

    let paymentType = this.state.paymentType;
    if (paymentType === 'all') {
      paymentType = null;
    }

    let amountType = this.state.amountType;
    if (this.state.amountType === 'all') {
      amountType = null;
    }

    let enableCredited = this.state.enableCredited;
    if (this.state.enableCredited === 'all') {
      enableCredited = null;
    }

    let type, itemName;
    if (this.state.categoryType === 'parking') {
      type = 'park_service';
    } else if (this.state.categoryType !== 'all') {
      type = 'park_rental_card';
      itemName = this.state.categoryType;
    }

    let status = ['approved', 'delivered'];
    if (this.state.status === 'uncomplete') {
      status = ['placed'];
    } else if (this.state.status === 'refund') {
      status = ['refund'];
    }

    let { content, pagination, amounts } = await pos.order.fetchOrders({
      pageSize: 30000,
      page: 0,
      startTime: this.state.startTime
        ? this.state.startTime.toISOString()
        : undefined,
      endTime: this.state.endTime
        ? this.state.endTime.toISOString()
        : undefined,
      status: status,
      sortBy: 'id',
      sortDirection: 'asc',
      appId,
      ivNumber: this.state.searchIvNumber,
      plate: this.state.searchItemName,
      paymentType,
      itemType: type,
      amountType,
      itemName,
      enableCredited,
    });

    let data = [];

    content.forEach(d => {
      let category = '一般';
      let name = '';
      let ivNumber = '';
      let carType = '';
      let enterTime = '';
      let paymentTime = '';
      let exitTime = '';
      let amount = 0;
      let discount = 0;
      let totalAmount = 0;
      let paymentType = '';
      let buyerBan = '';
      let carryNumber = '';
      let cardNumber = '';
      let appNumber = '';
      let discounts = '';

      let item = d.items[0];

      if (item) {
        if (item.type === 'park_rental_card') {
          category = item.name;
        } else if (item.type === 'park_service') {
          category = '臨停';
        }

        carType = item.car_type || item.data?.car_type || '';
      }

      if (d.rental_cards && d.rental_cards.length > 0) {
        name = d.rental_cards[0].plate;
      } else if (item) {
        name = item.name;
      }

      let invs = d.invoices;

      ivNumber = invs
        .map(inv => {
          if (inv.void) {
            return inv.iv_number + '(作廢)';
          }

          return inv.iv_number;
        })
        .join('\n');

      ivNumber = '"' + ivNumber + '"'; // avoid csv delimiter

      if (item && item.type === 'park_service') {
        enterTime = moment(item.ctime).format('YYYY-MM-DD HH:mm:ss');

        if (item.etime) {
          exitTime = moment(item.etime).format('YYYY-MM-DD HH:mm:ss');
        }
      }

      paymentTime = moment(d.ctime).format('YYYY-MM-DD HH:mm:ss');

      amount = d.amounts.amount;
      totalAmount = d.amounts.total_amount;
      discount = d.amounts.discount;

      let payment = d.payments[0];

      if (payment) {
        paymentType = paymentTypeMap[payment.type];
      }

      buyerBan = invs
        .map(inv => inv.buyer_ban)
        .filter(item => item)
        .join('\n');
      buyerBan = '"' + buyerBan + '"';

      carryNumber = invs
        .map(inv => inv.carry_number)
        .filter(item => item)
        .join('\n');
      carryNumber = '"' + carryNumber + '"';

      cardNumber = invs
        .map(inv => {
          if (!inv.extra) return null;

          let extraSplits = inv.extra.split(';');

          let extraMap = {};

          extraSplits.forEach(es => {
            let [key, token] = es.split(': ');

            if (key && token) {
              extraMap[key] = token;
            }
          });

          return extraMap['卡號'];
        })
        .filter(item => item)
        .join('\n');
      cardNumber = '"' + cardNumber + '"';

      let app = d.app;
      if (app) {
        appNumber = app.name;
      }

      let orderDiscounts = d.discounts;

      if (orderDiscounts && orderDiscounts.length > 0) {
        discounts = orderDiscounts.map(d => d.name).join(', ');
      }

      data.push({
        category,
        name,
        ivNumber,
        carType,
        enterTime,
        paymentTime,
        exitTime,
        amount,
        discount,
        totalAmount,
        paymentType,
        buyerBan,
        carryNumber,
        cardNumber,
        appNumber,
        discounts,
      });
    });

    const download = (filename, text) => {
      //text = text.replace(/\n/g, '\r\n');
      var element = document.createElement('a');

      if (getOS() === 'Windows') {
        let blob = new Blob(['\ufeff', text], {
          type: 'text/csv;charset=utf-8',
        });
        element.setAttribute('href', window.URL.createObjectURL(blob));
      } else {
        element.setAttribute(
          'href',
          'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
        );
      }

      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    };

    let result =
      '項目,車牌號,發票號,汽機車,進場時間,繳費時間,出場時間,繳費機號,折扣項目,繳費金額,折抵金額,總金額,付款方式,卡號,買方統編,載具碼\n';
    let tokens =
      'category,name,ivNumber,carType,enterTime,paymentTime,exitTime,appNumber,discounts,amount,discount,totalAmount,paymentType,cardNumber,buyerBan,carryNumber'.split(
        ','
      );

    data.forEach(d => {
      result += tokens.map(t => d[t]).join(',') + '\n';
    });

    download('交易紀錄表.csv', result);
  };

  handleStatusChange = status => {
    this.setState({
      status: status,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      status,
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                flex: 4,
              }}
            >
              搜尋範圍:
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                placeholder='start'
                onChange={value => this.handleTimeChange('startTime', value)}
                value={moment(this.state.startTime)}
                style={{ marginRight: 12, marginLeft: 12 }}
              ></DatePicker>
              <DatePicker
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                placeholder='end'
                onChange={value => this.handleTimeChange('endTime', value)}
              ></DatePicker>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              單頁筆數:
              <InputNumber
                style={{ marginLeft: 12 }}
                min={1}
                defaultValue={DEFAULT_PAGE_SIZE}
                onChange={value => {
                  if (this.state.pagination.pageSize === value) {
                    return;
                  }

                  // debounce
                  if (this.pageTimer) {
                    clearTimeout(this.pageTimer);
                  }

                  this.pageTimer = setTimeout(() => {
                    let pager = {
                      ...this.state.pagination,
                    };
                    pager.pageSize = value;
                    pager.current = 1;
                    this.setState({
                      pagination: pager,
                    });

                    this.fetchOrders({
                      pagination: pager,
                    });
                  }, 250);
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: 12 }}>
            機台號:
            <Select
              defaultValue='all'
              onChange={this.handleAppIdChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              {this.state.apps.map(ap => {
                return <Option value={ap.id}>{ap.name}</Option>;
              })}
            </Select>
            車號:
            <Search
              size='large'
              placeholder='車號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchItemNameChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            發票號:
            <Search
              size='large'
              placeholder='請輸入發票號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchIvNumberChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            {/*
            ['cash', 'icash', 'ezcard', 'ipass', 'pay_taipei', 'pay_taipei_real_time', 'pay_taipei_pos', 'credit_card', 'ez_pay', 'line_pay', 'big_voice', 'pay_taipei_smart', 'taipei_online_rental', 'jkopay', 'jkopoint', 'mochi_pay', 'mochi_pay_auto_pass', 'other']
             */}
            付款別:
            <Select
              defaultValue='all'
              onChange={this.handlePaymentTypeChange}
              style={{ width: 180, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='cash'>現金</Option>
              <Option value='ezcard'>悠遊卡</Option>
              <Option value='ipass'>一卡通</Option>
              <Option value='icash'>愛金卡</Option>
              <Option value='credit_card'>信用卡</Option>
              <Option value='ez_pay'>悠遊付</Option>
              <Option value='pay_taipei'>台北通</Option>
              <Option value='pay_taipei_real_time'>台北智慧支付</Option>
              <Option value='pay_taipei_pos'>台北行動支付</Option>
              <Option value='line_pay'>LinePay</Option>
              <Option value='line_pay_money'>一卡通Money</Option>
              <Option value='icash_pay'>iCashPay</Option>
              <Option value='op_wallet'>OP錢包</Option>
              <Option value='taiwan_pay'>台灣Pay</Option>
              <Option value='jkopay'>街口支付</Option>
              <Option value='mochi_pay'>麻吉付</Option>
              <Option value='mochi_pay_auto_pass'>麻吉付(自動)</Option>
              <Option value='remit'>電匯</Option>
            </Select>
            狀態:
            <Select
              defaultValue='complete'
              onChange={this.handleStatusChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='complete'>完成</Option>
              <Option value='uncomplete'>未完成</Option>
              <Option value='refund'>退款</Option>
            </Select>
            項目:
            <Select
              defaultValue='all'
              onChange={this.handleCategoryTypeChange}
              style={{ width: 150, marginLeft: 12, marginRight: 12 }}
            >
              {this.state.category.map(c => {
                return <Option value={c.id}>{c.name}</Option>;
              })}
            </Select>
            付款:
            <Select
              defaultValue='all'
              onChange={this.handleAmountTypeChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='normal'>付費</Option>
              <Option value='zero'>免費</Option>
            </Select>
            系統帳:
            <Select
              defaultValue='all'
              onChange={this.handleEnableCreditedChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='true'>入系統帳</Option>
              <Option value='false'>未入系統帳</Option>
            </Select>
            <Button
              style={{ marginLeft: 20 }}
              size='large'
              onClick={() => {
                this.handleExport();
              }}
            >
              匯出
            </Button>
          </div>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.orders}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            footer={this.onFooter}
            scroll={{ y: 520, x: 1000 }}
          />
        </Content>
      </Content>
    );
  }
}

export default orders;
