import React from 'react';
import { generatePath } from 'react-router-dom';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
  Modal,
  Avatar,
} from 'antd';
import { Drawer, Form, Input } from 'antd';

import pos from 'pos-api';
import { userMapper } from '../../utils/mapper';

import routes from '../../Routes';

import Highlighter from 'react-highlight-words';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const columns = [
  {
    title: '連絡電話',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
];

const UserDrawer = Form.create()(
  class extends React.Component {
    upload = async values => {
      let c = values;
      if (this.props.user) {
        c.id = this.props.user.id;
        c = userMapper.toEntity(c);
        c = await pos.user.updateUser(c);
      } else {
        c = userMapper.toEntity(c);
        c = await pos.user.addUser(c);
      }

      return c;
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(c => {
              hide();
              message.info('上傳成功');
              if (this.props.user) {
                if (this.props.actions && this.props.actions.replace) {
                  this.props.actions.replace(userMapper.toView(c));
                }
              } else {
                if (this.props.actions && this.props.actions.add) {
                  this.props.actions.add(userMapper.toView(c));
                }
              }

              if (this.props.onClose) {
                this.props.onClose();
              }
            })
            .catch(err => {
              hide();
              message.error('上傳失敗');
              if (this.props.onClose) {
                this.props.onClose();
              }
            });
        } else {
          console.log(err);
        }
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;

      return (
        <Drawer {...this.props}>
          <Form>
            <Form.Item label='名稱'>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter user name' }],
                initialValue: this.props.user ? this.props.user.name : null,
              })(<Input placeholder='Please enter user name' />)}
            </Form.Item>

            <Form.Item label='連絡電話'>
              {getFieldDecorator('phoneNumber', {
                rules: [{ required: true, message: 'Please enter user name' }],
                initialValue: this.props.user
                  ? this.props.user.phoneNumber
                  : null,
              })(<Input placeholder='Please enter user name' />)}
            </Form.Item>

            <Form.Item label='電子信箱'>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please enter user email' }],
                initialValue: this.props.user ? this.props.user.email : null,
              })(<Input placeholder='Please enter user email' />)}
            </Form.Item>

            {this.props.user ? (
              <div></div>
            ) : (
              <Form.Item label='密碼'>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please enter user pass' },
                  ],
                })(
                  <Input placeholder='Please enter user name' type='password' />
                )}
              </Form.Item>
            )}
          </Form>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class Users extends React.Component {
  state = {
    drawer: {
      title: '',
      visible: false,
    },
    pagination: {
      pageSize: 12,
      current: 1,
    },
    loading: true,
    users: null,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchUsers({
      pagination: pager,
    });
  };

  fetchUsers = async (opts = {}) => {
    this.setState({
      loading: true,
    });

    if (!opts.pagination) {
      opts.pagination = this.state.pagination;
    }

    let result = await pos.user.fetchUsers({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
      searchBy: this.searchBy,
      searchText: this.searchText,
    });

    console.log({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
      searchBy: this.searchBy,
      searchText: this.searchText,
    });

    let pagination = result.pagination;
    let users = result.content;
    users = userMapper.toView(users);
    users.forEach((user, idx) => {
      user.key = user.id;
      user.onEdit = this.onEditBtn(user);
      user.onDelete = this.onDeleteBtn(user);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      users: users,
      pagination: pager,
      loading: false,
    });
  };

  onDrawerClose = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = false;
      return {
        drawer,
      };
    });
  };

  showDrawer = title => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = true;
      return {
        drawer,
      };
    });
  };

  onAddBtn = () => {
    let path = `${routes.userForm.layout}${routes.userForm.path}`;
    path = generatePath(path, { id: 0 });
    this.props.history.push(path);
  };

  onEditBtn = user => () => {
    let path = `${routes.userForm.layout}${routes.userForm.path}`;
    path = generatePath(path, { id: user.id });
    this.props.history.push(path);
  };

  deleteUser = async user => {
    await pos.user.deleteUser(user.id);
  };

  onDeleteBtn = user => () => {
    confirm({
      title: `確定刪除使用者[${user.name}]?`,
      onOk: () => {
        let hide = message.loading('處理中...');
        this.deleteUser(user)
          .then(() => {
            hide();
            message.info('刪除成功');
            this.setState(prev => {
              return {
                users: prev.users.filter(u => u !== user),
              };
            });
          })
          .catch(err => {
            hide();
            message.error('刪除失敗');
          });
      },
    });
  };

  onSearch = text => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (text === '') {
        this.searchBy = null;
      } else {
        this.searchBy = 'name';
      }

      this.searchText = text;

      this.fetchUsers();
    }, 250);
  };

  drawerActoins = {
    add: c => {
      this.setState(prev => {
        c.key = c.id;
        c.onEdit = this.onEditBtn(c);
        c.onDelete = this.onDeleteBtn(c);
        return {
          users: [...prev.users, c],
        };
      });
    },
    replace: c => {
      this.setState(prev => {
        let cs = prev.users;
        let idx = -1;
        for (let i = 0; i < cs.length; i++) {
          if (cs[i].id === c.id) {
            idx = i;
            break;
          }
        }

        if (idx >= 0) {
          c.key = c.id;
          c.onEdit = this.onEditBtn(c);
          c.onDelete = this.onDeleteBtn(c);
          cs.splice(idx, 1, c);
          let next = [...cs];
          return {
            users: next,
          };
        }

        return {};
      });
    },
  };

  componentDidMount() {
    this.fetchUsers();
  }

  getColumns = () => {
    const nameColumn = {
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.searchText || '']}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
    };

    const opColumn = {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <span>
          {this.props.userRoutePrivilege.includes('u') ? (
            <Button type='link' icon='edit' onClick={record.onEdit}></Button>
          ) : (
            <Button type='link' icon='eye' onClick={record.onEdit}></Button>
          )}

          {this.props.userRoutePrivilege.includes('d') ? (
            <span>
              <Divider type='vertical' />
              <Button
                type='link'
                icon='delete'
                onClick={record.onDelete}
              ></Button>
            </span>
          ) : (
            <span></span>
          )}
        </span>
      ),
    };

    return [
      {
        title: '',
        dataIndex: 'cover',
        key: 'cover',
        render: (text, record) => {
          let img;

          if (record.picture) {
            img = `${record.picture.url}?_=${record.picture.mtime}`;
          }

          if (img) {
            return (
              <div style={{ width: '100%' }}>
                <img
                  src={img}
                  alt='user-cover'
                  style={{
                    height: 40,
                    borderRadius: 4,
                    marginLeft: 24,
                    display: 'block',
                  }}
                ></img>
              </div>
            );
          }

          return (
            <div style={{ width: '100%' }}>
              <Avatar
                shape='square'
                style={{
                  marginLeft: 24,
                  width: 40,
                  height: 40,
                  backgroundColor: '#48D049',
                  fontSize: 16,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {record.name[0] + ''}
              </Avatar>
            </div>
          );
        },
      },
      nameColumn,
      ...columns,
      opColumn,
    ];
  };

  render() {
    const _columns = this.getColumns();

    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          {this.props.userRoutePrivilege.includes('c') ? (
            <Button type='link' icon='plus' onClick={this.onAddBtn}>
              新增用戶
            </Button>
          ) : (
            <div></div>
          )}

          <Search
            placeholder='請輸入員工名稱'
            onSearch={this.onSearch}
            onChange={evt => {
              let value = evt.target.value;
              if (value == null) return;

              this.onSearch(value);
            }}
            style={{ width: 200, float: 'right' }}
          />
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={_columns}
            dataSource={this.state.users}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>

        <UserDrawer
          width={600}
          title={this.state.drawer.title}
          placement='right'
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawer.visible}
          user={this.state.current}
          actions={this.drawerActoins}
        ></UserDrawer>
      </Content>
    );
  }
}

export default Users;
