import React from 'react';
import { generatePath } from 'react-router-dom';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
  Modal,
} from 'antd';
import { Drawer, Form, Input } from 'antd';

import pos from 'pos-api';
import { userMapper } from '../../../utils/mapper';

import routes from '../../../Routes';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;

const columns = [
  {
    title: '服務名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '服務類型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '操作',
    key: 'action',
    render: (text, record) => (
      <span>
        <Button type='link' icon='edit' onClick={record.onEdit}></Button>
        <Divider type='vertical' />
        <Button type='link' icon='delete' onClick={record.onDelete}></Button>
      </span>
    ),
  },
];

class Services extends React.Component {
  state = {
    pagination: {
      pageSize: 12,
      current: 1,
    },
    loading: true,
    services: null,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchUsers({
      pagination: pager,
    });
  };

  fetchUsers = async (opts = {}) => {
    this.setState({
      loading: true,
    });

    if (!opts.pagination) {
      opts.pagination = this.state.pagination;
    }

    let result = await pos.service.fetchServices({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
    });

    let pagination = result.pagination;
    let services = result.content;
    services = userMapper.toView(services);
    services.forEach((service, idx) => {
      service.key = service.id;
      service.onEdit = this.onEditBtn(service);
      service.onDelete = this.onDeleteBtn(service);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      services: services,
      pagination: pager,
      loading: false,
    });
  };

  onAddBtn = () => {
    let path = `${routes.serviceForm.layout}${routes.serviceForm.path}`;
    path = generatePath(path, { id: 0 });
    this.props.history.push(path);
  };

  onEditBtn = service => () => {
    let path = `${routes.serviceForm.layout}${routes.serviceForm.path}`;
    path = generatePath(path, { id: service.id });
    this.props.history.push(path);
  };

  deleteService = async service => {
    let hide = message.info('處理中...');
    try {
      await pos.service.removeService(service.id);
      this.setState(prev => {
        let services = prev.services;
        services = services.filter(s => s !== service);
        return {
          services,
        };
      });
    } catch (err) {
      message.error('刪除失敗');
      return;
    }
    hide();
    message.success('刪除成功');
  };

  onDeleteBtn = service => () => {
    let that = this;
    confirm({
      title: `刪除[${service.name}]?`,
      content: ``,
      okText: '確定',
      cancelText: '取消',
      onOk() {
        that.deleteService(service);
      },
      onCancel() {},
    });
  };

  componentDidMount() {
    this.fetchUsers();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <Button type='link' icon='plus' onClick={this.onAddBtn}>
            新增服務
          </Button>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.services}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>
      </Content>
    );
  }
}

export default Services;
