import React from 'react';
import { Layout, Form, Input, Spin, Button, message, Divider } from 'antd';

import { systemInfoMapper } from '../../../utils/mapper';

import { connect } from 'react-redux';

import pos from 'pos-api';

const { Content } = Layout;

const InfoForm = Form.create()(
  class extends React.Component {
    state = {
      loaded: false,
    };

    init = async () => {
      let system = await pos.system.fetchSystemField('info');
      this.setState({
        loaded: true,
        system: systemInfoMapper.toView(system),
      });
    };

    upload = async values => {
      let body = systemInfoMapper.toEntity(values);
      await pos.system.updateSystemField('info', body);
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(c => {
              hide();
              message.info('成功');
            })
            .catch(err => {
              hide();
              message.error('失敗');
            });
        } else {
          message.error(err);
        }
      });
    };

    componentDidMount() {
      this.init();
    }

    render() {
      const { getFieldDecorator } = this.props.form;

      if (!this.state.loaded) {
        return <Spin></Spin>;
      }

      const disabled = !this.props.privilege.includes('u');

      return (
        <Form>
          <Form.Item label='公司名稱'>
            {getFieldDecorator('companyName', {
              rules: [{ required: true, message: 'Please enter companyName' }],
              initialValue: this.state.system
                ? this.state.system.companyName
                : '',
            })(
              <Input
                placeholder='Please enter companyName'
                disabled={disabled}
              />
            )}
          </Form.Item>

          <Form.Item label='分公司名稱'>
            {getFieldDecorator('childCompanyName', {
              rules: [
                { required: false, message: 'Please enter child company name' },
              ],
              initialValue: this.state.system
                ? this.state.system.childCompanyName
                : '',
            })(
              <Input
                placeholder='Please enter child company name'
                disabled={disabled}
              />
            )}
          </Form.Item>

          <Form.Item label='店面(場站)名稱'>
            {getFieldDecorator('storeName', {
              rules: [{ required: false, message: 'Please enter store name' }],
              initialValue: this.state.system
                ? this.state.system.storeName
                : '',
            })(
              <Input
                placeholder='Please enter store name'
                disabled={disabled}
              />
            )}
          </Form.Item>

          <Form.Item label='店面(場站)代號'>
            {getFieldDecorator('storeId', {
              rules: [{ required: false, message: 'Please enter store id' }],
              initialValue: this.state.system ? this.state.system.storeId : '',
            })(
              <Input placeholder='Please enter store id' disabled={disabled} />
            )}
          </Form.Item>

          <Form.Item label='統一編號'>
            {getFieldDecorator('taxId', {
              rules: [{ required: true, message: 'Please enter tax id' }],
              initialValue: this.state.system ? this.state.system.taxId : '',
            })(<Input placeholder='Please enter tax id' disabled={disabled} />)}
          </Form.Item>

          <Form.Item label='公司地址'>
            {getFieldDecorator('address', {
              rules: [{ required: false, message: 'Please enter address' }],
              initialValue: this.state.system ? this.state.system.address : '',
            })(
              <Input placeholder='Please enter address' disabled={disabled} />
            )}
          </Form.Item>

          <Form.Item label='連絡電話'>
            {getFieldDecorator('phoneNumber', {
              rules: [
                { required: false, message: 'Please enter phone number' },
              ],
              initialValue: this.state.system
                ? this.state.system.phoneNumber
                : '',
            })(
              <Input
                placeholder='Please enter phone number'
                disabled={disabled}
              />
            )}
          </Form.Item>

          <Form.Item label='電子信箱'>
            {getFieldDecorator('email', {
              rules: [{ required: false, message: 'Please enter email' }],
              initialValue: this.state.system ? this.state.system.email : '',
            })(<Input placeholder='Please enter email' disabled={disabled} />)}
          </Form.Item>

          <div
            style={{
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
            }}
          >
            {disabled ? (
              <div></div>
            ) : (
              <Button type='primary' onClick={this.onSubmit}>
                確認
              </Button>
            )}
          </div>
        </Form>
      );
    }
  }
);

const Info = props => {
  const { system } = props;

  const handleRefreshDoDoRentalUsers = () => {
    async function refresh() {
      try {
        await pos.rentalUserProvider.refreshRentalUser({
          provider_name: 'dodo',
        });

        message.success('刷新請求提交成功，系統將於10秒內更新月租名單');
      } catch (err) {
        message.error('刷新請求提交失敗');
      }
    }

    refresh();
  };

  return (
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
        minHeight: 280,
      }}
    >
      <InfoForm privilege={props.userRoutePrivilege}></InfoForm>

      <Divider></Divider>

      {system.RENTAL_USER_PROVIDER &&
      system.RENTAL_USER_PROVIDER.DODO &&
      system.RENTAL_USER_PROVIDER.DODO.ENABLE ? (
        <Button onClick={handleRefreshDoDoRentalUsers}>刷新嘟嘟月租戶</Button>
      ) : (
        <div></div>
      )}
    </Content>
  );
};

const InfoWapper = connect(state => {
  return {
    system: state.system,
  };
})(Info);

export default InfoWapper;
