import caseConverter from 'change-case';
import pos from 'pos-api';

export const paymentTypeMap = {
  cash: '現金',
  ezcard: '悠遊卡',
  icash: '愛金卡',
  ipass: '一卡通',
  pay_taipei: '台北支',
  pay_taipei_real_time: '台北智慧支付',
  pay_taipei_pos: '台北行動支付',
  credit_card: '信用卡',
  ez_pay: '悠遊付',
  line_pay: 'LinePay',
  line_pay_money: '一卡通Money',
  icash_pay: 'iCashPay',
  op_wallet: 'OP錢包',
  taiwan_pay: '台灣Pay',
  big_voice: '停車大聲公',
  pay_taipei_smart: 'pay_taipei_smart',
  jkopay: '街口支付',
  jkopoint: '街口支付',
  mochi_pay: '車麻吉',
  mochi_pay_auto_pass: '車麻吉-自動',
  remit: '電匯',
  other: '其他',
};

export async function login(user) {
  let resp = await pos.user.login(user);

  let token = resp.token;
  let id = token.split('-')[0];

  localStorage.setItem(
    'user',
    JSON.stringify({
      id: Number(id),
      user: user.name,
      token: token,
    })
  );

  resp = await pos.user.fetchUser(id);

  user = {
    id: Number(id),
    user: resp.name,
    token: token,
    roles: resp.roles,
  };

  localStorage.setItem('user', JSON.stringify(user));

  return user;
}

/**
 * dataURI to Blob
 * solution from https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
 */
export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

/**
 * convert all keys of obj to snake case
 * @param {*} obj
 */
export function snakelize(obj) {
  let res = {};
  if (!obj) return res;
  Object.keys(obj).forEach(key => {
    res[caseConverter.snakeCase(key)] = obj[key];
  });

  return res;
}

/**
 * convert all keys of obj to camel case
 * @param {*} obj
 */
export function camelize(obj) {
  let res = {};
  if (!obj) return res;
  Object.keys(obj).forEach(key => {
    res[caseConverter.camelCase(key)] = obj[key];
  });

  return res;
}

export function doWhenExist(fun) {
  return (...args) => {
    if (fun) {
      fun(...args);
    }
  };
}

export function isBasicType(v) {
  let t = typeof v;
  return (
    t === 'string' || t === 'number' || t === 'boolean' || Array.isArray(v)
  );
}

export function override(src, dest) {
  Object.keys(src).forEach(key => {
    if (isBasicType(src[key])) {
      dest[key] = src[key];
    } else {
      if (dest[key] == null) {
        dest[key] = {};
      }

      override(src[key], dest[key]);
    }
  });
}

export function getUserRoutePrivilege(user, route) {
  let userRoles = user.roles.map(r => r.name);

  let ps = route.privileges;

  if (typeof ps === 'string') {
    ps = [ps];
  }

  for (let i = 0; i < ps.length; i++) {
    let rp = ps[i];

    let [r, p] = rp.split(':');

    if (userRoles.includes(r)) {
      return p;
    }
  }

  return '';
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}
