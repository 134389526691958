import React, { useRef, useEffect, useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Divider,
  Tabs,
  Typography,
  Switch,
  Spin,
} from 'antd';

import { connect } from 'react-redux';

import Reflv from '../../../component/Reflv';

import flv from 'flv.js';

import './FloorLockMonitor.less';

const FlvEvents = flv.Events;

const { Content } = Layout;

const { TabPane } = Tabs;

const { Title, Paragraph } = Typography;

// max delay time for every player
// if player's current time small than LAST_FRAME_TIME - MAX_DELAY_TIME
// force player seek to last frame
const MAX_DELAY_TIME = 1.2;

let emitter = document.createElement('div');
let system = {};

const VideoContainer = props => {
  const playersRef = useRef([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    playersRef.current = props.sources.map(s => null);

    function change() {
      if (document.webkitVisibilityState === 'visible') {
        let players = playersRef.current;

        players.forEach(player => {
          if (!player) return;
          let mediaElement = player._mediaElement;
          if (mediaElement & (mediaElement.buffered.length > 0)) {
            try {
              mediaElement.currentTime = mediaElement.buffered.end(0);
            } catch (err) {
              console.error(err);
            }
          }
        });
      }
    }

    document.addEventListener('visibilitychange', change);

    const frameTracker = setInterval(() => {
      let players = playersRef.current;

      players.forEach(player => {
        if (!player) return;
        let mediaElement = player._mediaElement;

        if (!mediaElement) return;
        try {
          if (mediaElement.buffered.length > 0) {
            let diff = mediaElement.buffered.end(0) - mediaElement.currentTime;
            if (diff >= MAX_DELAY_TIME) {
              mediaElement.currentTime = mediaElement.buffered.end(0);
            }
          }
        } catch (err) {
          console.error(err);
        }
      });
    }, 2000);

    setLoaded(true);

    return function cleanup() {
      document.removeEventListener('visibilitychange', change);
      clearInterval(frameTracker);
    };
  }, []);

  const updatePlayers = (idx, player) => {
    const next = [...playersRef.current];
    next[idx] = player;

    Object.keys(FlvEvents).forEach(key => {
      player.on(key, data => {
        console.log(key);
        console.warn(data);
      });
    });

    playersRef.current = next;
  };

  const seekToLastFrame = idx => {
    let players = playersRef.current;
    let player = players[idx];
    if (!player) return;

    let mediaElement = player._mediaElement;

    if (mediaElement) {
      try {
        if (mediaElement.buffered.length > 0) {
          mediaElement.currentTime = mediaElement.buffered.end(0);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const { sources } = props;

  return loaded ? (
    <div>
      <Row gutter={12}>
        {sources.map((source, idx) => {
          return (
            <Col
              key={idx}
              className='gutter-row'
              span={12}
              style={{ marginBottom: 24 }}
            >
              <div
                style={{
                  float: 'left',
                  margin: '-15px 0px 0px 18px',
                  fontSize: 16,
                  background: '#fff',
                  fontWeight: 'bold',
                  padding: '4px 12px',
                }}
              >
                {source.name}
              </div>
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 5,
                  padding: '12px 12px',
                }}
              >
                <Reflv
                  url={source.http_flv}
                  hasAudio={false}
                  type='flv'
                  isLive={true}
                  cors={true}
                  config={{
                    enableStashBuffer: false,
                  }}
                  onInit={player => updatePlayers(idx, player)}
                  onPause={() => {
                    let players = playersRef.current;
                    let player = players[idx];
                    if (
                      player &&
                      document.webkitVisibilityState === 'visible'
                    ) {
                      console.log('try to play');
                      player.play();
                    }
                  }}
                  onPlay={() => {
                    seekToLastFrame(idx);
                  }}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <div>
      <Spin></Spin>
    </div>
  );
};

const FloorLockCard = props => {
  const [up, setUp] = useState(false);
  const [down, setDown] = useState(false);

  const onclick = type => {
    const send = async () => {
      const { IOSERVER } = system;
      const { floorLock } = props;

      if (type === 'up') {
        setUp(true);
        setTimeout(() => {
          setUp(false);
        }, 500);

        await fetch(
          `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/floor-locks/${floorLock.id}/up`,
          {
            method: 'POST',
            cors: true,
          }
        );
      } else if (type === 'down') {
        setDown(true);
        setTimeout(() => {
          setDown(false);
        }, 500);

        await fetch(
          `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/floor-locks/${floorLock.id}/down`,
          {
            method: 'POST',
            cors: true,
          }
        );
      }
    };

    return () => {
      send();
    };
  };

  const sMap = {
    lock: '鎖定',
    unlock: '解鎖',
    running: '動作中',
    unknown: '未知',
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: '0px 0px 4px 0px',
        marginTop: 12,
      }}
    >
      <div style={{ padding: '6px 8px' }}>
        <Paragraph style={{ fontSize: 18, margin: 0 }}>
          {props.floorLock.name}
        </Paragraph>
      </div>

      <Divider style={{ margin: '0px 0px 8px 0px' }} />

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          上升
        </div>
        <div style={{ flex: 1 }}>
          <Switch checked={up} onClick={onclick('up')} />
        </div>
      </div>

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          下降
        </div>
        <div style={{ flex: 1 }}>
          <Switch checked={down} onClick={onclick('down')} />
        </div>
      </div>

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          狀態:
        </div>
        <div style={{ flex: 1 }}>
          {sMap[props.floorLock.status] || sMap.unknown}
        </div>
      </div>
    </div>
  );
};

const FloorLockMonitor = props => {
  const [loaded, setLoaded] = useState(false);
  const [floorLocks, setLocks] = useState([]);

  const intervalHook = useRef();

  const init = async () => {
    emitter = document.createElement('div');

    system = props.system;

    const { IOSERVER } = props.system;
    let resp = await fetch(
      `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/floor-locks`,
      {
        mode: 'cors',
      }
    );
    let floorLocks = await resp.json();

    setLocks(floorLocks);

    setLoaded(true);
  };

  useEffect(() => {
    init();

    const { IOSERVER } = props.system;

    async function fetchLocks() {
      try {
        let resp = await fetch(
          `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/floor-locks`,
          {
            mode: 'cors',
          }
        );
        let floorLocks = await resp.json();

        setLocks(floorLocks);
      } catch (err) {
        console.log(err);
      }
    }

    intervalHook.current = setInterval(() => {
      fetchLocks();
    }, 3000);

    return () => {
      clearInterval(intervalHook.current);
    };
  }, []);

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        background: '#fff',
        padding: 24,
        minHeight: 280,
      }}
    >
      {loaded && floorLocks.length > 0 ? (
        <div>
          <Row>
            {floorLocks.map((floorLock, idx) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={4}
                  style={{ padding: '8px' }}
                  key={idx}
                >
                  <FloorLockCard floorLock={floorLock}></FloorLockCard>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : floorLocks.length <= 0 ? (
        <div></div>
      ) : (
        <Spin></Spin>
      )}
    </Content>
  );
};

const mapStateToProps = state => {
  return {
    system: state.system,
  };
};

export default connect(mapStateToProps)(FloorLockMonitor);
