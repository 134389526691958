import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Switch,
  Table,
} from 'antd';

import pos from 'pos-api';
import posv2 from '../../../lib/posv2';

const { Search } = Input;
const { Content } = Layout;

function useBlockModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBlockId, setCurrentBlockId] = useState('');
  const [block, setBlock] = useState(null);

  useEffect(() => {
    async function search() {
      if (!currentBlockId) return;
      try {
        let resp = await pos.userBlockEvent.getUserBlockEventById({
          id: currentBlockId,
        });
        const { content } = resp;
        setBlock(content);
      } catch (err) {
        console.error(err);
      }
    }
    search();
  }, [currentBlockId]);

  const showModal = useCallback(id => {
    setIsModalVisible(true);
    setCurrentBlockId(id);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return {
    isModalVisible,
    block,
    showModal,
    closeModal,
  };
}

const columns = [
  {
    title: '群組名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '群組代碼',
    dataIndex: 'group_code',
    key: 'group_code',
  },
  {
    title: '停車上限',
    dataIndex: 'limit',
    key: 'limit',
    render: (limit, record) => {
      return limit + (record.enable_strict_limit ? ' (啟用)' : ' (不啟用)');
    },
  },
  {
    title: '當前停車數',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    render: (_, record) => {
      return (
        <Button
          type='link'
          icon='edit'
          onClick={() => {
            record._update();
          }}
        >
          修改
        </Button>
      );
    },
  },
];

const UpdateModal = Form.create()(
  ({ group, visible, onOk, onCancel, form }) => {
    const { getFieldDecorator, setFieldsValue, validateFields } = form;

    const preGroup = useRef(group);

    useEffect(() => {
      if (group.id === preGroup.current.id) {
        return;
      }

      preGroup.current = group;
      setFieldsValue(group);
    }, [group]);

    const upload = async values => {
      console.log(values);
      let form = {
        name: values.name,
        group_code: values.group_code,
        enable_strict_limit: values.enable_strict_limit,
        limit: values.limit,
      };

      try {
        await posv2.updateRentalGroup(group.id, form);
      } catch (err) {
        console.error(err);
      }

      onOk && onOk();
    };

    const onSubmit = () => {
      validateFields((err, values) => {
        if (err) {
          console.error(err);
          return;
        }

        upload(values);
      });
    };

    return (
      <Modal
        key={group.id}
        title='更新群組'
        visible={visible}
        onOk={() => {
          onSubmit();
        }}
        onCancel={() => {
          onCancel && onCancel();
        }}
        okText='確定'
        cancelText='取消'
      >
        <Form>
          <Form.Item>
            群組名稱:
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '請輸入群組名稱!' }],
              initialValue: group.name,
            })(<Input placeholder='AIRIDER' />)}
          </Form.Item>
          <Form.Item>
            群組代碼:
            {getFieldDecorator('group_code', {
              rules: [{ required: true, message: '請輸入群組代碼!' }],
              initialValue: group.group_code,
            })(<Input placeholder='AIRIDER' />)}
          </Form.Item>
          <Form.Item>
            啟用停車上限: &nbsp;
            {getFieldDecorator('enable_strict_limit', {
              valuePropName: 'checked',
              initialValue: group.enable_strict_limit,
            })(<Switch />)}
          </Form.Item>
          <Form.Item>
            停車上限: &nbsp;
            {getFieldDecorator('limit', {
              rules: [{ required: true, message: '請輸入群組名稱!' }],
              initialValue: group.limit,
            })(<InputNumber />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

const AddModal = Form.create()(({ visible, onOk, onCancel, form }) => {
  const { getFieldDecorator, validateFields } = form;

  const upload = async values => {
    console.log(values);
    let form = {
      name: values.name,
      group_code: values.group_code,
      enable_strict_limit: values.enable_strict_limit,
      limit: values.limit,
    };

    try {
      await posv2.addRentalGroup(form);
    } catch (err) {
      console.error(err);
    }

    onOk && onOk();
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        console.error(err);
        return;
      }

      upload(values);
    });
  };

  return (
    <Modal
      title='新增群組'
      visible={visible}
      onOk={() => {
        onSubmit();
      }}
      onCancel={() => {
        onCancel && onCancel();
      }}
      okText='確定'
      cancelText='取消'
    >
      <Form>
        <Form.Item>
          群組名稱:
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '請輸入群組名稱!' }],
          })(<Input placeholder='AIRIDER' />)}
        </Form.Item>
        <Form.Item>
          群組代碼:
          {getFieldDecorator('group_code', {
            rules: [{ required: true, message: '請輸入群組代碼!' }],
          })(<Input placeholder='AIRIDER' />)}
        </Form.Item>
        <Form.Item>
          啟用停車上限: &nbsp;
          {getFieldDecorator('enable_strict_limit', {
            valuePropName: 'checked',
            initialValue: false,
          })(<Switch />)}
        </Form.Item>
        <Form.Item>
          停車上限: &nbsp;
          {getFieldDecorator('limit', {
            rules: [{ required: true, message: '請輸入群組名稱!' }],
            initialValue: 0,
          })(<InputNumber />)}
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default function RentalGroup({ className, children }) {
  const [rentalGroups, setRentalGroups] = useState([]);

  const [selected, setSelected] = useState(null);

  const [selectedKeys, setSelectedKeys] = useState([]);

  const [state, setState] = useState('view');

  useEffect(() => {
    if (state !== 'view') {
      return;
    }

    const loadRentalGroups = async () => {
      try {
        let resp = await posv2.findRentalGroups({});
        const { content } = resp;

        for (let i = 0; i < content.length; i++) {
          let g = content[i];

          g.key = g.id;
          g._update = () => {
            setSelected(g);
            setState('update');
          };
        }

        setRentalGroups(content);
      } catch (error) {
        console.error(error);
      }
    };
    loadRentalGroups();
  }, [state]);

  const onAdd = () => {
    setState('add');
  };

  const onDelete = async () => {
    try {
      await posv2.deleteRentalGroups(selectedKeys);
    } catch (err) {
      console.error(err);
    }

    setState('view');
  };

  return (
    <div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ float: 'left' }}>
            <Button type='link' icon='plus' onClick={onAdd}>
              新增
            </Button>

            <Button
              type='link'
              icon='delete'
              onClick={() => {
                setState('delete');

                Modal.confirm({
                  title: '刪除資料',
                  onOk: () => {
                    onDelete();
                  },
                });
              }}
            >
              刪除
            </Button>
          </div>
        </Content>
      </div>

      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        <Table
          columns={columns}
          dataSource={rentalGroups}
          pagination={true}
          rowSelection={{
            onChange: selectedRowKeys => {
              setSelectedKeys(selectedRowKeys);
            },
          }}
        />
      </Content>
      <AddModal
        visible={state === 'add'}
        onOk={() => {
          setState('view');
        }}
        onCancel={() => {
          setState('view');
        }}
      />
      {selected && (
        <UpdateModal
          group={selected}
          visible={state === 'update'}
          onOk={() => {
            setState('view');
          }}
          onCancel={() => {
            setState('view');
          }}
        />
      )}
    </div>
  );
}
