import React from 'react';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
} from 'antd';
import { Drawer, Form, Input } from 'antd';

import pos from 'pos-api';
import { memberMapper } from '../../utils/mapper';

import Highlighter from 'react-highlight-words';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: '會員類別',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '黑名單會員',
    dataIndex: 'isBlack',
    key: 'isBlack',
    render: (text, record) => <span>{record.isBlack ? '是' : '否'}</span>,
  },
  {
    title: '其他資訊',
    dataIndex: 'otherInfo',
    key: 'otherInfo',
    render: (text, record) => {
      let result = '';
      if (record.type === 'pay_taipei' && record.data) {
        result = '車種: ' + record.data.carType;
      }

      return <span>{result}</span>;
    },
  },
  {
    title: '操作',
    key: 'action',
    render: (text, record) => (
      <span>
        <Button
          type='link'
          icon='edit'
          onClick={record.onEdit}
          disabled
        ></Button>
        <Divider type='vertical' />
        <Button
          type='link'
          icon='delete'
          onClick={record.onDelete}
          disabled
        ></Button>
      </span>
    ),
  },
];

const UserDrawer = Form.create()(
  class extends React.Component {
    upload = async values => {
      let c = values;
      if (this.props.member) {
        c.id = this.props.member.id;
        c = memberMapper.toEntity(c);
        c = await pos.member.updateUser(c);
      } else {
        c = memberMapper.toEntity(c);
        c = await pos.member.addUser(c);
      }

      return c;
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(c => {
              hide();
              message.info('上傳成功');
              if (this.props.member) {
                if (this.props.actions && this.props.actions.replace) {
                  this.props.actions.replace(memberMapper.toView(c));
                }
              } else {
                if (this.props.actions && this.props.actions.add) {
                  this.props.actions.add(memberMapper.toView(c));
                }
              }

              if (this.props.onClose) {
                this.props.onClose();
              }
            })
            .catch(err => {
              hide();
              message.error('上傳失敗');
              if (this.props.onClose) {
                this.props.onClose();
              }
            });
        } else {
          console.log(err);
        }
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;

      return (
        <Drawer {...this.props}>
          <Form>
            <Form.Item label='名稱'>
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Please enter member name' },
                ],
                initialValue: this.props.member ? this.props.member.name : null,
              })(<Input placeholder='Please enter member name' />)}
            </Form.Item>

            <Form.Item label='連絡電話'>
              {getFieldDecorator('phoneNumber', {
                rules: [
                  { required: true, message: 'Please enter member name' },
                ],
                initialValue: this.props.member
                  ? this.props.member.phoneNumber
                  : null,
              })(<Input placeholder='Please enter member name' />)}
            </Form.Item>

            <Form.Item label='電子信箱'>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Please enter member email' },
                ],
                initialValue: this.props.member
                  ? this.props.member.email
                  : null,
              })(<Input placeholder='Please enter member email' />)}
            </Form.Item>

            {this.props.member ? (
              <div></div>
            ) : (
              <Form.Item label='密碼'>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please enter member pass' },
                  ],
                })(
                  <Input
                    placeholder='Please enter member name'
                    type='password'
                  />
                )}
              </Form.Item>
            )}
          </Form>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class Users extends React.Component {
  state = {
    drawer: {
      title: '',
      visible: false,
    },
    pagination: {
      pageSize: 12,
      current: 1,
    },
    loading: true,
    members: null,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchMembers({
      pagination: pager,
    });
  };

  fetchMembers = async (opts = {}) => {
    this.setState({
      loading: true,
    });

    if (!opts.pagination) {
      opts.pagination = this.state.pagination;
    }

    if (this.searchBy && this.searchText) {
      opts.searchBy = 'name';
      opts.searchText = this.searchText;
    }

    let result = await pos.member.fetchMembers({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
      searchText: opts.searchText,
      searchBy: opts.searchBy,
    });

    let pagination = result.pagination;
    let members = result.content;
    members = memberMapper.toView(members);
    members.forEach((member, idx) => {
      member.key = member.id;
      member.onEdit = this.onEditBtn(member);
      member.onDelete = this.onDeleteBtn(member);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      members: members,
      pagination: pager,
      loading: false,
    });
  };

  onDrawerClose = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = false;
      return {
        drawer,
      };
    });
  };

  showDrawer = title => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = true;
      return {
        drawer,
      };
    });
  };

  onAddBtn = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '新增';
      return {
        drawer,
        current: null,
      };
    });

    this.showDrawer();
  };

  onEditBtn = member => () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '修改';
      return {
        drawer,
        current: member,
      };
    });

    this.showDrawer();
  };

  deleteUser = async member => {
    await pos.member.deleteUser(member.id);
  };

  onDeleteBtn = member => () => {
    let hide = message.loading('處理中...');
    this.deleteUser(member)
      .then(() => {
        hide();
        message.info('刪除成功');
        this.setState(prev => {
          return {
            members: prev.members.filter(c => c !== member),
          };
        });
      })
      .catch(err => {
        hide();
        message.error('刪除失敗');
      });
  };

  drawerActoins = {
    add: c => {
      this.setState(prev => {
        c.key = c.id;
        c.onEdit = this.onEditBtn(c);
        c.onDelete = this.onDeleteBtn(c);
        return {
          members: [...prev.members, c],
        };
      });
    },
    replace: c => {
      this.setState(prev => {
        let cs = prev.members;
        let idx = -1;
        for (let i = 0; i < cs.length; i++) {
          if (cs[i].id === c.id) {
            idx = i;
            break;
          }
        }

        if (idx >= 0) {
          c.key = c.id;
          c.onEdit = this.onEditBtn(c);
          c.onDelete = this.onDeleteBtn(c);
          cs.splice(idx, 1, c);
          let next = [...cs];
          return {
            members: next,
          };
        }

        return {};
      });
    },
  };

  onSearch = text => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (text === '') {
        this.searchBy = null;
      } else {
        this.searchBy = 'name';
      }

      this.searchText = text;

      this.fetchMembers();
    }, 250);
  };

  getColumns = () => {
    const nameColumn = {
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.searchText || '']}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
    };

    return [nameColumn, ...columns];
  };

  componentDidMount() {
    this.fetchMembers();
  }

  render() {
    const totalColumns = this.getColumns();
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          {/*<Button type='link' icon='plus' onClick={this.onAddBtn} disabled>新增會員</Button>*/}

          <Search
            placeholder='請輸入車號'
            onSearch={this.onSearch}
            onChange={evt => {
              let value = evt.target.value;
              if (value == null) return;

              this.onSearch(value);
            }}
            style={{ width: 200, float: 'right' }}
          />
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={totalColumns}
            dataSource={this.state.members}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>

        <UserDrawer
          width={600}
          title={this.state.drawer.title}
          placement='right'
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawer.visible}
          member={this.state.current}
          actions={this.drawerActoins}
        ></UserDrawer>
      </Content>
    );
  }
}

export default Users;
