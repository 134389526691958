import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Form, Input, Layout, Modal, Table } from 'antd';

import pos from 'pos-api';
import posv2 from '../../../lib/posv2';

const { Search } = Input;
const { Content } = Layout;

function useBlockModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBlockId, setCurrentBlockId] = useState('');
  const [block, setBlock] = useState(null);

  useEffect(() => {
    async function search() {
      if (!currentBlockId) return;
      try {
        let resp = await pos.userBlockEvent.getUserBlockEventById({
          id: currentBlockId,
        });
        const { content } = resp;
        setBlock(content);
      } catch (err) {
        console.error(err);
      }
    }
    search();
  }, [currentBlockId]);

  const showModal = useCallback(id => {
    setIsModalVisible(true);
    setCurrentBlockId(id);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return {
    isModalVisible,
    block,
    showModal,
    closeModal,
  };
}

const columns = [
  {
    title: '部門名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '部門代碼',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    render: (_, record) => {
      return (
        <Button
          type='link'
          icon='edit'
          onClick={() => {
            record._update();
          }}
        >
          修改
        </Button>
      );
    },
  },
];

const UpdateModal = Form.create()(({ dept, visible, onOk, onCancel, form }) => {
  const { getFieldDecorator, setFieldsValue, validateFields } = form;

  const preDept = useRef(dept);

  useEffect(() => {
    if (dept.id === preDept.current.id) {
      return;
    }

    preDept.current = dept;
    setFieldsValue(dept);
  }, [dept]);

  const upload = async values => {
    let form = {
      name: values.name,
      code: values.code,
    };

    try {
      await posv2.updateDept(dept.id, form);
    } catch (err) {
      console.error(err);
    }

    onOk && onOk();
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        console.error(err);
        return;
      }

      upload(values);
    });
  };

  return (
    <Modal
      title='新增群組'
      visible={visible}
      onOk={() => {
        onSubmit();
      }}
      onCancel={() => {
        onCancel && onCancel();
      }}
      okText='確定'
      cancelText='取消'
    >
      <Form>
        <Form.Item>
          部門名稱:
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '請輸入部門名稱!' }],
            initialValue: dept.name,
          })(<Input placeholder='AIRIDER' />)}
        </Form.Item>
        <Form.Item>
          部門代碼:
          {getFieldDecorator('code', {
            rules: [{ required: true, message: '請輸入部門代碼!' }],
            initialValue: dept.code,
          })(<Input placeholder='AIRIDER' />)}
        </Form.Item>
      </Form>
    </Modal>
  );
});

const AddModal = Form.create()(({ visible, onOk, onCancel, form }) => {
  const { getFieldDecorator, validateFields } = form;

  const upload = async values => {
    console.log(values);
    let form = {
      name: values.name,
      code: values.code,
    };

    try {
      await posv2.addDept(form);
    } catch (err) {
      console.error(err);
    }

    onOk && onOk();
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        console.error(err);
        return;
      }

      upload(values);
    });
  };

  return (
    <Modal
      title='新增群組'
      visible={visible}
      onOk={() => {
        onSubmit();
      }}
      onCancel={() => {
        onCancel && onCancel();
      }}
      okText='確定'
      cancelText='取消'
    >
      <Form>
        <Form.Item>
          部門名稱:
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '請輸入部門名稱!' }],
          })(<Input placeholder='AIRIDER' />)}
        </Form.Item>
        <Form.Item>
          部門代碼:
          {getFieldDecorator('code', {
            rules: [{ required: true, message: '請輸入部門代碼!' }],
          })(<Input placeholder='AIRIDER' />)}
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default function Depts({ className, children }) {
  const [depts, setDepts] = useState([]);

  const [selected, setSelected] = useState(null);

  const [selectedKeys, setSelectedKeys] = useState([]);

  const [state, setState] = useState('view');

  useEffect(() => {
    if (state !== 'view') {
      return;
    }

    const loadDepts = async () => {
      try {
        let resp = await posv2.findDepts({});
        const { content } = resp;

        for (let i = 0; i < content.length; i++) {
          let g = content[i];

          g.key = g.id;
          g._update = () => {
            setSelected(g);
            setState('update');
          };
        }

        setDepts(content);
      } catch (error) {
        console.error(error);
      }
    };
    loadDepts();
  }, [state]);

  const onAdd = () => {
    setState('add');
  };

  const onDelete = async () => {
    try {
      await posv2.deleteDepts(selectedKeys);
    } catch (err) {
      console.error(err);
    }

    setState('view');
  };

  return (
    <div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ float: 'left' }}>
            <Button type='link' icon='plus' onClick={onAdd}>
              新增
            </Button>

            <Button
              type='link'
              icon='delete'
              onClick={() => {
                setState('delete');

                Modal.confirm({
                  title: '刪除資料',
                  onOk: () => {
                    onDelete();
                  },
                });
              }}
            >
              刪除
            </Button>
          </div>
        </Content>
      </div>

      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        <Table
          columns={columns}
          dataSource={depts}
          pagination={true}
          rowSelection={{
            onChange: selectedRowKeys => {
              setSelectedKeys(selectedRowKeys);
            },
          }}
        />
      </Content>
      <AddModal
        visible={state === 'add'}
        onOk={() => {
          setState('view');
        }}
        onCancel={() => {
          setState('view');
        }}
      />
      {selected && (
        <UpdateModal
          dept={selected}
          visible={state === 'update'}
          onOk={() => {
            setState('view');
          }}
          onCancel={() => {
            setState('view');
          }}
        />
      )}
    </div>
  );
}
