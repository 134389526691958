import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  TimePicker,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import pos from 'pos-api';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import posv2 from '../../../lib/posv2';
import routes from '../../../Routes';
import { defaultMapper, rentalUserMapper } from '../../../utils/mapper';
import './RentalUserForm.less';
// import { Layout, Form, Input, InputNumber, DatePicker, Typography, Select,
//   Button, Spin, message, Switch, Col, Row, Menu, Icon, List, TimePicker, Table, Collapse, Divider, Tag, Modal } from 'antd';
// import { serviceMapper, categoryMapper, defaultMapper, rentalUserMapper } from '../../../utils/mapper';
// import * as util from '../../../utils/utils';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const BasicSection = props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const { user } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label='名稱'
              htmlFor='name'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your service name!',
                  },
                ],
                initialValue: user ? user.name : 'name',
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='月租類型'
              htmlFor='type'
              {...formItemLayout}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('type', {
                rules: [
                  {
                    required: true,
                    message: 'Please select your rental user type!',
                  },
                ],
                initialValue: user ? user.type : 'park_rental_user',
              })(
                <Select>
                  <Option value='park_rental_user'>停車月租用戶</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

const ContentSection = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const { user, system } = props;

  let carTypeOptions = 'all';
  if (system.PARK_RENTAL_USER?.RENTAL_TYPE_SELECTION) {
    carTypeOptions = system.PARK_RENTAL_USER.RENTAL_TYPE_SELECTION;
  }

  let dayOptionsType = 'week';

  if (system.PARK_RENTAL_USER?.RENTAL_PERIOD_CALENDAR_TYPE === 'gov_calendar') {
    dayOptionsType = 'dayType';
  }

  const [plateKeys, setPlateKeys] = useState([]);

  const [etagKeys, setEtagKeys] = useState([]);

  const [periodKeys, setPeriodKeys] = useState([]);

  const [rentalServices, setRentalServices] = useState([]);

  const [loading, setLoading] = useState(true);

  const [nearestRentalCard, setNearestRentalCard] = useState();

  const [rentalGroups, setRentalGroups] = useState([]);

  const [depts, setDepts] = useState([]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const addNewPlate = () => {
    let key = uuid();
    let next = [...plateKeys, key];

    setPlateKeys(next);
  };

  const removePlate = key => {
    let next = plateKeys.filter(_key => _key !== key);

    setPlateKeys(next);
  };

  const addNewEtag = () => {
    let key = uuid();
    let next = [...etagKeys, key];

    setEtagKeys(next);
  };

  const removeEtag = key => {
    let next = plateKeys.filter(_key => _key !== key);

    setEtagKeys(next);
  };

  const addNewPeriod = () => {
    let key = uuid();
    let next = [...periodKeys, key];

    setPeriodKeys(next);
  };

  const removePeriod = key => {
    let next = periodKeys.filter(_key => _key !== key);

    setPeriodKeys(next);
  };

  const updatePeriod = (periodKey, key, value) => {
    let field = getFieldValue(`periods.${periodKey}`);
    field[key] = value;

    let periods = {};
    periods[periodKey] = field;

    setFieldsValue({
      periods: periods,
    });
  };

  const initIdentities = identities => {
    if (!identities) return;

    let plates = {};
    let etags = {};

    let plateIdentities = identities.filter(
      identity => identity.type !== 'etag'
    );
    let etagIdentities = identities.filter(
      identity => identity.type === 'etag'
    );

    plateIdentities.forEach(idt => {
      plates[uuid()] = idt;
    });

    etagIdentities.forEach(idt => {
      etags[uuid()] = idt;
    });

    let plateKeys = Object.keys(plates);
    let etagKeys = Object.keys(etags);

    setPlateKeys(plateKeys);
    setEtagKeys(etagKeys);

    // hack
    // ant form must render first
    // than set field value
    setTimeout(() => {
      setFieldsValue({
        plates: plates,
        etags,
      });
    }, 250);
  };

  const initPeriods = periods => {
    if (!periods) return;

    let fieldPeriods = {};

    periods.forEach(p => {
      fieldPeriods[uuid()] = p;
    });

    setPeriodKeys(Object.keys(fieldPeriods));

    setTimeout(() => {
      setFieldsValue({
        periods: fieldPeriods,
      });
    }, 250);
  };

  const initRentalService = async () => {
    let rentalServices = await pos.service.fetchServices({
      type: 'park_rental',
      page_size: 1000,
    });

    rentalServices = rentalServices.content;

    if (rentalServices) {
      setRentalServices(rentalServices);
    }
  };

  const initRentalGroups = async () => {
    let groups = await posv2.findRentalGroups({
      page_size: 1000,
    });

    groups = groups.content;

    if (groups) {
      setRentalGroups(groups);
    }
  };

  const initDepts = async () => {
    let depts = await posv2.findDepts({
      page_size: 1000,
    });

    depts = depts.content;

    if (depts) {
      setDepts(depts);
    }
  };

  const initNearestRentalCard = async () => {
    let resp = await pos.rentalUser.readRentalUserCards(user.id, {
      sortDirection: 'desc',
    });

    let items = resp.content;

    if (items[0]) {
      setNearestRentalCard(items[0]);
    }
  };

  useEffect(() => {
    if (!user) {
      initRentalService().then(() => {
        setLoading(false);
      });
      return;
    }
    let identities = user.identities;

    initIdentities(identities);

    let data = user.data;
    if (!data) return;
    let periods = data.periods;
    if (!periods) return;

    console.log(periods);

    initPeriods(periods);

    if (props.disableRentalTimeControl) {
      initNearestRentalCard();
    }

    initRentalService().then(() => {
      setLoading(false);
    });

    initRentalGroups();
    initDepts();
  }, [user]);

  if (loading) {
    return <div className='content-wrapper'></div>;
  }

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Form.Item label='月租時間' style={{ marginBottom: 0 }}>
          {user && props.disableRentalTimeControl ? (
            <>
              <p>
                {moment(nearestRentalCard?.start_time).format('YYYY-MM-DD') +
                  ' 至 ' +
                  moment(nearestRentalCard?.end_time).format('YYYY-MM-DD')}
              </p>
            </>
          ) : (
            <>
              <Form.Item
                help='開始時間'
                style={{ display: 'inline-block' }}
                htmlFor='startTime'
              >
                {getFieldDecorator('startTime', {
                  rules: [],
                  initialValue: user ? moment(user.startTime) : moment(),
                })(<DatePicker format='YYYY-MM-DD' />)}
              </Form.Item>
              <span
                style={{
                  display: 'inline-block',
                  width: '24px',
                  textAlign: 'center',
                }}
              >
                -
              </span>
              <Form.Item
                help='結束時間'
                style={{ display: 'inline-block' }}
                htmlFor='endTime'
              >
                {getFieldDecorator('endTime', {
                  rules: [],
                  initialValue: user ? moment(user.endTime) : moment(),
                })(<DatePicker format='YYYY-MM-DD' />)}
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item label='租賃車種' style={{ marginBottom: 0 }}>
          {carTypeOptions === 'car_only'
            ? getFieldDecorator('carType', {
                rules: [],
                initialValue: user && user.carType ? user.carType : 'C',
              })(
                <Select style={{ width: 200 }}>
                  <Option value='C'>汽車</Option>
                </Select>
              )
            : carTypeOptions === 'moto_only'
            ? getFieldDecorator('carType', {
                rules: [],
                initialValue: user && user.carType ? user.carType : 'M',
              })(
                <Select style={{ width: 200 }}>
                  <Option value='M'>機車</Option>
                </Select>
              )
            : getFieldDecorator('carType', {
                rules: [],
                initialValue: user && user.carType ? user.carType : 'C',
              })(
                <Select style={{ width: 200 }}>
                  <Option value='C'>汽車</Option>
                  <Option value='M'>機車</Option>
                </Select>
              )}
        </Form.Item>

        <Form.Item label='車牌:' style={{ marginBottom: 0, marginTop: 12 }}>
          {plateKeys.map((key, idx) => (
            <div key={idx}>
              {getFieldDecorator(`plates.${key}.id`, {
                rules: [],
                initialValue: null,
              })(
                <InputNumber
                  placeholder='Please enter plate number'
                  style={{ width: 160, marginRight: 8, display: 'none' }}
                />
              )}

              {getFieldDecorator(`plates.${key}.content`, {
                rules: [],
                initialValue: `ABC`,
              })(
                <Input
                  placeholder='Please enter plate number'
                  style={{ width: 160, marginRight: 8 }}
                />
              )}

              {
                <Icon
                  className='dynamic-delete-button'
                  type='minus-circle-o'
                  onClick={() => removePlate(key)}
                />
              }
            </div>
          ))}

          {!(props.onePlateOnly && plateKeys.length >= 1) && (
            <Button
              type='dashed'
              style={{ width: 160, marginTop: 12 }}
              onClick={addNewPlate}
            >
              <Icon type='plus' /> 新增車牌
            </Button>
          )}
        </Form.Item>

        <Form.Item label='啟用不計位' style={{ marginBottom: 0 }}>
          {getFieldDecorator(`isNotCountSpace`, {
            rules: [],
            valuePropName: 'checked',
            initialValue:
              user && user.isNotCountSpace ? user.isNotCountSpace : false,
          })(<Switch />)}
        </Form.Item>

        <Form.Item label='etag:' style={{ marginBottom: 0, marginTop: 12 }}>
          {etagKeys.map((key, idx) => (
            <div key={idx}>
              {getFieldDecorator(`etags.${key}.id`, {
                rules: [],
                initialValue: null,
              })(
                <InputNumber
                  placeholder='Please enter plate number'
                  style={{ width: 160, marginRight: 8, display: 'none' }}
                />
              )}

              {getFieldDecorator(`etags.${key}.content`, {
                rules: [],
                initialValue: ``,
              })(
                <Input
                  placeholder='Please enter etag number'
                  style={{ width: 160, marginRight: 8 }}
                />
              )}

              {
                <Icon
                  className='dynamic-delete-button'
                  type='minus-circle-o'
                  onClick={() => removeEtag(key)}
                />
              }
            </div>
          ))}

          <Button
            type='dashed'
            style={{ width: 160, marginTop: 12 }}
            onClick={addNewEtag}
          >
            <Icon type='plus' /> 新增Etag
          </Button>
        </Form.Item>

        <Form.Item label='票種' style={{ marginBottom: 0 }}>
          {getFieldDecorator('rentalServiceId', {
            rules: [],
            initialValue:
              user && user.rentalServiceId
                ? user.rentalServiceId + ''
                : rentalServices[0]
                ? rentalServices[0].id + ''
                : '',
          })(
            <Select style={{ width: 200 }}>
              {rentalServices.map(s => {
                return <Option value={s.id + ''}>{s.name}</Option>;
              })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label='群組' style={{ marginBottom: 0 }}>
          {getFieldDecorator('rentalGroupId', {
            rules: [],
            initialValue: user && user.rentalGroup ? user.rentalGroup.id : null,
          })(
            <Select style={{ width: 200 }}>
              {rentalGroups.map(s => {
                return <Option value={s.id}>{s.name}</Option>;
              })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label='部門' style={{ marginBottom: 0 }}>
          {getFieldDecorator('deptId', {
            rules: [],
            initialValue: user && user.dept ? user.dept.id : null,
          })(
            <Select style={{ width: 200 }}>
              {depts.map(s => {
                return <Option value={s.id}>{s.name}</Option>;
              })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label='入場上限' style={{ marginBottom: 0 }}>
          {getFieldDecorator(`entryLimit`, {
            rules: [],
            initialValue: user ? user.entryLimit : null,
            normalize: value => {
              const number = Number(value);
              // 把NaN或小於等於0轉成null
              if (isNaN(number) || number <= 0) return null;
              return number;
            },
          })(
            <Input
              type='number'
              min={0}
              placeholder='Please enter number'
              style={{ width: 160, marginRight: 8 }}
            />
          )}
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <div>月租時段:</div>

          <div style={{ width: '100%', margin: 'auto' }}>
            {periodKeys.map(key => (
              <div key={`time-block-${key}`} className='time-block'>
                <span className='time-block-header'>時段:</span>

                <div className='time-block-content' style={{ display: 'flex' }}>
                  {dayOptionsType === 'dayType' ? (
                    <div>
                      {getFieldDecorator(`periods.${key}.dayType`, {
                        rules: [],
                        initialValue: `all`,
                      })(
                        <Select style={{ width: 120 }}>
                          <Option value='all'>全部</Option>
                          <Option value='workday'>平日</Option>
                          <Option value='holiday'>假日</Option>
                          <Option value='sp_holiday'>特別假日</Option>
                        </Select>
                      )}
                    </div>
                  ) : (
                    <div>
                      {getFieldDecorator(`periods.${key}.startDay`, {
                        rules: [],
                        initialValue: `1`,
                      })(
                        <Select style={{ width: 120 }}>
                          <Option value='1'>星期一</Option>
                          <Option value='2'>星期二</Option>
                          <Option value='3'>星期三</Option>
                          <Option value='4'>星期四</Option>
                          <Option value='5'>星期五</Option>
                          <Option value='6'>星期六</Option>
                          <Option value='7'>星期日</Option>
                        </Select>
                      )}

                      <span
                        style={{
                          display: 'inline-block',
                          width: '24px',
                          textAlign: 'center',
                        }}
                      >
                        -
                      </span>

                      {getFieldDecorator(`periods.${key}.endDay`, {
                        rules: [],
                        initialValue: `7`,
                      })(
                        <Select style={{ width: 120 }}>
                          <Option value='1'>星期一</Option>
                          <Option value='2'>星期二</Option>
                          <Option value='3'>星期三</Option>
                          <Option value='4'>星期四</Option>
                          <Option value='5'>星期五</Option>
                          <Option value='6'>星期六</Option>
                          <Option value='7'>星期日</Option>
                        </Select>
                      )}
                    </div>
                  )}

                  <span
                    style={{
                      display: 'inline-block',
                      width: '24px',
                      textAlign: 'center',
                    }}
                  >
                    {' '}
                  </span>

                  <div>
                    <Form.Item
                      help='開始時間'
                      style={{ display: 'inline-block' }}
                      htmlFor='startTime'
                    >
                      {getFieldDecorator(`periods.${key}.startTime`, {
                        rules: [],
                        initialValue: moment('00:00', 'HH:mm'),
                      })(
                        <TimePicker
                          format='HH:mm'
                          onChange={v => updatePeriod(key, 'startTime', v)}
                        />
                      )}
                    </Form.Item>

                    <span
                      style={{
                        display: 'inline-block',
                        width: '24px',
                        textAlign: 'center',
                      }}
                    >
                      -
                    </span>

                    <Form.Item
                      help='結束時間'
                      style={{ display: 'inline-block' }}
                      htmlFor='endTime'
                    >
                      {getFieldDecorator(`periods.${key}.endTime`, {
                        rules: [],
                        initialValue: moment('23:59', 'HH:mm'),
                      })(
                        <TimePicker
                          format='HH:mm'
                          onChange={v => updatePeriod(key, 'endTime', v)}
                        />
                      )}
                    </Form.Item>
                  </div>

                  <Button
                    key={`periods-${key}-delete`}
                    type='link'
                    icon='delete'
                    style={{ marginLeft: 32 }}
                    onClick={() => removePeriod(key)}
                  ></Button>
                </div>
              </div>
            ))}

            <Button
              type='dashed'
              style={{ width: '100%', marginTop: 12 }}
              onClick={addNewPeriod}
            >
              <Icon type='plus' /> 新增時段
            </Button>
          </div>
        </Form.Item>
      </Content>
    </div>
  );
};

const columns = [
  {
    title: '車牌',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '進場時間',
    dataIndex: 'ctime',
    key: 'ctime',
    render: ctime => {
      if (!ctime) {
        return 'unknown';
      } else {
        return new Date(ctime).toLocaleString();
      }
    },
  },
  {
    title: '出場時間',
    dataIndex: 'etime',
    key: 'etime',
    render: etime => {
      if (!etime) {
        return '尚未出場';
      } else {
        return new Date(etime).toLocaleString();
      }
    },
  },
];

const ParkRecords = props => {
  const { user } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 8,
    current: 1,
    total: 100,
  });

  const fetchRentalUserItems = async pagination => {
    setLoading(true);

    let resp = await pos.rentalUser.fetchRentalUserItems(user.id, {
      sortDirection: 'desc',
      pageSize: pagination.pageSize,
      page: pagination.current - 1,
    });

    let items = resp.content;

    setItems(items);

    let nextPagination = {
      ...pagination,
    };

    nextPagination.total = resp.pagination.total_elements;
    setPagination(nextPagination);

    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchRentalUserItems(pagination);
  };

  const init = async pagination => {
    fetchRentalUserItems(pagination);
  };

  useEffect(() => {
    init(pagination);
  }, []);

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={items}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

const paymentTypeMap = {
  cash: '現金',
  ezcard: '悠遊卡',
  icash: '愛金卡',
  ipass: '一卡通',
  pay_taipei: '台北支',
  pay_taipei_real_time: '台北行動支付',
  pay_taipei_pos: '台北行動支付',
  credit_card: '信用卡',
  ez_pay: '悠遊付',
  line_pay: 'LinePay',
  big_voice: '停車大聲公',
  pay_taipei_smart: 'pay_taipei_smart',
  other: '其他',
};

const cardColumns = [
  {
    title: '車號',
    dataIndex: 'owner_plate',
    key: 'owner_plate',
    width: 150,
  },
  {
    title: '票種',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '售票時間',
    dataIndex: 'create_time',
    key: 'create_time',
    render: (text, record) => {
      return moment(text).format('YYYY/MM/DD HH:mm:ss');
    },
    width: 250,
  },

  {
    title: '開始日期',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (text, record) => {
      return moment(text).format('YYYY/MM/DD HH:mm:ss');
    },
    width: 250,
  },

  {
    title: '截止時間',
    dataIndex: 'end_time',
    key: 'end_time',
    width: 250,
    render: (text, record) => {
      return moment(text).format('YYYY/MM/DD HH:mm:ss');
    },
  },
  {
    title: '金額',
    dataIndex: 'price',
    key: 'price',
    width: 150,
  },
  {
    title: '支付方式',
    dataIndex: 'payment_type',
    key: 'payment_type',
    width: 150,
    render: (text, record) => {
      if (paymentTypeMap[text]) {
        text = paymentTypeMap[text];
      }
      return text;
    },
  },
];

const RentalCardRecord = props => {
  const { user } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 8,
    current: 1,
    total: 100,
  });

  const fetchRentalUserCards = async pagination => {
    setLoading(true);

    let resp = await pos.rentalUser.readRentalUserCards(user.id, {
      sortDirection: 'desc',
      pageSize: pagination.pageSize,
      page: pagination.current - 1,
    });

    let items = resp.content;

    setItems(items);

    let nextPagination = {
      ...pagination,
    };

    nextPagination.total = resp.pagination.total_elements;
    setPagination(nextPagination);

    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchRentalUserCards(pagination);
  };

  const init = async pagination => {
    fetchRentalUserCards(pagination);
  };

  useEffect(() => {
    init(pagination);
  }, []);

  return (
    <Table
      loading={loading}
      columns={cardColumns}
      dataSource={items}
      pagination={pagination}
      onChange={handleTableChange}
      scroll={{ x: true }}
    />
  );
};

const OtherSection = props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const { user } = props;

  const { detail } = user;

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='content-wrapper'>
      <Content className='content'>
        <Collapse defaultActiveKey={[]}>
          {/* <Panel header="車主資料" key="1">
                        <Form.Item label='地址' style={{ marginBottom: 0 }}>
                            {
                                getFieldDecorator('detail.address', {
                                    rules: [],
                                    initialValue: (detail && detail.address)? detail.address : ''
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='電話' style={{ marginBottom: 0 }}>
                            {
                                getFieldDecorator('detail.phoneNumber', {
                                    rules: [],
                                    initialValue: (detail && detail.phoneNumber)? detail.phoneNumber : ''
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='承銷號碼' style={{ marginBottom: 0 }}>
                            {
                                getFieldDecorator('detail.underwriteNumber', {
                                    rules: [],
                                    initialValue: (detail && detail.underwriteNumber)? detail.underwriteNumber : ''
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='統一編號' style={{ marginBottom: 0 }}>
                            {
                                getFieldDecorator('detail.taxNumber', {
                                    rules: [],
                                    initialValue: (detail && detail.taxNumber)? detail.taxNumber : ''
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='車色' style={{ marginBottom: 0 }}>
                            {
                                getFieldDecorator('detail.carColor', {
                                    rules: [],
                                    initialValue: (detail && detail.carColor)? detail.carColor : ''
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='載重' style={{ marginBottom: 0 }}>
                            {
                                getFieldDecorator('detail.maxLoad', {
                                    rules: [],
                                    initialValue: (detail && detail.maxLoad)? detail.maxLoad : ''
                                })(<Input></Input>)
                            }
                        </Form.Item>
                    </Panel> */}
          <Panel header='停車紀錄' key='1'>
            <ParkRecords user={user}></ParkRecords>
          </Panel>
          <Panel header='購票紀錄' key='2'>
            <RentalCardRecord user={user}></RentalCardRecord>
          </Panel>
        </Collapse>
      </Content>
    </div>
  );
};

function removeTime(date) {
  return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD');
}

const toEntityPerprocess = v => {
  let periods = v.periods || {};
  let plates = v.plates || {};
  let etags = v.etags || {};

  periods = Object.keys(periods).map(key => {
    return periods[key];
  });

  periods.forEach(p => {
    p.startDay = Number(p.startDay);
    p.endDay = Number(p.endDay);
  });

  let carType = v.carType;

  let enableParkSpaceManage = v.enableParkSpaceManage;

  let identities = Object.keys(plates).map(key => {
    plates[key].content = plates[key].content.replace('-', '');

    return {
      id: plates[key].id,
      content: plates[key].content,
      type: carType === 'M' ? 'plate_moto' : 'plate_car',
    };
  });

  let etagIdentities = Object.keys(etags).map(key => {
    return {
      id: etags[key].id,
      content: etags[key].content,
      type: 'etag',
    };
  });

  identities = [...identities, ...etagIdentities];

  let rentalServiceId;
  if (v.rentalServiceId) {
    rentalServiceId = Number(v.rentalServiceId);
  }

  return {
    name: v.name,
    type: v.type,
    stime: v.startTime && removeTime(v.startTime),
    etime:
      v.endTime &&
      moment(
        v.endTime.format('YYYY-MM-DD') + ' 23:59:59',
        'YYYY-MM-DD HH:mm:ss'
      ),
    data: {
      periods: defaultMapper.toEntity(periods),
      car_type: carType,
      enable_park_space_manage: enableParkSpaceManage,
      is_not_count_space: v.isNotCountSpace,
    },
    detail: defaultMapper.toEntity(v.detail),
    identities,
    rental_service_id: rentalServiceId,
    entry_limit: v.entryLimit,
    rental_group_id: v.rentalGroupId,
    dept_id: v.deptId,
  };
};

const toViewPostprocess = entity => {
  entity.startTime = moment(entity.startTime);
  entity.endTime = moment(entity.endTime);
  let data = entity.data;

  if (entity.rentalService) {
    entity.rentalServiceId = entity.rentalService.id;
  }

  if (data) {
    let periods = data.periods;

    if (periods) {
      data.periods = defaultMapper.toView(data.periods);
      data.periods.forEach(p => {
        p.startDay = p.startDay + '';
        p.endDay = p.endDay + '';
        p.startTime = moment(p.startTime);
        p.endTime = moment(p.endTime);
      });
    }

    entity.carType = data.car_type;
    entity.isNotCountSpace = data.is_not_count_space;
    entity.enableParkSpaceManage = data.enable_park_space_manage;
  }

  if (entity.detail) {
    entity.detail = defaultMapper.toView(entity.detail);
  }
};

class RentalUserForm extends React.Component {
  state = {
    op: 'add',
    privilege: 'r',
    showModal: false,
    rentalQty: {
      isEnable: false,
      totalQtyCar: 0,
      totalQtyMoto: 0,
      nameCar: '',
      nameMoto: '',
      soldQtyCar: 0,
      soldQtyMoto: 0,
    },
    rentalServices: [],
  };

  fetchRentalUser = async () => {
    let user = await pos.rentalUser.fetchRentalUser(this.props.match.params.id);

    user = rentalUserMapper.toView(user);
    toViewPostprocess(user);
    this.setState({
      user: user,
    });
  };

  findRentalServicesNameById = id => {
    const { rentalServices } = this.state;
    if (typeof rentalServices === 'undefined' || !/^\d+$/.test(id)) {
      console.error('沒有下拉資料 或 非數字');
      return '';
    }
    const numberID = parseInt(id, 10);
    const service = rentalServices.find(s => s.id === numberID);
    return service ? service.name : '';
  };

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id !== '0') {
      this.fetchRentalUser();
      this.setState({
        op: 'edit',
      });
    } else {
      this.setState({
        op: 'add',
      });
    }

    // START rentalQty
    const initRentalQty = async () => {
      try {
        let system = await pos.system.fetchSystem();
        const {
          MAXIMUM_CAR_RENTAL = 0, // 汽車月租的數量上限
          MAXIMUM_MOTO_RENTAL = 0, // 機車月租的數量上限
          CAR_RENTAL_NAME = '', // 月租票種 汽車
          MOTO_RENTAL_NAME = '', // 月租票種 機車
          ENABLE = false, // 是否啟用該功能 (汽機車的月租上限數量)
        } = system.CHECK_RENTAL_USER_NUMER || {};

        let rentalNumbers = await pos.service.fetchServiceRentalNumbers();
        const { car_rental_user_number = 0, moto_rental_user_number = 0 } =
          rentalNumbers || {};

        return {
          rentalQty: {
            isEnable: ENABLE,
            totalQtyCar: MAXIMUM_CAR_RENTAL,
            totalQtyMoto: MAXIMUM_MOTO_RENTAL,
            nameCar: CAR_RENTAL_NAME,
            nameMoto: MOTO_RENTAL_NAME,
            soldQtyCar: car_rental_user_number,
            soldQtyMoto: moto_rental_user_number,
          },
        };
      } catch (error) {
        console.error('An error occurred:', error);
        return {
          rentalQty: {
            isEnable: false,
            totalQtyCar: 0,
            totalQtyMoto: 0,
            nameCar: '',
            nameMoto: '',
            soldQtyCar: 0,
            soldQtyMoto: 0,
          },
        };
      }
    };
    // END rentalQty

    const initRentalServices = async () => {
      let resp = await pos.service.fetchServices({
        type: 'park_rental',
        page_size: 50,
      });

      return {
        rentalServices: resp.content,
      };
    };

    Promise.all([initRentalQty(), initRentalServices()]).then(
      ([rentalQtyResult, rentalServicesResult]) => {
        const { rentalQty } = rentalQtyResult;
        const { rentalServices } = rentalServicesResult;

        this.setState({
          privilege: this.props.userRoutePrivilege,
          rentalQty:
            typeof rentalQty !== 'undefined' ? rentalQty : this.state.rentalQty,
          rentalServices:
            Array.isArray(rentalServices) && rentalServices.length > 0
              ? rentalServices
              : this.state.rentalServices,
        });
      }
    );
  }

  upload = async values => {
    let hide = message.loading('處理中...');

    let result;
    try {
      let entity = toEntityPerprocess(values);
      entity = rentalUserMapper.toEntity(entity);

      const duplicatePlate = await this.checkDuplicatePlate(
        [...entity.identities],
        this.props.match.params.id
      );
      if (duplicatePlate) {
        let url = window.location.href;
        const pathIndex = url.lastIndexOf('/');
        const redirectUrl = url.slice(0, pathIndex) + '/' + duplicatePlate.id;
        this.getDuplicatePlate = () => duplicatePlate.plate;
        this.getRedirectUrl = () => redirectUrl;
        this.setState({ showModal: true });
        throw new Error('車牌已存在');
      }

      if (this.state.op === 'add') {
        result = await pos.rentalUser.addRentalUser(entity);
      } else {
        entity.id = this.props.match.params.id;
        result = await pos.rentalUser.updateRentalUser(entity);
      }
    } catch (err) {
      hide();
      message.error('處理失敗!');
      return;
    }

    hide();
    message.success('處理成功!');

    this.props.history.push(
      `${routes.manage.children.rentalUsers.layout}${routes.manage.children.rentalUsers.path}`
    );
    return result;
  };

  checkDuplicatePlate = async (identities, id) => {
    async function fetchPlateIds(identity) {
      try {
        const opts = {
          searchText: identity.content,
          searchBy: 'identity',
          identityType: identity.type,
        };
        const { content } = await pos.rentalUser.fetchLastRentalUser(opts);
        return {
          plate: identity.content,
          id: content.id,
        };
      } catch (error) {
        //車牌不存在，使用者正在更正錯誤車牌/新增車牌
        return {
          plate: identity.content,
          id: -1,
        };
      }
    }

    let idsOfPlates = await Promise.all(
      identities.map(identity => fetchPlateIds(identity))
    );

    let duplicatePlate;
    if (Number(id) === 0) {
      //add mode
      duplicatePlate = idsOfPlates.find(obj => obj.id !== -1);
    } else {
      //edit mode
      duplicatePlate = idsOfPlates.find(
        obj => obj.id !== -1 && obj.id !== Number(id)
      );
    }
    return duplicatePlate;
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // START rentalQty
      if (this.state.rentalQty.isEnable) {
        const rentalName = this.findRentalServicesNameById(
          values.rentalServiceId
        );
        const {
          nameCar,
          nameMoto,
          soldQtyCar,
          soldQtyMoto,
          totalQtyCar,
          totalQtyMoto,
        } = this.state.rentalQty;
        if (
          (rentalName === nameCar && soldQtyCar >= totalQtyCar) ||
          (rentalName === nameMoto && soldQtyMoto >= totalQtyMoto)
        ) {
          alert(`${rentalName} 已賣出的票種數量已滿`);
          return;
        }
      }
      // END rentalQty
      if (!err) {
        this.upload(values);
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    if (this.state.op === 'edit' && !this.state.user) {
      return <Spin></Spin>;
    }

    return (
      <>
        <Form {...formItemLayout} className='form'>
          <BasicSection
            {...this.props}
            user={this.state.user}
            op={this.state.op}
          ></BasicSection>

          <ContentSection
            {...this.props}
            user={this.state.user}
            op={this.state.op}
          ></ContentSection>

          {this.state.user ? (
            <OtherSection
              {...this.props}
              user={this.state.user}
              op={this.state.op}
            ></OtherSection>
          ) : (
            <div></div>
          )}

          <div
            style={{
              padding: '20px 30px',
            }}
          >
            {this.state.privilege.includes('u') ||
            this.state.privilege.includes('c') ? (
              <Button
                type='primary'
                size='large'
                block
                style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
                onClick={this.handleSubmit}
              >
                確認
              </Button>
            ) : (
              <div></div>
            )}

            <div
              style={{
                height: 20,
                width: '100%',
              }}
            ></div>

            <Button
              type='danger'
              size='large'
              block
              style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
              onClick={() => {
                this.props.history.push(
                  `${routes.manage.children.rentalUsers.layout}${routes.manage.children.rentalUsers.path}`
                );
              }}
            >
              取消
            </Button>
          </div>
        </Form>
        <Modal
          title='車牌已存在'
          centered
          visible={this.state.showModal}
          onOk={() => {
            this.setState({ showModal: false });
            if (typeof this.getRedirectUrl === 'function') {
              window.location = this.getRedirectUrl();
            }
          }}
          onCancel={() => {
            this.setState({ showModal: false });
          }}
        >
          欲新增/修改的車牌已存在
          {`(${this.getDuplicatePlate && this.getDuplicatePlate()})`}
          ，點擊確認前往該車牌頁面
          {this.getRedirectUrl && this.getRedirectUrl()}
        </Modal>
      </>
    );
  }
}

const WrappedItemForm = Form.create({ name: 'rental_user_form' })(
  RentalUserForm
);

function mapProps(s) {
  return {
    user: s.user,
    route: s.route,
    system: s.system,
  };
}

export default connect(mapProps)(WrappedItemForm);
