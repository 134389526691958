import { combineReducers } from 'redux';

import breadnav from './breadnav';
import user from './user';
import system from './system';
import route from './route';

export default combineReducers({
  breadnav,
  user,
  system,
  route,
});
