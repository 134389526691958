import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Tabs,
  Card,
  Avatar,
  Col,
  Layout,
  Spin,
  Table,
  Divider,
  Tag,
  Button,
  Modal,
  InputNumber,
  message,
  Radio,
  Select,
  Typography,
  Form,
} from 'antd';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import pos from 'pos-api';
import uuid from 'uuid/v1';

const { TabPane } = Tabs;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

const Discount = props => {
  const [discountDefines, setDiscountDefines] = useState([]);
  const [currentDefine, setCurrentDefine] = useState({});
  const [cars, setCars] = useState([]);

  const { getFieldDecorator, getFieldValue } = props.form;

  useEffect(() => {
    async function init() {
      try {
        let ds = await pos.discountDefine.readDiscountDefines({
          pageSize: 100,
        });

        if (!ds.content) {
          return;
        }

        setDiscountDefines(ds.content);

        if (ds.content.length > 0) {
          setCurrentDefine(ds.content[0]);
        }
      } catch (err) {
        console.warn(err);
      }
    }

    async function initCars() {
      try {
        let cars = await pos.item.fetchItems({
          pageSize: 3000,
          page: 0,
        });

        if (!cars.content) {
          return;
        }

        setCars(cars.content);
      } catch (err) {}
    }

    init();
    initCars();
  }, []);

  if (discountDefines.length === 0) {
    return <Spin></Spin>;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
  };

  return (
    <Form {...props} {...formItemLayout} className='form'>
      <div style={{ padding: 20 }}>
        <Form.Item htmlFor='define_id' label='優惠方式'>
          {getFieldDecorator('define_id', {
            initialValue: `${discountDefines[0].id}`,
          })(
            <Select size='large' style={{ width: 120 }}>
              {discountDefines.map(d => {
                return <Option value={`${d.id}`}>{d.name}</Option>;
              })}
            </Select>
          )}
        </Form.Item>

        {currentDefine.type === 'hourly' ? (
          <div>
            <Form.Item htmlFor='hours' label='小時數'>
              {getFieldDecorator('hours', {
                rules: [
                  {
                    required: true,
                    message: '請輸入時數',
                  },
                ],
                initialValue:
                  currentDefine &&
                  currentDefine.data &&
                  currentDefine.data.default_hours
                    ? currentDefine.data.default_hours
                    : 1,
              })(
                <InputNumber
                  style={{ width: 180 }}
                  step={0.5}
                  max={
                    currentDefine &&
                    currentDefine.data &&
                    currentDefine.data.max_hours
                      ? currentDefine.data.max_hours
                      : 1
                  }
                  min={
                    currentDefine &&
                    currentDefine.data &&
                    currentDefine.data.min_hours
                      ? currentDefine.data.min_hours
                      : 0.5
                  }
                ></InputNumber>
              )}
            </Form.Item>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Form>
  );
};

const WrappedDiscountForm = Form.create({ name: 'discount_form' })(Discount);

export default WrappedDiscountForm;
