import React from 'react';
import { Layout, Spin, Button } from 'antd';
import { generatePath } from 'react-router-dom';
import ItemContainer from '../../../component/ItemContainer';

import routes from '../../../Routes';
import pos from 'pos-api';

import { itemMapper } from '../../../utils/mapper';

import './Items.less';

const { Content } = Layout;

class Items extends React.Component {
  state = {
    items: {},
  };

  fetchItems = async category => {
    let pageSize = 20;
    try {
      const step = async page => {
        let resp = await pos.item.fetchItems({
          page,
          pageSize,
          categoryId: category.id,
        });

        let items = resp.content;

        this.setState(prev => {
          let next = Object.assign({}, prev.items);
          if (!prev.items[category.id]) {
            next[category.id] = itemMapper.toView(items);
          } else {
            next[category.id] = [
              ...prev.items[category.id],
              ...itemMapper.toView(items),
            ];
          }

          return {
            items: next,
          };
        });

        if (items.length >= pageSize) {
          await step(page + 1);
        }
      };

      await step(0);
    } catch (err) {
      console.log(err);
    }
  };

  init = async () => {
    let categorys = await pos.category.fetchCategorys();
    this.setState({
      categorys,
    });

    let ps = categorys.map(c => this.fetchItems(c));
    await Promise.all(ps);
  };

  onAddItem = evt => {
    evt.preventDefault();
    let path = `${routes.itemForm.layout}${routes.itemForm.path}`;
    path = generatePath(path, { id: 0 });
    this.props.history.push(path);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          paddingTop: 0,
          minHeight: 280,
        }}
      >
        {this.state.categorys ? (
          this.state.categorys.map((c, idx) => (
            <div style={{ maxWidth: 1400, margin: 'auto' }} key={idx}>
              <ItemContainer
                title={c.name}
                items={this.state.items[c.id]}
              ></ItemContainer>
            </div>
          ))
        ) : (
          <Spin></Spin>
        )}

        {this.state.categorys ? (
          <Button
            type='primary'
            shape='circle'
            icon='plus'
            className='add-item-btn'
            onClick={this.onAddItem}
          />
        ) : (
          <div></div>
        )}
      </Content>
    );
  }
}

export default Items;
