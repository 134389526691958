import React from 'react';

import { Redirect } from 'react-router-dom';

/**
 * validate current user is login state
 * if not redirect to login page
 * @param {React.Component} Comp react component
 */
export const validateUser = Comp =>
  class extends React.Component {
    state = {
      invalid: false,
    };

    componentDidMount() {
      let user = localStorage.getItem('user');
      if (!user) {
        this.setState({
          invalid: true,
        });
      }
    }

    render() {
      if (this.state.invalid) {
        return <Redirect to='/login'></Redirect>;
      }
      return <Comp {...this.props}></Comp>;
    }
  };

export const userRoutePrivilege = Comp =>
  class extends React.Component {
    state = {
      invalid: false,
    };

    componentDidMount() {
      let user = localStorage.getItem('user');
      if (!user) {
        this.setState({
          invalid: true,
        });
      }
    }

    render() {
      if (this.state.invalid) {
        return <Redirect to='/login'></Redirect>;
      }
      return <Comp {...this.props}></Comp>;
    }
  };
