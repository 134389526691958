import { UPDATE_CURRENT_ROUTE } from '../actions/route';

export default function item(state = {}, action) {
  switch (action.type) {
    case UPDATE_CURRENT_ROUTE:
      return { currentRoute: action.currentRoute };
    default:
      return state;
  }
}
