import React, { createRef } from 'react';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
  Modal,
  Upload,
  notification,
} from 'antd';
import { Drawer, Input } from 'antd';

import stomp from '../../../utils/stomp';

import moment from 'moment';

import { generatePath } from 'react-router-dom';

import pos from 'pos-api';

import { connect } from 'react-redux';

import routes from '../../../Routes';

import Highlighter from 'react-highlight-words';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const { confirm } = Modal;

let emitter = document.createElement('div');

const deviceMap = {
  11: '對外網路',
  12: '資料庫',
  13: '網站服務器',
  21: '驗幣機',
  22: '驗鈔機',
  23: '退鈔機',
  24: '5元退幣機',
  25: '10元退幣機',
  26: '50元退幣機',
  31: '卡機(UTP1000)',
  32: '悠遊卡',
  33: '愛金卡',
  34: '一卡通',
  35: '信用卡',
  36: '條碼機',
  41: '發票機1',
  42: '發票機2',
  43: 'NFC讀卡機',
  44: 'IO卡',
  1022: 'APS',
};

const deviceStatusMap = {
  41: {
    '-1': '異常',
    '-4000': '異常',
    '-4001': '紙將盡',
    '-4002': '沒紙',
  },
  42: {
    '-1': '異常',
    '-4000': '異常',
    '-4001': '紙將盡',
    '-4002': '沒紙',
  },
  1022: {
    0: '門關閉',
    1: '門開啟',
  },
};

const columns = [
  {
    title: '序號',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '機台',
    dataIndex: 'app',
    key: 'app',
    render: app => {
      if (app && app.name) {
        return app.name;
      } else {
        return '';
      }
    },
  },
  {
    title: '狀態紀錄',
    dataIndex: 'content',
    key: 'content',
    render: content => {
      if (!content) {
        return '';
      }

      let messages = Object.keys(content).map(key => {
        let msg = content[key] < 0 ? '異常' : '正常';

        if (deviceStatusMap[key] && deviceStatusMap[key][content[key]]) {
          msg = deviceStatusMap[key][content[key]];
        }

        return (deviceMap[key] ? deviceMap[key] : '???') + ': ' + msg;
      });

      return messages.join(',');
    },
  },
  {
    title: '紀錄時間',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: timestamp => {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

class appStatusLogs extends React.Component {
  state = {
    pagination: {
      pageSize: 12,
      current: 1,
    },
    loading: true,
    appStatusLogs: null,
    privilege: 'r',
    apps: [
      {
        id: 'all',
        name: '全部',
      },
    ],
    appId: 'all',
  };

  constructor(props) {
    super(props);

    this.deviceAlertRef = createRef();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchAppStatusLogs({
      pagination: pager,
    });
  };

  fetchAppStatusLogs = async (opts = {}) => {
    this.setState({
      loading: true,
    });

    if (!opts.pagination) {
      opts.pagination = this.state.pagination;
    }

    if (!opts.appId) {
      opts.appId = this.state.appId;
    }

    let appId;
    if (opts.appId !== 'all') {
      appId = opts.appId;
    }

    let result = await pos.app.fetchAppStatusLogs({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
      sortDirection: 'desc',
      appId,
    });

    let pagination = result.pagination;
    let appStatusLogs = result.content;

    appStatusLogs.forEach((slog, idx) => {
      slog.key = slog.id;
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      appStatusLogs: appStatusLogs,
      pagination: pager,
      loading: false,
    });
  };

  onSearch = text => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.searchBy = this.state.searchType;

      if (text === '') {
        this.searchBy = null;
      }

      this.searchText = text;

      this.fetchAppStatusLogs();
    }, 250);
  };

  onSearchTypeChange = type => {
    this.setState({
      searchType: type,
    });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.searchBy = this.state.searchType;

      if (this.searchText === '' || this.searchText == null) {
        this.searchBy = null;
      }

      this.fetchAppStatusLogs();
    }, 150);
  };

  init = async () => {
    if (this.props.userRoutePrivilege) {
      this.setState({
        privilege: this.props.userRoutePrivilege,
      });
    }

    let apps = await pos.app.fetchApps({
      pageSize: 100,
    });

    if (apps) {
      let _apps = apps.map(ap => {
        return {
          id: ap.id + '',
          name: ap.name,
        };
      });

      _apps = [
        {
          id: 'all',
          name: '全部',
        },
        ..._apps,
      ];

      this.setState({
        apps: _apps,
      });

      this.appMap = {};
      apps.forEach(ap => {
        this.appMap[ap.id] = ap.name;
      });
    }

    this.fetchAppStatusLogs();

    const refetch = () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        this.fetchAppStatusLogs();
      }, 150);
    };

    try {
      this.listenEvent = await stomp.asyncSubscribe(
        '/exchange/pos/app.status',
        _message => {
          try {
            // message = JSON.stringify(message);
            // message = JSON.parse(message);

            let body = _message.body;
            if (!body) return;

            body = JSON.parse(body);

            let status = body.status;

            let showAlert = false;

            let skeys = Object.keys(status);

            for (let i = 0; i < skeys.length; i++) {
              if (status[skeys[i]] < 0) {
                showAlert = true;
                break;
              }

              if (skeys[i] === '1022' && status[skeys[i]] === 1) {
                showAlert = true;
                break;
              }
            }

            if (!showAlert) {
              refetch();
              return;
            }

            if (this.deviceAlertRef.current) {
              let alertAudio = this.deviceAlertRef.current;

              alertAudio.loop = true;
              alertAudio.play();

              if (this.deviceAlertTimeout) {
                clearTimeout(this.deviceAlertTimeout);
              }

              this.deviceAlertTimeout = setTimeout(() => {
                alertAudio.pause();
              }, 5000);
            }

            let msg = '';
            if (status) {
              let messages = Object.keys(status).map(key => {
                if (key === '1022') {
                  return status[key] === 1 ? '門開啟' : '門關閉';
                }

                return (
                  (deviceMap[key] ? deviceMap[key] : '???') +
                  ': ' +
                  (status[key] < 0 ? '異常' : '正常')
                );
              });

              msg = messages.join(',');
            }

            let desc = '';
            if (this.appMap && this.appMap[body.app]) {
              desc = this.appMap[body.app] + '\n' + msg;
            } else {
              desc = msg;
            }

            notification.info({
              message: msg,
              description: desc,
              duration: 30,
            });

            refetch();
          } catch (err) {
            console.error(err);
          }
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount = () => {
    if (this.listenEvent) {
      this.listenEvent.unsubscribe();
    }
  };

  handleAppIdChange = id => {
    this.setState({
      appId: id,
    });

    this.fetchAppStatusLogs({
      appId: id,
    });
  };

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          機台號:
          <Select
            defaultValue='all'
            onChange={this.handleAppIdChange}
            style={{ width: 120, marginLeft: 12, marginRight: 12 }}
          >
            {this.state.apps.map(ap => {
              return <Option value={ap.id}>{ap.name}</Option>;
            })}
          </Select>
          <div style={{ float: 'right' }}>
            {/* <Select defaultValue="plate" onChange={this.onSearchTypeChange} style={{ width: 120 }}>
            <Option value="name">名稱搜尋</Option>
            <Option value="plate">車牌搜尋</Option>
            <Option value="identity_number">身分證字號搜尋</Option>
            <Option value="phone_number">手機號搜尋</Option>
            <Option value="tel_number">電話號碼搜尋</Option>
          </Select>

            <Search
              placeholder={'請輸入搜尋字串'}
              onSearch={this.onSearch}
              onChange={(evt) => {
                let value = evt.target.value;
                if(value == null) return;

                this.onSearch(value);
              }}
              style={{ width: 200, float: 'right', marginLeft: 14 }}
            /> */}
          </div>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.appStatusLogs}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>

        <audio id='device_alert' ref={this.deviceAlertRef}>
          <source src='/api/v1/system/files/alert.mp3' type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
      </Content>
    );
  }
}

function mapProps(s) {
  return {
    user: s.user,
    route: s.route,
  };
}

export default connect(mapProps)(appStatusLogs);
