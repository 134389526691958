/**
 *
 * @param {string} str
 */
const countStrSize = str => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    let code = str.charCodeAt(i);
    if (code < 256) {
      count++;
    } else {
      count += 2;
    }
  }

  return count;
};

/**
 * return receipt of an order
 * @param {Order} order
 */
export const buildOrderReceipt = async (
  order,
  opts = {
    partialCut: false,
    tableNumber: 0,
  }
) => {
  if (!opts) {
    opts = {
      partialCut: false,
      tableNumber: 0,
    };
  }

  let items = order.items;

  let printerbody = {
    ops: [
      {
        name: 'style',
        val: 'bu',
      },
      {
        name: 'align',
        val: 'ct',
      },
      {
        name: 'size',
        val: [2, 2],
      },
    ],
  };

  let ptext = '';

  if (order.take_type === 'take_in') {
    ptext += '內用單';
    if (opts.tableNumber && opts.tableNumber > 0) {
      ptext += `(${opts.tableNumber}桌)`;
    }
    ptext += ` ${order.waiting_number}\n\n`;
  } else {
    ptext += '外帶單';

    if (opts.tableNumber && opts.tableNumber > 0) {
      ptext += `(${opts.tableNumber}桌)`;
    }

    ptext += ` ${order.waiting_number}\n\n`;
  }

  //ptext += `單號${order.waiting_number}\n\n`;

  printerbody.ops.push({
    name: 'text',
    val: ptext,
  });

  printerbody.ops.push({
    name: 'size',
    val: [1, 1],
  });

  printerbody.ops.push({
    name: 'align',
    val: 'lt',
  });

  const line = '----------------------------------';

  let checkTime = new Date(order.check_time);
  ptext = '訂單時間:' + checkTime.toLocaleTimeString() + '\n';
  ptext += `${line}\n`;
  ptext +=
    '品名x數量' +
    ''.padStart(line.length - countStrSize('品名x數量金額'), ' ') +
    '金額\n';
  ptext += line;

  printerbody.ops.push({
    name: 'text',
    val: ptext,
  });

  ptext = '';

  let count = 0;
  items.forEach(item => {
    let price;
    if (order.take_type === 'take_in') {
      price = item.price.in;
    } else {
      price = item.price.out;
    }

    let nameAndNumber = `${item.name}x${item.number}`;
    ptext +=
      nameAndNumber +
      `${price * item.number}`.padStart(
        line.length - countStrSize(nameAndNumber),
        ' '
      ) +
      '\n';
    count += item.number;
  });
  ptext += `${line}\n`;
  ptext +=
    `共計<${count}>項` +
    `${order.amounts.total_amount}`.padStart(
      line.length - countStrSize(`共計<${count}>項`),
      ' '
    ) +
    '\n';
  ptext += checkTime.toLocaleDateString() + '\n';

  printerbody.ops.push({
    name: 'text',
    val: ptext,
  });

  printerbody.ops.push({
    name: 'cut',
    val: opts.partialCut,
  });

  printerbody.ops.push({
    name: 'flush',
  });

  return printerbody;
};
