import { UPDATE_SYSTEM } from '../actions/system';

export default function system(state = {}, action) {
  switch (action.type) {
    case UPDATE_SYSTEM:
      return action.system;
    default:
      return state;
  }
}
