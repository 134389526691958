import {
  Button,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Layout,
  Select,
  Table,
  Typography,
} from 'antd';
import convertCase from 'js-convert-case';
import moment from 'moment';
import pos from 'pos-api';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { agencyReceiptMapper } from '../../../utils/mapper';
import { getOS, paymentTypeMap } from '../../../utils/utils';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: '車牌號',
    dataIndex: 'plate',
    key: 'plate',
    width: 200,
  },
  {
    title: '卡號',
    dataIndex: 'cardNo',
    key: 'cardNo',
    width: 200,
  },
  {
    title: '交易時間',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: 250,
    render: (text, record) => (
      <span>{moment(record.timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
    ),
    sorter: (a, b) =>
      new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '銷售額',
    dataIndex: 'amount',
    key: 'amount',
    width: 150,
    render: (text, record) => <span>{text ? text : '0'}</span>,
  },
  {
    title: '支付方式',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 150,
    render: (text, record) => paymentTypeMap[record.paymentType],
  },
  {
    title: '停車單號',
    dataIndex: 'parkNo',
    key: 'parkNo',
    width: 150,
    render: (text, record) => {
      let rawParkNo = record.parkNo;
      return rawParkNo.replace(/[\[\]]/g, '').replace(/;/g, '\n');
      //移除括號[]、將分號換行
    },
  },
];

const DEFAULT_PAGE_SIZE = 100;

class AgencyReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.pageTimer = null;
  }

  state = {
    searchText: '',
    startTime: null,
    endTime: null,
    apps: [
      {
        id: 'all',
        name: '全部',
      },
    ],
    appId: 'all',
    searchCardNo: null,
    searchParkNo: null,
    searchItemName: null,
    amounts: {
      salesAmount: 0,
      totalAmount: 0,
      taxAmount: 0,
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      current: 1,
    },
    paymentType: 'all',
    amountType: 'all',
    type: 'street_park',
    cardNo: null,
    loading: true,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      filters: filters,
      sorter: sorter,
    });

    this.fetchAgencyReceipts({
      pagination: pager,
      sorter,
      filters,
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  fetchAgencyReceipts = async opts => {
    this.setState({
      loading: true,
    });

    opts = opts || {
      pagination: this.state.pagination,
      sorter: {},
    };

    if (!opts.sorter) opts.sorter = {};
    if (!opts.filters) opts.filters = {};
    if (!opts.startTime) opts.startTime = this.state.startTime;
    if (!opts.endTime) opts.endTime = this.state.endTime;
    if (!opts.appId) opts.appId = this.state.appId;
    if (!opts.searchCardNo) opts.searchCardNo = this.state.searchCardNo;
    if (!opts.searchParkNo) opts.searchParkNo = this.state.searchParkNo;
    if (!opts.searchItemName) opts.searchItemName = this.state.searchItemName;
    if (!opts.paymentType) opts.paymentType = this.state.paymentType;
    if (!opts.amountType) opts.amountType = this.state.amountType;
    if (!opts.type) opts.type = this.state.type;

    let appId;
    if (opts.appId !== 'all') {
      appId = Number(opts.appId);
    }

    let paymentType = opts.paymentType;
    if (paymentType === 'all') {
      paymentType = null;
    }

    let amountType = opts.amountType;
    if (opts.amountType === 'all') {
      amountType = null;
    }

    let type = opts.type;
    if (type === 'all') {
      type = null;
    }

    let { content, pagination, amounts } =
      await pos.agencyReceipt.fetchAgencyReceipts({
        pageSize: opts.pagination.pageSize,
        page: opts.pagination.current - 1,
        startTime: opts.startTime ? opts.startTime.toISOString() : undefined,
        endTime: opts.endTime ? opts.endTime.toISOString() : undefined,
        sortBy: opts.sorter.field
          ? convertCase.toSnakeCase(opts.sorter.field)
          : null,
        sortDirection: opts.sorter.order === 'descend' ? 'desc' : 'asc',
        appId,
        plate: opts.searchItemName,
        paymentType,
        amountType,
        cardNo: opts.searchCardNo,
        parkNo: opts.searchParkNo,
        type,
      });

    let agencyReceipt = agencyReceiptMapper.toView(content);
    agencyReceipt.forEach((g, idx) => {
      g.key = g.id;
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;

    this.setState({
      agencyReceipt: agencyReceipt,
      loading: false,
      amounts: {
        salesAmount: amounts.amount,
        totalAmount: amounts.total_amount,
      },
      pagination: pager,
    });
  };

  onFooter = record => {
    return (
      <div>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總筆數: {this.state.pagination.total}
            </Text>
          </div>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總金額:{' '}
              {`$ ${this.state.amounts.salesAmount}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  handleTimeChange = (field, value) => {
    this.setState({
      [field]: value,
    });

    let timeRange = {
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };

    timeRange[field] = value.toDate();

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchAgencyReceipts({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      ...timeRange,
    });
  };

  init = async () => {
    let apps = await pos.app.fetchApps({
      pageSize: 100,
      type: ['aps', 'online_sell_rental_card'],
    });

    if (apps) {
      let _apps = apps.map(ap => {
        return {
          id: ap.id + '',
          name: ap.name,
        };
      });

      _apps = [
        {
          id: 'all',
          name: '全部',
        },
        ..._apps,
      ];

      this.setState({
        apps: _apps,
      });
    }

    await this.fetchAgencyReceipts();
  };

  handleAppIdChange = appId => {
    this.setState({
      appId: appId,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchAgencyReceipts({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId,
    });
  };

  handlePaymentTypeChange = paymentType => {
    this.setState({
      paymentType: paymentType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchAgencyReceipts({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      paymentType,
    });
  };

  handleSearchCardNumberChange = cardNo => {
    if (this.searchCardNoTimer) {
      clearTimeout(this.searchCardNoTimer);
    }

    this.searchCardNoTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        searchCardNo: cardNo,
      });

      this.fetchAgencyReceipts({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        searchCardNo: cardNo,
      });
    }, 200);
  };

  handleSearchParkNumberChange = parkNo => {
    if (this.searchParkNoTimer) {
      clearTimeout(this.searchParkNoTimer);
    }

    this.searchParkNoTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        searchParkNo: parkNo,
      });

      this.fetchAgencyReceipts({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        searchParkNo: parkNo,
      });
    }, 200);
  };

  handleSearchItemNameChange = itemName => {
    if (this.searchItemNameTimer) {
      clearTimeout(this.searchItemNameTimer);
    }

    this.searchItemNameTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        searchItemName: itemName,
      });

      this.fetchAgencyReceipts({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        searchItemName: itemName,
      });
    }, 200);
  };

  handleCategoryTypeChange = categoryType => {
    this.setState({
      categoryType: categoryType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      categoryType,
    });
  };

  handleAmountTypeChange = amountType => {
    this.setState({
      amountType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchAgencyReceipts({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      amountType,
    });
  };

  handleExport = async () => {
    let appId;
    if (this.state.appId !== 'all') {
      appId = Number(this.state.appId);
    }

    let paymentType = this.state.paymentType;
    if (paymentType === 'all') {
      paymentType = null;
    }

    let amountType = this.state.amountType;
    if (this.state.amountType === 'all') {
      amountType = null;
    }

    let type = this.state.type;
    if (type === 'all') {
      type = null;
    }

    let { content, pagination, amounts } =
      await pos.agencyReceipt.fetchAgencyReceipts({
        pageSize: 30000,
        page: 0,
        startTime: this.state.startTime
          ? this.state.startTime.toISOString()
          : undefined,
        endTime: this.state.endTime
          ? this.state.endTime.toISOString()
          : undefined,
        sortBy: 'id',
        sortDirection: 'asc',
        appId,
        cardNo: this.state.searchCardNo,
        parkNo: this.state.searchParkNo,
        plate: this.state.searchItemName,
        paymentType,
        type,
        amountType,
      });

    let data = [];

    content.forEach(d => {
      let timestamp = moment(d.timestamp).format('YYYY-MM-DD HH:mm:ss');

      let amount = d.amount ?? 0;

      let paymentType = paymentTypeMap[d.payment_type] ?? '';

      let plate = d.plate ?? '';
      let cardNo = d.card_no ?? '';

      let parkNo = d.park_no ?? '';

      data.push({
        plate,
        cardNo,
        timestamp,
        amount,
        paymentType,
        parkNo,
      });
    });

    const download = (filename, text) => {
      var element = document.createElement('a');

      if (getOS() === 'Windows') {
        let blob = new Blob(['\ufeff', text], {
          type: 'text/csv;charset=utf-8',
        });
        element.setAttribute('href', window.URL.createObjectURL(blob));
      } else {
        element.setAttribute(
          'href',
          'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
        );
      }

      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    };

    let result = '車牌號,卡號,交易時間,銷售額,付款方式,停車單號\n';
    let tokens = 'plate,cardNo,timestamp,amount,paymentType,parkNo'.split(',');

    data.forEach(d => {
      result += tokens.map(t => d[t]).join(',') + '\n';
    });

    download('代收紀錄.csv', result);
  };

  handleStatusChange = status => {
    this.setState({
      status: status,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchOrders({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      status,
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                flex: 4,
              }}
            >
              搜尋範圍:
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                placeholder='start'
                onChange={value => this.handleTimeChange('startTime', value)}
                style={{ marginRight: 12, marginLeft: 12 }}
              ></DatePicker>
              <DatePicker
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                placeholder='end'
                onChange={value => this.handleTimeChange('endTime', value)}
              ></DatePicker>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              單頁筆數:
              <InputNumber
                style={{ marginLeft: 12 }}
                min={1}
                defaultValue={DEFAULT_PAGE_SIZE}
                onChange={value => {
                  if (this.state.pagination.pageSize === value) {
                    return;
                  }

                  // debounce
                  if (this.pageTimer) {
                    clearTimeout(this.pageTimer);
                  }

                  this.pageTimer = setTimeout(() => {
                    let pager = {
                      ...this.state.pagination,
                    };
                    pager.pageSize = value;
                    pager.current = 1;
                    this.setState({
                      pagination: pager,
                    });

                    this.fetchAgencyReceipts({
                      pagination: pager,
                    });
                  }, 250);
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: 12 }}>
            機台號:
            <Select
              defaultValue='all'
              onChange={this.handleAppIdChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              {this.state.apps.map(ap => {
                return <Option value={ap.id}>{ap.name}</Option>;
              })}
            </Select>
            車號:
            <Search
              size='large'
              placeholder='車號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchItemNameChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            卡號:
            <Search
              size='large'
              placeholder='請輸入卡號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchCardNumberChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            停車單號:
            <Search
              size='large'
              placeholder='請輸入停車單號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchParkNumberChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            付款別:
            <Select
              defaultValue='all'
              onChange={this.handlePaymentTypeChange}
              style={{ width: 180, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='cash'>現金</Option>
              <Option value='ezcard'>悠遊卡</Option>
              <Option value='ipass'>一卡通</Option>
              <Option value='icash'>愛金卡</Option>
              <Option value='pay_taipei'>台北通</Option>
              <Option value='pay_taipei_real_time'>台北智慧支付</Option>
              <Option value='pay_taipei_pos'>台北行動支付</Option>
              <Option value='line_pay'>LinePay</Option>
              <Option value='line_pay_money'>一卡通Money</Option>
              <Option value='icash_pay'>iCashPay</Option>
              <Option value='op_wallet'>OP錢包</Option>
              <Option value='taiwan_pay'>台灣Pay</Option>
              <Option value='jkopay'>街口支付</Option>
              <Option value='mochi_pay'>麻吉付</Option>
              <Option value='mochi_pay_auto_pass'>麻吉付(自動)</Option>
              <Option value='remit'>電匯</Option>
            </Select>
            {/* type: //預設為street_park
            <Select defaultValue="all" onChange={this.handleTypeChange} style={{ width: 180, marginLeft: 12, marginRight: 12 }}>
              <Option value="all">全部</Option>
              <Option value="one_pass">ONE_PASS</Option>
              <Option value="ezcard">悠遊卡</Option>
              <Option value="street_park">路邊代收</Option>
              <Option value="icash">愛金卡</Option>
            </Select> */}
            付款:
            <Select
              defaultValue='all'
              onChange={this.handleAmountTypeChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='normal'>付費</Option>
              <Option value='zero'>免費</Option>
            </Select>
            <Button
              style={{ marginLeft: 20 }}
              size='large'
              onClick={() => {
                this.handleExport();
              }}
            >
              匯出
            </Button>
          </div>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.agencyReceipt}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            footer={this.onFooter}
            scroll={{ y: 520, x: 1000 }}
          />
        </Content>
      </Content>
    );
  }
}

export default AgencyReceipt;
