import Pdf from '@mikecousins/react-pdf';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import pos from 'pos-api';
import printJs from 'print-js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './PrintReports.less';
// import { useLayoutEffect } from "react";

const { Content } = Layout;
const { Option } = Select;
const { Text, Title } = Typography;
const { MonthPicker } = DatePicker;

const REPORT_URL = process.env.REACT_APP_POS_API_URL || process.env.PUBLIC_URL;

const ItemCard = props => {
  return (
    <Card
      hoverable={true}
      style={{
        fontSize: 24,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 180,
        backgroundColor: '#f1f1f1',
        borderRadius: '5px',
      }}
      onClick={props.item.onClick}
    >
      <div>{props.item.name}</div>
    </Card>
  );
};

const Items = props => {
  return (
    <Row>
      {props.items.map(item => (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={6}
          xxl={4}
          style={{ padding: '8px' }}
          key={item.id}
        >
          <ItemCard item={item}></ItemCard>
        </Col>
      ))}
    </Row>
  );
};

const ExportReportTools = props => {
  const handleExportReport = format => {
    let parameters = {
      ...props.parameters,
      report_format: format,
    };

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      `${REPORT_URL}/api/v1/report/generate?define_id=${
        props.report.id
      }&parameters=${JSON.stringify(parameters)}&authorization=${props.token}`
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <div>
      {props.report && props.report.data && props.report.data.report_formats ? (
        props.report.data.report_formats.map((format, idx) => (
          <Button
            key={idx}
            size='large'
            onClick={() => handleExportReport(format)}
          >
            匯出{(format + '').toUpperCase()}
          </Button>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
};

const PdfPreview = ({ targetUrl }) => {
  const [pdfPreviewPage, setPdfPreviewPage] = useState(1);

  const loadingText = (
    <Title level={3} style={{ textAlign: 'center' }}>
      載入中..
    </Title>
  );
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  useEffect(() => {
    setPdfPreviewPage(1);
  }, [targetUrl]);

  return (
    <Pdf
      file={targetUrl}
      workerSrc='/js/pdfjs/pdf.worker.js'
      page={pdfPreviewPage}
      style={{ display: 'block', margin: '0 auto', maxWidth: '100%' }}
      scale={vw / 860}
    >
      {
        /* 預覽參考套件建議方法 https://github.com/mikecousins/react-pdf-js#pdf-component */
        ({ pdfDocument, pdfPage, canvas }) => (
          <>
            {!pdfDocument ? (
              loadingText
            ) : (
              <>
                {pdfDocument.numPages > 1 ? (
                  <Pagination
                    simple
                    showQuickJumper
                    defaultCurrent={pdfPreviewPage}
                    defaultPageSize={1}
                    total={pdfDocument.numPages}
                    current={pdfPreviewPage}
                    className='print-report-dialog'
                    onChange={page => {
                      setPdfPreviewPage(page);
                    }}
                  />
                ) : null}
                {canvas}
              </>
            )}
          </>
        )
      }
    </Pdf>
  );
};

const FilterReportSelect = ({ classifyBy, reportFilters, setReportFilter }) => {
  return (
    <Select
      size='large'
      showSearch
      allowClear
      style={{ width: 200, marginLeft: 10 }}
      placeholder={classifyBy === 'user' ? '依處理人員' : '依繳費機台'}
      optionFilterProp='children'
      onChange={value => setReportFilter(value)}
    >
      {reportFilters.map(filter => (
        <Option key={filter} value={filter}>
          {filter}
        </Option>
      ))}
    </Select>
  );
};

/**
 * @param {'user' | 'aps' | undefined} classify_by
 */
const useReportFilter = classify_by => {
  const [reportFilters, setReportFilters] = useState([]);
  const [reportFilter, setReportFilter] = useState('');

  useEffect(() => {
    async function getReportFilter() {
      if (classify_by === 'user') {
        const { content } = await pos.user.fetchUsers({
          page: 0,
          pageSize: 100,
        });
        setReportFilters(content.map(d => d.name));
      }
      if (classify_by === 'aps') {
        const data = await pos.app.fetchApps({
          page: 0,
          pageSize: 100,
        });
        setReportFilters(data.filter(d => d.type === 'aps').map(d => d.name));
      }
    }
    getReportFilter();
  }, []);

  return {
    reportFilters,
    reportFilter,
    setReportFilter,
  };
};

const PrintDailyReportModal = props => {
  const [currentDay, setCurrentDay] = useState(new Date());

  const [targetUrl, setTargetUrl] = useState('');

  const classifyBy = props.report.data?.classify_by;

  const { reportFilters, reportFilter, setReportFilter } =
    useReportFilter(classifyBy);

  useEffect(() => {
    let parameters = {
      day: moment(currentDay).format('YYYY-MM-DD'),
      ...(reportFilter && { search_text: reportFilter }),
    };

    setTargetUrl(
      `${REPORT_URL}/api/v1/report/generate?define_id=${
        props.report.id
      }&parameters=${JSON.stringify(parameters)}&authorization=${props.token}`
    );
  }, [reportFilter, currentDay, props.report]);

  return (
    <Modal
      title={<Title level={3}>{props.report.name}</Title>}
      className='print-report-dialog'
      style={{ top: 80 }}
      visible={props.visible}
      okText='確定'
      cancelText='取消'
      onOk={() => {
        props.onOk && props.onOk();
      }}
      onCancel={() => {
        props.onCancel && props.onCancel();
      }}
      width={'100wv'}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
        }}
      >
        <DatePicker
          size='large'
          defaultValue={moment()}
          onChange={date => {
            setCurrentDay(date.toDate());
          }}
        ></DatePicker>

        {reportFilters.length ? (
          <FilterReportSelect
            classifyBy={classifyBy}
            reportFilters={reportFilters}
            setReportFilter={setReportFilter}
          />
        ) : null}

        <div style={{ float: 'right' }}>
          <ExportReportTools
            report={props.report}
            parameters={{
              day: moment(currentDay).format('YYYY-MM-DD'),
              ...(reportFilter && { search_text: reportFilter }),
            }}
            token={props.token}
          />

          <Button
            size='large'
            onClick={() => {
              printJs(targetUrl);
            }}
            style={{
              marginTop: 12,
            }}
          >
            報表列印
          </Button>
        </div>
      </Content>

      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        <PdfPreview targetUrl={targetUrl} />
      </Content>
    </Modal>
  );
};

const PrintMonthlyReportModal = props => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [targetUrl, setTargetUrl] = useState('');

  const classifyBy = props.report.data?.classify_by;

  const { reportFilters, reportFilter, setReportFilter } =
    useReportFilter(classifyBy);

  useEffect(() => {
    let parameters = {
      month: moment(currentMonth).format('YYYY-MM-DD'),
      ...(reportFilter && { search_text: reportFilter }),
    };

    setTargetUrl(
      `${REPORT_URL}/api/v1/report/generate?define_id=${
        props.report.id
      }&parameters=${JSON.stringify(parameters)}&authorization=${props.token}`
    );
  }, [reportFilter, currentMonth, props.report]);

  return (
    <Modal
      title={<Title level={3}>{props.report.name}</Title>}
      className='print-report-dialog'
      style={{ top: 80 }}
      visible={props.visible}
      okText='確定'
      cancelText='取消'
      onOk={() => {
        props.onOk && props.onOk();
      }}
      onCancel={() => {
        props.onCancel && props.onCancel();
      }}
      width={'100wv'}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
        }}
      >
        <MonthPicker
          size='large'
          defaultValue={moment()}
          onChange={date => {
            setCurrentMonth(date.toDate());
          }}
        ></MonthPicker>

        {reportFilters.length ? (
          <FilterReportSelect
            classifyBy={classifyBy}
            reportFilters={reportFilters}
            setReportFilter={setReportFilter}
          />
        ) : null}

        <div style={{ float: 'right' }}>
          <ExportReportTools
            report={props.report}
            parameters={{
              month: moment(currentMonth).format('YYYY-MM-DD'),
              ...(reportFilter && { search_text: reportFilter }),
            }}
            token={props.token}
          />

          <Button
            size='large'
            onClick={() => {
              printJs(targetUrl);
            }}
            style={{
              marginTop: 12,
            }}
          >
            報表列印
          </Button>
        </div>
      </Content>

      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        <PdfPreview targetUrl={targetUrl} />
      </Content>
    </Modal>
  );
};

const RentalReportSiteSelector = props => {
  const { value, defaultValue = -1, onChange } = props;

  const [sites, setSites] = useState([
    {
      id: -1,
      name: '全部',
    },
  ]);

  useEffect(() => {
    (async () => {
      let resp = await pos.site.readSites({
        page_size: 500,
      });

      setSites([
        {
          id: -1,
          name: '全部',
        },
        ...resp.content,
      ]);
    })();
  }, []);

  return (
    <Select
      size='large'
      showSearch
      allowClear
      value={value == null ? defaultValue : value}
      style={{ width: 200, marginLeft: 10 }}
      placeholder='請選擇場站'
      optionFilterProp='children'
      onChange={value => onChange && onChange(value)}
    >
      {sites.map(s => (
        <Option key={s.id} value={s.id}>
          {s.name}
        </Option>
      ))}
    </Select>
  );
};

const RentalCardSelector = props => {
  const { value, defaultValue = -1, onChange, credited = true } = props;

  const [cards, setCards] = useState([
    {
      id: -1,
      name: '全部',
    },
  ]);

  useEffect(() => {
    (async () => {
      let resp = await pos.service.fetchServices({
        type: 'park_rental',
        is_credited: credited ? 1 : 0,
        page_size: 500,
      });

      setCards([
        {
          id: -1,
          name: '全部',
        },
        ...resp.content,
      ]);
    })();
  }, [credited]);

  return (
    <Select
      size='large'
      showSearch
      allowClear
      value={value == null ? defaultValue : value}
      style={{ width: 200, marginLeft: 10 }}
      placeholder='請選擇票種'
      optionFilterProp='children'
      onChange={value => onChange && onChange(value)}
    >
      {cards.map(s => (
        <Option key={s.id} value={s.id}>
          {s.name}
        </Option>
      ))}
    </Select>
  );
};

const PrintRangeReportModal = props => {
  const [startTime, setStartTime] = useState(
    moment(
      moment().format('YYYY-MM-DD 00:00:00'),
      'YYYY-MM-DD 00:00:00'
    ).toDate()
  );
  const [endTime, setEndTime] = useState(
    moment(
      moment().format('YYYY-MM-DD 23:59:59'),
      'YYYY-MM-DD 23:59:59'
    ).toDate()
  );
  const [orderStartTime, setOrderStartTime] = useState(null);
  const [orderEndTime, setOrderEndTime] = useState(null);

  const [targetUrl, setTargetUrl] = useState('');

  const [siteId, setSiteId] = useState(-1);

  const [cardId, setCardId] = useState(-1);

  const [manual, setManual] = useState('');

  const classifyBy = props.report.data?.classify_by;

  const type = props.report.type;

  const { reportFilters, reportFilter, setReportFilter } =
    useReportFilter(classifyBy);

  useEffect(() => {
    let parameters = {
      ...(startTime !== null && { start_time: startTime.toISOString() }),
      ...(endTime !== null && {
        end_time: moment(endTime).add(1, 'second').toISOString(),
      }),
      ...(reportFilter && { search_text: reportFilter }),
      ...(manual === 'is_manual_create' && { is_manual_create: true }),
      ...(manual === 'is_manual_leave' && { is_manual_leave: true }),
      ...(manual === 'is_both' && {
        is_manual_create: true,
        is_manual_leave: true,
      }),
      ...(props.report.type === 'range_taipei_water_item_record_report' && {
        ...(orderStartTime !== null && {
          order_start_time: orderStartTime.toISOString(),
        }),
        ...(orderEndTime !== null && {
          order_end_time: moment(orderEndTime).add(1, 'second').toISOString(),
        }),
      }),
    };

    if (
      props.report.type === 'range_rental_card_report' ||
      props.report.type === 'range_rental_card_detail_report'
    ) {
      if (siteId > 0) {
        parameters.site_id = siteId;
      }

      if (cardId > 0) {
        parameters.service_id = cardId;
      }
    }

    setTargetUrl(
      `${REPORT_URL}/api/v1/report/generate?define_id=${
        props.report.id
      }&parameters=${JSON.stringify(parameters)}&authorization=${props.token}`
    );
  }, [
    reportFilter,
    startTime,
    endTime,
    orderStartTime,
    orderEndTime,
    props.report,
    siteId,
    cardId,
    manual,
  ]);

  const showTime = props.report.range_type === 'detailed_range';
  const showTimeProps = showTime
    ? {
        showTime: {
          format: 'HH',
        },
      }
    : {};

  let parameters = {
    ...(startTime !== null && { start_time: startTime.toISOString() }),
    ...(endTime !== null && {
      end_time: moment(endTime).add(1, 'second').toISOString(),
    }),
    ...(reportFilter && { search_text: reportFilter }),
    ...(manual === 'is_manual_create' && { is_manual_create: true }),
    ...(manual === 'is_manual_leave' && { is_manual_leave: true }),
    ...(manual === 'is_both' && {
      is_manual_create: true,
      is_manual_leave: true,
    }),
    ...(props.report.type === 'range_taipei_water_item_record_report' && {
      ...(orderStartTime !== null && {
        order_start_time: orderStartTime.toISOString(),
      }),
      ...(orderEndTime !== null && {
        order_end_time: moment(orderEndTime).add(1, 'second').toISOString(),
      }),
    }),
  };

  if (
    props.report.type === 'range_rental_card_report' ||
    props.report.type === 'range_rental_card_detail_report'
  ) {
    if (siteId > 0) {
      parameters.site_id = siteId;
    }

    if (cardId > 0) {
      parameters.service_id = cardId;
    }
  }

  const rangeTaipeiWaterItemRecordAvailable =
    type === 'range_taipei_water_item_record_report' && props.report.data;

  return (
    <Modal
      title={<Title level={3}>{props.report.name}</Title>}
      className='print-report-dialog'
      style={{ top: 80 }}
      visible={props.visible}
      okText='確定'
      cancelText='取消'
      onOk={() => {
        props.onOk && props.onOk();
      }}
      onCancel={() => {
        props.onCancel && props.onCancel();
      }}
      width={'100wv'}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
        }}
      >
        {rangeTaipeiWaterItemRecordAvailable && <>進場時間&nbsp;:&nbsp;</>}
        <DatePicker
          {...showTimeProps}
          size='large'
          defaultValue={moment('00:00:00', 'HH:mm:ss')}
          onChange={date => {
            if (date === null) {
              setStartTime(null);
              return;
            }
            if (showTime) {
              setStartTime(
                moment(
                  date.format('YYYY-MM-DD HH:mm:ss'),
                  'YYYY-MM-DD HH:mm:ss'
                ).toDate()
              );
            } else {
              setStartTime(
                moment(
                  date.format('YYYY-MM-DD 00:00:00'),
                  'YYYY-MM-DD 00:00:00'
                ).toDate()
              );
            }
          }}
        ></DatePicker>

        <DatePicker
          {...showTimeProps}
          size='large'
          defaultValue={moment('23:59:59', 'HH:mm:ss')}
          onChange={date => {
            if (date === null) {
              setEndTime(null);
              return;
            }
            if (showTime) {
              setEndTime(
                moment(
                  date.format('YYYY-MM-DD HH:mm:ss'),
                  'YYYY-MM-DD HH:mm:ss'
                ).toDate()
              );
            } else {
              setEndTime(
                moment(
                  date.format('YYYY-MM-DD 23:59:59'),
                  'YYYY-MM-DD 23:59:59'
                ).toDate()
              );
            }
          }}
        ></DatePicker>

        {rangeTaipeiWaterItemRecordAvailable && (
          <div>
            交易時間&nbsp;:&nbsp;
            <DatePicker
              {...showTimeProps}
              size='large'
              defaultValue={null}
              onChange={date => {
                if (date === null) {
                  setOrderStartTime(null);
                  return;
                }
                if (showTime) {
                  setOrderStartTime(
                    moment(
                      date.format('YYYY-MM-DD HH:mm:ss'),
                      'YYYY-MM-DD HH:mm:ss'
                    ).toDate()
                  );
                } else {
                  setOrderStartTime(
                    moment(
                      date.format('YYYY-MM-DD 00:00:00'),
                      'YYYY-MM-DD 00:00:00'
                    ).toDate()
                  );
                }
              }}
            ></DatePicker>
            <DatePicker
              {...showTimeProps}
              size='large'
              defaultValue={null}
              onChange={date => {
                if (date === null) {
                  setOrderEndTime(null);
                  return;
                }
                if (showTime) {
                  setOrderEndTime(
                    moment(
                      date.format('YYYY-MM-DD HH:mm:ss'),
                      'YYYY-MM-DD HH:mm:ss'
                    ).toDate()
                  );
                } else {
                  setOrderEndTime(
                    moment(
                      date.format('YYYY-MM-DD 23:59:59'),
                      'YYYY-MM-DD 23:59:59'
                    ).toDate()
                  );
                }
              }}
            ></DatePicker>
          </div>
        )}

        {reportFilters.length ? (
          <FilterReportSelect
            classifyBy={classifyBy}
            reportFilters={reportFilters}
            setReportFilter={setReportFilter}
          />
        ) : null}

        {type === 'range_park_manual_record_report' && props.report.data && (
          <div
            style={{
              marginLeft: 6,
              display: 'inline-block',
            }}
          >
            <span>項目:</span>
            <Select
              defaultValue=''
              onChange={value => {
                setManual(value);
              }}
              style={{ width: 180, marginLeft: 12, marginRight: 12 }}
            >
              <Option value=''>全部</Option>
              <Option value='is_manual_create'>手動新增</Option>
              <Option value='is_manual_leave'>手動離場</Option>
              <Option value='is_both'>手動新增及離場</Option>
            </Select>
          </div>
        )}

        {(type === 'range_rental_card_report' ||
          type === 'range_rental_card_detail_report') &&
          props.report.data &&
          props.report.data.credited != null &&
          !props.report.data.credited && (
            <div
              style={{
                marginLeft: 6,
                display: 'inline-block',
              }}
            >
              <span>場站:</span>
              <RentalReportSiteSelector
                value={siteId}
                onChange={value => setSiteId(value)}
              />
            </div>
          )}

        {(type === 'range_rental_card_report' ||
          type === 'range_rental_card_detail_report') && (
          <div
            style={{
              marginLeft: 8,
              display: 'inline-block',
            }}
          >
            <span>票種:</span>
            <RentalCardSelector
              value={cardId}
              credited={
                props.report &&
                props.report.data &&
                props.report.data.credited != null &&
                !props.report.data.credited
                  ? false
                  : true
              }
              onChange={value => setCardId(value)}
            />
          </div>
        )}

        <div style={{ float: 'right' }}>
          <ExportReportTools
            report={props.report}
            parameters={parameters}
            token={props.token}
          />

          <Button
            size='large'
            onClick={() => {
              printJs(targetUrl);
            }}
            style={{
              marginTop: 12,
            }}
          >
            報表列印
          </Button>
        </div>
      </Content>

      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        <PdfPreview targetUrl={targetUrl} />
      </Content>
    </Modal>
  );
};

const PrintReports = props => {
  const [reportDefines, setReportDefines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dailyDialogVisible, setDailyDialogVisible] = useState(false);
  const [monthlyDialogVisible, setMonthlyDialogVisible] = useState(false);
  const [rangeDialogVisible, setRangeDialogVisible] = useState(false);
  const [currentReportDefine, setCurrentReportDefine] = useState({});

  useEffect(() => {
    async function init() {
      let _defines = await pos.report.fetchReportDefines({
        pageSize: 50,
      });

      if (_defines) {
        _defines.forEach(d => {
          d.onClick = () => {
            setCurrentReportDefine(d);
            switch (d.range_type) {
              case 'daily':
                setDailyDialogVisible(true);
                break;
              case 'monthly':
                setMonthlyDialogVisible(true);
                break;
              case 'range':
              case 'detailed_range':
                setRangeDialogVisible(true);
                break;
              default:
                break;
            }
          };
        });
      }

      setReportDefines(_defines);

      setLoading(false);
    }

    init();
  }, []);

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        {loading ? (
          <Spin></Spin>
        ) : reportDefines && reportDefines.length > 0 ? (
          <Items items={reportDefines}></Items>
        ) : (
          <Text style={{ fontSize: 24, fontWeight: 'bold' }}>無資料</Text>
        )}
      </Content>

      {/* 根據報表屬性render; 避免切換報表種類時發多次request */}
      {dailyDialogVisible && (
        <PrintDailyReportModal
          report={currentReportDefine}
          visible={dailyDialogVisible}
          onOk={() => setDailyDialogVisible(false)}
          onCancel={() => setDailyDialogVisible(false)}
          token={props.user.token}
        ></PrintDailyReportModal>
      )}
      {monthlyDialogVisible && (
        <PrintMonthlyReportModal
          report={currentReportDefine}
          visible={monthlyDialogVisible}
          onOk={() => setMonthlyDialogVisible(false)}
          onCancel={() => setMonthlyDialogVisible(false)}
          token={props.user.token}
        ></PrintMonthlyReportModal>
      )}
      {rangeDialogVisible && (
        <PrintRangeReportModal
          report={currentReportDefine}
          visible={rangeDialogVisible}
          onOk={() => setRangeDialogVisible(false)}
          onCancel={() => setRangeDialogVisible(false)}
          token={props.user.token}
        ></PrintRangeReportModal>
      )}
    </Content>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(PrintReports);
