import React from 'react';

import routes from '../Routes';
import './Dashboard.less';

import { connect } from 'react-redux';
import { Route, Link, Redirect, Switch } from 'react-router-dom';
import { Layout, Menu, Icon, Typography, message, Avatar } from 'antd';

import BreadNav from '../component/BreadNav';

import { updateBreadNav } from '../actions/breadnav';
import { updateCurrentRoute } from '../actions/route';

import * as utils from '../utils/utils';

import routeConfig from '../route-config';
import config from '../config';

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography;

function isDisplayPage(user, routeConfig) {
  let roles = user.roles;

  let permit = false;

  let permitRoles = [];

  if (typeof routeConfig.privileges == 'string') {
    let _r = routeConfig.privileges.split(':')[0];

    permitRoles.push(_r);
  } else if (routeConfig.privileges instanceof Array) {
    permitRoles = routeConfig.privileges.map(rp => rp.split(':')[0]);
  }

  if (permitRoles.includes('all')) {
    permit = true;
  } else {
    for (let i = 0; i < roles.length; i++) {
      let r = roles[i];

      if (permitRoles.includes(r.name)) {
        permit = true;
        break;
      }
    }
  }

  return routeConfig.display !== false && permit;
}

/**
 * specify props to page component on route-config
 */
function getRouteConfigProps(routes, key) {
  let configObj;

  const layoutRoute = routes[key].layout;
  let parentRoute = layoutRoute.replace('/dashboard', '');

  const keyExistsInRouteConfigFirstLevel = [
    'itemForm',
    'serviceForm',
    'rentalUserForm',
  ].includes(key);

  if (parentRoute && !keyExistsInRouteConfigFirstLevel) {
    // sub-component under object 'children'
    parentRoute = parentRoute.replace('/', '');
    configObj = routeConfig[parentRoute]?.['children']?.[key];
  } else {
    // first level component
    configObj = routeConfig[key];
  }

  const omitConfigProp = ['display', 'privileges', 'children'];

  if (configObj && typeof configObj === 'object') {
    const propsObject = Object.keys(configObj).reduce((prev, propKey) => {
      if (!omitConfigProp.includes(propKey)) {
        prev[propKey] = configObj[propKey];
      }
      return prev;
    }, {});
    return propsObject;
  }

  return {};
}

class SiderDemo extends React.Component {
  constructor(props) {
    super(props);
    this.breadnav = [];
  }

  state = {
    collapsed: false,
    title: routes.overview.name,
    sideNavHidden: false,
    defaultRoute: `${routes.overview.layout}${routes.overview.path}`,
    dashboardRoutes: {},
    loading: true,
    openKeys: [],
  };

  componentDidMount() {
    let user = this.props.user;
    let defaultRoute = `${routes.overview.layout}${routes.overview.path}`;

    if (user && user.roles) {
      let rs = user.roles.map(r => r.name);

      if (rs.includes('contractor') && rs.length === 1) {
        defaultRoute = '/dashboard/car-discount';
      }
    }

    let dashboardLayout = localStorage.getItem('dashboard-layout');

    if (!dashboardLayout) {
      dashboardLayout = {};
    } else {
      try {
        dashboardLayout = JSON.parse(dashboardLayout);
      } catch (err) {
        console.warn(err);
      }
    }

    if (dashboardLayout) {
      let hidden = dashboardLayout.sideNavHidden;

      if (hidden == null) {
        hidden = false;
      }

      if (dashboardLayout.defaultRoute) {
        defaultRoute = dashboardLayout.defaultRoute;
      }

      this.setState({
        sideNavHidden: hidden,
      });
    }

    this.setState({
      defaultRoute: defaultRoute,
    });

    let dashboardRoutes = JSON.parse(JSON.stringify(routes));

    // pre-process route table
    Object.keys(dashboardRoutes).forEach(key => {
      if (routeConfig[key]) {
        if (routeConfig[key].privileges) {
          dashboardRoutes[key].privileges = routeConfig[key].privileges;
        }

        if (routeConfig[key].display != null) {
          dashboardRoutes[key].display = routeConfig[key].display;
        }
      }

      if (dashboardRoutes[key].display === false) return;

      dashboardRoutes[key].display = isDisplayPage(user, dashboardRoutes[key]);

      if (dashboardRoutes[key].children && dashboardRoutes[key].children) {
        Object.keys(dashboardRoutes[key].children).forEach(ckey => {
          let rc = dashboardRoutes[key].children[ckey];

          rc.display = isDisplayPage(user, rc);
        });
      }
    });

    this.setState({
      dashboardRoutes,
      loading: false,
    });
  }

  logout = () => {
    localStorage.removeItem('user');
    this.props.history.push('/login');
    message.info('登出成功');
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  onNavigation = next => {
    this.props.dispatch(updateCurrentRoute(next));
    this.setState({
      title: next.name,
    });
  };

  onRouteRender = (routes, key, idx, props) => {
    while (this.breadnav.length >= routes[key].level) this.breadnav.pop();

    this.breadnav.push({
      name: routes[key].name,
      path: props.location.pathname,
    });

    //this.props.dispatch(updateBreadNav([...this.breadnav]))

    const Comp = routes[key].component;

    let p = utils.getUserRoutePrivilege(this.props.user, routes[key]);

    const routeConfigCustomProps = getRouteConfigProps(routes, key);

    return (
      <Comp {...routeConfigCustomProps} {...props} userRoutePrivilege={p} />
    );
  };

  onOpenChange = openKeys => {
    //submenu開合參考文件 https://3x.ant.design/components/menu/#components-menu-demo-sider-current
    const lastOpenKey = this.state.openKeys[0];
    const nowOpenKey = openKeys.filter(key => key !== lastOpenKey);
    this.setState({ openKeys: nowOpenKey });
  };

  render() {
    const dkeys = Object.keys(this.state.dashboardRoutes).filter(key => {
      return this.state.dashboardRoutes[key].display !== false;
    });

    let subRoutes = Object.keys(routes).filter(key => routes[key].children);
    let temp = subRoutes;
    subRoutes = {};
    temp.forEach(t => {
      subRoutes[t] = routes[t];
    });

    if (this.state.loading) {
      return <div></div>;
    }

    return (
      <Layout className='dashboard'>
        {this.state.sideNavHidden ? (
          <div></div>
        ) : this.state.loading ? (
          <div></div>
        ) : (
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <div
              className='logo'
              style={{ width: '100%', height: 60, padding: '16px 16px' }}
            >
              {this.state.collapsed ? (
                <div style={{ paddingLeft: 4 }}>
                  <Avatar
                    style={{
                      backgroundColor: '#FF5600',
                      verticalAlign: 'middle',
                    }}
                    size='large'
                  >
                    A
                  </Avatar>
                </div>
              ) : (
                <img
                  src={config.logoImage}
                  alt='logo'
                  style={{ width: '100%', height: '100%' }}
                ></img>
              )}
            </div>
            <Menu
              theme='dark'
              mode='inline'
              defaultSelectedKeys={['0']}
              onOpenChange={this.onOpenChange}
              openKeys={this.state.openKeys}
            >
              {dkeys.map((key, idx) =>
                this.state.dashboardRoutes[key].children ? (
                  <SubMenu
                    key={idx}
                    title={
                      <span>
                        <Icon type={this.state.dashboardRoutes[key].icon} />
                        <span>{this.state.dashboardRoutes[key].name}</span>
                      </span>
                    }
                  >
                    {Object.keys(this.state.dashboardRoutes[key].children).map(
                      (skey, sidx) => {
                        let troutes = this.state.dashboardRoutes[key].children;
                        if (troutes[skey].display === false)
                          return <div key={skey}></div>;

                        return (
                          <Menu.Item
                            key={`${idx}-${sidx}`}
                            onClick={evt => this.onNavigation(troutes[skey])}
                          >
                            <Link
                              to={`${troutes[skey].layout}${troutes[skey].path}`}
                            >
                              <Icon type={troutes[skey].icon} />
                              <span>{troutes[skey].name}</span>
                            </Link>
                          </Menu.Item>
                        );
                      }
                    )}
                  </SubMenu>
                ) : this.state.dashboardRoutes[key].link !== false ? (
                  <Menu.Item
                    key={idx}
                    onClick={evt =>
                      this.onNavigation(this.state.dashboardRoutes[key])
                    }
                  >
                    <Link
                      to={`${this.state.dashboardRoutes[key].layout}${this.state.dashboardRoutes[key].path}`}
                    >
                      <Icon type={this.state.dashboardRoutes[key].icon} />
                      <span>{this.state.dashboardRoutes[key].name}</span>
                    </Link>
                  </Menu.Item>
                ) : (
                  <Menu.Item key={idx}>
                    <Icon type={this.state.dashboardRoutes[key].icon} />
                    <span>{this.state.dashboardRoutes[key].name}</span>
                  </Menu.Item>
                )
              )}
            </Menu>
          </Sider>
        )}

        <Layout>
          {this.state.sideNavHidden ? (
            <div></div>
          ) : (
            <div>
              <Header
                className='header'
                style={{ display: 'flex', verticalAlign: 'middle' }}
              >
                <Title level={2} style={{ flex: 4 }}>
                  {this.state.title}
                </Title>

                <Text
                  style={{
                    textAlign: 'right',
                    flex: 1,
                    fontSize: 24,
                    lineHeight: '44px',
                  }}
                >
                  Hi, {this.props.user ? this.props.user.name : ''}
                  <span
                    onClick={this.logout}
                    style={{
                      textDecoration: 'underline',
                      fontSize: 18,
                      color: '#6259E1',
                      marginLeft: 8,
                      cursor: 'pointer',
                    }}
                  >
                    登出
                  </span>
                </Text>
              </Header>

              <BreadNav
                style={{
                  maxWidth: 1460,
                  display: 'block',
                  marginTop: 12,
                  marginLeft: 24,
                }}
              ></BreadNav>
            </div>
          )}

          <Content style={{ margin: '0px 24px' }}>
            <Route
              exact
              path='/dashboard'
              render={() => {
                console.log();
                return <Redirect to={this.state.defaultRoute} />;
              }}
            />
            {Object.keys(routes).map((key, idx) => (
              <Route
                path={`${routes[key].layout}${routes[key].path}`}
                key={idx}
                render={props => this.onRouteRender(routes, key, idx, props)}
              ></Route>
            ))}

            {Object.keys(subRoutes)
              .map((key, idx) => {
                let routes = subRoutes[key].children;
                return Object.keys(routes).map((key, idx) => (
                  <Route
                    path={`${routes[key].layout}${routes[key].path}`}
                    key={`sub-${key}-${idx}`}
                    render={props =>
                      this.onRouteRender(routes, key, idx, props)
                    }
                  ></Route>
                ));
              })
              .reduce((p, c) => {
                let n = [...p, ...c];
                return n;
              }, [])}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(SiderDemo);
