import { UPDATE_BREADNAV, PUSH, POP } from '../actions/breadnav';

export default function breadnav(state = [], action) {
  let next;
  switch (action.type) {
    case UPDATE_BREADNAV:
      return action.nav;
    case PUSH:
      next = [...state];
      next.push(action.location);
      return next;
    case POP:
      next = [...state];
      next.pop();
      return next;
    default:
      return state;
  }
}
