export const UPDATE_BREADNAV = 'UPDATE_BREADNAV';
export const PUSH = 'PUSH';
export const POP = 'POP';

export function updateBreadNav(nav) {
  return {
    type: UPDATE_BREADNAV,
    nav,
  };
}

export function push(location) {
  return {
    type: PUSH,
    location,
  };
}

export function pop() {
  return {
    type: POP,
  };
}
