import React, { useState, useEffect, useRef } from 'react';

import moment from 'moment';

import './DailyNonSystemRevenue.less';

import {
  Table,
  Typography,
  Button,
  Layout,
  Upload,
  Icon,
  message,
  Form,
  DatePicker,
  Row,
  Col,
  Input,
  InputNumber,
  Divider,
  Spin,
} from 'antd';

import { Link } from 'react-router-dom';
import pos from 'pos-api';

const { Title } = Typography;

const { Content } = Layout;

const layoutConfig = {
  layout: 'horizontal',
  gutter: 24,
  content: [
    {
      type: 'group',
      title: '其他收入',
      key: 'other_revenue',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'cost',
          name: '工本費',
        },
      ],
    },

    {
      type: 'group',
      title: '追繳停車費',
      key: 'park_forfeit',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'cost',
          name: '工本費',
        },
      ],
    },

    {
      type: 'group',
      title: '無面額代收',
      key: 'plain_receipt',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'cost',
          name: '工本費',
        },
      ],
    },

    {
      type: 'group',
      title: '催繳停車費',
      key: 'park_notice',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'cost',
          name: '工本費',
        },
      ],
    },

    {
      type: 'group',
      title: '停車退費',
      key: 'park_refund',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
      ],
    },

    {
      type: 'group',
      title: '平信催繳停車費',
      key: 'plain_park_notice',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'cost',
          name: '工本費',
        },
      ],
    },
    {
      type: 'group',
      title: '路邊停車',
      key: 'street_park',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'car_total',
          name: '汽車代收總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'car_amount',
          name: '汽車代收總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'moto_total',
          name: '機車代收總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'moto_amount',
          name: '機車代收總金額',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'ezcard_amount',
          name: '悠遊卡代收',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'ezcard_cost',
          name: '悠遊卡代收工本費',
        },
      ],
    },
    // {
    //     type: 'group',
    //     title: '平信催繳停車費',
    //     key: 'envelope_park_notice',
    //     span: 12,
    //     children: [
    //         {
    //             type: 'number-input',
    //             initialValue: 0,
    //             key: 'total',
    //             name: '總筆數'
    //         },
    //         {
    //             type: 'number-input',
    //             initialValue: 0,
    //             key: 'amount',
    //             name: '總金額'
    //         },
    //         {
    //             type: 'number-input',
    //             initialValue: 0,
    //             key: 'cost',
    //             name: '工本費'
    //         }
    //     ]
    // },
    {
      type: 'group',
      title: '線上購票',
      key: 'online_rental_card',
      span: 12,
      children: [
        {
          type: 'number-input',
          initialValue: 0,
          key: 'total',
          name: '總筆數',
        },
        {
          type: 'number-input',
          initialValue: 0,
          key: 'amount',
          name: '總金額',
        },
      ],
    },
  ],
};

const DailyNonSystemRevenue = props => {
  const { getFieldDecorator } = props.form;

  const [day, setDay] = useState(moment());
  const [revenue, setRevenue] = useState({
    day,
    data: {},
  });
  const [loading, setLoading] = useState(true);

  const transformLayout = (layoutConfig, initialValues) => {
    return (
      <Row gutter={layoutConfig.gutter}>
        {layoutConfig.content.map(item => transformItem(item, initialValues))}
      </Row>
    );
  };

  const transformItem = (item, initialValues, path = '') => {
    const pathKey = path === '' ? item.key : `${path}.${item.key}`;

    if (item.type === 'group') {
      return (
        <Col span={item.span}>
          <Title level={3}>{item.title}</Title>
          <Divider />
          {item.children.map(child =>
            transformItem(child, initialValues[item.key] || {}, pathKey)
          )}
        </Col>
      );
    }

    let initialValue;

    switch (item.type) {
      case 'number-input':
        initialValue =
          initialValues[item.key] !== undefined
            ? Number(initialValues[item.key])
            : 0;
        break;
      case 'text-input':
        initialValue =
          initialValues[item.key] !== undefined
            ? initialValues[item.key] + ''
            : '';
        break;
      default:
        break;
    }

    return (
      <Col span={item.span}>
        <Form.Item key={pathKey} label={item.name}>
          {getFieldDecorator(pathKey, {
            rules: [
              {
                required: false,
                message: item.name,
              },
            ],
            initialValue: initialValue,
          })(<Input placeholder={item.name} size='large' />)}
        </Form.Item>
      </Col>
    );
  };

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        let re = await pos.dailyNonSystemRevenue.readDailyNonSystemRevenue({
          day: day.format('YYYY-MM-DD'),
        });

        console.log(re);

        setRevenue(re);

        setLoading(false);
      } catch (err) {
        setRevenue({
          day,
          data: {},
        });

        setLoading(false);
      }
    }

    init();
  }, [day]);

  const handleDayChange = date => {
    setDay(date);
  };

  const mapData = (values, template) => {
    let data = {};

    if (template instanceof Array) {
      for (let i = 0; i < template.length; i++) {
        let t = template[i];

        data[t.key] = mapData(values, t);
      }

      return data;
    }

    switch (template.type) {
      case 'group':
        return mapData(values[template.key], template.children);
      case 'number-input':
        return values[template.key] !== null ? Number(values[template.key]) : 0;
      case 'text-input':
        return values[template.key] !== null ? values[template.key] : '';
      default:
        return null;
    }
  };

  const submit = async values => {
    let data = mapData(values, layoutConfig.content);

    try {
      await pos.dailyNonSystemRevenue.uploadDailyNonSystemRevenue({
        day: day.format('YYYY-MM-DD'),
        data,
      });

      message.success('成功');
    } catch (err) {
      message.error('失敗');
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) {
        console.error(err);
      }

      submit(values);
    });
  };

  return (
    <div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <DatePicker value={day} size='large' onChange={handleDayChange} />
        </Content>
      </div>

      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        {loading ? (
          <Spin></Spin>
        ) : (
          <Form className='ant-advanced-form' onSubmit={handleSubmit}>
            {transformLayout(layoutConfig, revenue.data)}

            <Button
              htmlType='submit'
              type='primary'
              style={{ width: '100%' }}
              size='large'
            >
              確認
            </Button>
          </Form>
        )}
      </Content>
    </div>
  );
};

export default Form.create({ name: 'daily-non-system-revenue' })(
  DailyNonSystemRevenue
);
