import React from 'react';
import { Upload, Icon, Button, Spin, Alert } from 'antd';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import config from '../config';

import './ImageUploader.less';

const states = {
  done: 0,
  loading: 1,
  crop: 2,
};

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.cropper = React.createRef();
    this.cropperEnable = props.cropperEnable;
    if (this.cropperEnable == null) {
      this.cropperEnable = true;
    }
  }

  state = {
    url: this.props.url || config.sampleImage,
    state: states.done,
    file: {
      loadPercent: 0,
    },
  };

  componentWillReceiveProps(props) {
    if (this.props.url !== props.url) {
      this.setState({
        url: props.url,
      });
    }
  }

  onChange = url => {
    if (this.props.onChange) {
      this.props.onChange(url);
    }
  };

  upload = file => {
    this.setState({
      state: states.loading,
      file: {
        loadPercent: 0,
      },
    });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        url: reader.result,
        state: this.cropperEnable ? states.crop : states.done,
      });

      this.onChange(reader.result);
    };
    reader.onerror = () => {
      this.setState({
        state: states.done,
      });
    };
    return false;
  };

  cropDone = () => {
    const dataUrl = this.cropper.current.getCroppedCanvas().toDataURL();
    this.setState({
      url: dataUrl,
      state: states.done,
    });

    this.onChange(dataUrl);
  };

  render() {
    return (
      <div className='image-uploader'>
        {this.state.state === states.done ? (
          <div
            style={{
              margin: 'auto',
              display: 'block',
              width: this.props.borderWidth || 360,
              border: '1px solid #ccc',
              borderRadius: 5,
              padding: 12,
            }}
          >
            <img
              src={this.state.url}
              alt='img'
              style={{
                height: this.props.height || 200,
                margin: 'auto',
                display: 'block',
              }}
            ></img>
          </div>
        ) : this.state.state === states.loading ? (
          <Spin tip='Loading...'>
            <Alert type='info' style={{ height: 120 }} />
          </Spin>
        ) : (
          <Cropper
            ref={this.cropper}
            src={this.state.url}
            style={{ height: 250, width: 480, margin: 'auto' }}
            // Cropper.js options
            aspectRatio={1}
            guides={false}
          />
        )}

        {this.state.state === states.crop ? (
          <Button onClick={this.cropDone}>
            <Icon type='check' />
            確定
          </Button>
        ) : (
          <Upload showUploadList={false} beforeUpload={this.upload}>
            <Button disabled={this.state.state === states.loading}>
              <Icon type='upload' />
              上傳圖片
            </Button>
          </Upload>
        )}
      </div>
    );
  }
}

export default ImageUploader;
