import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Select,
  Button,
  Spin,
  message,
  Collapse,
  Row,
  Col,
  Table,
  Tag,
  Card,
  Icon,
  Modal,
} from 'antd';
import { connect } from 'react-redux';

import moment from 'moment';

import { itemMapper, categoryMapper, userMapper } from '../../utils/mapper';
import pos from 'pos-api';
import * as util from '../../utils/utils';
import routes from '../../Routes';
import ImageUploader from '../../component/ImageUploader';
import Reflv from '../../component/Reflv';

import './UserForm.less';

const { Content } = Layout;
const { Panel } = Collapse;
const { Text } = Typography;

const BasicInfo = props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const enable = props.userRoutePrivilege.includes('u');

  return (
    <div>
      <Form.Item label='名稱'>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: '請輸入使用者名稱' }],
          initialValue: props.user ? props.user.name : null,
        })(<Input placeholder='請輸入使用者名稱' disabled={!enable} />)}
      </Form.Item>

      <Form.Item label='連絡電話'>
        {getFieldDecorator('phone_number', {
          rules: [{ required: false, message: '請輸入連絡電話' }],
          initialValue: props.user ? props.user.phone_number : null,
        })(<Input placeholder='請輸入連絡電話' disabled={!enable} />)}
      </Form.Item>

      <Form.Item label='電子信箱'>
        {getFieldDecorator('email', {
          rules: [{ required: false, message: '請輸入電子信箱' }],
          initialValue: props.user ? props.user.email : null,
        })(<Input placeholder='請輸入電子信箱' disabled={!enable} />)}
      </Form.Item>

      {!props.user ? (
        <Form.Item label='密碼'>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '請輸入密碼' }],
          })(
            <Input
              placeholder='請輸入密碼'
              type='password'
              disabled={!enable}
            />
          )}
        </Form.Item>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const MAX_DELAY_TIME = 1.2;

const WebCamCapture = props => {
  const playerRef = useRef(null);
  const [state, setState] = useState('uncapture');
  const [dataUrl, setDataUrl] = useState(null);

  const seekToLastFrame = () => {
    let player = playerRef.current;
    if (!player) return;

    let mediaElement = player._mediaElement;

    if (mediaElement) {
      try {
        if (mediaElement.buffered.length > 0) {
          mediaElement.currentTime = mediaElement.buffered.end(0);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  function capture(video) {
    let canvas = document.createElement('canvas');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    return canvas.toDataURL();
  }

  function visibilitychange() {
    if (document.webkitVisibilityState === 'visible') {
      seekToLastFrame();
    }
  }

  document.addEventListener('visibilitychange', visibilitychange);

  useEffect(() => {
    const frameTracker = setInterval(() => {
      let player = playerRef.current;
      if (!player) return;

      let mediaElement = player._mediaElement;

      if (!mediaElement) return;
      try {
        if (mediaElement.buffered.length > 0) {
          let diff = mediaElement.buffered.end(0) - mediaElement.currentTime;
          if (diff >= MAX_DELAY_TIME) {
            console.log(
              `player delay more than ${MAX_DELAY_TIME} sec. seek to last frame.`
            );
            mediaElement.currentTime = mediaElement.buffered.end(0);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }, 2000);

    return () => {
      document.removeEventListener('visibilitychange', visibilitychange);
      clearInterval(frameTracker);
    };
  }, []);

  return (
    <div>
      <div>
        {state === 'uncapture' ? (
          <Reflv
            url={props.url}
            hasAudio={false}
            type='flv'
            isLive={true}
            cors={true}
            config={{
              enableStashBuffer: false,
            }}
            onInit={player => {
              playerRef.current = player;
            }}
            onPause={() => {
              let player = playerRef.current;
              if (player && document.webkitVisibilityState === 'visible') {
                console.log('try to play');
                player.play();
              }
            }}
            onPlay={() => {
              seekToLastFrame();
            }}
          />
        ) : (
          <div style={{ padding: '14px 8px' }}>
            <img
              src={dataUrl}
              alt='capture'
              style={{ width: 340, margin: 'auto', display: 'block' }}
            ></img>
          </div>
        )}
      </div>

      <div>
        <Button
          style={{ margin: 'auto', display: 'block' }}
          onClick={() => {
            if (state === 'uncapture') {
              let player = playerRef.current;
              if (!player) return;

              let mediaElement = player._mediaElement;
              if (!mediaElement) return;

              let url = capture(mediaElement);

              setDataUrl(url);

              if (props.onChange) {
                props.onChange(url);
              }

              setState('captured');
            } else {
              setState('uncapture');
            }
          }}
        >
          {state === 'uncapture' ? '擷取照片' : '重新擷取'}
        </Button>
      </div>
    </div>
  );
};

const PhotoInfo = props => {
  const [useCam, setUseCam] = useState(false);

  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const { CAMERAS } = props.system;

  return (
    <div style={{ padding: '20px 6px' }}>
      <Form.Item htmlFor='image' style={{ margin: 'auto' }}>
        {CAMERAS && CAMERAS.length > 0 && useCam ? (
          <div>
            <div style={{ width: 400, margin: 'auto' }}>
              <WebCamCapture
                url={CAMERAS[0].SOURCE}
                onChange={url => {
                  setFieldsValue({
                    image: url,
                  });

                  if (props.onChange) {
                    props.onChange(url);
                  }
                }}
              ></WebCamCapture>
            </div>
          </div>
        ) : (
          <ImageUploader
            url={getFieldValue('image')}
            onChange={url => {
              setFieldsValue({
                image: url,
              });

              if (props.onChange) {
                props.onChange(url);
              }
            }}
          ></ImageUploader>
        )}
      </Form.Item>

      <div
        style={{
          textDecoration: 'underline',
          color: 'blue',
          fontSize: 18,
          float: 'right',
          cursor: 'pointer',
        }}
        onClick={() => {
          setUseCam(!useCam);
        }}
      >
        {useCam ? '使用檔案上傳' : '使用WebCam'}
      </div>
    </div>
  );
};

const columns = [
  {
    title: '裝置名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '狀態',
    dataIndex: 'status',
    key: 'status',
    render: (status, record) => {
      return (
        <Tag color={'geekblue'} key={status}>
          {status === 'sync'
            ? '已同步'
            : status === 'sync_fail'
              ? '同步失敗'
              : '同步中'}
        </Tag>
      );
    },
  },
];

const FaceDetectorTable = props => {
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    const job = async () => {
      let _user = await pos.user.fetchUser(user.id);
      setUser(_user);
    };

    let tracker = setInterval(() => {
      job();
    }, 3000);

    return () => {
      clearInterval(tracker);
    };
  });

  return <Table columns={columns} dataSource={user.register_apps} />;
};

const txColumns = [
  {
    title: '交易日期',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: timestamp => {
      if (!timestamp) {
        return '';
      } else {
        return moment(timestamp).format('YYYY/MM/DD');
      }
    },
  },
  {
    title: '交易類別',
    dataIndex: 'tx_type',
    key: 'tx_type',
    render: tx_type => {
      if (!tx_type) {
        return '';
      }

      if (!txTypeMap[tx_type]) {
        return '';
      }

      return txTypeMap[tx_type];
    },
  },
  {
    title: '交易金額',
    dataIndex: 'tx_amount',
    key: 'tx_amount',
  },
  {
    title: '餘額',
    dataIndex: 'after_balance',
    key: 'after_balance',
  },
  {
    title: '備註',
    dataIndex: 'comment',
    key: 'comment',
  },
];

const txTypeMap = {
  debit: '扣款',
  deposit: '存款',
  withdraw: '取款',
  feedback: '回饋',
};

const TransactionRecord = props => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 100,
  });

  const fetchTransactions = async pagination => {
    setLoading(true);

    const { user } = props;

    let resp = await pos.user.fetchTransactions(user.id, {
      sortDirection: 'desc',
      pageSize: pagination.pageSize,
      page: pagination.current - 1,
    });

    let items = resp.content;

    setItems(items);

    let nextPagination = {
      ...pagination,
    };

    nextPagination.total = resp.pagination.total_elements;
    setPagination(nextPagination);

    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchTransactions(pagination);
  };

  const init = async pagination => {
    fetchTransactions(pagination);
  };

  useEffect(() => {
    init(pagination);
  }, []);

  return (
    <Table
      loading={loading}
      columns={txColumns}
      dataSource={items}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

const DepositModal = props => {
  const [wallet, setWallet] = useState(null);

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setWallet(props.wallet);
  }, [props.wallet]);

  const deposit = useCallback(() => {
    if (!wallet) return;

    async function doDeposit() {
      await pos.wallet.deposit(wallet.id, {
        amount,
      });

      message.success('成功');

      props.onOk && props.onOk();
    }

    if (amount > 0) {
      doDeposit();
    } else {
      message.error('請輸入正確的存款金額');
    }
  }, [wallet, amount]);

  const handleAmountInputChange = evt => {
    const { value } = evt.target;

    const _amount = Number(value);

    setAmount(_amount);
  };

  return (
    <div>
      <Modal
        title='存款'
        visible={props.visible}
        onOk={deposit}
        onCancel={props.onCancel}
        okText='確定'
        cancelText='取消'
      >
        <Input
          placeholder='請輸入金額'
          value={amount}
          onChange={handleAmountInputChange}
        />
      </Modal>
    </div>
  );
};

const UserForm = props => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [photoChange, setPhotoChange] = useState(false);

  const [wallets, setWallets] = useState([]);
  const [currentWallet, setCurrentWallet] = useState(null);

  const [transactions, setTransactions] = useState([]);

  const { getFieldDecorator, setFieldsValue } = props.form;

  const { PLUGINS } = props.system;

  useEffect(() => {
    (async function init() {
      // init function scope

      if (props.match.params && props.match.params.id !== '0') {
        let user = await pos.user.fetchUser(props.match.params.id);
        setUser(user);

        setTimeout(() => {
          setFieldsValue({
            name: user.name,
            email: user.email,
            phone_number: user.phone_number,
          });

          let picurl;
          if (user && user.picture) {
            picurl = `${user.picture.url}?_=${user.picture.mtime}`;
            console.log(picurl);
            setFieldsValue({
              image: picurl,
            });
          }
        }, 250);

        let wallets = await pos.user.fetchWallets(props.match.params.id);

        setWallets(wallets.content);
      }

      setLoading(false);
    })();
  }, []);

  async function upload(values) {
    let entity = { ...values };
    delete entity.image;

    let _user;
    if (user && user.id) {
      entity.id = user.id;
      _user = await pos.user.updateUser(entity);
    } else {
      _user = await pos.user.addUser(entity);
    }

    if (photoChange && values.image) {
      let blob = util.dataURItoBlob(values.image);
      let file = new File([blob], 'image.png');

      await pos.user.updateUserPicture(_user.id, file);
    }

    return _user;
  }

  function submit() {
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        let hide = message.loading('處理中...');
        upload(values)
          .then(_user => {
            hide();
            props.history.push(`${routes.user.layout}${routes.user.path}`);
          })
          .catch(err => {
            hide();
            console.log(err);
            message.error('上傳失敗');
          });
      } else {
        console.log(err);
      }
    });
  }

  if (loading) {
    return <Spin></Spin>;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  let picurl;
  if (user && user.picture) {
    picurl = `${user.picture.url}?_=${user.picture.mtime}`;
  }

  const depositWallet = w => {
    setCurrentWallet(w);
  };

  const handleDepositOk = () => {
    setCurrentWallet(null);

    async function refetchWallet() {
      let wallets = await pos.user.fetchWallets(props.match.params.id);

      setWallets(wallets.content);
    }

    refetchWallet();
  };

  const handleDepositCancel = () => {
    setCurrentWallet(null);
  };

  return (
    <Form className='form'>
      <Content
        className='content'
        style={{
          margin: '12px 0px 16px 0px',
          background: '#fff',
          padding: 24,
        }}
      >
        <Collapse defaultActiveKey={['1']}>
          <Panel header='基本資料' key='1'>
            <BasicInfo
              {...props}
              user={user}
              privilege={props.userRoutePrivilege}
            ></BasicInfo>
          </Panel>
          <Panel header='大頭貼' key='2'>
            {getFieldDecorator('image', {
              rules: [],
            })(
              <PhotoInfo
                url={picurl}
                {...props}
                onChange={() => {
                  setPhotoChange(true);
                }}
              ></PhotoInfo>
            )}
          </Panel>

          <Panel header='錢包' key='3'>
            <Row>
              {wallets.map((w, idx) => {
                return (
                  <Col key={idx} span={6}>
                    <Card
                      title={`#${idx + 1}`}
                      bordered={true}
                      actions={[
                        <Button icon='wallet' onClick={() => depositWallet(w)}>
                          存款
                        </Button>,
                        <Button icon='wallet'>取款</Button>,
                      ]}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                        }}
                      >
                        餘額:
                      </div>

                      <div
                        style={{
                          fontWeight: 'bold',
                          fontSize: 28,
                        }}
                      >
                        {w.balance}
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Panel>

          <Panel header='錢包交易紀錄' key='4'>
            {user ? <TransactionRecord user={user} /> : <div></div>}
          </Panel>

          {user &&
          PLUGINS.EDGE_FACE_DETECTOR &&
          PLUGINS.EDGE_FACE_DETECTOR.ENABLE ? (
            <Panel header='人臉偵測裝置' key='5'>
              <FaceDetectorTable {...props} user={user}></FaceDetectorTable>
            </Panel>
          ) : (
            <div></div>
          )}
        </Collapse>
      </Content>

      <div
        style={{
          padding: '20px 30px',
        }}
      >
        <Button
          type='primary'
          size='large'
          block
          style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
          onClick={submit}
        >
          確認
        </Button>

        <div
          style={{
            height: 20,
            width: '100%',
          }}
        ></div>

        <Button
          type='danger'
          size='large'
          block
          style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
        >
          取消
        </Button>
      </div>

      <DepositModal
        wallet={currentWallet}
        visible={!!currentWallet}
        onOk={handleDepositOk}
        onCancel={handleDepositCancel}
      />
    </Form>
  );
};

const WrappedUserForm = Form.create({ name: 'user_form' })(UserForm);

const mapStateToProps = state => {
  return {
    system: state.system,
  };
};

export default connect(mapStateToProps)(WrappedUserForm);
