import React from 'react';
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Select,
  Button,
  Spin,
  message,
} from 'antd';

import moment from 'moment';

import ImageUploader from '../../../component/ImageUploader';

import './ItemForm.less';
import { itemMapper, categoryMapper } from '../../../utils/mapper';
import pos from 'pos-api';
import * as util from '../../../utils/utils';
import routes from '../../../Routes';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ImageSection = props => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <div className='content-wrapper'>
      <Title level={3}>商品圖片</Title>
      <Content className='content'>
        <Form.Item htmlFor='image' {...formItemLayout}>
          {getFieldDecorator('image', {
            rules: [],
          })(
            <ImageUploader
              url={props.item.image}
              onChange={url => {
                setFieldsValue({
                  image: url,
                });

                if (props.onChange) {
                  props.onChange(url);
                }
              }}
            ></ImageUploader>
          )}
        </Form.Item>
      </Content>
    </div>
  );
};

const BasicInfoSection = props => {
  const { getFieldDecorator } = props.form;

  return (
    <div className='content-wrapper'>
      <Title level={3}>基本資料</Title>
      <Content className='content'>
        <Form.Item label='分類' htmlFor='type'>
          {getFieldDecorator('type', {
            rules: [
              {
                required: true,
                message: 'Please select your item type!',
              },
            ],
            initialValue: props.item.type,
          })(
            <Select>
              <Option value='single'>單品</Option>
              <Option value='package'>套餐</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label='名稱' htmlFor='name'>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input your item name!',
              },
            ],
            initialValue: props.item.name,
          })(<Input />)}
        </Form.Item>

        <Form.Item label='商品描述' htmlFor='description'>
          {getFieldDecorator('description', {
            rules: [],
            initialValue: props.item.description,
          })(<TextArea rows={4} />)}
        </Form.Item>

        <Form.Item label='類別' htmlFor='categoryId'>
          {getFieldDecorator('categoryId', {
            rules: [
              {
                required: true,
                message: 'Please select your item category!',
              },
            ],
            initialValue: props.item.categoryId,
          })(
            <Select>
              {props.categorys.map((c, idx) => (
                <Option value={c.id} key={idx}>
                  {c.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label='適用日期' style={{ marginBottom: 0 }}>
          <Form.Item
            help='開始時間'
            style={{ display: 'inline-block' }}
            htmlFor='startTime'
          >
            {getFieldDecorator('startTime', {
              rules: [],
              initialValue: props.item.startTime
                ? moment(props.item.startTime)
                : null,
            })(<DatePicker showTime format='YYYY-MM-DD HH:mm:ss' />)}
          </Form.Item>
          <span
            style={{
              display: 'inline-block',
              width: '24px',
              textAlign: 'center',
            }}
          >
            -
          </span>
          <Form.Item
            help='結束時間'
            style={{ display: 'inline-block' }}
            htmlFor='endTime'
          >
            {getFieldDecorator('endTime', {
              rules: [],
              initialValue: props.item.endTime
                ? moment(props.item.endTime)
                : null,
            })(<DatePicker showTime format='YYYY-MM-DD HH:mm:ss' />)}
          </Form.Item>
        </Form.Item>
      </Content>
    </div>
  );
};

const PriceSection = props => {
  const { getFieldDecorator, getFieldValue } = props.form;
  return (
    <div className='content-wrapper'>
      <Title level={3}>適用價格</Title>
      <Content className='content'>
        <Form.Item label='適用' htmlFor='price.type'>
          {getFieldDecorator('price.type', {
            rules: [
              {
                required: true,
                message: 'Please select your item type!',
              },
            ],
            initialValue: props.item.price.type,
          })(
            <Select>
              <Option value='take_in'>內用</Option>
              <Option value='take_out'>外帶</Option>
              <Option value='all'>內用/外帶</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label='價格' style={{ marginBottom: 0 }}>
          <Form.Item
            help='內用'
            style={{ display: 'inline-block' }}
            htmlFor='price.in'
          >
            {getFieldDecorator('price.in', {
              rules:
                getFieldValue('price.type') !== 'take_out'
                  ? [
                      {
                        required: true,
                        message: 'Please set price in value',
                      },
                    ]
                  : [],
              initialValue: props.item.price.in,
            })(
              <InputNumber
                style={{ width: 180 }}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              ></InputNumber>
            )}
          </Form.Item>

          <span
            style={{
              display: 'inline-block',
              width: '24px',
              textAlign: 'center',
            }}
          ></span>

          <Form.Item
            help='外帶'
            style={{ display: 'inline-block' }}
            htmlFor='price.out'
          >
            {getFieldDecorator('price.out', {
              rules:
                getFieldValue('price.type') !== 'take_in'
                  ? [
                      {
                        required: true,
                        message: 'Please set price out value',
                      },
                    ]
                  : [],
              initialValue: props.item.price.out,
            })(
              <InputNumber
                style={{ width: 180 }}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              ></InputNumber>
            )}
          </Form.Item>
        </Form.Item>
      </Content>
    </div>
  );
};

class ItemForm extends React.Component {
  state = {};

  onImageChange = () => {
    this.isImageChange = true;
  };

  fetchItem = async () => {
    let item = await pos.item.fetchItem(this.props.match.params.id);

    this.setState({
      item: itemMapper.toView(item, {
        imageType: 'w320',
      }),
    });
  };

  fetchCategory = async () => {
    let categorys = await pos.category.fetchCategorys();
    categorys = categoryMapper.toView(categorys);
    this.setState({
      categorys,
    });
    return categorys;
  };

  componentDidMount() {
    this.fetchCategory().then(categorys => {
      if (categorys.length <= 0) return;
      if (this.props.match.params && this.props.match.params.id !== '0') return;
      this.setState({
        item: {
          name: 'name',
          image: null,
          type: 'single',
          price: {
            type: 'all',
            in: 100,
            out: 100,
          },
          categoryId: categorys[0].id,
        },
      });
    });

    if (this.props.match.params && this.props.match.params.id !== '0') {
      this.fetchItem();
      this.setState({
        op: 'edit',
      });
    } else {
      this.setState({
        op: 'add',
      });
    }
  }

  upload = async values => {
    let item = values;

    let hide = message.loading('處理中...');
    try {
      if (this.state.op === 'edit') {
        item.id = this.props.match.params.id;
        await pos.item.updateItem(itemMapper.toEntity(item));
      } else {
        item = await pos.item.addItem(itemMapper.toEntity(item));
      }

      if (this.isImageChange) {
        // swaggerjs just accept file instance for upload file
        // finded this answer in https://github.com/swagger-api/swagger-js/issues/1109#issuecomment-320791276
        let blob = util.dataURItoBlob(values.image);
        let file = new File([blob], 'image.png');
        await pos.item.updateItemPicture(item.id, file);
      }

      hide();
      message.success('儲存成功');
      this.props.history.push(`${routes.items.layout}${routes.items.path}`);
    } catch (err) {
      message.error('修改失敗');
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.upload(values);
      } else {
        console.log(err);
      }
    });
  };

  render() {
    if (!this.state.item || !this.state.categorys) {
      return <Spin></Spin>;
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const infoProps = {
      item: this.state.item,
      categorys: this.state.categorys,
    };

    return (
      <Form {...formItemLayout} className='form'>
        <ImageSection
          {...infoProps}
          {...this.props}
          onChange={this.onImageChange}
        ></ImageSection>
        <BasicInfoSection {...infoProps} {...this.props}></BasicInfoSection>
        <PriceSection {...infoProps} {...this.props}></PriceSection>

        <div
          style={{
            padding: '20px 30px',
          }}
        >
          <Button
            type='primary'
            size='large'
            block
            style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
            onClick={this.handleSubmit}
          >
            確認
          </Button>

          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>

          <Button
            type='danger'
            size='large'
            block
            style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
          >
            取消
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedItemForm = Form.create({ name: 'item_form' })(ItemForm);

export default WrappedItemForm;
