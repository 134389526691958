import React from 'react';
import { Layout, Table, Divider, Button, message } from 'antd';
import { Drawer, Form, Input } from 'antd';
import ImageUploader from '../../component/ImageUploader';

import pos from 'pos-api';
import { categoryMapper } from '../../utils/mapper';
import * as util from '../../utils/utils';

const { Content } = Layout;

const columns = [
  {
    title: '類別名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '類別圖片',
    dataIndex: 'image',
    key: 'image',
    render: (text, record) => {
      return (
        <img
          src={record.image}
          style={{ maxWidth: 86, maxHeight: 48, borderRadius: 5 }}
          alt='img'
        />
      );
    },
  },
  {
    title: '操作',
    key: 'action',
    render: (text, record) => (
      <span>
        <Button type='link' icon='edit' onClick={record.onEdit}></Button>
        <Divider type='vertical' />
        <Button type='link' icon='delete' onClick={record.onDelete}></Button>
      </span>
    ),
  },
];

const CategoryDrawer = Form.create()(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.imageChange = false;
    }

    upload = async values => {
      let c = values;
      if (this.props.category) {
        c.id = this.props.category.id;
        c = categoryMapper.toEntity(c);
        c = await pos.category.updateCategory(c);
      } else {
        c = categoryMapper.toEntity(c);
        c = await pos.category.addCategory(c);
      }

      if (this.imageChange) {
        let blob = util.dataURItoBlob(values.image);
        let file = new File([blob], 'image.png');
        await pos.category.updateCategoryPicture(c.id, file);
      }

      return await pos.category.fetchCategory(c.id);
    };

    onSubmit = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let hide = message.loading('處理中...');
          this.upload(values)
            .then(c => {
              hide();
              message.info('上傳成功');
              if (this.props.category) {
                if (this.props.actions && this.props.actions.replace) {
                  this.props.actions.replace(categoryMapper.toView(c));
                }
              } else {
                if (this.props.actions && this.props.actions.add) {
                  this.props.actions.add(categoryMapper.toView(c));
                }
              }

              if (this.props.onClose) {
                this.props.onClose();
              }
            })
            .catch(err => {
              hide();
              message.error('上傳失敗');
              if (this.props.onClose) {
                this.props.onClose();
              }
            });
        } else {
          console.log(err);
        }
      });
    };

    render() {
      const { getFieldDecorator, setFieldsValue } = this.props.form;

      return (
        <Drawer {...this.props}>
          <Form>
            <Form.Item label='名稱'>
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Please enter category name' },
                ],
                initialValue: this.props.category
                  ? this.props.category.name
                  : null,
              })(<Input placeholder='Please enter category name' />)}
            </Form.Item>

            <Form.Item label='圖片'>
              {getFieldDecorator('image', {
                rules: [],
              })(
                <ImageUploader
                  url={this.props.category ? this.props.category.image : null}
                  onChange={url => {
                    setFieldsValue({
                      image: url,
                    });

                    this.imageChange = true;
                  }}
                ></ImageUploader>
              )}
            </Form.Item>
          </Form>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class Categorys extends React.Component {
  state = {
    drawer: {
      title: '',
      visible: false,
    },
  };

  fetchCategorys = async () => {
    let categorys = await pos.category.fetchCategorys();
    categorys = categoryMapper.toView(categorys);
    categorys.forEach((c, idx) => {
      c.key = c.id;
      c.onEdit = this.onEditBtn(c);
      c.onDelete = this.onDeleteBtn(c);
    });

    this.setState({
      categorys,
    });
  };

  onDrawerClose = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = false;
      return {
        drawer,
      };
    });
  };

  showDrawer = title => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.visible = true;
      return {
        drawer,
      };
    });
  };

  onAddBtn = () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '新增';
      return {
        drawer,
        current: null,
      };
    });

    this.showDrawer();
  };

  onEditBtn = category => () => {
    this.setState(prev => {
      let drawer = Object.assign({}, prev.drawer);
      drawer.title = '修改';
      return {
        drawer,
        current: category,
      };
    });

    this.showDrawer();
  };

  deleteCategory = async category => {
    await pos.category.deleteCategory(category.id);
  };

  onDeleteBtn = category => () => {
    let hide = message.loading('處理中...');
    this.deleteCategory(category)
      .then(() => {
        hide();
        message.info('刪除成功');
        this.setState(prev => {
          return {
            categorys: prev.categorys.filter(c => c !== category),
          };
        });
      })
      .catch(err => {
        hide();
        message.error('刪除失敗');
      });
  };

  drawerActoins = {
    add: c => {
      this.setState(prev => {
        c.key = c.id;
        c.onEdit = this.onEditBtn(c);
        c.onDelete = this.onDeleteBtn(c);
        return {
          categorys: [...prev.categorys, c],
        };
      });
    },
    replace: c => {
      this.setState(prev => {
        let cs = prev.categorys;
        let idx = -1;
        for (let i = 0; i < cs.length; i++) {
          if (cs[i].id === c.id) {
            idx = i;
            break;
          }
        }

        if (idx >= 0) {
          c.key = c.id;
          c.onEdit = this.onEditBtn(c);
          c.onDelete = this.onDeleteBtn(c);
          cs.splice(idx, 1, c);
          let next = [...cs];
          return {
            categorys: next,
          };
        }

        return {};
      });
    },
  };

  componentDidMount() {
    this.fetchCategorys();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <Button type='link' icon='plus' onClick={this.onAddBtn}>
            新增類別
          </Button>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          {this.state.categorys ? (
            <Table columns={columns} dataSource={this.state.categorys} />
          ) : (
            <Table columns={columns} loading />
          )}
        </Content>

        <CategoryDrawer
          width={600}
          title={this.state.drawer.title}
          placement='right'
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawer.visible}
          category={this.state.current}
          actions={this.drawerActoins}
        ></CategoryDrawer>
      </Content>
    );
  }
}

export default Categorys;
