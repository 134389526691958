import React from 'react';
import { Form, Icon, Input, Button, Checkbox, Card, message } from 'antd';

import { connect } from 'react-redux';

import uuid from 'uuid/v4';

import { login } from '../utils/utils';
import pos from 'pos-api';
import { updateUser } from '../actions/user';

const FloatCard = props => {
  const w = props.width || 500;
  const h = props.height || 560;

  const offset = {};
  offset.w = w / 2;
  offset.h = h / 2;

  return (
    <div
      style={{
        width: w,
        height: h,
        position: 'absolute',
        margin: `-${offset.h}px 0px 0px -${offset.w}px`,
        transform: `translate(50vw, 50vh)`,
      }}
    >
      <Card style={{ height: '100%' }} {...props}></Card>
    </div>
  );
};

class Init extends React.Component {
  state = {};

  submitForm = async form => {
    try {
      await pos.user.addUser(form);
      let user = await login({
        name: form.name || form.email,
        pass: form.password,
      });

      let store = await pos.store.addStore({
        name: '本店',
        host: 'localhost',
        access_token: uuid(),
        access_key: uuid(),
      });

      await pos.category.addCategory({
        name: 'Default',
      });

      message.info('初始化成功!');
      this.props.dispatch(
        updateUser({
          name: user.user,
          roles: user.roles,
          id: user.id,
          token: user.token,
        })
      );

      this.props.history.push('/dashboard');
    } catch (err) {
      message.error('初始化失敗');
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let form = {
          name: values.username,
          password: values.password,
          ph_num: values.ph_num,
          email: values.email,
        };

        this.submitForm(form);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <FloatCard>
        <Form onSubmit={this.handleSubmit} className='login-form'>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                { required: true, message: 'Please input your username!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder='Username'
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type='password'
                placeholder='Password'
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: false, message: 'Please input your email!' }],
            })(<Input placeholder='Email' />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('phone_number', {
              rules: [
                { required: false, message: 'Please input your phone number!' },
              ],
            })(<Input placeholder='Phone Number' />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}

            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
            >
              註冊
            </Button>
          </Form.Item>
        </Form>
      </FloatCard>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(Init);
export default connect()(WrappedNormalLoginForm);
