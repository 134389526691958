import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Spin, Button, message, Divider } from 'antd';

import { systemInfoMapper } from '../../../utils/mapper';

import { connect } from 'react-redux';

import pos from 'pos-api';

const { Content } = Layout;

const infoKeys = [
  {
    key: 'version',
    name: '版本',
  },
  {
    key: 'build_timestamp',
    name: '建置時間',
  },
];

const Info = props => {
  const [health, setHealth] = useState({});

  useEffect(() => {
    const init = async () => {
      const _health = await pos.health.readHealth();

      setHealth(_health);
    };

    init();
  }, []);

  return (
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
        minHeight: 280,
      }}
    >
      {infoKeys.map(info => {
        return (
          <div>
            {info.name}:{health[info.key] ? health[info.key] : ''}
          </div>
        );
      })}
    </Content>
  );
};

const InfoWapper = connect(state => {
  return {
    system: state.system,
  };
})(Info);

export default InfoWapper;
