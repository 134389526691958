import React from 'react';
import { Form, Icon, Input, Button, Checkbox, Card, message } from 'antd';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { updateUser } from '../actions/user';
import { login } from '../utils/utils';

const FloatCard = props => {
  const w = props.width || 500;
  const h = props.height || 560;

  const offset = {};
  offset.w = w / 2;
  offset.h = h / 2;

  return (
    <div
      style={{
        width: w,
        height: h,
        position: 'absolute',
        margin: `-${offset.h}px 0px 0px -${offset.w}px`,
        transform: `translate(50vw, 50vh)`,
      }}
    >
      <Card style={{ height: '100%' }} {...props}></Card>
    </div>
  );
};

class NormalLoginForm extends React.Component {
  state = {
    logined: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        login({
          name: values.username,
          pass: values.password,
        })
          .then(user => {
            user = {
              name: user.user,
              token: user.token,
              roles: user.roles,
              id: user.id,
            };

            this.props.dispatch(updateUser(user));

            this.setState({
              logined: true,
            });
          })
          .catch(err => {
            console.log(err);
            message.error('帳號或密碼錯誤');
          });
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <FloatCard>
        <Form onSubmit={this.handleSubmit} className='login-form'>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                { required: true, message: 'Please input your username!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder='帳號'
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type='password'
                placeholder='密碼'
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}

            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
            >
              登入
            </Button>
          </Form.Item>
        </Form>

        {this.state.logined ? (
          <Redirect to={'/dashboard'}></Redirect>
        ) : (
          <div></div>
        )}
      </FloatCard>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(
  NormalLoginForm
);
export default connect()(WrappedNormalLoginForm);
