import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Card,
  Typography,
  Popover,
  Button,
  Switch,
  Spin,
  Empty,
} from 'antd';

import { Link, generatePath, Redirect } from 'react-router-dom';

import './ItemContainer.less';

import routes from '../Routes';
import { itemMapper } from '../utils/mapper';
import pos from 'pos-api';

const { Meta } = Card;
const { Title } = Typography;

class ItemHeader extends React.Component {
  state = {
    popover: {
      visible: false,
    },
    item: this.props.item,
  };

  onPopoverVisibleChange = visible => {
    this.setState({
      popover: {
        visible,
      },
    });
  };

  onEdit = () => {
    return evt => {
      evt.preventDefault();
      let path = `${routes.itemForm.layout}${routes.itemForm.path}`;
      this.setState({
        next: generatePath(path, { id: this.state.item.id }),
      });
    };
  };

  onDuplicate = () => {
    const duplicate = async item => {
      let resp = await pos.item.addItem(item, {
        duplicate: true,
      });

      return resp;
    };

    return evt => {
      evt.preventDefault();
      duplicate(this.state.item).then(item => {
        if (this.props.actions.addItem) {
          this.props.actions.addItem(itemMapper.toView(item));
        }
      });

      this.setState({
        popover: {
          visible: false,
        },
      });
    };
  };

  onDelete = () => {
    const deleteItem = async item => {
      await pos.item.deleteItem(item);
    };

    return evt => {
      evt.preventDefault();
      deleteItem(this.state.item).then(() => {
        if (this.props.actions.deleteItem) {
          this.props.actions.deleteItem(this.state.item);
        }
      });

      this.setState({
        popover: {
          visible: false,
        },
      });
    };
  };

  onSwitch = () => {
    return value => {
      this.setState(prev => {
        prev.item.active = value;
        pos.item.updateItem(itemMapper.toEntity(prev.item));
        return prev;
      });
    };
  };

  shouldComponentUpdate(props, nextState) {
    return (
      this.state.popover.visible !== nextState.popover.visible ||
      this.state.next !== nextState.next
    );
  }

  render() {
    const PopoverContent = props => (
      <div className='popover-content'>
        <Button type='link' icon='edit' onClick={this.onEdit()}>
          編輯
        </Button>
        <Button type='link' icon='copy' onClick={this.onDuplicate()}>
          複製
        </Button>
        <Button type='link' icon='search' onClick={this.onDelete()}>
          刪除
        </Button>
        <Button type='link' icon='search' onClick={evt => evt.preventDefault()}>
          供應中{' '}
          <Switch
            defaultChecked={this.state.item.active}
            style={{ float: 'right' }}
            onChange={this.onSwitch()}
          />
        </Button>
      </div>
    );

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {this.state.next ? (
          <Redirect to={this.state.next}></Redirect>
        ) : (
          <div></div>
        )}
        <div style={{ width: '70%' }}>
          <h3 style={{ marginBottom: 0 }}>{this.state.item.name}</h3>
        </div>

        <div style={{ width: '30%', textAlign: 'right' }}>
          <Popover
            trigger='click'
            content={<PopoverContent></PopoverContent>}
            visible={this.state.popover.visible}
            onVisibleChange={this.onPopoverVisibleChange}
          >
            <Button
              type='primary'
              shape='circle'
              icon='ellipsis'
              className='popover-btn'
              style={{ fontSize: 28, color: '#666' }}
            />
          </Popover>
        </div>
      </div>
    );
  }
}

const ItemDesc = props => {
  let { type, priceType, price } = itemMapper.toDescView(props.item);

  return (
    <div className='item-desc'>
      <p>分類: {type}</p>
      <p>適用: {priceType}</p>
      <p
        style={{
          textAlign: 'right',
          color: '#444',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        價格: ${price}
      </p>
    </div>
  );
};

class Container extends React.Component {
  state = {
    items: this.props.items,
  };

  itemActions = {
    addItem: item => {
      this.setState(prev => {
        return {
          items: [...prev.items, item],
        };
      });
    },

    deleteItem: item => {
      this.setState(prev => {
        return {
          items: prev.items.filter(t => t.id !== item.id),
        };
      });
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
      this.setState({ items: nextProps.items });
    }
  }

  render() {
    if (!this.state.items) {
      return <Spin></Spin>;
    }

    return (
      <Row>
        {this.props.title ? (
          <Title level={4} style={{ marginLeft: 8 }}>
            {this.props.title}
          </Title>
        ) : (
          <div></div>
        )}

        {this.state.items.length > 0 ? (
          this.state.items.map((item, idx) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              style={{ padding: '8px' }}
              key={idx}
            >
              <Link
                to={generatePath(
                  `${routes.itemForm.layout}${routes.itemForm.path}`,
                  { id: item.id }
                )}
              >
                <Card
                  bordered
                  hoverable
                  cover={<img alt='example' src={item.image} />}
                  bodyStyle={{ padding: '24px 24px 14px 24px' }}
                >
                  <Meta
                    title={
                      <ItemHeader
                        key={item.id}
                        item={item}
                        history={this.props.history}
                        actions={this.itemActions}
                      ></ItemHeader>
                    }
                    description={<ItemDesc item={item}></ItemDesc>}
                  />
                </Card>
              </Link>
            </Col>
          ))
        ) : (
          <Empty></Empty>
        )}
      </Row>
    );
  }
}

Container.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default Container;
