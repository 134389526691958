import React, { useState, useEffect } from 'react';
import { Layout, Pagination, Button, Typography } from 'antd';
import pos from 'pos-api';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

const { Content } = Layout;
const { Paragraph } = Typography;

const useLogState = (state = {}) => {
  const [text, setText] = useState(state.text || '');
  const [logs, setLogs] = useState(state.logs || []);
  const [pagination, setPagination] = useState(
    state.pagination || {
      current: 1,
      total: 100,
      pageSize: 30,
    }
  );

  const fetchLogs = async pagination => {
    let resp = await pos.log.fetchLogs({
      page: pagination.current - 1,
      page_size: pagination.pageSize,
      sort_direction: 'desc',
    });

    let logs = resp.content;
    let pagin = resp.pagination;

    setLogs(logs);

    let _text = '';
    logs.forEach(log => {
      delete log.message.title;
      _text += `[${new Date(log.timestamp).toLocaleString()}] ${log.title}\n${JSON.stringify(log.message, undefined, 3)}\n`;
    });

    setText(_text);

    let next = {
      ...pagination,
    };

    next.total = pagin.total_elements;

    setPagination(next);
  };

  const init = async () => {
    fetchLogs(pagination);
  };

  // initial
  useEffect(() => {
    init();
  }, []);

  const onPageChange = page => {
    let next = {
      ...pagination,
    };

    next.current = page;

    setPagination(next);
    fetchLogs(next);
  };

  return {
    onPageChange,
    text,
    logs,
    pagination,
  };
};

const Log = props => {
  const { onPageChange, text, logs, pagination } = useLogState({});

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 80,
        }}
      ></Content>

      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 280,
        }}
      >
        <AceEditor
          mode='javascript'
          theme='github'
          name='UNIQUE_ID_OF_DIV'
          editorProps={{ $blockScrolling: true }}
          fontSize={16}
          value={text}
          readOnly={true}
          style={{ width: '100%', height: 600 }}
        />

        <div
          style={{
            marginTop: 22,
            minHeight: 42,
          }}
        >
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            onChange={onPageChange}
            style={{ float: 'right' }}
          ></Pagination>
        </div>
      </Content>
    </Content>
  );
};

export default Log;
