import React from 'react';
import { SketchPicker } from 'react-color';

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.color || {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
  };

  componentWillReceiveProps(props) {
    this.setState({
      color: props.color,
    });
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleChange = color => {
    this.setState({ color: color.hex });
    if (this.props.onChange) {
      this.props.onChange(color.hex);
    }
  };

  render() {
    return (
      <div {...this.props}>
        <div
          style={{
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          }}
          onClick={this.handleClick}
        >
          <div
            style={{
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `${this.state.color}`,
            }}
          />
        </div>
        {this.state.displayColorPicker ? (
          <div
            style={{
              position: 'absolute',
              zIndex: '2',
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              onClick={this.handleClose}
            />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
              style={{ position: 'fixed', zIndex: 70 }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
