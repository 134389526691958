import { ConfigProvider, Spin } from 'antd';
import zhTw from 'antd/es/locale/zh_TW';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import { Provider } from 'react-redux';
import store from './Store';

import { updateSystem } from './actions/system';
import { updateUser } from './actions/user';

import Init from './view/Init';
import Login from './view/Login';

import pos from 'pos-api';
import routes, { layouts } from './Routes';

import stomp from './utils/stomp';
import { override } from './utils/utils';

import { notification } from 'antd';

import posv2 from './lib/posv2';

import routeConfig from './route-config';

import moment from 'moment';
import 'moment/locale/zh-tw';
moment.locale('zh-tw');

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

console.log(process.env);

let isRentalNotificationSend = false;

class App extends Component {
  state = {};

  init = async () => {
    // init route config
    try {
      let resp = await fetch(
        `${
          process.env.REACT_APP_POS_API_URL || process.env.PUBLIC_URL
        }/api/v1/system/route-config`
      );
      let routeConfigBody = await resp.json();
      let serverRouteConfig = routeConfigBody.content;
      override(serverRouteConfig, routeConfig);

      console.log(routeConfig);
    } catch (err) {
      console.warn(err);
    }

    override(routeConfig, routes);

    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      this.setState({
        user,
      });
    }

    const ignoreTransport = ['/api/v1/system', '/api/v1/store'];
    let client = await pos.init({
      url: `${process.env.PUBLIC_URL}/data/src/api.yaml`,
      server: {
        url: `${
          process.env.REACT_APP_POS_API_URL || process.env.PUBLIC_URL
        }/api/v1`,
      },
      requestInterceptor: req => {
        if (req.url !== '../src/api.yaml') {
          let f = true;
          ignoreTransport.forEach(i => {
            if (req.url.includes(i)) {
              f = false;
            }
          });

          let storeId = sessionStorage.getItem('store_id');
          if (f && storeId) {
            if (req.url.includes('?')) {
              req.url = req.url + `&store_id=${storeId}`;
            } else {
              req.url = req.url + `?store_id=${storeId}`;
            }
          }
        }

        let user = localStorage.getItem('user');
        if (user) {
          user = JSON.parse(user);
          if (user.token) {
            req.headers.Authorization = user.token;
          }
        }
      },
    });

    let system = await pos.system.fetchSystem();

    store.dispatch(updateSystem(system));

    this.system = system;

    this.setState({
      systemInit: system.SERVER.INIT,
    });

    let delay = async delay => {
      let d = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(1);
        }, delay);
      });

      await d;
    };

    let waitForStomp = Promise.any([this.initStomp(), delay(1000)]);

    try {
      await waitForStomp;
    } catch (err) {
      console.error(err);
    }

    let startPage = '/dashboard';
    if (!system.SERVER.INIT) {
      startPage = '/init';
    } else if (user) {
      startPage = '/dashboard';
    } else {
      startPage = '/login';
    }

    this.setState({
      init: true,
      startPage,
    });

    console.log(system);

    if (
      ['taipei', 'debug'].includes(system.MODE) &&
      startPage === '/dashboard'
    ) {
      console.log('get summary');

      const periodQueryRentalStatus = async () => {
        try {
          let summary = await posv2.getRentalStatusSummary();

          if (summary.expire_rentals_total > 0) {
            let message = `${moment().year() - 1911} 年 ${moment().format(
              'MM'
            )}尚有
                  ${summary.expire_rentals_total}
                  輛車尚未續購月票`;
            isRentalNotificationSend = true;

            notification.open({
              message: '未續購月票通知',
              description: message,
              onClick: () => {
                console.log('Notification Clicked!');
              },
              duration: 0,
              style: {
                color: '#f00',
              },
            });
          }
        } finally {
          if (!isRentalNotificationSend) {
            setTimeout(periodQueryRentalStatus, 10000);
          }
        }
      };

      periodQueryRentalStatus();
    }
  };

  initStomp = async () => {
    let { MQ } = this.system;

    if (!MQ.WEB_STOMP_ENABLE) {
      console.log(`realtime stomp messaging feature didn't enable.`);
      return;
    }

    let customMq = localStorage.getItem('custom-web-stomp');

    if (customMq) {
      MQ = JSON.parse(customMq);
    }

    await stomp.connect({
      host: MQ.HOST,
      port: MQ.WEB_STOMP_PORT,
      username: MQ.WEB_STOMP_USERNAME,
      password: MQ.WEB_STOMP_PASSWORD,
      protocal: MQ.WEB_STOMP_PROTOCAL,
    });

    try {
      this.sub = await stomp.asyncSubscribe(
        '/exchange/pos/order.auto_checkout.failure',
        message => {
          if (this.isShowNotification) return;
          let body = message.body;
          if (!body) return;

          let order = JSON.parse(body);
          let item = order.items[0];
          if (!item) return;

          const Message = props => (
            <span>
              <span>車號:</span>
              <a
                href='script: void(0)'
                onClick={evt => {
                  evt.preventDefault();
                  //history.push(`${routes.manage.children.car.layout}${routes.manage.children.car.path}`)
                }}
              >
                {item.name}
              </a>
              <span>尚未繳費，無法離場!</span>
            </span>
          );

          notification['info']({
            message: <Message></Message>,
            onClose: () => {
              this.isShowNotification = false;
            },
          });

          this.isShowNotification = true;
        }
      );
    } catch (err) {
      console.error(err);
    }

    try {
      this.edgeDetectorSub = await stomp.asyncSubscribe(
        '/exchange/pos/edge.face.detector.callback',
        _message => {
          let body = _message.body;
          if (!body) return;

          body = JSON.parse(body);

          let message = '';
          if (body.type === 3 && body.temperature < 37.3) {
            message = `${body.name}[員工]: 體溫${body.temperature}允許通行`;
          } else {
            message = `${body.name}[陌生人]: 體溫${body.temperature}不允許通行`;
          }

          notification['info']({
            message: message,
            duration: 3,
          });
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount() {
    this.init();

    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      user = {
        name: user.user,
        token: user.token,
        roles: user.roles,
        id: user.id,
      };

      store.dispatch(updateUser(user));
    }
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.edgeDetectorSub) {
      this.edgeDetectorSub.unsubscribe();
    }
  }

  render() {
    if (!this.state.init) {
      return (
        <div style={{ height: '100vh' }}>
          <div className='loading-background'>
            <Spin></Spin>
          </div>
        </div>
      );
    }

    return (
      <ConfigProvider locale={zhTw}>
        <Provider store={store}>
          <Router history={history}>
            <Route
              exact
              path='/'
              render={() => {
                return <Redirect to={this.state.startPage}></Redirect>;
              }}
            />

            {Object.keys(layouts).map((key, idx) => (
              <Route
                path={process.env.PUBLIC_URL + layouts[key].path}
                component={layouts[key].component}
                key={idx}
              ></Route>
            ))}

            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/init`}
              component={Init}
            ></Route>
          </Router>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default App;
