import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Spin,
  Switch,
  Tabs,
  Typography,
  message,
  notification,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';

import Reflv from '../../../component/Reflv';

import stomp from '../../../utils/stomp';

import flv from 'flv.js';

import pos from 'pos-api';
import './CarMonitor.less';

const FlvEvents = flv.Events;

const { Content } = Layout;

const { TabPane } = Tabs;

const { Title, Paragraph } = Typography;

const { confirm } = Modal;

// max delay time for every player
// if player's current time small than LAST_FRAME_TIME - MAX_DELAY_TIME
// force player seek to last frame
const MAX_DELAY_TIME = 1.2;

let emitter = document.createElement('div');
let system = {};

const VideoContainer = props => {
  const playersRef = useRef([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    playersRef.current = props.sources.map(s => null);

    function change() {
      if (document.webkitVisibilityState === 'visible') {
        let players = playersRef.current;

        players.forEach(player => {
          if (!player) return;
          let mediaElement = player._mediaElement;
          if (mediaElement & (mediaElement.buffered.length > 0)) {
            try {
              mediaElement.currentTime = mediaElement.buffered.end(0);
            } catch (err) {
              console.error(err);
            }
          }
        });
      }
    }

    document.addEventListener('visibilitychange', change);

    const frameTracker = setInterval(() => {
      let players = playersRef.current;

      players.forEach(player => {
        if (!player) return;
        let mediaElement = player._mediaElement;

        if (!mediaElement) return;
        try {
          if (mediaElement.buffered.length > 0) {
            let diff = mediaElement.buffered.end(0) - mediaElement.currentTime;
            if (diff >= MAX_DELAY_TIME) {
              mediaElement.currentTime = mediaElement.buffered.end(0);
            }
          }
        } catch (err) {
          console.error(err);
        }
      });
    }, 2000);

    setLoaded(true);

    return function cleanup() {
      document.removeEventListener('visibilitychange', change);
      clearInterval(frameTracker);
    };
  }, []);

  const updatePlayers = (idx, player) => {
    const next = [...playersRef.current];
    next[idx] = player;

    Object.keys(FlvEvents).forEach(key => {
      player.on(key, data => {
        console.log(key);
        console.warn(data);
      });
    });

    playersRef.current = next;
  };

  const seekToLastFrame = idx => {
    let players = playersRef.current;
    let player = players[idx];
    if (!player) return;

    let mediaElement = player._mediaElement;

    if (mediaElement) {
      try {
        if (mediaElement.buffered.length > 0) {
          mediaElement.currentTime = mediaElement.buffered.end(0);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const { sources } = props;

  return loaded ? (
    <div>
      <Row gutter={12}>
        {sources.map((source, idx) => {
          return (
            <Col
              key={idx}
              className='gutter-row'
              span={12}
              style={{ marginBottom: 24 }}
            >
              <div
                style={{
                  float: 'left',
                  margin: '-15px 0px 0px 18px',
                  fontSize: 16,
                  background: '#fff',
                  fontWeight: 'bold',
                  padding: '4px 12px',
                }}
              >
                {source.name}
              </div>
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 5,
                  padding: '12px 12px',
                }}
              >
                <Reflv
                  url={source.http_flv}
                  hasAudio={false}
                  type='flv'
                  isLive={true}
                  cors={true}
                  config={{
                    enableStashBuffer: false,
                  }}
                  onInit={player => updatePlayers(idx, player)}
                  onPause={() => {
                    let players = playersRef.current;
                    let player = players[idx];
                    if (
                      player &&
                      document.webkitVisibilityState === 'visible'
                    ) {
                      console.log('try to play');
                      player.play();
                    }
                  }}
                  onPlay={() => {
                    seekToLastFrame(idx);
                  }}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <div>
      <Spin></Spin>
    </div>
  );
};

const GatewayControlCard = props => {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const trigger = type => {
    if (type === 'open') {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 500);
    } else if (type === 'close') {
      setClose(true);
      setTimeout(() => {
        setClose(false);
      }, 500);
    }
  };

  useEffect(() => {
    let listener = event => {
      let body = event.detail;

      let gateway = props.gateway;

      if (!body.gateway) {
        return;
      }

      if (body.gateway.id !== gateway.id) {
        return;
      }

      trigger(body.event_type);

      if (body.event_type === 'open' && body.car) {
        let title;
        if (body.gateway.type === 'in') {
          title = `${body.car.plate_number}進場`;
        } else {
          title = `${body.car.plate_number}離場`;
        }

        notification.info({
          message: title,
          description: title,
          duration: 3,
        });
      }
    };

    emitter.addEventListener('gateway', listener);

    return () => {
      emitter.removeEventListener('gateway', listener);
    };
  }, []);

  const onclick = type => {
    const send = async () => {
      const {
        IOSERVER,
        ENABLE_GATEWAY_RECORD: { ENABLE },
      } = system;
      const { gateway } = props;
      let backendIoserver = localStorage.getItem('backend-ioserver');

      if (type === 'open') {
        if (ENABLE) await pos.drivewayGateController.raiseGate(gateway.id);
        else {
          let url = `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/gateways/${gateway.id}/open`;
          if (backendIoserver) {
            url = `${backendIoserver}/api/v1/gateways/${gateway.id}/open`;
          }
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 500);
          await fetch(url, {
            method: 'POST',
            cors: true,
          });
        }
      } else if (type === 'close') {
        if (ENABLE) await pos.drivewayGateController.lowerGate(gateway.id);
        else {
          let url = `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/gateways/${gateway.id}/close`;

          if (backendIoserver) {
            url = `${backendIoserver}/api/v1/gateways/${gateway.id}/close`;
          }

          setClose(true);
          setTimeout(() => {
            setClose(false);
          }, 500);

          await fetch(url, {
            method: 'POST',
            cors: true,
          });
        }
      }
    };

    return () => {
      send();
    };
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: '0px 0px 4px 0px',
        marginTop: 12,
      }}
    >
      <div style={{ padding: '6px 8px' }}>
        <Paragraph style={{ fontSize: 20, margin: 0 }}>{props.name}</Paragraph>
      </div>

      <Divider style={{ margin: '0px 0px 8px 0px' }} />

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          開閘
        </div>
        <div style={{ flex: 1 }}>
          <Switch
            checked={open}
            onClick={onclick('open')}
            style={{ transform: 'scale(1.2, 1.2)' }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          關閘
        </div>
        <div style={{ flex: 1 }}>
          <Switch
            checked={close}
            onClick={onclick('close')}
            style={{ transform: 'scale(1.2, 1.2)' }}
          />
        </div>
      </div>
    </div>
  );
};

const ApsControlCard = props => {
  const [reset, setReset] = useState(false);

  const onclick = type => {
    const send = async () => {
      const { IOSERVER } = system;
      const { aps } = props;

      let backendIoserver = localStorage.getItem('backend-ioserver');

      if (type === 'reset') {
        let url = `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/aps/${aps.id}/reset`;

        if (backendIoserver) {
          url = `${backendIoserver}/api/v1/aps/${aps.id}/reset`;
        }

        setReset(true);
        setTimeout(() => {
          setReset(false);
        }, 500);

        await fetch(url, {
          method: 'POST',
          cors: true,
        });
      }
    };

    return () => {
      send();
    };
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: '0px 0px 4px 0px',
        marginTop: 12,
      }}
    >
      <div style={{ padding: '6px 8px' }}>
        <Paragraph style={{ fontSize: 20, margin: 0 }}>{props.name}</Paragraph>
      </div>

      <Divider style={{ margin: '0px 0px 8px 0px' }} />

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          重置
        </div>
        <div style={{ flex: 1 }}>
          <Switch
            checked={reset}
            onClick={onclick('reset')}
            style={{ transform: 'scale(1.2, 1.2)' }}
          />
        </div>
      </div>
    </div>
  );
};

const ParkingSpaceCard = props => {
  const [parkingSpace, setParkingSpace] = useState(props.space);
  const [edit, setEdit] = useState(false);
  const [uploading, setUploading] = useState(false);

  const listenerRef = useRef(null);

  useEffect(() => {
    if (edit && listenerRef.current) {
      emitter.removeEventListener('parking_space', listenerRef.current);
    }

    function listener(event) {
      let body = event.detail;

      let pk = body.content;

      if (pk.id !== parkingSpace.id) {
        return;
      }

      setParkingSpace(pk);
    }

    if (!edit) {
      listenerRef.current = listener;
      emitter.addEventListener('parking_space', listenerRef.current);
    }

    return () => {
      if (listenerRef.current) {
        emitter.removeEventListener('parking_space', listenerRef.current);
      }
    };
  }, [edit]);

  useEffect(() => {
    setParkingSpace(props.space);
  }, [props.space]);

  const handleParkingCountChange = value => {
    setParkingSpace(pk => {
      let next = { ...pk };
      next.count = value;
      return next;
    });
  };

  const uploadWrapper = func => {
    return async (...args) => {
      setUploading(true);
      await func(...args);
      setUploading(false);
    };
  };

  const confirmParkingCount = async pk => {
    const { IOSERVER } = system;

    let backendIoserver = localStorage.getItem('backend-ioserver');

    let url = `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/parking-space/${pk.id}/count`;

    if (backendIoserver) {
      url = `${backendIoserver}/api/v1/parking-space/${pk.id}/count`;
    }

    try {
      await fetch(url, {
        method: 'PUT',
        cors: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          count: pk.count,
        }),
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const handleEditChange = () => {
    let nextEdit = !edit;

    if (!nextEdit) {
      // confirm
      // save to backend
      const runConfirm = uploadWrapper(confirmParkingCount);
      runConfirm(parkingSpace).then(() => {
        // confirm complete
        // set edit value
        setEdit(nextEdit);
      });
    } else {
      setEdit(nextEdit);
    }
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: '0px 0px 4px 0px',
        marginTop: 12,
      }}
    >
      <div style={{ padding: '6px 8px', display: 'flex' }}>
        <div style={{ flex: 2 }}>
          <Paragraph style={{ fontSize: 20, margin: 0 }}>
            {parkingSpace.name}
          </Paragraph>
        </div>

        <div style={{ float: 'right' }}>
          <Button
            icon='edit'
            type='link'
            onClick={handleEditChange}
            loading={uploading && edit}
          >
            {edit ? (
              <span
                style={{
                  fontSize: 20,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                確定
              </span>
            ) : (
              <span
                style={{
                  fontSize: 20,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                修改
              </span>
            )}
          </Button>
        </div>
      </div>

      <Divider style={{ margin: '0px 0px 8px 0px' }} />

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          剩餘車位數:
        </div>
        {edit ? (
          <InputNumber
            max={parkingSpace.capacity}
            defaultValue={parkingSpace.count}
            onChange={handleParkingCountChange}
          />
        ) : (
          <div
            style={{
              flex: 2,
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {parkingSpace.count}
          </div>
        )}
      </div>

      <div style={{ display: 'flex', padding: '4px 6px' }}>
        <div
          style={{
            flex: 2,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          總車位數:
        </div>
        <div
          style={{
            flex: 2,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {parkingSpace.capacity}
        </div>
      </div>
    </div>
  );
};

const MonitorSection = props => {
  let disable = false;

  if (props.disable != null) {
    disable = props.disable;
  }

  return (
    <div style={{ display: 'flex', padding: '0px 8px' }}>
      <div style={{ flex: 5 }}>
        <Title level={3} style={{ marginBottom: 0 }}>
          影像
        </Title>
        <Divider style={{ margin: '8px 0px 8px 0px' }} />

        <div style={{ marginTop: 24 }}>
          {disable === true ? (
            <div></div>
          ) : (
            <VideoContainer
              disable={disable}
              sources={props.division.sources}
            ></VideoContainer>
          )}
        </div>
      </div>

      <div style={{ flex: 3, padding: '0px 0px 8px 12px', maxWidth: 320 }}>
        <Title level={3} style={{ marginBottom: 0 }}>
          控制
        </Title>
        <Divider style={{ margin: '8px 0px 8px 0px' }} />

        <div style={{ padding: '4px 8px 4px 16px' }}>
          {props.division.gateways.map((gateway, idx) => {
            return (
              <GatewayControlCard
                key={idx}
                name={gateway.name}
                gateway={gateway}
              ></GatewayControlCard>
            );
          })}
        </div>

        {
          /* after 2021/04/25 ioserver provide aps list to get aps info */
          props.division.aps != null && Array.isArray(props.division.aps) ? (
            <div style={{ padding: '4px 8px 4px 16px' }}>
              {props.division.aps.map((aps, idx) => {
                return (
                  <ApsControlCard
                    key={idx}
                    name={aps.name}
                    aps={aps}
                  ></ApsControlCard>
                );
              })}
            </div>
          ) : (
            <div></div>
          )
        }

        <Title level={3} style={{ marginBottom: 0 }}>
          場站資訊
        </Title>
        <Divider style={{ margin: '8px 0px 8px 0px' }} />

        <div style={{ padding: '4px 8px 4px 16px' }}>
          {props.division.parking_spaces.map((space, idx) => {
            return (
              <ParkingSpaceCard
                key={idx}
                name={space.name}
                space={space}
              ></ParkingSpaceCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const BlackCarDialog = props => {
  const [gateway, setGateway] = useState('');
  const [plate, setPlate] = useState('');
  const [alert, setAlert] = useState(false);
  const permitBlackCarAlertRef = useRef();

  useEffect(() => {
    setPlate(props.plate);
  }, [props.plate]);

  useEffect(() => {
    setGateway(props.gateway);
  }, [props.gateway]);

  const submit = useCallback(() => {
    async function register() {
      let c = {
        name: plate,
        type: 'park_service',
        // dirty
        car_type: 'C',
        ctime: new Date(),
      };

      let success = true;

      const { IOSERVER } = props.system;

      let backendIoserver = localStorage.getItem('backend-ioserver');
      let url = `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/gateways/${gateway}/open`;

      if (backendIoserver) {
        url = `${backendIoserver}/api/v1/gateways/${gateway}/open`;
      }

      if (permitBlackCarAlertRef.current) {
        let alertAudio = permitBlackCarAlertRef.current;

        alertAudio.loop = true;
        alertAudio.play();

        setTimeout(() => {
          alertAudio.pause();
        }, 5000);
      }

      try {
        await pos.item.addItem(c);

        await fetch(url, {
          method: 'POST',
          cors: true,
          body: JSON.stringify({
            plate,
          }),
        });
      } catch (err) {
        success = false;
        message.error('失敗');
      }

      if (success) {
        message.success('成功');
      }

      props.onOk && props.onOk();
    }

    register();
  }, [plate, gateway, alert]);

  const handlePlateChange = evt => {
    const { value } = evt.target;

    setPlate(value);
  };

  const handleAlertChange = value => {
    setAlert(value);
  };

  return (
    <div>
      <Modal
        title='是否允許黑名單車輛入場'
        visible={props.visible}
        onOk={submit}
        onCancel={props.onCancel}
        okText='確定'
        cancelText='取消'
      >
        車號:
        <Input
          placeholder='請輸入車號'
          value={plate}
          onChange={handlePlateChange}
        />
        <div style={{ marginTop: 12 }}>
          發出警報:
          <Switch checked={alert} onChange={handleAlertChange} />
        </div>
      </Modal>

      <audio id='dialog_device_alert' ref={permitBlackCarAlertRef}>
        <source src='/api/v1/system/files/alert.mp3' type='audio/mpeg' />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const CarMonitor = props => {
  const [currentTab, setCurrentTab] = useState('0');
  const [loaded, setLoaded] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const permitBlackCarConfirmOnceRef = useRef(false);
  const [showBlackCarDialog, setShowBloackCarDialog] = useState(false);
  const [blackCarGateway, setBlackCarGateway] = useState({});
  const [blackCar, setBlackCar] = useState({});
  const permitBlackCarAlertRef = useRef();

  const changeTab = key => {
    setCurrentTab(key);
  };

  const init = async () => {
    emitter = document.createElement('div');

    system = props.system;

    let backendIoserver = localStorage.getItem('backend-ioserver');

    const { IOSERVER } = props.system;

    let divisionsEndpoint = '';
    if (backendIoserver) {
      divisionsEndpoint = `${backendIoserver}/api/v1/divisions`;
    } else {
      divisionsEndpoint = `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/divisions`;
    }

    let resp = await fetch(divisionsEndpoint, {
      mode: 'cors',
    });
    let divisions = await resp.json();

    let backendStreamServer = localStorage.getItem('backend-stream-server');

    if (backendStreamServer) {
      let stremServerUrl = new URL(backendStreamServer);

      divisions.forEach(d => {
        let sources = d.sources;

        // replace all stream server
        sources.forEach(s => {
          let url = new URL(s.http_flv);

          url.protocol = stremServerUrl.protocol;
          url.hostname = stremServerUrl.hostname;
          url.port = stremServerUrl.port;

          s.http_flv = url.href;
        });
      });
    }

    setDivisions(divisions);

    setLoaded(true);
  };

  useEffect(() => {
    init();

    let subcribeGateway = null,
      subcribeSpace = null;
    const register = async () => {
      if (!stomp) return;

      try {
        subcribeGateway = await stomp.asyncSubscribe(
          '/exchange/ioserver/api.v1.gateways',
          ms => {
            let body = ms.body;
            if (!body) return;

            body = JSON.parse(body);

            if (
              body &&
              body.gateway &&
              body.car &&
              body.event_type === 'black_car_want_enter'
            ) {
              if (!permitBlackCarConfirmOnceRef.current) {
                permitBlackCarConfirmOnceRef.current = true;

                if (permitBlackCarAlertRef.current) {
                  let alertAudio = permitBlackCarAlertRef.current;

                  alertAudio.loop = true;
                  alertAudio.play();

                  setTimeout(() => {
                    alertAudio.pause();
                  }, 5000);
                }

                setBlackCarGateway(body.gateway);
                setBlackCar(body.car);
                setShowBloackCarDialog(true);
              }

              return;
            }

            let event = new CustomEvent('gateway', {
              detail: body,
            });

            emitter.dispatchEvent(event);
          }
        );
      } catch (err) {
        console.error(err);
      }

      try {
        subcribeSpace = await stomp.asyncSubscribe(
          '/exchange/ioserver/api.v1.parking_spaces',
          ms => {
            let body = ms.body;
            if (!body) return;

            body = JSON.parse(body);

            let event = new CustomEvent('parking_space', {
              detail: body,
            });

            emitter.dispatchEvent(event);
          }
        );
      } catch (err) {
        console.error(err);
      }
    };

    register();

    return () => {
      if (subcribeGateway) {
        subcribeGateway.unsubscribe();
      }

      if (subcribeSpace) {
        subcribeSpace.unsubscribe();
      }
    };
  }, []);

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        background: '#fff',
        padding: '12px 24px',
        minHeight: 280,
      }}
    >
      {loaded && divisions.length > 0 ? (
        <div>
          <Tabs defaultActiveKey={'0'} onChange={changeTab}>
            {divisions.map((division, idx) => {
              return (
                <TabPane tab={division.name} key={idx + ''}>
                  <MonitorSection
                    disable={currentTab !== idx + ''}
                    division={division}
                  ></MonitorSection>
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      ) : divisions.length <= 0 ? (
        <div></div>
      ) : (
        <Spin></Spin>
      )}

      <audio id='device_alert' ref={permitBlackCarAlertRef}>
        <source src='/api/v1/system/files/alert.mp3' type='audio/mpeg' />
        Your browser does not support the audio element.
      </audio>

      <BlackCarDialog
        plate={blackCar.plate}
        gateway={blackCarGateway.id}
        visible={showBlackCarDialog}
        system={props.system}
        onOk={() => {
          permitBlackCarConfirmOnceRef.current = false;
          setShowBloackCarDialog(false);
        }}
        onCancel={() => {
          permitBlackCarConfirmOnceRef.current = false;
          setShowBloackCarDialog(false);
        }}
      />
    </Content>
  );
};

const mapStateToProps = state => {
  return {
    system: state.system,
  };
};

export default connect(mapStateToProps)(CarMonitor);
