import React, { useState, useEffect } from 'react';
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Select,
  Button,
  Spin,
  message,
  Switch,
  Col,
  Row,
  Menu,
  Icon,
  List,
  TimePicker,
  Table,
  Collapse,
  Divider,
  Tag,
} from 'antd';
import uuid from 'uuid/v4';

import moment from 'moment';

import {
  serviceMapper,
  categoryMapper,
  defaultMapper,
  rentalUserMapper,
} from '../../../utils/mapper';
import pos from 'pos-api';
import * as util from '../../../utils/utils';
import routes from '../../../Routes';

import './CarRegistrationForm.less';

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

class CarRegistrationForm extends React.Component {
  state = {
    op: 'add',
    cities: [],
    districts: [],
    roads: [],
    loading: true,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    if (this.props.match.params.id !== '0') {
      await this.fetchCarRegistration();
    }

    let cities = await pos.system.fetchSystemRoads({
      searchType: 'city',
    });

    let districts = [];
    if (cities && cities.length > 0) {
      districts = await pos.system.fetchSystemRoads({
        searchType: 'district',
        city: cities[0],
      });
    }

    let roads = [];
    if (districts && districts.length > 0) {
      roads = await pos.system.fetchSystemRoads({
        searchType: 'road',
        city: cities[0],
        district: districts[0],
      });
    }

    this.setState({
      cities,
      districts,
      roads,
      loading: false,
    });
  };

  fetchDistricts = async city => {
    return await pos.system.fetchSystemRoads({
      searchType: 'district',
      city,
    });
  };

  fetchRoads = async (city, district) => {
    return await pos.system.fetchSystemRoads({
      searchType: 'road',
      city,
      district,
    });
  };

  fetchCarRegistration = async () => {
    let reg = await pos.carRegistration.readCarRegistration(
      this.props.match.params.id
    );

    this.setState({
      reg: reg,
    });
  };

  upload = async values => {
    try {
      if (this.props.match.params.id === '0') {
        await pos.carRegistration.addCarRegistration(values);
      } else {
        await pos.carRegistration.updateCarRegistration(
          this.props.match.params.id,
          values
        );
      }

      message.success('成功');

      this.props.history.push(
        `${routes.manage.children.carRegistrations.layout}${routes.manage.children.carRegistrations.path}`
      );
    } catch (err) {
      message.error('失敗');
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.upload(values);
      } else {
        console.log(err);
      }
    });
  };

  onRoadChange = type => {
    return async value => {
      const { getFieldValue, setFieldsValue } = this.props.form;

      if (type === 'city') {
        let nextDists = await this.fetchDistricts(value);
        let nextRoads = await this.fetchRoads(value, nextDists[0]);

        setFieldsValue({
          'address_detail.district': nextDists[0],
          'address_detail.road': nextRoads[0],
        });

        this.setState({
          districts: nextDists,
          roads: nextRoads,
        });
      } else if (type === 'district') {
        let nextRoads = await this.fetchRoads(
          getFieldValue('address_detail.city'),
          value
        );

        setFieldsValue({
          'address_detail.road': nextRoads[0],
        });

        this.setState({
          roads: nextRoads,
        });
      }
    };
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const { getFieldDecorator, setFieldsValue } = this.props.form;

    if (this.state.loading) {
      return <Spin></Spin>;
    }

    const reg = this.state.reg;
    let regData;
    if (reg) {
      regData = reg.data;
    }

    let addressDetail;

    if (reg) {
      addressDetail = reg.address_detail;
    }

    return (
      <Form {...formItemLayout} className='form'>
        <Content className='content' style={{ marginTop: 12 }}>
          <Title level={3}>基本資料</Title>
          <Form.Item label='姓名' style={{ marginBottom: 0 }}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: '請輸入車主名稱',
                },
              ],
              initialValue: reg && reg.name ? reg.name : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='車號' style={{ marginBottom: 0 }}>
            {getFieldDecorator('plate', {
              rules: [
                {
                  required: true,
                  message: '請輸入車號',
                },
              ],
              initialValue: reg && reg.plate ? reg.plate : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='手機號碼' style={{ marginBottom: 0 }}>
            {getFieldDecorator('phone_number', {
              rules: [],
              initialValue: reg && reg.phone_number ? reg.phone_number : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='電話號碼' style={{ marginBottom: 0 }}>
            {getFieldDecorator('tel_number', {
              rules: [],
              initialValue: reg && reg.tel_number ? reg.tel_number : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='身分證字號' style={{ marginBottom: 0 }}>
            {getFieldDecorator('identity_number', {
              rules: [],
              initialValue:
                reg && reg.identity_number ? reg.identity_number : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='統一編號' style={{ marginBottom: 0 }}>
            {getFieldDecorator('tax_number', {
              rules: [],
              initialValue: reg && reg.tax_number ? reg.tax_number : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='承銷號碼' style={{ marginBottom: 0 }}>
            {getFieldDecorator('data.underwrite_number', {
              rules: [],
              initialValue:
                regData && regData.underwrite_number
                  ? regData.underwrite_number
                  : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='車色' style={{ marginBottom: 0 }}>
            {getFieldDecorator('data.car_color', {
              rules: [],
              initialValue:
                regData && regData.car_color ? regData.car_color : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='載重' style={{ marginBottom: 0 }}>
            {getFieldDecorator('data.max_load', {
              rules: [],
              initialValue: regData && regData.max_load ? regData.max_load : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='備註一' style={{ marginBottom: 0 }}>
            {getFieldDecorator('data.comment1', {
              rules: [],
              initialValue: regData && regData.comment1 ? regData.comment1 : '',
            })(<Input></Input>)}
          </Form.Item>

          <Form.Item label='備註二' style={{ marginBottom: 0 }}>
            {getFieldDecorator('data.comment2', {
              rules: [],
              initialValue: regData && regData.comment2 ? regData.comment2 : '',
            })(<Input></Input>)}
          </Form.Item>

          <Title level={3}>地址</Title>

          {/**
                             * "address_detail": {
                            "city": "",
                            "district": "",
                            "village": "",
                            "neighbor": "",
                            "road": "",
                            "section": "",
                            "lane": "",
                            "alley": "",
                            "number": "",
                            "floor": "",
                            "room": "",
                            "sub_number": "",
                            "sub_floor": ""
                        }
                            */
          /*
                            
                                巷 
                                弄 
                                號之 
                                
                                樓之 
                                
                                室
                            */}

          <Row>
            <Col span={6}>
              <Form.Item label='城市' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.city', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.city
                      ? addressDetail.city
                      : this.state.cities[0],
                })(
                  <Select
                    onChange={this.onRoadChange('city')}
                    showSearch={true}
                  >
                    {this.state.cities.map(city => {
                      return <Option value={city}>{city}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='地區' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.district', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.district
                      ? addressDetail.district
                      : this.state.districts[0],
                })(
                  <Select
                    onChange={this.onRoadChange('district')}
                    showSearch={true}
                  >
                    {this.state.districts.map(dis => {
                      return <Option value={dis}>{dis}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='里' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.village', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.village
                      ? addressDetail.village
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='鄰' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.neighbor', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.neighbor
                      ? addressDetail.neighbor
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Form.Item label='路' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.road', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.road
                      ? addressDetail.road
                      : this.state.roads[0],
                })(
                  <Select showSearch={true}>
                    {this.state.roads.map(r => {
                      return <Option value={r}>{r}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='巷' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.lane', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.lane
                      ? addressDetail.lane
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='弄' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.alley', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.alley
                      ? addressDetail.alley
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='號' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.number', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.number
                      ? addressDetail.number
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Form.Item label='之' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.sub_number', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.sub_number
                      ? addressDetail.sub_number
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='樓' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.floor', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.floor
                      ? addressDetail.floor
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='之' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.sub_floor', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.sub_floor
                      ? addressDetail.sub_floor
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label='室' style={{ marginBottom: 0 }}>
                {getFieldDecorator('address_detail.room', {
                  rules: [],
                  initialValue:
                    addressDetail && addressDetail.room
                      ? addressDetail.room
                      : '',
                })(<Input></Input>)}
              </Form.Item>
            </Col>
          </Row>
        </Content>

        <div
          style={{
            padding: '20px 30px',
          }}
        >
          <Button
            type='primary'
            size='large'
            block
            style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
            onClick={this.handleSubmit}
          >
            確認
          </Button>

          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>

          <Button
            type='danger'
            size='large'
            block
            style={{ maxWidth: 1080, margin: 'auto', display: 'block' }}
          >
            取消
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedItemForm = Form.create({ name: 'rental_user_form' })(
  CarRegistrationForm
);

export default WrappedItemForm;
