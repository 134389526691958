import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Layout,
  Modal,
  Select,
  Table,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import pos from 'pos-api';
import React from 'react';
import { getOS, paymentTypeMap } from '../../../utils/utils';

import posv2 from '../../../lib/posv2';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: '車號',
    dataIndex: 'plate',
    key: 'plate',
    width: 150,
    render: (text, record) => {
      return (
        <>
          <span>{text}</span>
          <Button
            onClick={record.onClickUpdatePlate}
            style={{ margin: '0 6px', padding: '0 10px' }}
          >
            修改
          </Button>
        </>
      );
    },
  },
  {
    title: '票種',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '售票時間',
    dataIndex: 'create_time',
    key: 'create_time',
    render: (text, record) => {
      return moment(text).format('YYYY/MM/DD HH:mm:ss');
    },
    width: 250,
  },

  {
    title: '開始日期',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (text, record) => {
      return moment(text).format('YYYY/MM/DD HH:mm:ss');
    },
    width: 250,
  },
  {
    title: '截止時間',
    dataIndex: 'end_time',
    key: 'end_time',
    width: 250,
    render: (text, record) => {
      return moment(text).format('YYYY/MM/DD HH:mm:ss');
    },
  },
  {
    title: '售票期數',
    dataIndex: 'months',
    key: 'months',
    width: 150,
  },
  {
    title: '金額',
    dataIndex: 'amount',
    key: 'amount',
    width: 150,
  },
  {
    title: '發票號',
    dataIndex: 'invoices',
    key: 'invoiceNumbers',
    width: 200,
    render: (text, record) => {
      let numbers = '';
      if (record && record.invoices && record.invoices.length > 0) {
        let invoices = record.invoices;

        if (invoices.length > 1) {
          numbers = invoices
            .map(iv => {
              if (iv.void) {
                return iv.iv_number + '(作廢)';
              }

              return iv.iv_number;
            })
            .join(',');
        } else {
          numbers = invoices[0].iv_number;

          if (invoices[0].void) {
            numbers += '(作廢)';
          }
        }
      }

      return <span>{numbers}</span>;
    },
  },
  {
    title: '支付方式',
    dataIndex: 'payment_type',
    key: 'payment_type',
    width: 150,
    render: (text, record) => {
      if (paymentTypeMap[text]) {
        text = paymentTypeMap[text];
      }
      return text;
    },
  },
];

const DEFAULT_PAGE_SIZE = 100;

class RentalCards extends React.Component {
  constructor(props) {
    super(props);
    this.pageTimer = null;
  }

  state = {
    searchText: '',
    startTime: null,
    searchPlate: null,
    excludeSearchText: null,
    paymentType: null,
    endTime: null,
    enableCredited: 'all',
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      current: 1,
    },
    loading: true,
    rentalType: null,
    cards: [
      {
        id: 'all',
        name: '全部',
      },
    ],
    summary: {},
    updatePlateTarget: null,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      filters: filters,
      sorter: sorter,
    });

    this.fetchRentalCards({
      pagination: pager,
      sorter,
      filters,
      paymentType: this.state.paymentType,
      searchText: this.state.searchText,
      excludeSearchText: this.state.excludeSearchText,
      searchPlate: this.state.searchPlate,
    });
  };

  handleExport = async () => {
    let opts = {};
    let searchType;

    if (!opts.sorter) opts.sorter = {};
    if (!opts.filters) opts.filters = {};
    if (!opts.startTime) opts.startTime = this.state.startTime;
    if (!opts.endTime) opts.endTime = this.state.endTime;
    if (!opts.rentalType) opts.rentalType = this.state.rentalType;
    if (!opts.enableCredited) opts.enableCredited = this.state.enableCredited;
    if (!opts.searchPlate) opts.searchPlate = this.state.searchPlate;

    if (
      opts.excludeSearchText ||
      opts.searchText ||
      opts.rentalType !== 'all'
    ) {
      searchType = 'name';
    }

    let searchText = opts.searchText;
    if (opts.rentalType !== 'all') {
      searchText = opts.rentalType;
    }

    let enableCredited = opts.enableCredited;
    if (opts.enableCredited === 'all') {
      enableCredited = null;
    }

    let { content } = await pos.rentalCard.readRentalCards({
      pageSize: 20000,
      page: 0,
      stime: opts.startTime ? opts.startTime.toISOString() : null,
      etime: opts.endTime ? opts.endTime.toISOString() : null,
      searchBy: searchType,
      searchText: searchText,
      excludeSearchText: opts.excludeSearchText,
      paymentType: opts.paymentType,
      plate: opts.searchPlate,
      enableCredited,
    });

    let csv =
      '車號,票種,售票時間,開始日期,截止日期,金額,發票號,支付方式,售票期數\n';

    content.forEach(card => {
      // plate, name, create_time, start_time, end_time, price, invoices, payment_type

      let {
        plate,
        name,
        create_time,
        start_time,
        end_time,
        price,
        invoices,
        payment_type,
        months,
      } = card;

      create_time = moment(create_time).format('yyyy-MM-DD HH:mm:ss');
      start_time = moment(start_time).format('yyyy-MM-DD HH:mm:ss');

      end_time = moment(end_time).format('yyyy-MM-DD HH:mm:ss');

      let iv_numbers = '';
      if (invoices.length > 0) {
        if (invoices.length > 1) {
          iv_numbers = invoices
            .map(iv => {
              if (iv.void) {
                return iv.iv_number + '(作廢)';
              }

              return iv.iv_number;
            })
            .join(',');
        } else {
          iv_numbers = invoices[0].iv_number;

          if (invoices[0].void) {
            iv_numbers += '(作廢)';
          }
        }
      }

      let payName = paymentTypeMap[payment_type]
        ? paymentTypeMap[payment_type]
        : payment_type;

      csv += `${plate},${name},${create_time},${start_time},${end_time},${price},"${iv_numbers}",${payName},${months}\n`;
    });

    const download = (filename, text) => {
      //text = text.replace(/\n/g, '\r\n');
      var element = document.createElement('a');

      if (getOS() === 'Windows') {
        let blob = new Blob(['\ufeff', text], {
          type: 'text/csv;charset=utf-8',
        });
        element.setAttribute('href', window.URL.createObjectURL(blob));
      } else {
        element.setAttribute(
          'href',
          'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
        );
      }

      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    };

    download('售票紀錄表.csv', csv);
  };

  fetchRentalCards = async opts => {
    this.setState({
      loading: true,
    });

    opts = opts || {
      pagination: this.state.pagination,
      sorter: {},
    };

    let searchType;

    if (!opts.sorter) opts.sorter = {};
    if (!opts.filters) opts.filters = {};
    if (!opts.startTime) opts.startTime = this.state.startTime;
    if (!opts.endTime) opts.endTime = this.state.endTime;
    if (!opts.rentalType) opts.rentalType = this.state.rentalType;
    if (!opts.enableCredited) opts.enableCredited = this.state.enableCredited;
    if (!opts.searchPlate) opts.searchPlate = this.state.searchPlate;

    if (
      opts.excludeSearchText ||
      opts.searchText ||
      opts.rentalType !== 'all'
    ) {
      searchType = 'name';
    }

    let searchText = opts.searchText;
    if (opts.rentalType !== 'all') {
      searchText = opts.rentalType;
    }

    let enableCredited = opts.enableCredited;
    if (opts.enableCredited === 'all') {
      enableCredited = null;
    }

    let { content, pagination } = await pos.rentalCard.readRentalCards({
      pageSize: opts.pagination.pageSize,
      page: opts.pagination.current - 1,
      stime: opts.startTime ? opts.startTime.toISOString() : null,
      etime: opts.endTime ? opts.endTime.toISOString() : null,
      searchBy: searchType,
      searchText: searchText,
      excludeSearchText: opts.excludeSearchText,
      paymentType: opts.paymentType,
      plate: opts.searchPlate,
      enableCredited,
    });

    let cards = content;
    cards.forEach((g, idx) => {
      g.key = g.id;
      g.onClickUpdatePlate = this.onUpdatePlate(g);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      rentalCards: cards,
      loading: false,
      pagination: pager,
    });
  };

  onUpdatePlate = rentalCard => () => {
    this.setState({
      updatePlateTarget: { ...rentalCard },
    });
  };

  onFooter = record => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <div style={{ fontWeight: 'bold' }}>
          <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
            總筆數: {this.state.pagination.total}
          </Text>
        </div>

        <div style={{ fontWeight: 'bold' }}>
          <span></span>
          {/* <Text style={{marginRight: 6, marginLeft: 6, fontWeight: 'bold'}}>總金額: {`$ ${0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text> */}
        </div>
      </div>
    );
  };

  handleSearchTypeChange = type => {
    let searchText, excludeSearchText;
    if (type === 'all') {
      searchText = '';
      excludeSearchText = null;
    } else if (type === 'normal') {
      searchText = '';
      excludeSearchText = '身障';
    } else if (type === 'disabled') {
      searchText = '身障';
      excludeSearchText = null;
    }

    this.setState({
      searchText,
      excludeSearchText,
    });

    let pagin = {
      ...this.state.pagination,
    };

    pagin.current = 1;

    this.fetchRentalCards({
      pagination: pagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      searchText: searchText,
      excludeSearchText: excludeSearchText,
      paymentType: this.state.paymentType,
      searchPlate: this.state.searchPlate,
    });
  };

  handlePaymentTypeChange = type => {
    let ptype;
    if (type !== 'all') {
      ptype = type;
    }

    this.setState({
      paymentType: ptype,
    });

    let pagin = {
      ...this.state.pagination,
    };

    pagin.current = 1;

    this.fetchRentalCards({
      pagination: pagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      paymentType: ptype,
      searchText: this.state.searchText,
      excludeSearchText: this.state.excludeSearchText,
      searchPlate: this.state.searchPlate,
    });
  };

  handleTimeChange = (field, value) => {
    this.setState({
      [field]: value.toDate(),
    });

    let timeRange = {
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };

    timeRange[field] = value.toDate();

    let pagin = {
      ...this.state.pagination,
    };

    pagin.current = 1;

    this.fetchRentalCards({
      pagination: pagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      paymentType: this.state.paymentType,
      searchText: this.state.searchText,
      excludeSearchText: this.state.excludeSearchText,
      searchPlate: this.state.searchPlate,
      ...timeRange,
    });
  };

  handleSearchPlateChange = value => {
    let pagin = {
      ...this.state.pagination,
    };

    pagin.current = 1;

    this.setState({
      searchPlate: value,
    });

    if (this.searchPlateTimer) {
      clearTimeout(this.searchPlateTimer);
    }

    this.searchPlateTimer = setTimeout(() => {
      this.fetchRentalCards({
        pagination: pagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        paymentType: this.state.paymentType,
        searchText: this.state.searchText,
        excludeSearchText: this.state.excludeSearchText,
        searchPlate: value,
      });
    }, 200);
  };

  handleRentalTypeChange = rentalType => {
    let pagin = {
      ...this.state.pagination,
    };

    pagin.current = 1;

    this.setState({
      rentalType,
    });

    this.fetchRentalCards({
      pagination: pagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      paymentType: this.state.paymentType,
      searchText: this.state.searchText,
      excludeSearchText: this.state.excludeSearchText,
      rentalType: rentalType,
    });
  };

  handleEnableCreditedChange = enableCredited => {
    let pagin = {
      ...this.state.pagination,
    };

    pagin.current = 1;

    this.setState({
      enableCredited,
    });

    this.fetchRentalCards({
      pagination: pagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      paymentType: this.state.paymentType,
      searchText: this.state.searchText,
      excludeSearchText: this.state.excludeSearchText,
      enableCredited: enableCredited,
    });
  };

  init = async () => {
    let resp = await pos.item.fetchItems({
      type: 'park_rental_card',
      pageSize: 100,
    });

    let cards = resp.content;

    if (cards && cards.length > 0) {
      this.setState({
        cards: [
          {
            id: 'all',
            name: '全部',
          },
          ...cards.map(c => {
            return {
              id: c.name,
              name: c.name,
            };
          }),
        ],
      });
    }

    await this.fetchRentalCards();

    let summary = await posv2.getRentalStatusSummary();

    this.setState({
      summary: summary,
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: 3,
              }}
            >
              搜尋範圍:
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                placeholder='開始時間'
                onChange={value => this.handleTimeChange('startTime', value)}
                style={{ marginRight: 12, marginLeft: 12 }}
              ></DatePicker>
              <DatePicker
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                placeholder='結束時間'
                onChange={value => this.handleTimeChange('endTime', value)}
              ></DatePicker>
            </div>
            <div
              style={{
                flex: 2,
              }}
            >
              單頁筆數:
              <InputNumber
                style={{ marginLeft: 12 }}
                min={1}
                defaultValue={DEFAULT_PAGE_SIZE}
                onChange={value => {
                  if (this.state.pagination.pageSize === value) {
                    return;
                  }

                  // debounce
                  if (this.pageTimer) {
                    clearTimeout(this.pageTimer);
                  }

                  this.pageTimer = setTimeout(() => {
                    let pager = {
                      ...this.state.pagination,
                    };
                    pager.pageSize = value;
                    pager.current = 1;
                    this.setState({
                      pagination: pager,
                    });

                    this.fetchRentalCards({
                      pagination: pager,
                    });
                  }, 250);
                }}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 12,
            }}
          >
            車牌號碼:
            <Search
              size='large'
              placeholder='請輸入車牌號碼'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchPlateChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            身分:
            <Select
              defaultValue='all'
              onChange={this.handleSearchTypeChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='normal'>一般</Option>
              <Option value='disabled'>身障</Option>
            </Select>
            票種:
            <Select
              defaultValue='all'
              onChange={this.handleRentalTypeChange}
              style={{ width: 150, marginLeft: 12, marginRight: 12 }}
            >
              {this.state.cards.map(card => {
                return (
                  <Option key={card.id} value={card.id}>
                    {card.name}
                  </Option>
                );
              })}
            </Select>
            付款別:
            <Select
              defaultValue='all'
              onChange={this.handlePaymentTypeChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='cash'>現金</Option>
              <Option value='ezcard'>悠遊卡</Option>
              <Option value='remit'>電匯</Option>
            </Select>
            系統帳:
            <Select
              defaultValue='all'
              onChange={this.handleEnableCreditedChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='true'>入系統帳</Option>
              <Option value='false'>未入系統帳</Option>
            </Select>
            <Button size='large' onClick={() => this.handleExport()}>
              匯出
            </Button>
          </div>
        </Content>

        {this.state.summary.expire_rentals_total > 0 && (
          <Content
            style={{
              margin: '12px 0px 16px 0px',
              padding: '24px',
              background: '#fff',
              minHeight: 80,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                fontSize: 22,
                color: '#f00',
              }}
            >
              {
                // 警示文字000年00月尚有00輛車尚未續購月票
              }
              <div>
                {moment().year() - 1911} 年 {moment().format('MM')}尚有
                {this.state.summary.expire_rentals_total}
                輛車尚未續購月票
              </div>
            </div>
          </Content>
        )}

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.rentalCards}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            footer={this.onFooter}
            scroll={{ y: 520, x: 1000 }}
          />
        </Content>
        <Modal
          title='修正月票之車牌'
          centered
          visible={this.state.updatePlateTarget !== null}
          onOk={async () => {
            try {
              const targetRentalCard = this.state.updatePlateTarget;
              // basic validation
              if (targetRentalCard.correctPlate === targetRentalCard.plate) {
                throw '車牌與原車牌相同';
              }
              if (
                !targetRentalCard.correctPlate ||
                !/^[a-z0-9]{4,7}$/i.test(targetRentalCard.correctPlate)
              ) {
                throw '車牌格式不符';
              }
              const { content } = await pos.rentalCard.updateRentalCardPlate({
                id: targetRentalCard.id,
                plate: targetRentalCard.correctPlate,
              });
              // close modal & reload
              this.setState({
                updatePlateTarget: null,
              });
              this.fetchRentalCards();
            } catch (error) {
              message.error(
                `修改車牌失敗${typeof error === 'string' ? `:${error}` : ''}`
              );
            }
          }}
          onCancel={() => {
            this.setState({
              updatePlateTarget: null,
            });
          }}
        >
          您欲修改的錯誤車牌是：{this.state.updatePlateTarget?.plate}。
          輸入正確車牌後，按下確定：
          <Input
            maxLength={7}
            allowClear
            value={this.state.updatePlateTarget?.correctPlate ?? ''}
            onChange={e => {
              this.setState({
                updatePlateTarget: {
                  ...this.state.updatePlateTarget,
                  correctPlate: e.target.value.toUpperCase(),
                },
              });
            }}
          />
        </Modal>
      </Content>
    );
  }
}

export default RentalCards;
