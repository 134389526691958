import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Tabs,
  Card,
  Avatar,
  Col,
  Layout,
  Spin,
  Table,
  Divider,
  Tag,
  Button,
  Modal,
  InputNumber,
  message,
  Radio,
} from 'antd';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import pos from 'pos-api';
import uuid from 'uuid/v1';

import { categoryMapper, itemMapper } from '../../../utils/mapper';
import { buildOrderReceipt } from '../../../service/order';

const { TabPane } = Tabs;
const { Content } = Layout;
const { confirm } = Modal;

const ItemCard = props => {
  return (
    <Card
      hoverable={true}
      style={{
        fontSize: 24,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 180,
        backgroundColor: '#f1f1f1',
        borderRadius: '5px',
      }}
      onClick={props.item.onClick}
    >
      <div>{props.item.name}</div>
    </Card>
  );
};

const Items = props => {
  return (
    <div>
      {props.items.map(item => (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={6}
          xxl={4}
          style={{ padding: '8px' }}
          key={item.id}
        >
          <ItemCard item={item}></ItemCard>
        </Col>
      ))}
    </div>
  );
};

const ItemsPanel = props => {
  return (
    <TabPane {...props}>
      <Items items={props.items}></Items>
    </TabPane>
  );
};

const columns = [
  {
    title: '餐點',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
    width: 100,
  },
  {
    title: '數量',
    dataIndex: 'number',
    key: 'number',
    width: 100,
  },
  {
    title: '價格',
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
  },
  {
    title: '動作',
    dataIndex: 'action',
    key: 'action',
    render: (text, record) => {
      return (
        <span>
          <span>
            <Button type='danger' onClick={record.onDelete}>
              刪除
            </Button>
          </span>
        </span>
      );
    },
    width: 100,
  },
];

const OrderState = {
  placed: 0,
  approved: 1,
};

const OrderItems = props => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categorys, setCategorys] = useState([]);
  const [items, setItems] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [keyboard, setKeyboard] = useState({});
  const [tableKeyboard, setTableKeyboard] = useState({});
  const [tableNumber, setTableNumber] = useState(0);
  const [key, setKey] = useState('');
  const [tableKey, setTableKey] = useState('');
  const [number, setNumber] = useState(1);
  const [item, setItem] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [amount, setAmount] = useState(0);
  const [takeType, setTakeType] = useState('take_in');
  const orderState = useRef(OrderState.placed);

  const init = async () => {
    let pageSize = 100;
    let cs = await pos.category.fetchCategorys({ pageSize });

    setCategorys(categoryMapper.toView(cs));
  };

  useEffect(() => {
    init();
  }, []);

  const fetchItems = async (category, page) => {
    let pageSize = 100;

    let items = await pos.item.fetchItems({
      pageSize,
      page,
      categoryId: category.id,
    });

    return items;
  };

  const deleteOrderItem = useCallback(
    item => {
      let next = orderItems.filter(i => i !== item);
      setOrderItems(next);
    },
    [orderItems]
  );

  useEffect(() => {
    if (!orderItems) return;
    // re-inialize orderItems every time when orderItems is changed
    orderItems.forEach(item => {
      item.onDelete = () => deleteOrderItem(item);
    });
  }, [orderItems]);

  const fetchAllItems = async category => {
    let page = 0;
    let items = [];
    let _items;
    do {
      let resp = await fetchItems(category, page);
      _items = itemMapper.toView(resp.content);

      _items.forEach(item => {
        item.onClick = () => {
          setItem(item);
          setShowModal(true);
        };
      });

      items.push(..._items);

      page++;
    } while (_items && _items.length > 0);

    setItems(items);
  };

  useEffect(() => {
    if (categorys && categorys.length > 0) {
      let map = {};
      categorys.forEach(c => {
        map[`${c.id}`] = c;
      });

      setCategoryMap(map);
      setCurrentCategory(categorys[0]);
    }
  }, [categorys]);

  useEffect(() => {
    if (!currentCategory || !currentCategory.id) return;
    fetchAllItems(currentCategory);
  }, [currentCategory]);

  const changeCategory = id => {
    setCurrentCategory(categoryMap[id]);
  };

  // set default keyboard input
  useEffect(() => {
    if (keyboard.setInput) {
      keyboard.setInput('');
    }
  }, [keyboard]);

  const onKeyPress = useCallback(
    key => {
      if (key === '{clear}' && keyboard.clearInput) {
        keyboard.setInput('');
        setNumber(1);
      }
    },
    [keyboard]
  );

  useEffect(() => {
    if (key === '') return;

    onKeyPress(key);
  }, [key]);

  useEffect(() => {
    if (number === 1) return;
    if (keyboard.setInput) {
      keyboard.setInput(number + '');
    }
  }, [number]);

  // set default table keyboard input
  useEffect(() => {
    if (tableKeyboard.setInput) {
      tableKeyboard.setInput('');
    }
  }, [tableKeyboard]);

  const onTableKeyPress = useCallback(
    key => {
      if (key === '{clear}' && tableKeyboard.clearInput) {
        tableKeyboard.setInput('');
        setTableNumber(0);
      }
    },
    [tableKeyboard]
  );

  useEffect(() => {
    if (tableKey === '') return;
    onTableKeyPress(tableKey);
  }, [tableKey]);

  useEffect(() => {
    if (number === 1) return;
    if (tableKeyboard.setInput) {
      tableKeyboard.setInput(number + '');
    }
  }, [tableNumber]);

  useEffect(() => {
    let amount = orderItems.reduce((prev, current) => {
      return prev + current.amount;
    }, 0);

    setAmount(amount);
  }, [orderItems]);

  const processOrder = async order => {
    let hide = message.info('處理中....');
    try {
      order = await pos.order.addOrder(order);
      order.status = 'approved';
      order = await pos.order.updateOrder(order);

      message.success('處理成功!');

      setOrderItems([]);

      let receipt = await buildOrderReceipt(order, {
        tableNumber,
        partialCut: true,
      });
      let receipt2 = await buildOrderReceipt(order, {
        tableNumber,
        partialCut: false,
      });
      await fetch('http://localhost:3333/api/v1/printer/machine', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(receipt),
      });

      await fetch('http://localhost:3333/api/v1/printer/machine', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(receipt2),
      });
    } catch (err) {
      message.error('處理失敗!');
    } finally {
      hide();
      orderState.current = OrderState.placed;
    }
  };

  const makeOrder = items => {
    let orderItems = items.map(item => {
      return {
        id: item.id,
        number: item.number,
        item_ids: [],
      };
    });

    return {
      items: orderItems,
      status: 'placed',
      take_type: takeType,
    };
  };

  const confirmed = () => {
    let order = makeOrder(orderItems);
    processOrder(order);
  };

  const openDrawerAndConfirm = async () => {
    try {
      await fetch('http://localhost:3333/api/v1/printer/machine', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ops: [
            {
              name: 'cashdraw',
              val: 2,
            },
          ],
        }),
      });
    } catch (err) {
      console.error(err);
    }

    confirmed();
  };

  const openDrawer = async () => {
    try {
      await fetch('http://localhost:3333/api/v1/printer/machine', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ops: [
            {
              name: 'cashdraw',
              val: 2,
            },
            {
              name: 'flush',
            },
          ],
        }),
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (!categorys || categorys.length < 1 || !currentCategory) {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Spin></Spin>
      </Content>
    );
  }

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
        //display: 'flex'
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '12px 24px',
          background: '#fff',
          height: 80,
        }}
      >
        <Button
          style={{
            float: 'right',
            height: 42,
            fontSize: 18,
            width: 150,
            marginTop: 8,
          }}
          onClick={() => {
            openDrawer();
          }}
        >
          退出錢箱
        </Button>
      </Content>

      <div style={{ width: '70%', float: 'left' }}>
        <div
          style={{
            backgroundColor: '#fff',
            padding: '16px 16px',
            width: '100%',
          }}
        >
          <Tabs
            defaultActiveKey={`${currentCategory.id}`}
            activeKey={`${currentCategory.id}`}
            onChange={changeCategory}
            style={{ width: '100%' }}
          >
            {categorys.map(c => {
              return (
                <ItemsPanel
                  tab={c.name}
                  key={c.id}
                  items={items}
                  style={{
                    height: 'calc(100vh - 314px)',
                    overflowY: 'auto',
                    width: '100%',
                  }}
                ></ItemsPanel>
              );
            })}
          </Tabs>
        </div>
      </div>

      <div
        style={{ width: '30%', padding: '0px 0px 12px 12px', float: 'left' }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            boxShadow: '2px 2px 5px #ccc',
            height: 'calc(100vh - 224px)',
          }}
        >
          <Table
            columns={columns}
            dataSource={orderItems}
            pagination={false}
            scroll={{ y: 'calc(100vh - 426px)' }}
            style={{ height: 'calc(100vh - 426px)' }}
          />

          <div
            style={{
              height: 60,
              width: '100%',
              boxShadow: '0px -5px 5px -5px #ccc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 12,
              fontSize: 22,
              fontWeight: 'bold',
            }}
          >
            <span>${amount}</span>
          </div>

          <div
            style={{
              height: 60,
              width: '100%',
              boxShadow: '0px -5px 5px -5px #ccc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 12,
              fontSize: 22,
              fontWeight: 'bold',
            }}
          >
            <Radio.Group
              defaultValue='take_in'
              size='large'
              onChange={v => {
                setTakeType(v.target.value);
              }}
            >
              <Radio.Button
                value='take_in'
                style={{ width: 120, marginRight: 12, borderRadius: 5 }}
              >
                內用
              </Radio.Button>
              <Radio.Button
                value='take_out'
                style={{ width: 120, borderRadius: 5 }}
              >
                外帶
              </Radio.Button>
            </Radio.Group>
          </div>

          <div
            style={{
              height: 80,
              width: '100%',
              boxShadow: '0px -5px 5px -5px #ccc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 12,
            }}
          >
            <Button
              type='danger'
              style={{ flex: 2, height: 42, fontSize: 18 }}
              onClick={() => {
                setOrderItems([]);
              }}
            >
              清除
            </Button>

            <Button
              type='primary'
              style={{ flex: 2, marginLeft: 12, height: 42, fontSize: 18 }}
              onClick={() => {
                if (orderItems.length <= 0) return;
                if (tableKeyboard.setInput) {
                  tableKeyboard.setInput('');
                }
                setTableNumber(0);
                setShowConfirmModal(true);
              }}
            >
              結帳
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title='數量'
        visible={showModal}
        onOk={() => {
          let _item = { ...item };
          _item.number = number;
          _item.amount = _item.price.in * number;
          _item.key = uuid();
          let next = [...orderItems, _item];

          setOrderItems(next);
          setShowModal(false);
          setNumber(1);
          onKeyPress('{clear}');
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        okText='確定'
        cancelText='取消'
      >
        <InputNumber
          value={number}
          min={1}
          defaultValue={1}
          style={{
            width: '100%',
            height: 48,
            fontSize: 24,
            fontWeight: 'bold',
          }}
          onChange={v => setNumber(v)}
        />
        <div style={{ marginTop: 12 }}>
          <Keyboard
            layout={{
              default: [`1 2 3`, `4 5 6`, `7 8 9`, `{clear} 0 {bksp}`],
            }}
            keyboardRef={r => setKeyboard(r)}
            display={{
              '{bksp}': '修改',
              '{clear}': '清除',
            }}
            onChange={v => {
              if (v === '') {
                setNumber(1);
              } else {
                setNumber(Number(v));
              }
            }}
            onKeyPress={v => setKey(v)}
            baseClass={'number-keyboard'}
          />
        </div>
      </Modal>

      <Modal
        key='order-items-confirm-modal'
        title='確定結帳嗎'
        visible={showConfirmModal}
        onOk={() => {
          if (orderState.current !== OrderState.placed) {
            return;
          }

          orderState.current = OrderState.approved;

          openDrawerAndConfirm();
          setShowConfirmModal(false);
        }}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        okText='確定'
        cancelText='取消'
      >
        <div>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              margin: '0px 2px 6px 2px',
            }}
          >{`結帳金額 $${amount}`}</div>

          {/**<InputNumber value={tableNumber} min={0} defaultValue={0} style={{width: '100%', height: 48, fontSize: 24, fontWeight: 'bold'}} /> */}

          {takeType === 'take_in' ? (
            <div>
              <div
                style={{ fontSize: 20, fontWeight: 'bold', margin: '6px 2px' }}
              >{`桌號: ${tableNumber}`}</div>
              <div style={{ marginTop: 12 }}>
                <Keyboard
                  layout={{
                    default: [`1 2 3`, `4 5 6`, `7 8 9`, `{clear} 0 {bksp}`],
                  }}
                  keyboardRef={r => setTableKeyboard(r)}
                  display={{
                    '{bksp}': '修改',
                    '{clear}': '清除',
                  }}
                  onChange={v => {
                    if (v === '') {
                      setTableNumber(0);
                    } else {
                      setTableNumber(Number(v));
                    }
                  }}
                  onKeyPress={v => setTableKey(v)}
                  baseClass={'table-keyboard'}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Modal>
    </Content>
  );
};

export default OrderItems;
