import {
  Button,
  DatePicker,
  // Icon,
  Input,
  InputNumber,
  Layout,
  Select,
  Table,
  Typography,
  message,
} from 'antd';
import convertCase from 'js-convert-case';
import moment from 'moment';
import pos from 'pos-api';
import React from 'react';
import posv2 from '../../../lib/posv2';
import { invoiceMapper } from '../../../utils/mapper';
import { getOS, paymentTypeMap } from '../../../utils/utils';
// import Highlighter from "react-highlight-words";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

const statusMap = {
  placed: '等待上傳',
  uploaded: '已上傳',
  upload_failure: '上傳失敗',
};

const columns = [
  {
    title: '狀態',
    dataIndex: 'status',
    key: 'status',
    width: 200,
    render: (status, record) => {
      let color = '#389e0d';

      if (status === 'upload_failure') {
        color = '#cf1322';
      }

      return (
        <span
          style={{
            color: color,
            fontWeight: 'bold',
          }}
        >
          {statusMap[status] ? statusMap[status] : status}
        </span>
      );
    },
  },
  {
    title: '銷貨時間',
    dataIndex: 'salesTime',
    key: 'salesTime',
    width: 250,
    render: (text, record) => (
      <span>{moment(record.salesTime).format('YYYY-MM-DD HH:mm:ss')}</span>
    ),
    sorter: (a, b) => a.salesTime.getTime() - b.salesTime.getTime(),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '發票號碼',
    dataIndex: 'invNumber',
    key: 'invNumber',
    width: 150,
    render: (text, record) => {
      if (record.void) {
        return text + '(作廢)';
      }

      return text;
    },
  },
  {
    title: '做廢',
    dataIndex: 'void',
    key: 'void',
    width: 150,
    render: (text, record) => {
      if (record.void === true) return '是';
      return '否';
    },
  },
  {
    title: '機台號',
    dataIndex: 'app',
    key: 'app',
    width: 200,
    render: (text, record) => {
      let appName = '';
      if (record.app) {
        appName = record.app.name;
      }

      return appName;
    },
  },

  {
    title: '車號',
    dataIndex: 'buyerName',
    key: 'buyerName',
    render: (text, record) => <span>{text ? text : ''}</span>,
    width: 150,
  },

  {
    title: '買方統編',
    dataIndex: 'buyerBan',
    key: 'buyerBan',
    render: (text, record) => <span>{text ? text : ''}</span>,
    width: 150,
  },

  {
    title: '銷售額',
    dataIndex: 'salesAmount',
    key: 'salesAmount',
    width: 150,
  },
  {
    title: '稅額',
    dataIndex: 'taxAmount',
    key: 'taxAmount',
    width: 120,
  },
  {
    title: '總金額',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    width: 150,
  },
  {
    title: '稅率',
    dataIndex: 'taxRate',
    key: 'taxRate',
    width: 120,
  },
  {
    title: '訂單編號',
    dataIndex: 'order',
    key: 'order',
    render: (text, record) => (
      <span>{record.order ? record.order.id : '查無資料'}</span>
    ),
    width: 150,
  },
  {
    title: '發票隨機碼',
    dataIndex: 'randomCode',
    key: 'randomCode',
    width: 150,
  },
  {
    title: '支付方式',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 150,
    render: (text, record) => {
      if (paymentTypeMap[text]) {
        text = paymentTypeMap[text];
      }
      return text;
    },
  },
];

const DEFAULT_PAGE_SIZE = 100;

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.pageTimer = null;
  }

  state = {
    searchText: '',
    searchIvNumber: null,
    startTime: moment(moment().format('YYYY-MM') + '-01', 'YYYY-MM-DD'),
    endTime: null,
    appId: '_all',
    apps: [
      {
        id: '_all',
        name: '全部',
      },
    ],
    amounts: {
      salesAmount: 0,
      totalAmount: 0,
      taxAmount: 0,
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      current: 1,
    },
    plate: null,
    status: 'all',
    isVoid: null, // default all = null
    type: 'invoice',
    loading: true,
    paymentType: 'all',
    isExportingCsv: false,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      filters: filters,
      sorter: sorter,
    });

    this.fetchInvoices({
      pagination: pager,
      sorter,
      filters,
    });
  };

  fetchInvoices = async opts => {
    this.setState({
      loading: true,
    });

    opts = opts || {
      pagination: this.state.pagination,
      sorter: {},
    };

    if (!opts.sorter) opts.sorter = {};
    if (!opts.filters) opts.filters = {};
    if (!opts.startTime) opts.startTime = this.state.startTime;
    if (!opts.endTime) opts.endTime = this.state.endTime;
    if (!opts.appId) opts.appId = this.state.appId;
    if (!opts.searchIvNumber) opts.searchIvNumber = this.state.searchIvNumber;
    if (!opts.plate) opts.plate = this.state.plate;
    if (!opts.paymentType) opts.paymentType = this.state.paymentType;
    if (!opts.isVoid) opts.isVoid = this.state.isVoid;
    if (!opts.type) opts.type = this.state.type;
    if (!opts.status) opts.status = this.state.status;

    let isVoid = null;
    if (opts.isVoid && opts.isVoid !== 'all') {
      isVoid = opts.isVoid;
    }

    let appId = null;
    if (opts.appId && opts.appId !== '_all') {
      appId = opts.appId;
    }

    let searchBy = null,
      searchText = null;
    if (opts.searchIvNumber) {
      searchBy = 'iv_number';
      searchText = opts.searchIvNumber;
    }

    let paymentType = opts.paymentType;
    if (paymentType === 'all') {
      paymentType = null;
    }

    let status = opts.status;

    if (status === 'all') {
      status = null;
    }

    let { content, pagination, amounts } = await pos.invoice.fetchInvoices({
      pageSize: opts.pagination.pageSize,
      page: opts.pagination.current - 1,
      stime: opts.startTime,
      etime: opts.endTime,
      sortBy: opts.sorter.field
        ? convertCase.toSnakeCase(opts.sorter.field)
        : null,
      sortDirection: opts.sorter.order === 'descend' ? 'desc' : 'asc',
      searchBy: searchBy,
      searchText: searchText,
      appId: appId ? Number(appId) : null,
      buyerName: opts.plate,
      paymentType,
      isVoid,
      type: opts.type,
      status,
    });

    let invs = invoiceMapper.toView(content);
    invs.forEach((g, idx) => {
      g.key = g.id;
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      invoices: invs,
      loading: false,
      amounts: {
        salesAmount: amounts.sales_amount,
        totalAmount: amounts.total_amount,
        taxAmount: amounts.tax_amount,
      },
      pagination: pager,
    });
  };

  onFooter = record => {
    return (
      <div>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總筆數: {this.state.pagination.total}
            </Text>
          </div>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總銷售額:{' '}
              {`$ ${this.state.amounts.salesAmount}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}
            </Text>
            +
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總稅額:{' '}
              {`$ ${this.state.amounts.taxAmount}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}
            </Text>
            =
            <Text style={{ marginRight: 6, marginLeft: 6, fontWeight: 'bold' }}>
              總金額:{' '}
              {`$ ${this.state.amounts.totalAmount}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  handleTimeChange = (field, value) => {
    this.setState({
      [field]: value.toDate(),
    });

    let timeRange = {
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };

    timeRange[field] = value.toDate();

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchInvoices({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId: this.state.appId,
      ...timeRange,
    });
  };

  handlePlateChange = plate => {
    console.log(plate);
    if (this.searchPlateTimer) {
      clearTimeout(this.searchPlateTimer);
    }

    this.searchPlateTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        plate,
      });

      this.fetchInvoices({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        appId: this.state.appId,
        plate,
      });
    }, 200);
  };

  handlePaymentTypeChange = paymentType => {
    this.setState({
      paymentType: paymentType,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchInvoices({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId: this.state.appId,
      paymentType,
    });
  };

  handleVoidChange = isVoid => {
    this.setState({
      isVoid: isVoid,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchInvoices({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId: this.state.appId,
      isVoid,
    });
  };

  handleTypeChange = type => {
    this.setState({
      type: type,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchInvoices({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId: this.state.appId,
      type,
    });
  };

  handleStatusChange = status => {
    this.setState({
      status: status,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchInvoices({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId: this.state.appId,
      status,
    });
  };

  init = async () => {
    let apps = await pos.app.fetchApps({
      pageSize: 100,
      type: ['aps', 'online_sell_rental_card', 'admin'],
    });

    if (apps) {
      let _apps = apps.map(ap => {
        return {
          id: ap.id + '',
          name: ap.name,
        };
      });

      _apps = [
        {
          id: '_all',
          name: '全部',
        },
        ..._apps,
      ];

      this.setState({
        apps: _apps,
      });
    }

    await this.fetchInvoices();
  };

  componentDidMount() {
    this.init();
  }

  handleAppIdChange = appId => {
    this.setState({
      appId: appId,
    });

    let nextPagin = {
      ...this.state.pagination,
    };

    nextPagin.current = 1;

    this.fetchInvoices({
      pagination: nextPagin,
      filters: this.state.filters,
      sorter: this.state.sorter,
      appId,
    });
  };

  handleSearchIvNumberChange = ivNumber => {
    if (this.searchIvNumberTimer) {
      clearTimeout(this.searchIvNumberTimer);
    }

    this.searchIvNumberTimer = setTimeout(() => {
      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.setState({
        searchIvNumber: ivNumber,
      });

      this.fetchInvoices({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
        searchIvNumber: ivNumber,
      });
    }, 200);
  };

  handleResetUploadFailureInvoices = () => {
    const reset = async () => {
      try {
        await posv2.resetFailureInvoices({
          startTime: this.state.startTime,
          endTime: this.state.endTime,
        });

        message.info('成功');
      } catch (err) {
        message.error('失敗');
      }

      let nextPagin = {
        ...this.state.pagination,
      };

      nextPagin.current = 1;

      this.fetchInvoices({
        pagination: nextPagin,
        filters: this.state.filters,
        sorter: this.state.sorter,
      });
    };

    reset();
  };

  handleExport = async () => {
    try {
      this.setState({
        isExportingCsv: true,
      });

      let appId = null;
      if (this.state.appId && this.state.appId !== '_all') {
        appId = this.state.appId;
      }

      let searchBy = null,
        searchText = null;
      if (this.state.searchIvNumber) {
        searchBy = 'iv_number';
        searchText = this.state.searchIvNumber;
      }

      let paymentType = this.state.paymentType;
      if (paymentType === 'all') {
        paymentType = null;
      }

      let status = this.state.status;

      if (status === 'all') {
        status = null;
      }

      // sync total_elements
      const opts = {
        pageSize: 10,
        page: 0,
        stime: this.state.startTime,
        etime: this.state.endTime,
        sortBy: 'id',
        sortDirection: 'asc',
        searchBy: searchBy,
        searchText: searchText,
        appId: appId ? Number(appId) : null,
        buyerName: this.state.plate,
        paymentType,
        type: this.state.type,
        status,
      };
      const { pagination: syncPagination } = await pos.invoice.fetchInvoices(
        opts
      );

      const pageSize = 5000;
      const totalElements = syncPagination.total_elements;
      const totalPages = Math.floor(totalElements / pageSize + 1);
      const pagesToFetch = Array.from(Array(totalPages).keys()); // [0,1,2,3,4] if totalPages is 5
      const fetched = await Promise.all(
        pagesToFetch.map(page =>
          pos.invoice.fetchInvoices({
            ...opts,
            pageSize: pageSize,
            page: page,
          })
        )
      );
      let content = fetched.reduce((contents, currentResolve) => {
        return [...contents, ...currentResolve.content];
      }, []);

      let data = [];

      content = invoiceMapper.toView(content);

      content.forEach(d => {
        let status = statusMap[d.status];
        let salesTime = moment(d.salesTime).format('YYYY-MM-DD HH:mm:ss');
        let ivNumber = d.invNumber;
        let apsNumber = d.app ? d.app.name : '';
        let plate = d.buyerName;
        let buyberBan = d.buyerBan;
        let salesAmount = d.salesAmount;
        let taxAmount = d.taxAmount;
        let totalAmount = d.totalAmount;
        let taxRate = d.taxRate;
        let orderNumber = d.order ? d.order.id : '';
        let randomCode = d.randomCode;
        let paymentType = paymentTypeMap[d.paymentType];

        data.push({
          status,
          salesTime,
          ivNumber,
          apsNumber,
          plate,
          buyberBan,
          salesAmount,
          taxAmount,
          totalAmount,
          taxRate,
          orderNumber,
          randomCode,
          paymentType,
        });
      });

      const download = (filename, text) => {
        //text = text.replace(/\n/g, '\r\n');
        var element = document.createElement('a');

        if (getOS() === 'Windows') {
          let blob = new Blob(['\ufeff', text], {
            type: 'text/csv;charset=utf-8',
          });
          element.setAttribute('href', window.URL.createObjectURL(blob));
        } else {
          element.setAttribute(
            'href',
            'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
          );
        }

        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      };

      /**
       * status,
          salesTime,
          ivNumber,
          apsNumber,
          plate,
          buyberBan,
          salesAmount,
          taxAmount,
          totalAmount,
          taxRate,
          orderNumber,
          randomCode,
          paymentType
      */
      let result =
        '狀態,銷貨時間,發票號碼,機台號,車號,買方統編,銷售額,稅額,總金額,稅率,訂單號,發票隨機碼,支付方式\n';
      let tokens =
        'status,salesTime,ivNumber,apsNumber,plate,buyberBan,salesAmount,taxAmount,totalAmount,taxRate,orderNumber,randomCode,paymentType'.split(
          ','
        );

      data.forEach(d => {
        result += tokens.map(t => d[t]).join(',') + '\n';
      });

      download('發票紀錄.csv', result);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isExportingCsv: false,
      });
    }
  };

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                flex: 4,
              }}
            >
              搜尋範圍:
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                placeholder='start'
                onChange={value => this.handleTimeChange('startTime', value)}
                value={moment(this.state.startTime)}
                style={{ marginRight: 12, marginLeft: 12 }}
              ></DatePicker>
              <DatePicker
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                placeholder='end'
                onChange={value => this.handleTimeChange('endTime', value)}
              ></DatePicker>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              單頁筆數:
              <InputNumber
                style={{ marginLeft: 12 }}
                min={1}
                defaultValue={DEFAULT_PAGE_SIZE}
                onChange={value => {
                  if (this.state.pagination.pageSize === value) {
                    return;
                  }

                  // debounce
                  if (this.pageTimer) {
                    clearTimeout(this.pageTimer);
                  }

                  this.pageTimer = setTimeout(() => {
                    let pager = {
                      ...this.state.pagination,
                    };
                    pager.pageSize = value;
                    pager.current = 1;
                    this.setState({
                      pagination: pager,
                    });

                    this.fetchInvoices({
                      pagination: pager,
                    });
                  }, 250);
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: 12 }}>
            機台號:
            <Select
              defaultValue='_all'
              onChange={this.handleAppIdChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              {this.state.apps.map(ap => {
                return <Option value={ap.id}>{ap.name}</Option>;
              })}
            </Select>
            發票號:
            <Search
              size='large'
              placeholder='請輸入發票號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handleSearchIvNumberChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            車號:
            <Search
              size='large'
              placeholder='車號'
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.handlePlateChange(value.toUpperCase());
              }}
              style={{ width: 200, marginLeft: 12, marginRight: 12 }}
            />
            付款別:
            <Select
              defaultValue='all'
              onChange={this.handlePaymentTypeChange}
              style={{ width: 180, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='cash'>現金</Option>
              <Option value='ezcard'>悠遊卡</Option>
              <Option value='ipass'>一卡通</Option>
              <Option value='icash'>愛金卡</Option>
              <Option value='credit_card'>信用卡</Option>
              <Option value='ez_pay'>悠遊付</Option>
              <Option value='pay_taipei'>台北通</Option>
              <Option value='pay_taipei_pos'>台北行動支付</Option>
              <Option value='line_pay'>LinePay</Option>
              <Option value='line_pay_money'>一卡通Money</Option>
              <Option value='icash_pay'>iCashPay</Option>
              <Option value='op_wallet'>OP錢包</Option>
              <Option value='taiwan_pay'>台灣Pay</Option>
              <Option value='jkopay'>街口支付</Option>
              <Option value='mochi_pay'>麻吉付</Option>
              <Option value='mochi_pay_auto_pass'>麻吉付(自動)</Option>
              <Option value='remit'>電匯</Option>
            </Select>
            是否做廢:
            <Select
              defaultValue='all'
              onChange={this.handleVoidChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='1'>是</Option>
              <Option value='0'>否</Option>
            </Select>
            類別:
            <Select
              defaultValue='invoice'
              onChange={this.handleTypeChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='invoice'>發票</Option>
              <Option value='receipt'>收據</Option>
            </Select>
            狀態:
            <Select
              defaultValue='all'
              onChange={this.handleStatusChange}
              style={{ width: 120, marginLeft: 12, marginRight: 12 }}
            >
              <Option value='all'>全部</Option>
              <Option value='placed'>等待上傳</Option>
              <Option value='uploaded'>上傳成功</Option>
              <Option value='upload_failure'>上傳失敗</Option>
            </Select>
          </div>

          <Button
            style={{ marginLeft: 20 }}
            size='large'
            disabled={this.state.isExportingCsv}
            onClick={() => {
              this.handleExport();
            }}
          >
            匯出
          </Button>

          {this.state.status === 'upload_failure' ? (
            <Button
              type='danger'
              size='large'
              onClick={this.handleResetUploadFailureInvoices}
            >
              全部重傳
            </Button>
          ) : (
            <div></div>
          )}
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={columns}
            dataSource={this.state.invoices}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            footer={this.onFooter}
            scroll={{ y: 520, x: 1000 }}
          />
        </Content>
      </Content>
    );
  }
}

export default Invoices;
