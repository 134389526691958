import config from '../config';
import * as utils from './utils';

function entityMap(entity, fun) {
  if (entity instanceof Array) {
    return entity.map(item => fun(item));
  }

  return fun(entity);
}

export const categoryMapper = (() => {
  var toView = (
    entity,
    opts = {
      imageType: 'rw320',
    }
  ) => {
    opts = opts || {
      imageType: 'rw320',
    };

    let _ = entity => {
      if (!entity) return entity;
      return {
        id: entity.id,
        name: entity.name,
        image: entity.picture
          ? entity.picture.base_url +
            `/${opts.imageType}.jpg?_=${entity.picture.mtime}`
          : config.sampleImage,
        visibility: true,
      };
    };

    return entityMap(entity, _);
  };

  /**
   * transform category view to entity
   * @param {*} view
   */
  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const itemMapper = (() => {
  /**
   * transform item entity to view
   * @param {*} entity
   */
  var toView = (
    entity,
    opts = {
      imageType: 'rw320',
      autoSample: true,
    }
  ) => {
    opts = opts || {
      imageType: 'rw320',
    };

    let _ = entity => {
      if (!entity) return entity;

      return {
        id: entity.id,
        name: entity.name,
        description: entity.description,
        type: entity.type,
        price: entity.price,
        categoryId: entity.category_id,
        startTime: entity.stime ? new Date(entity.stime) : null,
        endTime: entity.etime ? new Date(entity.etime) : null,
        createTime: entity.ctime ? new Date(entity.ctime) : null,
        image:
          entity.picture && entity.picture.base_url
            ? entity.picture.base_url +
              `/${opts.imageType}.jpg?_=${entity.picture.mtime}`
            : opts.autoSample
              ? config.sampleImage
              : null,
        visibility: true,
        active: entity.active,
        status: entity.status,
        baseImageUrl:
          entity.picture && entity.picture.base_url
            ? entity.picture.base_url
            : null,
        rentalUsers: entity.rental_users,
        discounts: entity.discounts,
        mark: entity.mark,
        rentalInfo: entity.rental_info,
        carType: entity.car_type,
        paymentTime: entity.payment_time,
        parkSpaceId: entity.park_space_id,
        manualCreate: entity.manual_create,
        manualLeave: entity.manual_leave,
        carTowerNumber: entity.car_tower_number,
      };
    };

    return entityMap(entity, _);
  };

  /**
   * transform view to entity
   * @param {*} view
   */
  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
      descption: view.descption,
      type: view.type,
      price: view.price,
      category_id: view.categoryId,
      stime: view.startTime,
      etime: view.endTime,
      ctime: view.createTime,
      active: view.active,
      car_type: view.carType,
      mark: view.mark,
      park_space_id: view.parkSpaceId,
      manual_create: view.manualCreate,
      manual_leave: view.manualLeave,
    };
  };

  /**
   * transform item entity or view to descption section
   * @param {*} entity item entity or view
   */
  var toDescView = entity => {
    const item = entity;

    let type;
    if (item.type === 'single') {
      type = '單品';
    } else {
      type = '套餐';
    }

    let ptype;
    let price = item.price;
    switch (item.price.type) {
      case 'all':
        price = `${price.in}/${price.out}`;
        ptype = '內用/外帶';
        break;
      case 'take_in':
        price = `${price.in}`;
        ptype = '內用';
        break;
      case 'take_out':
        price = `${price.out}`;
        ptype = '外帶';
        break;
      default:
        break;
    }

    return {
      price,
      priceType: ptype,
      type: type,
    };
  };

  return {
    toView,
    toDescView,
    toEntity,
  };
})();

export const itemPackageMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;
      let items = itemMapper.toView(entity.items);
      let defaultItem = entity.default_item;
      if (!defaultItem) {
        defaultItem = items[0];
      }

      return {
        id: entity.id,
        name: entity.name,
        defaultItem: defaultItem,
        currentItem: defaultItem,
        items: items,
      };
    };

    return entityMap(entity, _);
  };

  return {
    toView: toView,
  };
})();

export const ingredientMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;

      return {
        id: entity.id,
        name: entity.name,
        abbreviation: entity.abbreviation,
        options: entity.options,
      };
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
      abbreviation: view.abbreviation,
      options: view.options,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const appMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;

      let data = entity.data;
      if (data) {
        data = utils.camelize(data);
      }

      return {
        id: entity.id,
        name: entity.name,
        type: entity.type,
        accessToken: entity.access_token,
        accessKey: entity.access_key,
        data: data,
      };
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    let data = view.data;
    if (data) {
      data = utils.snakelize(data);
    }

    return {
      id: view.id,
      name: view.name,
      type: view.type,
      data: data,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const invoiceMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;

      let app;
      if (entity.app) {
        app = utils.camelize(entity.app);
      }

      return {
        id: entity.id,
        invNumber: entity.iv_number,
        salesTime: new Date(entity.sales_time),
        sellerBan: entity.seller_ban,
        buyerBan: entity.buyer_ban,
        salesAmount: entity.sales_amount,
        totalAmount: entity.total_amount,
        taxRate: entity.tax_rate,
        taxAmount: entity.tax_amount,
        randomCode: entity.random_code,
        order: entity.order,
        buyerName: entity.buyer_name,
        void: entity.void,
        paymentType: entity.payment_type,
        status: entity.status,
        app,
      };
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
      type: view.type,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const orderMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;

      let v = utils.camelize(entity);
      if (v.amounts) {
        v.amounts = utils.camelize(v.amounts);
      }

      if (v.ctime) {
        v.ctime = new Date(v.ctime);
      }

      if (v.items) {
        v.items = v.items.map(item => utils.camelize(item));
      }

      if (v.rentalCards) {
        v.rentalCards = v.rentalCards.map(card => utils.camelize(card));
      }

      if (v.app) {
        v.app = utils.camelize(v.app);
      }

      if (v.payments) {
        v.payments = v.payments.map(p => utils.camelize(p));
      }

      if (v.invoices) {
        v.invoices = v.invoices.map(iv => utils.camelize(iv));
      }

      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
      type: view.type,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const agencyReceiptMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;

      let v = utils.camelize(entity);

      if (v.amounts) {
        v.amounts = utils.camelize(v.amounts);
      }

      if (v.ctime) {
        v.ctime = new Date(v.ctime);
      }

      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
      type: view.type,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const cashMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;
      let v = { ...entity };
      v.timestamp = new Date(v.timestamp);

      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return {
      id: view.id,
      name: view.name,
      type: view.type,
    };
  };

  return {
    toView,
    toEntity,
  };
})();

export const userMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      return utils.camelize(entity);
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return utils.snakelize(view);
  };

  return {
    toView,
    toEntity,
  };
})();

function processChageByTime(service) {
  if (!service.data) return;
  if (!service.data.periods) return;

  const transformPeriods = periods => {
    periods.forEach((p, idx) => {
      periods[idx] = {
        startTime: new Date(p.stime),
        endTime: new Date(p.etime),
        color: p.color,
        timeUnit: p.time_unit,
        chargeInterval: p.charge_interval,
        price: p.price,
        enableMaxPrice: p.enable_max_price,
        maxPrice: p.max_price,
      };
    });
  };

  service.data.periods = utils.camelize(service.data.periods);
  let periods = service.data.periods;

  let types = ['normal', 'holiday', 'spHoliday'];
  types.forEach(t => {
    if (periods[t] instanceof Array) {
      transformPeriods(periods[t]);
    }
  });
}

export const serviceMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      switch (entity.type) {
        case 'charge_by_time':
          processChageByTime(entity);
          break;
        case 'park_slot':
          processChageByTime(entity);
          break;
        case 'not_use_electric_station':
          processChageByTime(entity);
          break;
        default:
          break;
      }

      let v = utils.camelize(entity);
      if (v.data) {
        v.data = utils.camelize(v.data);
      }
      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return utils.snakelize(view);
  };

  return {
    toView,
    toEntity,
  };
})();

export const dayMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      let v = utils.camelize(entity);
      v.date = new Date(v.date);
      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return utils.snakelize(view);
  };

  return {
    toView,
    toEntity,
  };
})();

export const memberMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      let v = utils.camelize(entity);
      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return utils.snakelize(view);
  };

  return {
    toView,
    toEntity,
  };
})();

export const systemInfoMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      if (!entity) return entity;
      let v = utils.camelize(entity);
      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return utils.snakelize(view);
  };

  return {
    toView,
    toEntity,
  };
})();

export const rentalUserMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      let v = utils.camelize(entity);
      v.startTime = new Date(v.stime);
      v.endTime = new Date(v.etime);
      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    return utils.snakelize(view);
  };

  return {
    toView,
    toEntity,
  };
})();

export const defaultMapper = (() => {
  var toView = entity => {
    let _ = entity => {
      let v = utils.camelize(entity);
      return v;
    };

    return entityMap(entity, _);
  };

  var toEntity = view => {
    let _ = view => {
      let e = utils.snakelize(view);
      return e;
    };

    return entityMap(view, _);
  };

  return {
    toView,
    toEntity,
  };
})();
