import 'stompjs/lib/stomp';
import EventEmitter from 'events';
import { type } from 'os';

let emitter = new EventEmitter();

let connected = false;

let _client;

async function connect(config) {
  let { host, port, username, password, protocal } = config;

  if (!username) {
    username = 'guest';
  }

  if (!password) {
    password = 'guest';
  }

  let ws = new WebSocket(`${protocal || 'ws'}://${host}:${port}/ws`);

  _client = window.Stomp.over(ws);

  return new Promise((resolve, reject) => {
    _client.connect(
      username,
      password,
      () => {
        connected = true;
        resolve('connected');

        emitter.emit('connected');
      },
      err => {
        if (connected) {
          console.log('stomp connection lost!');
          console.log('try reconnect after 3 sec.');

          // connection close
          // reconnect
          setTimeout(() => {
            connect(config);
          }, 3000);
        } else {
          reject(err);
        }
      },
      '/'
    );
  });
}

async function subscribe(topic, callback) {
  if (!connected) {
    throw Error('stomp unconnect');
  }

  return _client.subscribe(topic, callback);
}

export default {
  connect,
  asyncSubscribe: subscribe,
};
