import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button, Input, Layout, Modal, Table } from 'antd';

import pos from 'pos-api';

const { Search } = Input;
const { Content } = Layout;

function useBlockModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBlockId, setCurrentBlockId] = useState('');
  const [block, setBlock] = useState(null);

  useEffect(() => {
    async function search() {
      if (!currentBlockId) return;
      try {
        let resp = await pos.userBlockEvent.getUserBlockEventById({
          id: currentBlockId,
        });
        const { content } = resp;
        setBlock(content);
      } catch (err) {
        console.error(err);
      }
    }
    search();
  }, [currentBlockId]);

  const showModal = useCallback(id => {
    setIsModalVisible(true);
    setCurrentBlockId(id);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return {
    isModalVisible,
    block,
    showModal,
    closeModal,
  };
}

function useBlockEvents() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 100,
  });
  const [searchText, setSearchText] = useState('');
  const [blockEvents, setBlockEvents] = useState([]);
  const searchRef = useRef();

  useEffect(() => {
    const loadBlockEvents = async () => {
      if (pagination.current < 1) return;
      try {
        let resp = await pos.userBlockEvent.getUserBlockEvent({
          pageSize: pagination.pageSize,
          page: pagination.current - 1,
          searchText,
          sortDirection: 'desc',
        });
        const { pagination: respPagination, content } = resp;
        const keyContent = content.map(c => ({ ...c, key: c.id }));
        setBlockEvents(keyContent);

        setPagination({
          current: pagination.current,
          pageSize: Number(respPagination.page_size),
          total: respPagination.total_elements,
        });
      } catch (error) {
        console.error(error);
      }
    };
    loadBlockEvents();
  }, [pagination.current, searchText]);

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  const onDebounceSearch = text => {
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      setSearchText(text);
    }, 250);
  };

  return {
    pagination,
    blockEvents,
    handleTableChange,
    onDebounceSearch,
  };
}

export default function QCardBlockNotification({ className, children }) {
  const { pagination, blockEvents, handleTableChange, onDebounceSearch } =
    useBlockEvents();
  const { isModalVisible, block, showModal, closeModal } = useBlockModal();

  const columns = useMemo(
    () => [
      {
        title: '月租用戶',
        dataIndex: ['rental_user', 'name'],
        key: 'user',
      },
      {
        title: '被檔的車號',
        dataIndex: ['identity', 'content'],
        key: 'plate',
      },
      {
        title: '群組',
        dataIndex: ['rental_user', 'rental_group', 'group_code'],
        key: 'group',
      },
      {
        title: '被檔的時間',
        dataIndex: 'timestamp',
        key: 'blockTime',
        render: timestamp => new Date(timestamp).toLocaleString('zh-TW'),
      },
      {
        title: '被檔事件時群組已停車輛',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Button
            type='link'
            icon={'question-circle'}
            onClick={() => showModal(id)}
          />
        ),
      },
    ],
    [showModal]
  );

  return (
    <div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div style={{ float: 'right' }}>
            <Search
              placeholder={'請輸入車牌號碼'}
              onSearch={onDebounceSearch}
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;
                onDebounceSearch(value);
              }}
              style={{ width: 200 }}
            />
          </div>
        </Content>
      </div>

      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        <Table
          columns={columns}
          dataSource={blockEvents}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Content>
      <Modal
        visible={isModalVisible}
        footer={null}
        maskClosable={true}
        onCancel={closeModal}
      >
        <h3>車號</h3>
        {block?.items?.map(item => (
          <p key={item.id}>{item.name}</p>
        ))}
      </Modal>
    </div>
  );
}
