import React from 'react';
import Dashboard from './layout/Dashboard';

import { validateUser } from './utils/hoc';

import App from './view/dashboard/App';
import Category from './view/dashboard/Category';
import Ingredients from './view/dashboard/Ingredients';
import Overview from './view/dashboard/Overview';
import Store from './view/dashboard/Store';
import User from './view/dashboard/User';
import UserForm from './view/dashboard/UserForm';

// items
import ItemForm from './view/dashboard/item/ItemForm';
import Items from './view/dashboard/item/Items';

// order
import OrderItems from './view/dashboard/item/OrderItems';

// reports
import AgencyReceipt from './view/dashboard/report/AgencyReceipt';
import Cashs from './view/dashboard/report/Cashs';
import Invoice from './view/dashboard/report/Invoices';
import Orders from './view/dashboard/report/Orders';

// services
import ServiceForm from './view/dashboard/services/ServiceForm';
import Services from './view/dashboard/services/Services';

import AppStatusLogs from './view/dashboard/manage/AppStatus';

import Members from './view/dashboard/Members';
import CarRegistrationForm from './view/dashboard/manage/CarRegistrationForm';
import CarRegistrations from './view/dashboard/manage/CarRegistrations';
import ParkRecords from './view/dashboard/manage/ParkRecords';
import RentalUserForm from './view/dashboard/manage/RentalUserForm';
import RentalUsers from './view/dashboard/manage/RentalUsers';

import EdgeDetectorRecords from './view/dashboard/report/DetectorRecords';

import RentalCards from './view/dashboard/report/RentalCards';

// system
import Calendar from './view/dashboard/system/Calendar';
import CompanyInfo from './view/dashboard/system/Company';
import SystemInfo from './view/dashboard/system/Info';
import SystemInvoice from './view/dashboard/system/Invoice';
import Log from './view/dashboard/system/Log';

import Car from './view/dashboard/manage/Car';
import CarMonitor from './view/dashboard/manage/CarMonitor';

import CarWithDiscount from './view/dashboard/manage/CarWithDiscount2';
import FloorLockMonitor from './view/dashboard/manage/FloorLockMonitor';

import PrintReports from './view/dashboard/report/PrintReports';

import DiscountDefineForm from './view/dashboard/system/DiscountDefineForm';
import DiscountDefines from './view/dashboard/system/DiscountDefines';

import CustomerForm from './view/dashboard/manage/CustomerForm';
import Customers from './view/dashboard/manage/Customers';

import DailyNonSystemRevenue from './view/dashboard/manage/DailyNonSystemRevenue';
import Depts from './view/dashboard/manage/Dept';
import GatewaySetting from './view/dashboard/manage/GatewaySetting';
import QCardBlockNotification from './view/dashboard/manage/QCardBlockNotification';
import RentalGroup from './view/dashboard/manage/RentalGroup';

const layouts = {
  global: {
    path: '',
  },
  dashboard: {
    path: '/dashboard',
    component: Dashboard,
  },
};

const routes = {
  overview: {
    name: '概覽',
    icon: 'desktop',
    layout: '/dashboard',
    path: '/overview',
    component: validateUser(Overview),
    level: 1,
    privileges: [
      'admin:crud',
      'supervisor:crud',
      'manager:crud',
      'basic:crud',
      'aduit:crud',
    ],
  },
  items: {
    name: '商品',
    icon: 'appstore',
    layout: '/dashboard',
    path: '/items',
    component: validateUser(Items),
    level: 1,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud', 'basic:crud'],
  },
  orderItems: {
    name: '點餐',
    icon: 'appstore',
    layout: '/dashboard',
    path: '/order-items',
    component: validateUser(OrderItems),
    level: 1,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud', 'basic:crud'],
  },
  category: {
    name: '類別',
    icon: 'table',
    layout: '/dashboard',
    path: '/category',
    component: validateUser(Category),
    level: 1,
    privileges: ['admin', 'supervisor', 'manager'],
  },
  ingredients: {
    name: '配料',
    icon: 'heat-map',
    layout: '/dashboard',
    path: '/ingredients',
    component: validateUser(Ingredients),
    level: 1,
    privileges: ['admin', 'supervisor', 'manager'],
  },
  user: {
    name: '使用者',
    icon: 'user',
    layout: '/dashboard',
    path: '/users',
    component: validateUser(User),
    level: 1,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud'],
  },
  app: {
    name: '應用',
    icon: 'appstore',
    layout: '/dashboard',
    path: '/app',
    component: validateUser(App),
    level: 1,
    privileges: ['admin', 'supervisor', 'manager'],
  },
  service: {
    name: '服務',
    icon: 'appstore',
    layout: '/dashboard',
    path: '/services',
    component: validateUser(Services),
    level: 1,
    privileges: ['admin', 'supervisor', 'manager'],
  },
  member: {
    name: '會員',
    icon: 'appstore',
    layout: '/dashboard',
    path: '/members',
    component: validateUser(Members),
    level: 1,
    privileges: ['admin', 'supervisor', 'manager', 'basic'],
  },
  carWithDiscount: {
    name: '優惠折扣',
    icon: 'appstore',
    layout: '/dashboard',
    path: '/car-discount',
    component: validateUser(CarWithDiscount),
    level: 1,
    privileges: ['contractor'],
  },
  manage: {
    link: false,
    name: '管理',
    icon: 'snippets',
    layout: '/dashboard',
    path: '/manage',
    component: props => <div></div>,
    level: 1,
    privileges: ['admin', 'supervisor', 'manager', 'basic'],
    children: {
      car: {
        name: '機/汽車管理',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/car',
        component: validateUser(Car),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:crud',
        ],
      },
      rentalUsers: {
        name: '月租用戶',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/rental-users',
        component: validateUser(RentalUsers),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:crud',
        ],
      },
      carMonitor: {
        name: '出入口監控',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/car-monitor',
        component: validateUser(CarMonitor),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      carRegistrations: {
        name: '車籍資料',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/car-registrations',
        component: validateUser(CarRegistrations),
        level: 2,
        privileges: ['admin:crud', 'supervisor:crud', 'manager:crud', 'basic'],
      },
      dailyNonSystemRevenue: {
        name: '其他收入',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/daily-non-system-revenue',
        component: validateUser(DailyNonSystemRevenue),
        level: 2,
        privileges: ['admin:crud', 'supervisor', 'manager:crud', 'basic:crud'],
      },
      floorLockMonitor: {
        name: '地鎖控制',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/floor-lock-monitor',
        component: validateUser(FloorLockMonitor),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      parkRecords: {
        name: '停車紀錄',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/park-records',
        component: validateUser(ParkRecords),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      gatewaySettings: {
        name: '出入口設定',
        display: false,
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/gateway-settings',
        component: validateUser(GatewaySetting),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      customers: {
        name: '顧客資料',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/customers',
        component: validateUser(Customers),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      customerForm: {
        display: false,
        name: '表單',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/customer/:id',
        component: validateUser(CustomerForm),
        level: 3,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      appStatusLogs: {
        name: '裝置紀錄',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/app-status-logs',
        component: validateUser(AppStatusLogs),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      qCardBlockNotification: {
        name: '擋車通知',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/q-card-block-notification',
        component: validateUser(QCardBlockNotification),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      rentalGroup: {
        name: '月租群組',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/rental-group',
        component: validateUser(RentalGroup),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
      depts: {
        name: '部門',
        icon: 'file-text',
        layout: '/dashboard/manage',
        path: '/depts',
        component: validateUser(Depts),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'basic'],
      },
    },
  },
  report: {
    link: false,
    name: '報表',
    icon: 'snippets',
    layout: '/dashboard',
    path: '/report',
    component: props => <div></div>,
    level: 1,
    privileges: [
      'admin:crud',
      'supervisor:crud',
      'manager:crud',
      'basic:crud',
      'aduit:crud',
    ],
    children: {
      invoice: {
        name: '發票',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/invoices',
        component: validateUser(Invoice),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      order: {
        name: '交易紀錄',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/orders',
        component: validateUser(Orders),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      agencyReceipt: {
        name: '代收查詢',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/agency-receipt',
        component: validateUser(AgencyReceipt),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      rentalCards: {
        name: '售票紀錄',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/rental-cards',
        component: validateUser(RentalCards),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      printReports: {
        name: '報表印製',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/print-reports',
        component: validateUser(PrintReports),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      cash: {
        name: '現金帳',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/cashs',
        component: validateUser(Cashs),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      edgeDetectorHistory: {
        name: '辨識紀錄',
        icon: 'snippets',
        layout: '/dashboard/report',
        path: '/edge-detector-history',
        component: validateUser(EdgeDetectorRecords),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
    },
  },
  store: {
    name: '店面',
    icon: 'shop',
    layout: '/dashboard',
    path: '/store',
    component: validateUser(Store),
    level: 1,
    display: false,
    privileges: ['admin'],
  },
  system: {
    link: false,
    name: '系統',
    icon: 'snippets',
    layout: '/dashboard',
    path: '/system',
    component: props => <div></div>,
    level: 1,
    privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
    children: {
      log: {
        name: '日誌',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/log',
        component: validateUser(Log),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      calendar: {
        name: '日曆',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/calendar',
        component: validateUser(Calendar),
        level: 2,
        privileges: ['admin', 'supervisor', 'manager', 'audit', 'basic'],
      },
      companyInfo: {
        name: '公司資訊',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/company_info',
        component: validateUser(CompanyInfo),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:r',
        ],
      },
      invoice: {
        name: '發票設定',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/invoice',
        component: validateUser(SystemInvoice),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:r',
        ],
      },
      discountDefines: {
        name: '折扣設定',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/discount-defines',
        component: validateUser(DiscountDefines),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:r',
        ],
      },
      discountDefineForm: {
        display: false,
        name: '折扣設定',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/discount-define/:id',
        component: validateUser(DiscountDefineForm),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:r',
        ],
      },
      info: {
        name: '系統資訊',
        icon: 'file-text',
        layout: '/dashboard/system',
        path: '/info',
        component: validateUser(SystemInfo),
        level: 2,
        privileges: [
          'admin:crud',
          'supervisor:crud',
          'manager:crud',
          'basic:r',
        ],
      },
    },
  },

  // below are not display in dashboard menu

  userForm: {
    display: false,
    name: '員工表單',
    icon: 'user',
    layout: '/dashboard',
    path: '/user/:id',
    component: validateUser(UserForm),
    level: 2,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud'],
  },
  itemForm: {
    display: false,
    name: '商品表單',
    icon: 'user',
    layout: '/dashboard',
    path: '/item/:id',
    component: validateUser(ItemForm),
    level: 2,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud'],
  },
  serviceForm: {
    display: false,
    name: '服務表單',
    icon: 'app',
    layout: '/dashboard',
    path: '/service/:id',
    component: validateUser(ServiceForm),
    level: 2,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud'],
  },
  rentalUserForm: {
    display: false,
    name: '月租用戶表單',
    icon: 'user',
    layout: '/dashboard/manage',
    path: '/rental-user/:id',
    component: validateUser(RentalUserForm),
    level: 3,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud'],
  },
  carRegistrationForm: {
    display: false,
    name: '月租用戶表單',
    icon: 'user',
    layout: '/dashboard/manage',
    path: '/car-registration/:id',
    component: validateUser(CarRegistrationForm),
    level: 3,
    privileges: ['admin:crud', 'supervisor:crud', 'manager:crud'],
  },
};

export default routes;
export { layouts };
