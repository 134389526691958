import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import pos from 'pos-api';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';

import moment from 'moment';

const { Content } = Layout;
const { Option } = Select;

const { Panel } = Collapse;

const CustomerForm = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const [loading, setLoading] = useState(true);

  const [plateKeys, setPlateKeys] = useState([]);

  const [wallets, setWallets] = useState([]);
  const [currentWallet, setCurrentWallet] = useState(null);

  const [walletOp, setWalletOp] = useState('none');

  const [discountKeys, setDiscountKeys] = useState([]);

  const [discountDefines, setDiscountDefines] = useState([]);

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    async function init() {
      let customerId = Number(props.match.params.id);

      let customer = {};

      if (customerId) {
        customer = await pos.customer.readCustomer(customerId);
      }

      if (customer && customer.id) {
        setCustomer(customer);

        let plates = [];

        if (customer.identities) {
          let identities = customer.identities;

          plates = identities.filter(idt =>
            ['plate_car', 'plate_moto'].includes(idt.type)
          );
        }

        let plateMap = {};
        plates.forEach(p => {
          plateMap[uuid()] = p;
        });

        let disMap = {};

        if (customer.discounts) {
          customer.discounts.forEach(dis => {
            disMap[uuid()] = {
              id: dis.id,
              expire_time: dis.expire_time
                ? moment(new Date(dis.expire_time))
                : moment().add(10, 'year'),
            };
          });
        }

        setPlateKeys(Object.keys(plateMap));

        setDiscountKeys(Object.keys(disMap));

        setTimeout(() => {
          setFieldsValue({
            name: customer.name,
            plates: plateMap,
            discounts: disMap,
          });
        }, 250);

        let wallets = await pos.customer.fetchWallets(props.match.params.id);

        setWallets(wallets.content);
      }

      let resp = await pos.discountDefine.readDiscountDefines({
        pageSize: 50,
      });

      if (resp.content) {
        setDiscountDefines(resp.content);
      }

      setLoading(false);
    }

    init();
  }, []);

  const addCustomer = async customer => {
    await pos.customer.addCustomer(customer);
  };

  const updateCustomer = async customer => {
    await pos.customer.updateCustomer(customer.id, customer);
  };

  const depositWallet = w => {
    setCurrentWallet(w);

    setWalletOp('deposit');
  };

  const withdrawWallet = w => {
    setCurrentWallet(w);
    setWalletOp('withdraw');
  };

  const handleDepositOk = () => {
    setCurrentWallet(null);
    setWalletOp('none');

    async function refetchWallet() {
      let wallets = await pos.customer.fetchWallets(props.match.params.id);

      setWallets(wallets.content);
    }

    refetchWallet();
  };

  const handleDepositCancel = () => {
    setCurrentWallet(null);
    setWalletOp('none');
  };

  const handleWithdrawOk = () => {
    setCurrentWallet(null);
    setWalletOp('none');

    async function refetchWallet() {
      let wallets = await pos.customer.fetchWallets(props.match.params.id);

      setWallets(wallets.content);
    }

    refetchWallet();
  };

  const handleWithdrawCancel = () => {
    setCurrentWallet(null);
    setWalletOp('none');
  };

  const handleSubmit = e => {
    e.preventDefault();

    function mapValuesToCustomer(values) {
      return {
        name: values.name,
        identities: values.plates
          ? Object.keys(values.plates).map(key => {
              return values.plates[key];
            })
          : [],
        discounts: values.discounts
          ? Object.keys(values.discounts).map(key => {
              return {
                id: values.discounts[key].id,
                expire_time: values.discounts[key].expire_time?.toDate(),
              };
            })
          : [],
      };
    }

    props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      let _customer = mapValuesToCustomer(values);
      if (customer.id) {
        _customer.id = customer.id;
      }

      if (_customer.id) {
        updateCustomer(_customer)
          .then(() => {
            message.success('成功');

            props.history.push('/dashboard/manage/customers');
          })
          .catch(err => {
            console.error(err);
            message.error('失敗');
          });
      } else {
        addCustomer(_customer)
          .then(() => {
            message.success('成功');

            props.history.push('/dashboard/manage/customers');
          })
          .catch(err => {
            message.error('失敗');
          });
      }
    });
  };

  const addNewPlate = () => {
    let key = uuid();
    let next = [...plateKeys, key];

    setPlateKeys(next);
  };

  const removePlate = key => {
    let next = plateKeys.filter(_key => _key !== key);

    setPlateKeys(next);
  };

  const addNewDiscount = () => {
    let key = uuid();
    let next = [...discountKeys, key];

    setDiscountKeys(next);
  };

  const removeDiscount = key => {
    let next = discountKeys.filter(_key => _key !== key);

    setDiscountKeys(next);
  };

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
        minHeight: 280,
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item label='顧客名稱' htmlFor='name' style={{ marginBottom: 0 }}>
          {getFieldDecorator('name', {
            initialValue: customer.name || '',
          })(<Input></Input>)}
        </Form.Item>

        <Form.Item label='車牌:' style={{ marginBottom: 0, marginTop: 12 }}>
          {plateKeys.map((key, idx) => (
            <div key={key}>
              {getFieldDecorator(`plates.${key}.id`, {
                rules: [],
                initialValue: null,
              })(
                <InputNumber
                  placeholder='Please enter plate number'
                  style={{ width: 160, marginRight: 8, display: 'none' }}
                />
              )}

              {getFieldDecorator(`plates.${key}.content`, {
                rules: [],
                initialValue: `ABC`,
              })(
                <Input
                  placeholder='Please enter plate number'
                  style={{ width: 160, marginRight: 8 }}
                />
              )}

              {getFieldDecorator(`plates.${key}.type`, {
                rules: [],
                initialValue: 'plate_car',
              })(
                <Select style={{ width: 160, marginRight: 8 }}>
                  <Option value='plate_car'>汽車</Option>
                  <Option value='plate_moto'>機車</Option>
                </Select>
              )}

              {
                <Icon
                  className='dynamic-delete-button'
                  type='minus-circle-o'
                  onClick={() => removePlate(key)}
                />
              }
            </div>
          ))}

          <Button
            type='dashed'
            style={{ width: 160, marginTop: 12 }}
            onClick={addNewPlate}
          >
            <Icon type='plus' /> 新增車牌
          </Button>
        </Form.Item>

        <Form.Item label='優惠折扣:' style={{ marginBottom: 0, marginTop: 12 }}>
          {discountKeys.map((key, idx) => (
            <div key={key}>
              {getFieldDecorator(`discounts.${key}.id`, {
                rules: [],
                initialValue: 1,
              })(
                <Select style={{ width: 160, marginRight: 8 }}>
                  {discountDefines.map(dis => {
                    return (
                      <Option key={dis.id} value={dis.id}>
                        {dis.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              {getFieldDecorator(`discounts.${key}.expire_time`, {
                rules: [],
                initialValue: moment().add(10, 'year'),
              })(<DatePicker></DatePicker>)}
              {
                <Icon
                  className='dynamic-delete-button'
                  type='minus-circle-o'
                  onClick={() => removeDiscount(key)}
                />
              }
            </div>
          ))}

          <Button
            type='dashed'
            style={{ width: 160, marginTop: 12 }}
            onClick={addNewDiscount}
          >
            <Icon type='plus' /> 新增優惠
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' style={{ width: 120 }}>
            提交
          </Button>
        </Form.Item>

        <Form.Item>
          <Link to='/dashboard/manage/customers'>
            <Button type='danger' style={{ width: 120 }}>
              取消
            </Button>
          </Link>
        </Form.Item>
      </Form>

      <div>
        <Collapse defaultActiveKey={['1']}>
          <Panel header='錢包' key='1'>
            <Row>
              {wallets.map((w, idx) => {
                return (
                  <Col key={idx} span={6}>
                    <Card
                      title={`#${idx + 1}`}
                      bordered={true}
                      actions={[
                        <Button icon='wallet' onClick={() => depositWallet(w)}>
                          存款
                        </Button>,
                        <Button icon='wallet' onClick={() => withdrawWallet(w)}>
                          取款
                        </Button>,
                      ]}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                        }}
                      >
                        餘額:
                      </div>

                      <div
                        style={{
                          fontWeight: 'bold',
                          fontSize: 28,
                        }}
                      >
                        {w.balance}
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Panel>

          <Panel header='錢包交易紀錄' key='2'>
            {customer && customer.id ? (
              <TransactionRecord
                customer={walletOp === 'none' ? customer : null}
              />
            ) : (
              <div></div>
            )}
          </Panel>
        </Collapse>
      </div>

      <DepositModal
        wallet={currentWallet}
        visible={walletOp === 'deposit'}
        onOk={handleDepositOk}
        onCancel={handleDepositCancel}
      />

      <WithdrawModal
        wallet={currentWallet}
        visible={walletOp === 'withdraw'}
        onOk={handleWithdrawOk}
        onCancel={handleWithdrawCancel}
      />
    </Content>
  );
};

const txTypeMap = {
  debit: '扣款',
  deposit: '存款',
  withdraw: '取款',
  feedback: '回饋',
};

const txColumns = [
  {
    title: '交易日期',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: timestamp => {
      if (!timestamp) {
        return '';
      } else {
        return moment(timestamp).format('YYYY/MM/DD');
      }
    },
  },
  {
    title: '交易類別',
    dataIndex: 'tx_type',
    key: 'tx_type',
    render: tx_type => {
      if (!tx_type) {
        return '';
      }

      if (!txTypeMap[tx_type]) {
        return '';
      }

      return txTypeMap[tx_type];
    },
  },
  {
    title: '交易金額',
    dataIndex: 'tx_amount',
    key: 'tx_amount',
  },
  {
    title: '餘額',
    dataIndex: 'after_balance',
    key: 'after_balance',
  },
  {
    title: '備註',
    dataIndex: 'comment',
    key: 'comment',
  },
];

const TransactionRecord = props => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 100,
  });

  const fetchTransactions = async (customer, pagination) => {
    setLoading(true);

    let resp = await pos.customer.fetchTransactions(customer.id, {
      sortDirection: 'desc',
      pageSize: pagination.pageSize,
      page: pagination.current - 1,
    });

    let items = resp.content;

    setItems(items);

    let nextPagination = {
      ...pagination,
    };

    nextPagination.total = resp.pagination.total_elements;
    setPagination(nextPagination);

    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchTransactions(pagination);
  };

  const init = async (customer, pagination) => {
    fetchTransactions(customer, pagination);
  };

  useEffect(() => {
    if (!props.customer) {
      setLoading(true);
      return;
    }

    init(props.customer, pagination);
  }, [props.customer]);

  return (
    <Table
      loading={loading}
      columns={txColumns}
      dataSource={items}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

const DepositModal = props => {
  const [wallet, setWallet] = useState(null);

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setWallet(props.wallet);
  }, [props.wallet]);

  const deposit = useCallback(() => {
    if (!wallet) return;

    async function doDeposit() {
      await pos.customerWallet.deposit(wallet.id, {
        amount,
      });

      message.success('成功');

      props.onOk && props.onOk();
    }

    if (amount > 0) {
      doDeposit();
    } else {
      message.error('請輸入正確的存款金額');
    }
  }, [wallet, amount]);

  const handleAmountInputChange = evt => {
    const { value } = evt.target;

    const _amount = Number(value);

    setAmount(_amount);
  };

  return (
    <div>
      <Modal
        title='存款'
        visible={props.visible}
        onOk={deposit}
        onCancel={props.onCancel}
        okText='確定'
        cancelText='取消'
      >
        <Input
          placeholder='請輸入金額'
          value={amount}
          onChange={handleAmountInputChange}
        />
      </Modal>
    </div>
  );
};

const WithdrawModal = props => {
  const [wallet, setWallet] = useState(null);

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setWallet(props.wallet);
  }, [props.wallet]);

  const withdraw = useCallback(() => {
    if (!wallet) return;

    async function doWithdraw() {
      await pos.customerWallet.withdraw(wallet.id, {
        amount,
      });

      message.success('成功');

      props.onOk && props.onOk();
    }

    if (amount > 0) {
      doWithdraw();
    } else {
      message.error('請輸入正確的存款金額');
    }
  }, [wallet, amount]);

  const handleAmountInputChange = evt => {
    const { value } = evt.target;

    const _amount = Number(value);

    setAmount(_amount);
  };

  return (
    <div>
      <Modal
        title='存款'
        visible={props.visible}
        onOk={withdraw}
        onCancel={props.onCancel}
        okText='確定'
        cancelText='取消'
      >
        <Input
          placeholder='請輸入金額'
          value={amount}
          onChange={handleAmountInputChange}
        />
      </Modal>
    </div>
  );
};

export default Form.create({
  name: 'customer-define-form',
})(CustomerForm);
