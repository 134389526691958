import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
  message,
} from 'antd';
import pos from 'pos-api';
import { Link } from 'react-router-dom';

import moment from 'moment';
import uuid from 'uuid/v4';

const { Content } = Layout;
const { Option } = Select;

const DAY = 24 * 60 * 60 * 1000;

const UTC_8 = 8 * 60 * 60 * 1000;

const ComplexDiscountItem = props => {
  const { discountName, discount } = props;

  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const [periodKeys, setPeriodKeys] = useState([]);

  useEffect(() => {
    const transformPeriods = periods => {
      periods = periods.map(p => {
        return {
          start_time: moment('2000-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss').add(
            p.start_time,
            'milliseconds'
          ),
          end_time: moment('2000-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss').add(
            p.end_time,
            'milliseconds'
          ),
          dis_rate: p.rate,
        };
      });

      let pKeys = [];
      let periodMap = {};
      periods.forEach(p => {
        let key = uuid();
        pKeys.push(key);
        periodMap[key] = p;
      });

      return [pKeys, periodMap];
    };

    async function init() {
      if (discount.type === 'periods') {
        let periods = discount.data.periods;

        console.log(discount);

        let [keys, periodMap] = transformPeriods(periods);

        setPeriodKeys(keys);

        setTimeout(() => {
          let discounts = getFieldValue('discounts');

          discounts[discountName].periods = periodMap;

          setFieldsValue({
            discounts,
          });
        }, 500);
      }
    }

    init();
  }, []);

  const handleAddPeriod = () => {
    setPeriodKeys([...periodKeys, uuid()]);
  };

  const handleRemovePeriod = _key => {
    setPeriodKeys(periodKeys.filter(key => key !== _key));
  };

  return (
    <div>
      <Form.Item
        label='折扣類型'
        htmlFor={`discounts.${discountName}.type`}
        style={{ marginBottom: 0 }}
      >
        {getFieldDecorator(`discounts.${discountName}.type`, {
          initialValue: discount.type || 'amount',
        })(
          /**
                     * 2024-05-17 ADD
                     * TONG_YI_MERCHANT: 'tong_yi_merchant',
                        TONG_YI_ZENDASUITE: 'tong_yi_zendasuite',
                        TONG_YI_ZENDASUITE_LONG_STAY: 'tong_yi_zendasuite_long_stay',
                        TONG_YI_NTUDORM: 'tong_yi_ntudorm',
                        TONG_YI_NTUDORM_LONG_STAY: 'tong_yi_ntudorm_long_stay',
                    */
          <Select style={{ width: 300 }}>
            <Option value='amount'>金額</Option>
            <Option value='hourly'>小時</Option>
            <Option value='periods'>時段</Option>
            <Option value='all'>全部</Option>
            <Option value='rate'>rate</Option>
            <Option value='tong_yi_merchant'>統一門市優惠</Option>
            <Option value='tong_yi_zendasuite'>統一成大會館優惠</Option>
            <Option value='tong_yi_zendasuite_long_stay'>
              統一成大會館長住優惠
            </Option>
            <Option value='tong_yi_ntudorm'>統一太子學舍優惠</Option>
            <Option value='tong_yi_ntudorm_long_stay'>
              統一太子學舍長住優惠
            </Option>
            <Option value='taipei_electric_moto'>北市府電動機車</Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item
        label='每日僅限一次'
        htmlFor={`discounts.${discountName}.discount_once_every_day`}
        style={{ marginBottom: 0 }}
      >
        {getFieldDecorator(
          `discounts.${discountName}.discount_once_every_day`,
          {
            initialValue: discount.data.discount_once_every_day || false,
            valuePropName: 'checked',
          }
        )(<Switch />)}
      </Form.Item>

      <Form.Item
        label='使用正則表達式對應名稱'
        htmlFor={`discounts.${discountName}.data.use_regex_match`}
        style={{ marginBottom: 0 }}
      >
        {getFieldDecorator(`discounts.${discountName}.data.use_regex_match`, {
          initialValue: discount.data.use_regex_match || false,
          valuePropName: 'checked',
        })(<Switch />)}
      </Form.Item>

      {getFieldValue(`discounts.${discountName}.data.use_regex_match`) ? (
        <Form.Item
          label='正則表達式'
          htmlFor={`discounts.${discountName}.data.regex`}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`discounts.${discountName}.data.regex`, {
            initialValue: discount.data.regex || '',
          })(<Input />)}
        </Form.Item>
      ) : (
        <div></div>
      )}

      {getFieldValue(`discounts.${discountName}.type`) === 'amount' && (
        <div>
          <Form.Item
            label='預設折扣金額'
            htmlFor={`discounts.${discountName}.data.default_amount`}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator(
              `discounts.${discountName}.data.default_amount`,
              {
                initialValue: discount.data
                  ? discount.data.default_amount || 50
                  : 50,
              }
            )(<InputNumber></InputNumber>)}
          </Form.Item>
        </div>
      )}

      {getFieldValue(`discounts.${discountName}.type`) === 'hourly' && (
        <div>
          <Form.Item
            label='預設折扣時數'
            htmlFor={`discounts.${discountName}.data.default_hours`}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator(`discounts.${discountName}.data.default_hours`, {
              initialValue: discount.data
                ? discount.data.default_hours || 4
                : 4,
            })(<InputNumber></InputNumber>)}
          </Form.Item>

          <Form.Item
            label='最大折扣時數'
            htmlFor={`discounts.${discountName}.data.max_hours`}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator(`discounts.${discountName}.data.max_hours`, {
              initialValue: discount.data
                ? discount.data.max_hours || 999
                : 999,
            })(<InputNumber></InputNumber>)}
          </Form.Item>

          <Form.Item
            label='啟用折扣N小時候折價'
            htmlFor={`discounts.${discountName}.data.enable_after_hours_discount`}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator(
              `discounts.${discountName}.data.enable_after_hours_discount`,
              {
                initialValue: discount.data
                  ? discount.data.enable_after_hours_discount
                  : false,
                valuePropName: 'checked',
              }
            )(<Switch></Switch>)}
          </Form.Item>

          {getFieldValue('data.enable_after_hours_discount') ? (
            <Form.Item
              label='N小時後折價率(%)'
              htmlFor={`discounts.${discountName}.data.after_hours_discount_rate`}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator(
                `discounts.${discountName}.data.after_hours_discount_rate`,
                {
                  initialValue:
                    discount.data && discount.data.after_hours_discount_rate
                      ? discount.data.after_hours_discount_rate
                      : 0.5,
                }
              )(
                <InputNumber
                  formatter={value => `${Math.floor(value * 100)}%`}
                  parser={value => Number(value.replace('%', '')) / 100}
                ></InputNumber>
              )}
            </Form.Item>
          ) : (
            <div />
          )}
        </div>
      )}

      {getFieldValue(`discounts.${discountName}.type`) === 'periods' && (
        <div>
          <Form.Item
            label='啟用累計時數'
            htmlFor={`discounts.${discountName}.data.enable_accumulate`}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator(
              `discounts.${discountName}.data.enable_accumulate`,
              {
                initialValue: discount.data
                  ? discount.data.enable_accumulate
                  : false,
                valuePropName: 'checked',
              }
            )(<Switch></Switch>)}
          </Form.Item>

          <Form.Item
            label='折扣時段:'
            style={{ marginBottom: 0, marginTop: 12 }}
          >
            {periodKeys.map((key, idx) => (
              <div key={key}>
                <span style={{ marginRight: 12 }}>開始時間:</span>
                {getFieldDecorator(
                  `discounts.${discountName}.periods.${key}.start_time`,
                  {
                    rules: [],
                    initialValue: moment(
                      moment().format('YYYY-MM-DD HH:mm:ss'),
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                  }
                )(<TimePicker size='large' />)}

                <span style={{ marginRight: 12, marginLeft: 12 }}>
                  結束時間:
                </span>

                {getFieldDecorator(
                  `discounts.${discountName}.periods.${key}.end_time`,
                  {
                    rules: [],
                    initialValue: moment(
                      moment().format('YYYY-MM-DD HH:mm:ss'),
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                  }
                )(<TimePicker size='large' />)}

                <span style={{ marginRight: 12, marginLeft: 12 }}>折扣率:</span>

                {getFieldDecorator(
                  `discounts.${discountName}.periods.${key}.dis_rate`,
                  {
                    rules: [],
                    initialValue: 0.5,
                  }
                )(
                  <InputNumber
                    formatter={value => `${Math.floor(value * 100)}%`}
                    parser={value => Number(value.replace('%', '')) / 100}
                  ></InputNumber>
                )}

                {
                  <Icon
                    className='dynamic-delete-button'
                    type='minus-circle-o'
                    onClick={() => handleRemovePeriod(key)}
                    style={{ marginLeft: 12 }}
                  />
                }
              </div>
            ))}

            <Button
              type='dashed'
              style={{ width: 160, marginTop: 12 }}
              onClick={handleAddPeriod}
            >
              <Icon type='plus' /> 新增時段
            </Button>
          </Form.Item>
        </div>
      )}
    </div>
  );
};

const DiscountDefines = props => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;

  const [loading, setLoading] = useState(true);

  const [periodKeys, setPeriodKeys] = useState([]);

  const [complexItems, setComplexItems] = useState([]);

  const [spaceKeys, setSpaceKeys] = useState([]);

  const [parkSpaces, setParkSpaces] = useState([]);

  const complexItemCounterRef = useRef(1);

  const conditionCountRef = useRef(1);
  /**
   * complex item shape
   * {
   *  "type": string // discount or condition or match
   *  "name": string,
   *  "content": any
   * }
   */

  const [discount, setDiscount] = useState({
    type: 'amount',
    data: {},
  });

  const initParkSpaces = parkSpaces => {
    if (!(parkSpaces instanceof Array)) {
      return;
    }

    let fieldParkSpaces = {};

    parkSpaces.forEach(s => {
      fieldParkSpaces[uuid()] = s.id;
    });

    setSpaceKeys(Object.keys(fieldParkSpaces));

    setTimeout(() => {
      setFieldsValue({
        parkSpaces: fieldParkSpaces,
      });
    }, 250);
  };

  const initParkSpaceDefines = async () => {
    let pks = await pos.parkSpace.readParkSpaces();

    if (pks.content) {
      setParkSpaces(pks.content);
    }
  };

  const removeParkSpace = key => {
    setSpaceKeys(spaceKeys.filter(_key => _key !== key));
  };

  const addParkSpace = () => {
    let key = uuid();

    setSpaceKeys([...spaceKeys, key]);
  };

  useEffect(() => {
    async function init() {
      let disId = Number(props.match.params.id);

      let _discount = {
        type: 'amount',
        data: {},
      };

      if (disId) {
        _discount = await pos.discountDefine.readDiscountDefine(disId);
      }

      initParkSpaces(_discount.park_spaces);

      console.log(_discount);

      setDiscount(_discount);

      setFieldsValue({
        ..._discount,
      });

      initParkSpaceDefines().then(() => {
        setLoading(false);
      });

      const transformPeriods = periods => {
        periods = periods.map(p => {
          return {
            start_time: moment(
              '2000-01-01 00:00:00',
              'YYYY-MM-DD HH:mm:ss'
            ).add(p.start_time, 'milliseconds'),
            end_time: moment('2000-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss').add(
              p.end_time,
              'milliseconds'
            ),
            dis_rate: p.rate,
          };
        });

        let pKeys = [];
        let periodMap = {};
        periods.forEach(p => {
          let key = uuid();
          pKeys.push(key);
          periodMap[key] = p;
        });

        return [pKeys, periodMap];
      };

      if (_discount.type === 'periods') {
        let periods = _discount.data.periods || [];

        let [pKeys, periodMap] = transformPeriods(periods);

        setPeriodKeys(pKeys);

        setTimeout(() => {
          setFieldsValue({
            periods: periodMap,
          });
        }, 500);
      } else if (_discount.type === 'complex') {
        let data = _discount.data;

        let discounts = data.discounts;

        if (!discounts) return;

        let script = data.script;

        if (!script) return;

        let tokens = script
          .replace('\n', '')
          .split(' ')
          .filter(t => t !== '');

        let _complexItems = [];

        let discountMap = {};

        let conditionMap = {};

        for (let i = 0; i < tokens.length; i++) {
          let t = tokens[i];

          if (['or', 'OR', 'and', 'AND'].includes(t)) {
            _complexItems.push({
              type: 'condition',
              name: `c${conditionCountRef.current}`,
              content: t,
            });

            conditionMap[`c${conditionCountRef.current}`] = {
              content: t,
            };

            conditionCountRef.current++;

            continue;
          }

          if (['match', 'MATCH'].includes(t)) {
            i++;
            // skip regex pattern
            let regexPatten = tokens[i];

            i++;
            let matchedDis = tokens[i];

            _complexItems.push({
              type: 'discount',
              name: `d${complexItemCounterRef.current}`,
              content: discounts[matchedDis],
            });

            discountMap[`d${complexItemCounterRef.current}`] =
              discounts[matchedDis];

            complexItemCounterRef.current++;

            continue;
          }

          _complexItems.push({
            type: 'discount',
            name: `d${complexItemCounterRef.current}`,
            content: discounts[t],
          });

          discountMap[`d${complexItemCounterRef.current}`] = discounts[t];

          complexItemCounterRef.current++;
        }

        setComplexItems(_complexItems);

        setTimeout(() => {
          setFieldsValue({
            discounts: discountMap,
            conditions: conditionMap,
          });
        }, 500);
      }
    }

    init();
  }, []);

  const addDiscount = async dis => {
    await pos.discountDefine.addDiscountDefine(dis);
  };

  const updateDiscount = async dis => {
    await pos.discountDefine.updateDiscountDefine(dis);
  };

  const handleAddPeriod = () => {
    setPeriodKeys([...periodKeys, uuid()]);
  };

  const handleRemovePeriod = _key => {
    setPeriodKeys(periodKeys.filter(key => key !== _key));
  };

  const handleAddComplexItem = () => {
    if (complexItems.length <= 0) {
      setComplexItems([
        {
          type: 'discount',
          name: `d${complexItemCounterRef.current}`,
          content: {
            data: {},
          },
        },
      ]);

      complexItemCounterRef.current++;

      return;
    }

    let lastItem = complexItems[complexItems.length - 1];

    if (lastItem.type === 'discount') {
      setComplexItems([
        ...complexItems,
        {
          type: 'condition',
          name: `c${conditionCountRef.current}`,
          content: 'or',
        },
      ]);

      conditionCountRef.current++;
    } else if (lastItem.type === 'condition') {
      setComplexItems([
        ...complexItems,
        {
          type: 'discount',
          name: `d${complexItemCounterRef.current}`,
          content: {
            data: {},
          },
        },
      ]);

      complexItemCounterRef.current++;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const mapValues = values => {
      let dis = { ...values };

      if (dis.can_mix) {
        dis.can_mix = 1;
      } else {
        dis.can_mix = 0;
      }

      if (dis.parkSpaces) {
        dis.park_space_ids = Object.values(dis.parkSpaces);

        delete dis.parkSpaces;
      }

      if (values.type === 'periods') {
        let periods = Object.keys(values.periods).map(
          key => values.periods[key]
        );

        periods = periods.map(p => {
          return {
            start_time: (p.start_time.toDate().getTime() + UTC_8) % DAY,
            end_time: (p.end_time.toDate().getTime() + UTC_8) % DAY,
            type: 'hourly_ratio',
            rate: p.dis_rate,
          };
        });

        dis.data = {
          ...dis.data,
          periods,
        };

        delete dis.periods;
      } else if (values.type === 'complex') {
        let dItems = complexItems.filter(item => item.type === 'discount');

        let disMap = {};
        dItems.forEach(item => {
          disMap[item.name] = mapValues(values.discounts[item.name]);
        });

        dis.data.discounts = disMap;

        let scriptBuilder = [];

        complexItems.forEach(item => {
          if (item.type === 'discount') {
            let d = values.discounts[item.name];

            if (d.data.use_regex_match) {
              scriptBuilder.push('match', `name:${d.data.regex}`, item.name);
            } else {
              scriptBuilder.push(item.name);
            }
          } else if (item.type === 'condition') {
            scriptBuilder.push(item.content);
          }
        });

        let script = scriptBuilder.join(' ') + ' \n';

        dis.data.script = script;

        // clean

        delete dis.discounts;
        delete dis.conditions;
      }

      return dis;
    };

    props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (!values.data) {
        values.data = {};
      }

      let dis = mapValues(values);

      if (discount.id) {
        dis = {
          ...dis,
          id: discount.id,
        };

        updateDiscount(dis)
          .then(() => {
            message.success('成功');

            props.history.push('/dashboard/system/discount-defines');
          })
          .catch(err => {
            console.error(err);
            message.error('失敗');
          });
      } else {
        addDiscount(dis)
          .then(() => {
            message.success('成功');

            props.history.push('/dashboard/system/discount-defines');
          })
          .catch(err => {
            message.error('失敗');
          });
      }
    });
  };

  const handleRemoveComplexItem = () => {
    setComplexItems(complexItems.slice(0, complexItems.length - 1));
  };

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
        minHeight: 280,
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item label='折扣名稱' htmlFor='name' style={{ marginBottom: 0 }}>
          {getFieldDecorator('name', {
            initialValue: discount.name || '',
          })(<Input></Input>)}
        </Form.Item>

        <Form.Item label='折扣代碼' htmlFor='code' style={{ marginBottom: 0 }}>
          {getFieldDecorator('code', {
            initialValue: discount.code || '',
          })(<Input></Input>)}
        </Form.Item>

        <Form.Item
          label='適用車種'
          htmlFor='data.car_type'
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('data.car_type', {
            initialValue: discount.data.car_type || 'all',
          })(
            <Select style={{ width: 300 }}>
              <Option value='all'>全部</Option>
              <Option value='C'>汽車</Option>
              <Option value='M'>機車</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label='每日僅限一次'
          htmlFor={`data.discount_once_every_day`}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`data.discount_once_every_day`, {
            initialValue: discount.data.discount_once_every_day || false,
            valuePropName: 'checked',
          })(<Switch />)}
        </Form.Item>

        <Form.Item
          label='不能與月票同時使用'
          htmlFor={`data.disable_with_rental`}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`data.disable_with_rental`, {
            initialValue: discount.data.disable_with_rental || false,
            valuePropName: 'checked',
          })(<Switch />)}
        </Form.Item>

        <Form.Item
          label='使用正則表達式對應名稱'
          htmlFor={`data.use_regex_match`}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`data.use_regex_match`, {
            initialValue: discount.data.use_regex_match || false,
            valuePropName: 'checked',
          })(<Switch />)}
        </Form.Item>

        {getFieldValue(`data.use_regex_match`) ? (
          <Form.Item
            label='正則表達式'
            htmlFor={`data.regex`}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator(`data.regex`, {
              initialValue: discount.data.regex || '',
            })(<Input />)}
          </Form.Item>
        ) : (
          <div></div>
        )}

        <Form.Item
          label='自動加入折扣(正則表達式)'
          htmlFor={`auto_regex_match_item`}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`auto_regex_match_item`, {
            initialValue: discount.auto_regex_match_item || false,
            valuePropName: 'checked',
          })(<Switch />)}
        </Form.Item>

        <Form.Item label='啟用分區管理' style={{ marginBottom: 0 }}>
          {getFieldDecorator('data.enableParkSpaceManage', {
            rules: [],
            valuePropName: 'checked',
            initialValue:
              discount.data && discount.data.enableParkSpaceManage != null
                ? discount.data.enableParkSpaceManage
                : false,
          })(<Switch />)}
        </Form.Item>

        <Form.Item label='可以混和折扣' style={{ marginBottom: 0 }}>
          {getFieldDecorator('can_mix', {
            rules: [],
            valuePropName: 'checked',
            initialValue: discount.can_mix === 1 ? true : false,
          })(<Switch />)}
        </Form.Item>

        {getFieldValue('data.enableParkSpaceManage') ? (
          <div>
            {spaceKeys.map((key, idx) => (
              <div key={key}>
                {getFieldDecorator(`parkSpaces.${key}`, {
                  rules: [],
                  initialValue: 1,
                })(
                  <Select style={{ width: 160, marginRight: 8 }}>
                    {parkSpaces.map(pk => {
                      return (
                        <Option key={pk.id} value={pk.id}>
                          {pk.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}

                {
                  <Icon
                    className='dynamic-delete-button'
                    type='minus-circle-o'
                    onClick={() => removeParkSpace(key)}
                  />
                }
              </div>
            ))}

            <Button
              type='dashed'
              style={{ width: 160, marginTop: 12 }}
              onClick={addParkSpace}
            >
              <Icon type='plus' /> 新增區域
            </Button>
          </div>
        ) : (
          <div></div>
        )}

        <Form.Item label='折扣類型' htmlFor='type' style={{ marginBottom: 0 }}>
          {getFieldDecorator('type', {
            initialValue: discount.type || 'amount',
          })(
            <Select style={{ width: 300 }}>
              <Option value='amount'>金額</Option>
              <Option value='hourly'>小時</Option>
              <Option value='periods'>時段</Option>
              <Option value='complex'>混合</Option>
              <Option value='fixed_price'>定額支付</Option>
              <Option value='all'>全部</Option>
              <Option value='rate'>rate</Option>
              <Option value='tong_yi_merchant'>統一門市優惠</Option>
              <Option value='tong_yi_zendasuite'>統一成大會館優惠</Option>
              <Option value='tong_yi_zendasuite_long_stay'>
                統一成大會館長住優惠
              </Option>
              <Option value='tong_yi_ntudorm'>統一太子學舍優惠</Option>
              <Option value='tong_yi_ntudorm_long_stay'>
                統一太子學舍長住優惠
              </Option>
              <Option value='taipei_electric_moto'>北市府電動機車</Option>
              <Option value='special_lovely_car'>特製身障機車</Option>
              <Option value='daily_stack_dis'>累計計費折扣</Option>
            </Select>
          )}
        </Form.Item>

        {getFieldValue('type') === 'amount' && (
          <div>
            <Form.Item
              label='預設折扣金額'
              htmlFor='data.default_amount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.default_amount', {
                initialValue: discount.data
                  ? discount.data.default_amount || 50
                  : 50,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='固定折扣金額'
              htmlFor={`data.static`}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator(`data.static`, {
                initialValue:
                  typeof discount.data.static === 'boolean'
                    ? discount.data.static
                    : true,
                valuePropName: 'checked',
              })(<Switch />)}
            </Form.Item>

            <Form.Item
              label='最小折扣金額'
              htmlFor='data.min_amount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.min_amount', {
                initialValue: discount.data
                  ? discount.data.min_amount || 50
                  : 50,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='最大折扣金額'
              htmlFor='data.max_amount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.max_amount', {
                initialValue: discount.data
                  ? discount.data.max_amount || 50
                  : 50,
              })(<InputNumber></InputNumber>)}
            </Form.Item>
          </div>
        )}

        {getFieldValue('type') === 'hourly' && (
          <div>
            <Form.Item
              label='預設折扣時數'
              htmlFor='data.default_hours'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.default_hours', {
                initialValue: discount.data
                  ? discount.data.default_hours || 4
                  : 4,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='最小折扣時數'
              htmlFor='data.min_hours'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.min_hours', {
                initialValue: discount.data
                  ? discount.data.min_hours || 0.5
                  : 0.5,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='最大折扣時數'
              htmlFor='data.max_hours'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.max_hours', {
                initialValue: discount.data
                  ? discount.data.max_hours || 999
                  : 999,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='啟用折扣N小時候折價'
              htmlFor='data.enable_after_hours_discount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.enable_after_hours_discount', {
                initialValue: discount.data
                  ? discount.data.enable_after_hours_discount
                  : false,
              })(<Switch></Switch>)}
            </Form.Item>

            {getFieldValue('data.enable_after_hours_discount') ? (
              <Form.Item
                label='N小時後折價率(%)'
                htmlFor='data.after_hours_discount_rate'
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('data.after_hours_discount_rate', {
                  initialValue:
                    discount.data && discount.data.after_hours_discount_rate
                      ? discount.data.after_hours_discount_rate
                      : 0.5,
                })(
                  <InputNumber
                    formatter={value => `${Math.floor(value * 100)}%`}
                    parser={value => Number(value.replace('%', '')) / 100}
                  ></InputNumber>
                )}
              </Form.Item>
            ) : (
              <div />
            )}
          </div>
        )}

        {getFieldValue('type') === 'periods' && (
          <div>
            <Form.Item
              label='啟用累計時數'
              htmlFor='data.enable_accumulate'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.enable_accumulate', {
                initialValue: discount.data
                  ? discount.data.enable_accumulate
                  : false,
              })(<Switch></Switch>)}
            </Form.Item>

            <Form.Item
              label='折扣時段:'
              style={{ marginBottom: 0, marginTop: 12 }}
            >
              {periodKeys.map((key, idx) => (
                <div key={key}>
                  <span style={{ marginRight: 12 }}>開始時間:</span>
                  {getFieldDecorator(`periods.${key}.start_time`, {
                    rules: [],
                    initialValue: moment(
                      moment().format('YYYY-MM-DD HH:mm:ss'),
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                  })(<TimePicker size='large' />)}

                  <span style={{ marginRight: 12, marginLeft: 12 }}>
                    結束時間:
                  </span>

                  {getFieldDecorator(`periods.${key}.end_time`, {
                    rules: [],
                    initialValue: moment(
                      moment().format('YYYY-MM-DD HH:mm:ss'),
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                  })(<TimePicker size='large' />)}

                  <span style={{ marginRight: 12, marginLeft: 12 }}>
                    折扣率:
                  </span>

                  {getFieldDecorator(`periods.${key}.dis_rate`, {
                    rules: [],
                    initialValue: 0.5,
                  })(
                    <InputNumber
                      formatter={value => `${Math.floor(value * 100)}%`}
                      parser={value => Number(value.replace('%', '')) / 100}
                    ></InputNumber>
                  )}

                  {
                    <Icon
                      className='dynamic-delete-button'
                      type='minus-circle-o'
                      onClick={() => handleRemovePeriod(key)}
                      style={{ marginLeft: 12 }}
                    />
                  }
                </div>
              ))}

              <Button
                type='dashed'
                style={{ width: 160, marginTop: 12 }}
                onClick={handleAddPeriod}
              >
                <Icon type='plus' /> 新增時段
              </Button>
            </Form.Item>
          </div>
        )}

        {getFieldValue('type') === 'complex' && (
          <div>
            <Form.Item
              label='自定義:'
              style={{ marginBottom: 0, marginTop: 12 }}
            >
              {
                /**
                 * complex item shape
                 * {
                 *  "type": string // discount or condition or match
                 *  "name": string,
                 *  "content": any
                 * }
                 */
                complexItems.map((item, idx) => {
                  if (item.type === 'discount') {
                    return (
                      <div
                        key={'complex-item-' + idx}
                        style={{
                          padding: '8px 0px',
                        }}
                      >
                        <div
                          style={{
                            background: '#ccc',
                            padding: '12px 12px',
                            borderRadius: 6,
                          }}
                        >
                          <Row>
                            <Col sm={18}>
                              <ComplexDiscountItem
                                discountName={item.name}
                                discount={item.content}
                                form={props.form}
                              ></ComplexDiscountItem>
                            </Col>

                            {idx === complexItems.length - 1 ? (
                              <Col sm={6}>
                                <Icon
                                  className='dynamic-delete-button'
                                  type='minus-circle-o'
                                  style={{
                                    fontSize: 80,
                                  }}
                                  onClick={handleRemoveComplexItem}
                                />
                              </Col>
                            ) : (
                              <div></div>
                            )}
                          </Row>
                        </div>
                      </div>
                    );
                  } else if (item.type === 'condition') {
                    return (
                      <div
                        key={'complex-item-' + idx}
                        style={{
                          padding: '8px 0px',
                        }}
                      >
                        <div
                          style={{
                            background: '#ccc',
                            padding: '12px 12px',
                            borderRadius: 6,
                          }}
                        >
                          <Row>
                            <Col sm={18}>
                              {getFieldDecorator(
                                `conditions.${item.name}.content`,
                                {
                                  rules: [],
                                  initialValue: 'or',
                                }
                              )(
                                <Select value={item.content}>
                                  <Option value='or'>擇一</Option>
                                  <Option value='and'>並用</Option>
                                </Select>
                              )}
                            </Col>

                            {idx === complexItems.length - 1 ? (
                              <Col sm={6}>
                                <Icon
                                  className='dynamic-delete-button'
                                  type='minus-circle-o'
                                  style={{
                                    fontSize: 24,
                                  }}
                                  onClick={handleRemoveComplexItem}
                                />
                              </Col>
                            ) : (
                              <div></div>
                            )}
                          </Row>
                        </div>
                      </div>
                    );
                  }
                })
              }

              <Button
                type='dashed'
                style={{ width: 160, marginTop: 12 }}
                onClick={handleAddComplexItem}
              >
                <Icon type='plus' />{' '}
                {complexItems.length <= 0 ? (
                  <span>新增折扣</span>
                ) : complexItems[complexItems.length - 1].type ===
                  'discount' ? (
                  <span>新增邏輯</span>
                ) : complexItems[complexItems.length - 1].type ===
                  'condition' ? (
                  <span>新增折扣</span>
                ) : (
                  <div></div>
                )}
              </Button>
            </Form.Item>
          </div>
        )}

        {getFieldValue('type') === 'fixed_price' && (
          <div>
            <Form.Item
              label='預設支付金額'
              htmlFor='data.default_amount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.default_amount', {
                initialValue: discount.data
                  ? discount.data.default_amount || 50
                  : 50,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='固定支付金額'
              htmlFor={`data.static`}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator(`data.static`, {
                initialValue:
                  typeof discount.data.static === 'boolean'
                    ? discount.data.static
                    : true,
                valuePropName: 'checked',
              })(<Switch />)}
            </Form.Item>

            <Form.Item
              label='最小支付金額'
              htmlFor='data.min_amount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.min_amount', {
                initialValue: discount.data
                  ? discount.data.min_amount || 50
                  : 50,
              })(<InputNumber></InputNumber>)}
            </Form.Item>

            <Form.Item
              label='最大支付金額'
              htmlFor='data.max_amount'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.max_amount', {
                initialValue: discount.data
                  ? discount.data.max_amount || 50
                  : 50,
              })(<InputNumber></InputNumber>)}
            </Form.Item>
          </div>
        )}

        {getFieldValue('type') === 'daily_stack_dis' && (
          <div>
            <Form.Item
              label='預設折扣金額'
              htmlFor='data.rule'
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator('data.rule', {
                initialValue: discount.data.rule || 'negibor',
              })(
                <Select style={{ width: 300 }}>
                  <Option value='negibor'>鄰近里優惠</Option>
                  <Option value='local'>所在里優惠</Option>
                  <Option value='lovely'>愛心優惠</Option>
                </Select>
              )}
            </Form.Item>
          </div>
        )}

        <Form.Item>
          <Button type='primary' htmlType='submit' style={{ width: 120 }}>
            提交
          </Button>
        </Form.Item>

        <Form.Item>
          <Link to='/dashboard/system/discount-defines'>
            <Button type='danger' style={{ width: 120 }}>
              取消
            </Button>
          </Link>
        </Form.Item>
      </Form>
    </Content>
  );
};

export default Form.create({
  name: 'discount-define-form',
})(DiscountDefines);
