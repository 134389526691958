import { Button, Layout, Table } from 'antd';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import pos from 'pos-api';

const { Content } = Layout;

/**
 * 2024-05-17 ADD
 * TONG_YI_MERCHANT: 'tong_yi_merchant',
    TONG_YI_ZENDASUITE: 'tong_yi_zendasuite',
    TONG_YI_ZENDASUITE_LONG_STAY: 'tong_yi_zendasuite_long_stay',
    TONG_YI_NTUDORM: 'tong_yi_ntudorm',
    TONG_YI_NTUDORM_LONG_STAY: 'tong_yi_ntudorm_long_stay',
 */
const typeMap = {
  amount: '金額',
  hourly: '小時',
  periods: '時段',
  complex: '混合',
  all: '全部',
  fixed_price: '定額支付',
  rate: 'RATE',
  tong_yi_merchant: '統一門市優惠',
  tong_yi_zendasuite: '統一成大會館優惠',
  tong_yi_zendasuite_long_stay: '統一成大會館長住優惠',
  tong_yi_ntudorm: '統一太子學舍優惠',
  tong_yi_ntudorm_long_stay: '統一太子學舍長住優惠',
  taipei_electric_moto: '北市府電動機車',
  special_lovely_car: '特製身障機車',
  daily_stack_dis: '累進計價優惠',
};

const columns = [
  {
    title: '名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '類型',
    dataIndex: 'type',
    key: 'type',
    render: type => {
      return typeMap[type];
    },
  },
  {
    title: '代碼',
    dataIndex: 'code',
    key: 'code',
    render: code => {
      return code;
    },
  },
  {
    title: '內容',
    dataIndex: 'data',
    key: 'data',
    render: (data, record) => {
      if (record.type === 'all') {
        return '全部折抵';
      } else if (record.type === 'hourly') {
        let messages = [];

        if (data.default_hours != null) {
          messages.push(`預設折抵: ${data.default_hours}小時`);
        }

        if (data.max_hours != null) {
          messages.push(`最高折抵: ${data.max_hours}小時`);
        }

        if (data.enable_after_hours_discount) {
          let rate = data.after_hours_discount_rate;
          if (rate == null) {
            rate = 0.5;
          }

          rate = Math.floor(rate * 100);
          if (rate % 10 === 0) {
            rate /= 10;
          }

          messages.push(`折抵N小時後，打${rate}折`);
        }

        return messages.join(', ');
      } else if (record.type === 'amount') {
        let messages = ['折抵N元'];

        if (data.default_amount) {
          messages.push(`預設折抵: ${data.default_amount}元`);
        }

        return messages.join(',');
      } else if (record.type === 'periods') {
        return '時段';
      } else if (record.type === 'complex') {
        return '混合';
      } else if (record.type === 'fixed_price') {
        let messages = ['支付N元'];

        if (data.default_amount) {
          messages.push(`預設支付: ${data.default_amount}元`);
        }

        return messages.join(',');
      } else if (record.type === 'taipei_electric_moto') {
        return '前兩天全額折抵，兩天後正常收費';
      } else if (record.type === 'special_lovely_car') {
        return '當日全額折抵，隔日半價';
      } else if (record.type === 'daily_stack_dis') {
        return '累進計價優惠';
      }

      return '';
    },
  },
  {
    title: '動作',
    dataIndex: 'action',
    key: 'action',
    render: (act, record) => {
      return (
        <span>
          <Link to={`/dashboard/system/discount-define/${record.id}`}>
            <Button type='link' icon='edit'></Button>
          </Link>
        </span>
      );
    },
  },
];

const DiscountDefines = props => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 100,
  });

  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    async function init() {
      let resp = await pos.discountDefine.readDiscountDefines({
        page: pagination.current - 1,
        pageSize: pagination.pageSize,
      });

      setDiscounts(resp.content);

      setPagination({
        current: pagination.current,
        pageSize: Number(resp.pagination.page_size),
        total: resp.pagination.total_elements,
      });
    }

    init();
  }, [pagination.current]);

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  return (
    <div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <Link to='/dashboard/system/discount-define/0'>
            <Button type='link' icon='plus'>
              新增折扣
            </Button>
          </Link>
        </Content>
      </div>

      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        <Table
          columns={columns}
          dataSource={discounts}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Content>
    </div>
  );
};

export default DiscountDefines;
