import {
  Button,
  Divider,
  Icon,
  Input,
  Layout,
  Modal,
  Select,
  Table,
  Typography,
  Upload,
  message,
} from 'antd';
import pos from 'pos-api';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import routes from '../../../Routes';
import { rentalUserMapper } from '../../../utils/mapper';
// import * as utils from '../../../utils/utils';
// import { Drawer, Form, Input } from 'antd';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const { confirm } = Modal;

const columns = [
  {
    title: '票種',
    dataIndex: 'rental_service',
    key: 'rental_service',
    render: (_, record) => {
      return record.rentalService?.name ?? '';
    },
  },
  {
    title: '群組',
    dataIndex: 'rentalGroup',
    key: 'rentalGroup',
    render: (_, record) => {
      return record.rentalGroup?.name ?? '';
    },
  },
  {
    title: '部門',
    dataIndex: 'dept',
    key: 'dept',
    render: (_, record) => {
      return record.dept?.name ?? '';
    },
  },
];

class RentalUsers extends React.Component {
  state = {
    pagination: {
      pageSize: 12,
      current: 1,
    },
    loading: true,
    members: null,
    searchType: 'plate',
    importModal: {
      display: false,
    },
    privilege: 'r',
    rentalQty: {
      isEnable: false,
      totalQtyCar: 0,
      totalQtyMoto: 0,
      nameCar: '',
      nameMoto: '',
      soldQtyCar: 0,
      soldQtyMoto: 0,
    },
    serviceId: -1,
    services: [
      {
        id: -1,
        text: '全部',
      },
    ],
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchUsers({
      pagination: pager,
    });
  };

  fetchUsers = async (opts = {}) => {
    this.setState({
      loading: true,
    });

    if (!opts.pagination) {
      opts.pagination = this.state.pagination;
    }

    let result = await pos.rentalUser.fetchRentalUsers({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
      searchBy: this.searchBy,
      searchText: this.searchText,
      serviceId: this.state.serviceId > 0 ? this.state.serviceId : null,
      identityType: 'plate',
    });

    let pagination = result.pagination;
    let members = result.content;
    members = rentalUserMapper.toView(members);
    members.forEach((rentalUser, idx) => {
      rentalUser.key = rentalUser.id;

      if (rentalUser.identities && rentalUser.identities.length > 0) {
        let idts = rentalUser.identities;
        idts = idts.filter(idt => {
          return idt.type === 'plate_car' || idt.type === 'plate_moto';
        });

        if (idts.length > 0) {
          const plate = idts.map(idt => idt.content).join(', ');
          rentalUser.plate = plate;
        }
      }

      if (!rentalUser.plate) {
        rentalUser.plate = '無資料';
      }

      rentalUser.onEdit = this.onEditBtn(rentalUser);
      rentalUser.onDelete = this.onDeleteBtn(rentalUser);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      members: members,
      pagination: pager,
      loading: false,
    });
  };

  onAddBtn = () => {
    let path = `${routes.rentalUserForm.layout}${routes.rentalUserForm.path}`;
    path = generatePath(path, { id: 0 });
    this.props.history.push(path);
  };

  onEditBtn = rentalUser => () => {
    let path = `${routes.rentalUserForm.layout}${routes.rentalUserForm.path}`;
    path = generatePath(path, { id: rentalUser.id });
    this.props.history.push(path);
  };

  deleteUser = async rentalUser => {
    await pos.rentalUser.deleteRentalUser(rentalUser.id);
  };

  onDeleteBtn = rentalUser => () => {
    let that = this;
    confirm({
      title: `刪除用戶[${rentalUser.name}]?`,
      content: ``,
      okText: '確定',
      cancelText: '取消',
      onOk() {
        let hide = message.loading('處理中...');
        that
          .deleteUser(rentalUser)
          .then(() => {
            hide();
            message.info('刪除成功');
            that.setState(prev => {
              return {
                members: prev.members.filter(c => c !== rentalUser),
              };
            });
          })
          .catch(err => {
            hide();
            message.error('刪除失敗');
          });
      },
      onCancel() {},
    });
  };

  onSearch = text => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.searchBy = this.state.searchType;
      if (this.searchBy === 'plate') {
        this.searchBy = 'identity';
      }

      if (text === '') {
        this.searchBy = null;
      }

      this.searchText = text;

      this.fetchUsers();
    }, 250);
  };

  getColumns = () => {
    let nameColumn = {
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
    };

    const Render = text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.searchText || '']}
        autoEscape
        textToHighlight={text.toString()}
      />
    );

    let plateColumn = {
      title: '車號',
      dataIndex: 'plate',
      key: 'plate',
    };

    if (this.state.searchType === 'plate') {
      plateColumn.render = Render;
    } else if (this.state.searchType === 'name') {
      nameColumn.render = Render;
    }

    let opColumn = {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <span>
          {this.state.privilege.includes('u') ? (
            <Button type='link' icon='edit' onClick={record.onEdit}></Button>
          ) : (
            <Button type='link' icon='eye' onClick={record.onEdit}></Button>
          )}
          {this.state.privilege.includes('d') ? (
            <span>
              <Divider type='vertical' />
              <Button
                type='link'
                icon='delete'
                onClick={record.onDelete}
              ></Button>
            </span>
          ) : (
            <span></span>
          )}
        </span>
      ),
    };

    return [nameColumn, plateColumn, ...columns, opColumn];
  };

  onSearchTypeChange = type => {
    this.setState({
      searchType: type,
    });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.searchBy = this.state.searchType;
      if (this.searchBy === 'plate') {
        this.searchBy = 'identity';
      }

      if (this.searchText === '' || this.searchText == null) {
        this.searchBy = null;
      }

      this.fetchUsers();
    }, 150);
  };

  onServiceChange = serviceId => {
    this.setState({
      serviceId: serviceId,
    });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.fetchUsers();
    }, 150);
  };

  componentDidMount() {
    // START rentalQty
    const initRentalQty = async () => {
      try {
        let system = await pos.system.fetchSystem();
        const {
          MAXIMUM_CAR_RENTAL = 0, // 汽車月租的數量上限
          MAXIMUM_MOTO_RENTAL = 0, // 機車月租的數量上限
          CAR_RENTAL_NAME = '', // 月租票種 汽車
          MOTO_RENTAL_NAME = '', // 月租票種 機車
          ENABLE = false, // 是否啟用該功能 (汽機車的月租上限數量)
        } = system.CHECK_RENTAL_USER_NUMER || {};

        let rentalNumbers = await pos.service.fetchServiceRentalNumbers();
        const { car_rental_user_number = 0, moto_rental_user_number = 0 } =
          rentalNumbers || {};

        return {
          rentalQty: {
            isEnable: ENABLE,
            totalQtyCar: MAXIMUM_CAR_RENTAL,
            totalQtyMoto: MAXIMUM_MOTO_RENTAL,
            nameCar: CAR_RENTAL_NAME,
            nameMoto: MOTO_RENTAL_NAME,
            soldQtyCar: car_rental_user_number,
            soldQtyMoto: moto_rental_user_number,
          },
        };
      } catch (error) {
        console.error('An error occurred:', error);
        return {
          rentalQty: {
            isEnable: false,
            totalQtyCar: 0,
            totalQtyMoto: 0,
            nameCar: '',
            nameMoto: '',
            soldQtyCar: 0,
            soldQtyMoto: 0,
          },
        };
      }
    };
    // END rentalQty

    this.fetchUsers()
      .then(() => {
        return initRentalQty();
      })
      .then(rentalQtyResult => {
        const { rentalQty } = rentalQtyResult;

        this.setState({
          privilege: this.props.userRoutePrivilege,
          rentalQty:
            typeof rentalQty !== 'undefined' ? rentalQty : this.state.rentalQty,
        });
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });

    pos.service
      .fetchServices({
        pageSize: 500,
      })
      .then(services => {
        let s = services.content
          .filter(s => s.type === 'park_rental')
          .map(s => {
            return {
              id: s.id,
              text: s.name,
            };
          });

        this.setState({
          services: [
            {
              id: -1,
              text: '全部',
            },
            ...s,
          ],
        });
      });
  }

  onImport = async file => {
    let hide = message.loading('處理中...');
    try {
      await pos.rentalUser.importRentalUsers(file);
    } catch (err) {
      hide();
      console.log(err);
      message.error('處理失敗');
      return;
    }

    hide();
    message.success('處理成功');
  };

  download = (filename, text) => {
    //text = text.replace(/\n/g, '\r\n');
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  onExport = async file => {
    let hide = message.loading('處理中...');
    try {
      let resp = await fetch(
        `/api/v1/rental-user/export?authorization=${this.props.user.token}`
      );
      let data = await resp.text();
      this.download('月租名單.csv', data);
    } catch (err) {
      hide();
      console.log(err);
      message.error('處理失敗');
      return;
    }

    hide();
    message.success('處理成功');
  };

  render() {
    const totalColumns = this.getColumns();

    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          <div
            style={{
              // 左
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {this.state.privilege.includes('c') ? (
              <div style={{}}>
                <Button type='link' icon='plus' onClick={this.onAddBtn}>
                  新增用戶
                </Button>

                <div style={{ display: 'inline-block' }}>
                  <Upload
                    {...{
                      accept: '.csv',
                      beforeUpload: file => {
                        console.log(file);
                        this.onImport(file);

                        return false;
                      },
                      fileList: [],
                    }}
                  >
                    <Button type='link'>
                      <Icon type='import' /> 導入用戶
                    </Button>
                  </Upload>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <Button type='link' icon='export' onClick={this.onExport}>
              導出用戶
            </Button>
          </div>

          <div
            style={{
              // 右
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>票種:</span>

            <Select
              defaultValue={-1}
              onChange={this.onServiceChange}
              style={{ marginLeft: 8, width: 150 }}
            >
              {this.state.services.map(s => {
                return <Option value={s.id}>{s.text}</Option>;
              })}
            </Select>

            <Select
              defaultValue='plate'
              onChange={this.onSearchTypeChange}
              style={{ marginLeft: 8, width: 120 }}
            >
              <Option value='name'>名稱搜尋</Option>
              <Option value='plate'>車牌搜尋</Option>
            </Select>

            <Search
              placeholder={
                this.state.searchType === 'name'
                  ? '請輸入會員名稱'
                  : '請輸入車牌號碼'
              }
              onSearch={this.onSearch}
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.onSearch(value);
              }}
              style={{ width: 200, float: 'right', marginLeft: 14 }}
            />
          </div>
        </Content>

        {
          // 啟用才會顯示
          this.state.rentalQty.isEnable ? (
            <Content
              style={{
                margin: '12px 0px 16px 0px',
                padding: '24px',
                background: '#fff',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  margin: '0 0.5rem',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '0.5rem',
                  justifyItems: 'start',
                  width: '50%',
                }}
              >
                <div style={{ fontWeight: 'bold' }}>票種</div>
                <div style={{ fontWeight: 'bold' }}>售出的月租數量</div>
                <div style={{ fontWeight: 'bold' }}>月租數量上限</div>
                <div> {this.state.rentalQty.nameCar} </div>
                <div> {this.state.rentalQty.soldQtyCar} </div>
                <div> {this.state.rentalQty.totalQtyCar} </div>
                <div> {this.state.rentalQty.nameMoto} </div>
                <div> {this.state.rentalQty.soldQtyMoto} </div>
                <div> {this.state.rentalQty.totalQtyMoto} </div>
              </div>
            </Content>
          ) : null
        }

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={totalColumns}
            dataSource={this.state.members}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>

        <div>
          <Modal
            visible={this.state.importModal.display}
            title='導入'
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key='back'>Return</Button>,
              <Button key='submit' type='primary'>
                Submit
              </Button>,
            ]}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal>
        </div>
      </Content>
    );
  }
}

function mapProps(s) {
  return {
    user: s.user,
    route: s.route,
  };
}

export default connect(mapProps)(RentalUsers);
