import React, { useRef } from 'react';

import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import {
  Layout,
  Button,
  TimePicker,
  Form,
  Typography,
  Checkbox,
  Icon,
} from 'antd';

import moment from 'moment';

import uuid from 'uuid/v4';

const { Content } = Layout;

const { Text, Title } = Typography;

const fakeRegulations = {
  car_in: [
    {
      cars: [
        {
          membership: ['rental', 'temp'],
          type: 'car',
        },
        {
          membership: ['rental', 'temp'],
          type: 'truck',
        },
      ],
      start: '08:00',
      end: '12:00',
    },
  ],
  car_out: [],
};

const SettingForm = Form.create()(props => {
  const { system } = props;

  const { IOSERVER } = system;

  const { gateways, form } = props;

  const { getFieldDecorator } = form;

  const [selectedGateway, setSelectedGateway] = useState(null);

  const [regulationKeys, setRegulationKeys] = useState([]);

  const [regulationMap, setRegulationMap] = useState({});

  const updatedRef = useRef(false);

  useEffect(() => {
    if (gateways.length <= 0) return;

    setSelectedGateway(gateways[0]);
  }, [gateways]);

  useEffect(() => {
    if (!selectedGateway) return;

    (async () => {
      let regulation = await fetch(
        `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/gateways/${selectedGateway.id}/open_regulations`
      );

      let data = await regulation.json();
      console.log(data);

      let keys = [];
      let regulationMap = {};
      data.forEach(r => {
        let key = uuid();

        keys.push(key);
        regulationMap[key] = {
          start: r.start,
          end: r.end,
          cars: r.cars.reduce((prev, current) => {
            prev[current.type] = {
              temp: current.membership.includes('temp'),
              rental: current.membership.includes('rental'),
            };
            return prev;
          }, {}),
        };
      });

      console.log(regulationMap);

      setRegulationMap(regulationMap);
      setRegulationKeys(keys);
    })();
  }, [selectedGateway]);

  const addRegulation = evt => {
    let newKey = uuid();

    setRegulationMap(prev => {
      let next = { ...prev };

      next[newKey] = {
        start: '08:00',
        end: '20:00',
        cars: {
          car: {
            temp: false,
            rental: false,
          },
          moto: {
            temp: false,
            rental: false,
          },
          truck: {
            temp: false,
            rental: false,
          },
        },
      };

      return next;
    });

    setRegulationKeys(prev => {
      let next = [...prev];

      next.push(newKey);

      return next;
    });
  };

  const removeRegulation = key => {
    setRegulationMap(prev => {
      let next = { ...prev };

      delete next[key];

      return next;
    });

    setRegulationKeys(prev => {
      let next = [...prev];

      return next.filter(r => r !== key);
    });
  };

  const handleUpdate = async data => {
    let regs = regulationKeys.map(key => {
      let { start, end, cars } = data[key];

      let reg = {
        start: start.format('HH:mm'),
        end: end.format('HH:mm'),
        cars: [
          {
            type: 'car',
            membership: Object.keys(cars.car)
              .filter(carKey => cars.car[carKey])
              .map(carKey => {
                return carKey;
              }),
          },
          {
            type: 'moto',
            membership: Object.keys(cars.moto)
              .filter(carKey => cars.moto[carKey])
              .map(carKey => {
                return carKey;
              }),
          },
          {
            type: 'truck',
            membership: Object.keys(cars.truck)
              .filter(carKey => cars.truck[carKey])
              .map(carKey => {
                return carKey;
              }),
          },
        ],
      };

      return reg;
    });

    console.log(regs);

    try {
      await fetch(
        `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/gateways/${selectedGateway.id}/open_regulations`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(regs),
        }
      );
    } catch (err) {
      console.error(err);
    } finally {
      updatedRef.current = false;
    }
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        handleUpdate(values);
      } else {
        console.log(err);
      }
    });
  };

  if (!selectedGateway) {
    return <div></div>;
  }

  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '100px 200px' }}>
        {gateways.map(g => {
          return (
            <div key={g.id}>
              <Button
                onClick={() => {
                  if (selectedGateway === g) return;

                  if (updatedRef.current) {
                    alert('請先儲存資料');

                    return;
                  }

                  setSelectedGateway(g);
                }}
              >
                {g.name}
              </Button>
            </div>
          );
        })}
      </div>

      <div
        style={{
          marginTop: 8,
        }}
      >
        <Title level={4}>
          已選閘道: {selectedGateway && selectedGateway.name}
        </Title>
      </div>

      <div
        style={{
          marginTop: '24px',
        }}
        onClick={() => {
          updatedRef.current = true;
        }}
      >
        {regulationKeys.map(key => {
          let regulation = regulationMap[key];

          if (!regulation || !regulation.cars) return <div key={key}></div>;

          return (
            <div
              key={key}
              style={{
                display: 'flex',
                border: '1px solid gray',
                padding: '8px 8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyItems: 'center',
                }}
              >
                <Form.Item label='開始時間'>
                  {getFieldDecorator(`${key}.start`, {
                    rules: [{ required: true, message: '' }],
                    initialValue: moment(regulation.start, 'HH:mm'),
                  })(<TimePicker format={'HHmm'} size='large'></TimePicker>)}
                </Form.Item>

                <Form.Item label='結束時間'>
                  {getFieldDecorator(`${key}.end`, {
                    rules: [{ required: true, message: '' }],
                    initialValue: moment(regulation.end, 'HH:mm'),
                  })(<TimePicker format={'HHmm'} size='large'></TimePicker>)}
                </Form.Item>

                <div
                  style={{
                    marginLeft: '8px',
                    display: 'flex',
                  }}
                >
                  <div>
                    <Title level={4}>汽車</Title>

                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form.Item label='臨停'>
                        {getFieldDecorator(`${key}.cars.car.temp`, {
                          valuePropName: 'checked',
                          initialValue: regulation.cars.car.temp,
                        })(<Checkbox size='large'></Checkbox>)}
                      </Form.Item>

                      <Form.Item label='月租'>
                        {getFieldDecorator(`${key}.cars.car.rental`, {
                          valuePropName: 'checked',
                          initialValue: regulation.cars.car.rental,
                        })(<Checkbox size='large'></Checkbox>)}
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <Title level={4}>機車</Title>

                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form.Item label='臨停'>
                        {getFieldDecorator(`${key}.cars.moto.temp`, {
                          valuePropName: 'checked',
                          initialValue: regulation.cars.moto.temp,
                        })(<Checkbox size='large'></Checkbox>)}
                      </Form.Item>

                      <Form.Item label='月租'>
                        {getFieldDecorator(`${key}.cars.moto.rental`, {
                          valuePropName: 'checked',
                          initialValue: regulation.cars.moto.rental,
                        })(<Checkbox size='large'></Checkbox>)}
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <Title level={4}>大貨車</Title>

                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form.Item label='臨停'>
                        {getFieldDecorator(`${key}.cars.truck.temp`, {
                          valuePropName: 'checked',
                          initialValue: regulation.cars.truck.temp,
                        })(<Checkbox size='large'></Checkbox>)}
                      </Form.Item>

                      <Form.Item label='月租'>
                        {getFieldDecorator(`${key}.cars.truck.rental`, {
                          valuePropName: 'checked',
                          initialValue: regulation.cars.truck.rental,
                        })(<Checkbox size='large'></Checkbox>)}
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    onClick={() => removeRegulation(key)}
                    type='danger'
                    icon='delete'
                  ></Button>
                </div>
              </div>
            </div>
          );
        })}

        <div>
          <Button
            onClick={addRegulation}
            style={{
              width: '100%',
            }}
          >
            新增
          </Button>

          <Button
            type='primary'
            onClick={evt => {
              evt.stopPropagation();

              handleSubmit();
            }}
            style={{
              width: '100%',
              marginTop: '8px',
            }}
          >
            提交
          </Button>
        </div>
      </div>
    </div>
  );
});

function GatewaySetting(props) {
  const { system } = props;

  const { IOSERVER } = system;

  const [gateways, setGateways] = useState([]);

  useEffect(() => {
    (async () => {
      let resp = await fetch(
        `http://${IOSERVER.HOST}:${IOSERVER.PORT}/api/v1/gateways`
      );

      let data = await resp.json();

      setGateways(data);
    })();
  }, []);

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        background: '#fff',
        padding: '12px 24px',
        minHeight: 280,
      }}
    >
      <SettingForm gateways={gateways} system={system} />
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    system: state.system,
  };
};

export default connect(mapStateToProps)(GatewaySetting);
