import React, { useState, useEffect, useRef } from 'react';
import {
  Layout,
  Pagination,
  Button,
  Typography,
  Select,
  Avatar,
  Spin,
  List,
  Input,
  Tabs,
  Form,
  message,
  Drawer,
  DatePicker,
  TimePicker,
  Switch,
  Modal,
  Divider,
  InputNumber,
} from 'antd';
import moment from 'moment';
import pos from 'pos-api';

import Highlighter from 'react-highlight-words';

import * as utils from '../../../utils/utils';
import stomp from '../../../utils/stomp';

import { itemMapper } from '../../../utils/mapper';

import Viewer from 'react-viewer';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import { useCallback } from 'react';

import DiscountForm from '../../../component/DiscountForm';

import './CarWithDiscount.less';

const { Content, Sider } = Layout;
const { Paragraph, Title } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

const CarDiscount = props => {
  const [discountDefines, setDiscountDefines] = useState([]);
  const [currentDefine, setCurrentDefine] = useState({});

  const { getFieldDecorator, getFieldValue } = props.form;

  useEffect(() => {
    async function init() {
      try {
        let ds = await pos.discountDefine.readDiscountDefines({
          pageSize: 100,
        });

        if (!ds.content) {
          return;
        }

        setDiscountDefines(ds.content);

        if (ds.content.length > 0) {
          setCurrentDefine(ds.content[0]);
        }
      } catch (err) {
        console.warn(err);
      }
    }

    init();
  }, []);

  if (discountDefines.length === 0) {
    return <Spin></Spin>;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
  };

  const handleCurrentDefineChange = defineId => {
    defineId = Number(defineId);

    let nextDefine = null;

    for (let i = 0; i < discountDefines.length; i++) {
      if (defineId === discountDefines[i].id) {
        nextDefine = discountDefines[i];
        break;
      }
    }

    if (nextDefine) {
      setCurrentDefine(nextDefine);
    }
  };

  return (
    <Modal
      title={<Title level={3}>優惠折扣</Title>}
      className='discount-dialog'
      style={{ top: 80 }}
      visible={props.visible}
      okText='確定'
      cancelText='取消'
      onOk={() => {
        props.form.validateFields((err, values) => {
          if (!err) {
            let defineId = Number(values.define_id);

            for (let i = 0; i < discountDefines.length; i++) {
              if (discountDefines[i].id === defineId) {
                values.define = discountDefines[i];
                break;
              }
            }
          }

          if (
            currentDefine &&
            currentDefine.type === 'hourly' &&
            currentDefine.data &&
            currentDefine.data.static
          ) {
            values.hours =
              currentDefine &&
              currentDefine.data &&
              currentDefine.data.default_hours
                ? currentDefine.data.default_hours
                : 1;
          }

          if (
            currentDefine &&
            currentDefine.type === 'amount' &&
            currentDefine.data &&
            currentDefine.data.static
          ) {
            values.amount =
              currentDefine &&
              currentDefine.data &&
              currentDefine.data.default_amount
                ? currentDefine.data.default_amount
                : 50;
          }

          if (!values.define) {
            err = 'no define value';
          }
          props.onOk && props.onOk(err, values);
        });
      }}
      onCancel={() => {
        props.onCancel && props.onCancel();
      }}
      width={'100wv'}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 2 }}>
          <CarField car={props.car}></CarField>
        </div>
        <div style={{ flex: 3 }}>
          <Form {...formItemLayout} className='form'>
            <div
              style={{
                padding: 20,
                border: '0.6px solid #aaa',
                borderRadius: 5,
              }}
            >
              <Form.Item htmlFor='define_id' label='優惠方式'>
                {getFieldDecorator('define_id', {
                  initialValue: `${discountDefines[0].id}`,
                })(
                  <Select
                    size='large'
                    style={{ width: 180 }}
                    onChange={handleCurrentDefineChange}
                  >
                    {discountDefines.map(d => {
                      return <Option value={`${d.id}`}>{d.name}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>

              {currentDefine.type === 'hourly' ? (
                <div>
                  {currentDefine.data && currentDefine.data.static ? (
                    <div style={{ display: 'flex' }}>
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          flex: 2,
                          color: '#222',
                        }}
                      >
                        小時數:
                      </span>
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          flex: 2,
                          color: '#222',
                        }}
                      >
                        {currentDefine &&
                        currentDefine.data &&
                        currentDefine.data.default_hours
                          ? currentDefine.data.default_hours
                          : 1}
                      </span>
                    </div>
                  ) : (
                    <Form.Item htmlFor='hours' label='小時數'>
                      {getFieldDecorator('hours', {
                        rules: [
                          {
                            required: true,
                            message: '請輸入時數',
                          },
                        ],
                        initialValue:
                          currentDefine &&
                          currentDefine.data &&
                          currentDefine.data.default_hours
                            ? currentDefine.data.default_hours
                            : 1,
                      })(
                        <InputNumber
                          style={{ width: 180 }}
                          step={0.5}
                          size='large'
                          max={
                            currentDefine &&
                            currentDefine.data &&
                            currentDefine.data.max_hours
                              ? currentDefine.data.max_hours
                              : 1
                          }
                          min={
                            currentDefine &&
                            currentDefine.data &&
                            currentDefine.data.min_hours
                              ? currentDefine.data.min_hours
                              : 0.5
                          }
                        ></InputNumber>
                      )}
                    </Form.Item>
                  )}
                </div>
              ) : currentDefine.type === 'amount' ||
                currentDefine.type === 'fixed_price' ? (
                <div>
                  {currentDefine.data && currentDefine.data.static ? (
                    <div style={{ display: 'flex' }}>
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          flex: 2,
                          color: '#222',
                        }}
                      >
                        金額:
                      </span>
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          flex: 2,
                          color: '#222',
                        }}
                      >
                        {currentDefine &&
                        currentDefine.data &&
                        currentDefine.data.default_amount
                          ? currentDefine.data.default_amount
                          : 50}
                      </span>
                    </div>
                  ) : (
                    <Form.Item htmlFor='amount' label='金額'>
                      {getFieldDecorator('amount', {
                        rules: [
                          {
                            required: true,
                            message: '請輸入金額',
                          },
                        ],
                        initialValue:
                          currentDefine &&
                          currentDefine.data &&
                          currentDefine.data.default_amount
                            ? currentDefine.data.default_amount
                            : 50,
                      })(
                        <InputNumber
                          style={{ width: 180 }}
                          step={5}
                          size='large'
                          max={
                            currentDefine &&
                            currentDefine.data &&
                            currentDefine.data.max_amount
                              ? currentDefine.data.max_amount
                              : 100
                          }
                          min={
                            currentDefine &&
                            currentDefine.data &&
                            currentDefine.data.min_amount
                              ? currentDefine.data.min_amount
                              : 0
                          }
                        ></InputNumber>
                      )}
                    </Form.Item>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

const CarDiscountForm = Form.create({ name: 'discount_form' })(CarDiscount);

const CarAvater = props => {
  return (
    <div {...props} style={{ cursor: 'pointer' }}>
      {props.image ? (
        <img
          src={props.image}
          alt='car'
          style={{
            width: 360,
            height: 240,
            borderRadius: 6,
          }}
        ></img>
      ) : (
        <Avatar
          shape='square'
          style={{
            width: 360,
            height: 240,
            backgroundColor: '#48D049',
            fontSize: 52,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.name}
        </Avatar>
      )}
    </div>
  );
};

const CarField = props => {
  const [viewerVisible, setViewerVisible] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.car) {
      if (props.car.image) {
        setImages([{ src: `${props.car.baseImageUrl}/origin.jpg`, alt: '' }]);
      } else {
        setImages([]);
      }
    }
  }, [props.car]);

  if (!props.car) return <div></div>;

  return (
    <div
      style={{
        fontSize: 26,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CarAvater
        name={props.car.name}
        image={props.car.image}
        onClick={() => {
          if (props.car.image) {
            setViewerVisible(true);
          }
        }}
      ></CarAvater>

      <Paragraph style={{ marginTop: 22 }}>
        車牌號碼: {props.car.name}
      </Paragraph>

      <Paragraph>入場時間: {props.car.createTime.toLocaleString()}</Paragraph>

      <Paragraph>
        身分:{' '}
        {props.car.rentalUsers && props.car.rentalUsers.length > 0
          ? '月租用戶'
          : '臨停'}
      </Paragraph>

      {props.car.discounts && props.car.discounts.length > 0 ? (
        <Paragraph style={{ color: '#cf1322' }}>
          優惠: {props.car.discounts[0].name}
        </Paragraph>
      ) : (
        <div></div>
      )}

      <Viewer
        visible={viewerVisible}
        onClose={() => {
          setViewerVisible(false);
        }}
        images={images}
      />
    </div>
  );
};

const useToolBarState = () => {
  const [searchText, setSearchText] = useState('');

  return {
    setSearchText,
    searchText,
  };
};

const ToolBar = props => {
  const { setSearchText, searchText } = useToolBarState();

  const externalOnSearchChange = utils.doWhenExist(props.onSearchChange);
  const onDiscount = useCallback(utils.doWhenExist(props.onDiscount), [
    props.onDiscount,
  ]);

  useEffect(() => {
    externalOnSearchChange(searchText);
  }, [searchText]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          flex: 2,
        }}
      >
        <span
          style={{
            marginLeft: 20,
            marginRight: 12,
            fontSize: 20,
            fontWeight: 'bold',
          }}
        >
          請輸入車牌號碼:
        </span>
        <Search
          size='large'
          placeholder='請輸入車牌號碼'
          onSearch={text => setSearchText(text.toUpperCase())}
          onChange={evt => {
            let value = evt.target.value;
            if (value == null) return;

            setSearchText(value.toUpperCase());
          }}
          style={{ width: 200 }}
        />

        {/* <span style={{marginLeft: 20, marginRight: 6, fontSize: 20}}>未知車牌通知:</span>
        <Switch onChange={onUnknownNotifitionChange} checked={props.unknownNotifitionChecked} size='large'></Switch> */}
      </div>

      <div
        style={{
          flex: 2,
        }}
      >
        <div
          style={{
            float: 'right',
          }}
        >
          <Button
            size='large'
            style={{ fontWeight: 'bold' }}
            onClick={onDiscount}
          >
            優惠折扣
          </Button>
        </div>
      </div>
    </div>
  );
};

const useCarsNavigatorState = (state = {}) => {
  // carType: any of 'all'、'car'、'moto'、'unknown'
  const { searchText, disable, carType } = state;

  const onCarChange = utils.doWhenExist(state.onCarChange);

  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState(state.cars || []);
  const [pagination, setPagination] = useState(
    state.pagination || {
      current: 1,
      total: 1000,
      pageSize: 1,
    }
  );
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (disable) return;

    if (state.action && state.action.type === 'add') {
      if (state.action.car) {
        updateCars([...cars, state.action.car], searchText);
        let nextPagin = { ...pagination };
        nextPagin.total += 1;
        nextPagin.current = Number(nextPagin.total);

        setPagination(nextPagin);
      }
    } else if (state.action && state.action.type === 'update') {
      if (state.action.car) {
        let idx = -1;
        for (let i = 0; i < cars.length; i++) {
          if (cars[i].id === state.action.car.id) {
            idx = i;
            break;
          }
        }

        if (idx < 0) return;
        let next = [...cars];
        next[idx] = state.action.car;
        updateCars(next, searchText);
        let nextPagin = { ...pagination };
        nextPagin.current = idx + 1;

        setPagination(nextPagin);
      }
    }
  }, [state.action]);

  // tracking component state
  const componentIsMounted = useRef(true);

  const syncItemTimeout = useRef(null);

  const fetchItems = async searchText => {
    if (!searchText || searchText.length < 4) {
      return {
        cars: [],
        pagin: {
          page: 1,
          total_elements: 0,
        },
      };
    }

    let opts = {
      page: 0,
      page_size: 3000,
      sort_direction: 'desc',
      type: 'park_service',
      status: ['free', 'serving'],
    };

    if (carType === 'unknown') {
      opts.search_by = 'name';
      opts.search_text = '######';
    } else if (carType === 'moto' || carType === 'car') {
      opts.carType = carType === 'car' ? 'C' : 'M';
      opts.search_text = searchText;
      opts.search_by = 'name';
    } else if (carType === 'one_time') {
      opts.membership_type = 'one_time';
      opts.search_text = searchText;
      opts.search_by = 'name';
    }

    let resp = await pos.item.fetchItems(opts);

    let cars = itemMapper.toView(resp.content, {
      imageType: 'rw320',
      autoSample: false,
    });

    cars.forEach(c => {
      c.display = true;
    });

    let pagin = resp.pagination;

    if (pagin.total_elements > 3000) {
      pagin.total_elements = 3000;
    }

    return {
      cars: cars,
      pagin: pagin,
    };
  };

  const nextSyncItem = refresh => {
    clearTimeout(syncItemTimeout.current);

    syncItemTimeout.current = setTimeout(() => {
      if (componentIsMounted.current) {
        setRefresh(!refresh);
      }
    }, 3000);
  };

  const init = async () => {
    setLoading(true);

    try {
      let { cars, pagin } = await fetchItems();

      updateCars(cars, searchText);

      let next = {
        ...pagination,
      };

      next.total = pagin.total_elements;

      if (next.current > cars.length) {
        next.current = cars.length;
      }

      onCarChange(cars[next.current - 1]);

      setPagination(next);
    } catch (err) {
      // ignore now
      console.log(err);
    }

    setLoading(false);

    nextSyncItem(refresh);
  };

  // initial
  useEffect(() => {
    init();
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const syncItems = (cars, pagination, refresh, searchText) => {
    const asyncSyncItems = async () => {
      let resp;
      try {
        resp = await fetchItems(searchText);
      } catch (err) {
        // do in next time
        nextSyncItem(refresh);
        return;
      }

      let currentCar = cars[Number(pagination.current) - 1];
      let idx = -1;

      if (currentCar) {
        for (let i = 0; i < resp.cars.length; i++) {
          let c = resp.cars[i];
          if (c.id === currentCar.id) {
            idx = i;
            break;
          }
        }
      }

      let nextPagin = {
        ...pagination,
      };

      // cannot find current car
      // find closet neighbor
      if (idx < 0) {
        if (nextPagin.current > resp.pagin.total_elements) {
          nextPagin.current = resp.pagin.total_elements;
        }
      } else {
        nextPagin.current = idx + 1;
      }

      if (nextPagin.current <= 0) {
        nextPagin.current = 1;
      }

      onCarChange(resp.cars[nextPagin.current - 1]);

      nextPagin.total = resp.pagin.total_elements;

      setPagination(nextPagin);

      updateCars(resp.cars, searchText);

      nextSyncItem(refresh);
    };

    asyncSyncItems();
  };

  useEffect(() => {
    // when nagivator is disable
    // don't to sync items
    if (!disable) {
      syncItems(cars, pagination, refresh, searchText);
    }
  }, [refresh, disable, state.refresh]);

  const onPageChange = page => {
    let next = {
      ...pagination,
    };

    next.current = page;

    onCarChange(cars[page - 1]);

    setPagination(next);
  };

  const updateCars = (cars, text) => {
    let next = [...cars];
    next.forEach(c => {
      if (c.name.includes(text)) {
        c.display = true;
      } else {
        c.display = false;
      }
    });

    setCars(next);
  };

  useEffect(() => {
    updateCars(cars, searchText);
  }, [searchText]);

  return {
    onPageChange,
    cars,
    pagination,
    loading,
    setCars,
  };
};

const CarsNavigator = props => {
  const { onPageChange, cars, pagination, loading } =
    useCarsNavigatorState(props);

  return (
    <Layout style={{ background: '#fff' }}>
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '12px',
          background: '#fff',
          minHeight: 280,
          //boxShadow: '2px 2px 4px #ccc',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <Spin></Spin>
        ) : cars.length > 0 ? (
          <CarField car={cars[Number(pagination.current) - 1]}></CarField>
        ) : (
          <div></div>
        )}

        <div
          style={{
            minHeight: 42,
            position: 'absolute',
            right: 22,
            top: -2,
          }}
        >
          <span style={{ fontSize: 32, fontWeight: 'bold' }}>
            {pagination.current}
          </span>
          <span style={{ fontSize: 32, fontWeight: 'bold' }}>
            &nbsp;/&nbsp;
          </span>
          <span style={{ fontSize: 32, fontWeight: 'bold' }}>
            {pagination.total}
          </span>

          <Button
            size='large'
            onClick={() => {
              let next = { ...pagination };
              next.current -= 1;
              if (next.current < 1) {
                next.current = 1;
              }

              onPageChange(next.current);
            }}
            style={{
              marginLeft: 20,
              fontSize: 24,
              fontWeight: 'bold',
              borderRadius: 4,
              width: 48,
              height: 48,
            }}
          >
            &lt;
          </Button>

          <Button
            size='large'
            onClick={() => {
              let next = { ...pagination };
              next.current += 1;
              if (next.current > pagination.total) {
                next.current = pagination.total;
              }

              onPageChange(next.current);
            }}
            style={{
              marginLeft: 12,
              fontSize: 24,
              fontWeight: 'bold',
              borderRadius: 4,
              width: 48,
              height: 48,
            }}
          >
            &gt;
          </Button>
        </div>
      </Content>

      <Sider
        width={260}
        style={{
          background: '#fff',
          //boxShadow: '2px 2px 5px #ccc',
          margin: '0px 0px 16px 12px',
          padding: '8px 12px',
          borderLeft: '1px solid #ddd',
        }}
      >
        <div
          style={{
            padding: '0px 12px 12px 12px',
            boxShadow: '0px 5px 5px -5px #ccc',
          }}
        >
          <span style={{ fontSize: 22, fontWeight: 'bold' }}>車牌號碼</span>
        </div>

        <List
          loading={loading}
          style={{ height: 500, overflowY: 'auto' }}
          dataSource={cars}
          renderItem={(item, idx) => (
            <List.Item style={{ display: item.display ? 'block' : 'none' }}>
              <Button
                onClick={() => onPageChange(idx + 1)}
                size='large'
                style={{ fontWeight: 'bold' }}
              >
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[props.searchText]}
                  autoEscape
                  textToHighlight={item.name}
                />
              </Button>

              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#cf1322',
                  marginLeft: 6,
                }}
              >
                {item.discounts && item.discounts.length > 0
                  ? item.discounts[0].name
                  : ''}
              </span>
            </List.Item>
          )}
        />
      </Sider>
    </Layout>
  );
};

const Car = props => {
  const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState('car');
  const [drawer, setDrawer] = useState({
    title: '修改',
    visible: false,
  });
  const [car, setCar] = useState({});
  const [notifiCar, setNotifiCar] = useState(null);
  const [needRefresh, setNeedFresh] = useState(false);

  const [navAction, setNavAction] = useState({});

  const [inhibitCarChange, setInhibitCarChange] = useState(false);

  const [discountDialogVisible, setDiscountDialogVisible] = useState(false);

  const tabs = [
    {
      name: '汽車',
      key: 'car',
    },
    {
      name: '機車',
      key: 'moto',
    },
  ];

  const onTabChange = value => {
    if (value !== currentTab) {
      setCurrentTab(value);
    }
  };

  const onCarChange = car => {
    // debounce
    setTimeout(() => {
      if (inhibitCarChange) {
        return;
      }

      setCar(car);
    }, 250);
  };

  useEffect(() => {
    if (discountDialogVisible) {
      setInhibitCarChange(true);
    } else {
      setInhibitCarChange(false);
    }
  }, [discountDialogVisible]);

  const addCarDiscount = async (car, values) => {
    let carDiscounts = car.discounts;

    if (carDiscounts && carDiscounts.length > 0) {
      message.error('優惠折扣已使用');
      return;
    }

    let discount;
    if (values.define.type === 'hourly') {
      discount = {
        item_id: car.id,
        minutes: values.hours * 60,
        type: values.define.type,
        amount: 0,
        data: values.define.data,
        define_id: values.define_id,
      };
    } else if (
      values.define.type === 'amount' ||
      values.define.type === 'fixed_price'
    ) {
      discount = {
        item_id: car.id,
        minutes: 0,
        type: values.define.type,
        amount: values.amount,
        data: values.define.data,
        define_id: values.define_id,
      };
    } else if (values.define.type === 'all') {
      discount = {
        item_id: car.id,
        minutes: 0,
        type: values.define.type,
        amount: 0,
        data: values.define.data,
        define_id: values.define_id,
      };
    } else {
      message.error('失敗');
      return;
    }

    try {
      await pos.itemDiscount.addDiscount(discount);
      message.success('成功');
    } catch (err) {
      message.error('失敗');
    }
  };

  return (
    <Content
      style={{
        margin: '12px 0px 16px 0px',
        minHeight: 280,
      }}
    >
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 80,
        }}
      >
        <ToolBar
          onSearchChange={setSearchText}
          privilege={props.userRoutePrivilege}
          onDiscount={() => {
            if (car) {
              setDiscountDialogVisible(true);
            } else {
              message.info('沒有選定車輛');
            }
          }}
        ></ToolBar>
      </Content>

      <Content
        style={{
          margin: '4px 0px 16px 0px',
          padding: '12px 24px',
          background: '#fff',
        }}
      >
        <Tabs
          defaultActiveKey={tabs[0].key}
          onChange={onTabChange}
          size='large'
          style={{ fontSize: 18 }}
        >
          {tabs.map(t => (
            <TabPane tab={t.name} key={t.key}>
              <CarsNavigator
                searchText={searchText}
                disable={t.key !== currentTab}
                carType={t.key}
                onCarChange={onCarChange}
                refresh={needRefresh}
                action={navAction}
              ></CarsNavigator>
            </TabPane>
          ))}
        </Tabs>
      </Content>

      <CarDiscountForm
        visible={discountDialogVisible}
        car={car}
        onOk={(err, values) => {
          if (!err) {
            addCarDiscount(car, values).then(() => {
              setNeedFresh(!needRefresh);
            });
          } else {
            message.error('失敗');
          }
          setDiscountDialogVisible(false);
        }}
        onCancel={() => setDiscountDialogVisible(false)}
      ></CarDiscountForm>
    </Content>
  );
};

export default Car;
