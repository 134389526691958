import React from 'react';
import { Layout, Row, Col, Card, Statistic, Spin } from 'antd';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import pos from 'pos-api';

const { Content } = Layout;

const data = [
  {
    name: 'Page A',
    uv: 4000,
  },
  {
    name: 'Page B',
    uv: 3000,
  },
  {
    name: 'Page C',
    uv: 2000,
  },
  {
    name: 'Page D',
    uv: 2780,
  },
  {
    name: 'Page E',
    uv: 1890,
  },
  {
    name: 'Page F',
    uv: 2390,
  },
];

class Overview extends React.Component {
  constructor() {
    super();
  }

  state = {
    monthAmount: null,
    dailyAmount: null,
    visitorVolume: null,
    data: null,
  };

  init = async () => {
    let now = new Date();
    let year = now.getFullYear();
    let startMonth = now.getMonth() - 6;
    if (startMonth < 0) {
      startMonth += 13;
      year--;
    }
    let bundle = [
      pos.report.fetchDailyReport(),
      pos.report.fetchMonthReport({
        year,
        start_month: startMonth,
        months: 6,
      }),
    ];
    let [daily, month] = await Promise.all(bundle);

    this.setState({
      monthAmount: month[month.length - 1].amount,
      dailyAmount: daily.amount,
      visitorVolume: daily.visitor_volume,
    });

    let months = [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月',
    ];

    let data = month.map((re, idx) => {
      return {
        name: months[(startMonth + 1 + idx) % 12],
        銷售額: re.amount,
      };
    });

    this.setState({
      data: data,
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          padding: '24px',
          background: '#fff',
          minHeight: 240,
        }}
      >
        <div style={{ maxWidth: 900, margin: 'auto' }}>
          <Row gutter={16}>
            <Col span={8}>
              <Card title='來客量' style={{ height: 240 }}>
                {this.state.visitorVolume !== null ? (
                  <Statistic value={this.state.visitorVolume}></Statistic>
                ) : (
                  <Spin></Spin>
                )}
              </Card>
            </Col>

            <Col span={8}>
              <Card title='日銷售額' style={{ height: 240 }}>
                {this.state.dailyAmount !== null ? (
                  <Statistic
                    value={this.state.dailyAmount}
                    prefix={<span>$</span>}
                  ></Statistic>
                ) : (
                  <Spin></Spin>
                )}
              </Card>
            </Col>

            <Col span={8}>
              <Card title='月銷售額' style={{ height: 240 }}>
                {this.state.monthAmount !== null ? (
                  <Statistic
                    value={this.state.monthAmount}
                    prefix={<span>$</span>}
                  ></Statistic>
                ) : (
                  <Spin></Spin>
                )}
              </Card>
            </Col>
          </Row>
        </div>

        <div
          style={{
            margin: 'auto',
            width: 900,
            padding: 24,
          }}
        >
          {this.state.data ? (
            <AreaChart
              width={860}
              height={380}
              data={this.state.data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey='name' />
              <YAxis />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip />
              <Area
                type='monotone'
                dataKey='銷售額'
                stroke='#8884d8'
                fillOpacity={1}
                fill='url(#colorUv)'
              />
              <Area
                type='monotone'
                dataKey='pv'
                stroke='#82ca9d'
                fillOpacity={1}
                fill='url(#colorPv)'
              />
            </AreaChart>
          ) : (
            <Spin></Spin>
          )}
        </div>
      </Content>
    );
  }
}

export default Overview;
