import React from 'react';
import {
  Layout,
  Table,
  Divider,
  Button,
  message,
  Select,
  Typography,
  Modal,
  Upload,
} from 'antd';
import { Drawer, Input, Icon } from 'antd';

import { generatePath } from 'react-router-dom';

import pos from 'pos-api';
import { rentalUserMapper } from '../../../utils/mapper';
import * as utils from '../../../utils/utils';

import { connect } from 'react-redux';

import routes from '../../../Routes';

import Highlighter from 'react-highlight-words';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const { confirm } = Modal;

class CarRegistrations extends React.Component {
  state = {
    pagination: {
      pageSize: 12,
      current: 1,
    },
    loading: true,
    regs: null,
    searchType: 'plate',
    importModal: {
      display: false,
    },
    privilege: 'r',
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchUsers({
      pagination: pager,
    });
  };

  handleImport = async file => {
    let hide = message.loading('處理中...');
    try {
      await pos.carRegistration.importCarRegistrations(file);
    } catch (err) {
      hide();
      console.log(err);
      message.error('處理失敗');
      return;
    }

    hide();
    message.success('處理成功');
  };

  fetchUsers = async (opts = {}) => {
    this.setState({
      loading: true,
    });

    if (!opts.pagination) {
      opts.pagination = this.state.pagination;
    }

    let result = await pos.carRegistration.readCarRegistrations({
      page: opts.pagination.current - 1,
      pageSize: opts.pagination.pageSize,
      searchBy: this.searchBy,
      searchText: this.searchText,
      identityType: 'plate',
    });

    let pagination = result.pagination;
    let regs = result.content;

    regs.forEach((reg, idx) => {
      reg.key = reg.id;

      reg.onEdit = this.onEditBtn(reg);
      //reg.onDelete = this.onDeleteBtn(reg);
    });

    let pager = { ...this.state.pagination };
    pager.total = pagination.total_elements;
    this.setState({
      regs: regs,
      pagination: pager,
      loading: false,
    });
  };

  onAddBtn = () => {
    let path = `${routes.carRegistrationForm.layout}${routes.carRegistrationForm.path}`;
    path = generatePath(path, { id: 0 });
    this.props.history.push(path);
  };

  onEditBtn = rentalUser => () => {
    let path = `${routes.carRegistrationForm.layout}${routes.carRegistrationForm.path}`;
    path = generatePath(path, { id: rentalUser.id });
    this.props.history.push(path);
  };

  onSearch = text => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.searchBy = this.state.searchType;

      if (text === '') {
        this.searchBy = null;
      }

      this.searchText = text;

      this.fetchUsers();
    }, 250);
  };

  getColumns = () => {
    let nameColumn = {
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
    };

    const Render = text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.searchText || '']}
        autoEscape
        textToHighlight={text.toString()}
      />
    );

    let plateColumn = {
      title: '車號',
      dataIndex: 'plate',
      key: 'plate',
    };

    let phoneColumn = {
      title: '手機號碼',
      dataIndex: 'phone_number',
      key: 'phone_number',
    };

    let identityColumn = {
      title: '身分證字號',
      dataIndex: 'identity_number',
      key: 'identity_number',
    };

    if (this.state.searchType === 'plate') {
      plateColumn.render = Render;
    } else if (this.state.searchType === 'name') {
      nameColumn.render = Render;
    }

    let opColumn = {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <span>
          {this.state.privilege.includes('u') ? (
            <Button type='link' icon='edit' onClick={record.onEdit}></Button>
          ) : (
            <Button type='link' icon='eye' onClick={record.onEdit}></Button>
          )}
        </span>
      ),
    };

    return [nameColumn, plateColumn, phoneColumn, identityColumn, opColumn];
  };

  onSearchTypeChange = type => {
    this.setState({
      searchType: type,
    });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.searchBy = this.state.searchType;

      if (this.searchText === '' || this.searchText == null) {
        this.searchBy = null;
      }

      this.fetchUsers();
    }, 150);
  };

  componentDidMount() {
    this.fetchUsers();

    if (this.props.userRoutePrivilege) {
      this.setState({
        privilege: this.props.userRoutePrivilege,
      });
    }
  }

  render() {
    const totalColumns = this.getColumns();

    return (
      <Content
        style={{
          margin: '12px 0px 16px 0px',
          minHeight: 280,
        }}
      >
        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 80,
          }}
        >
          {this.state.privilege.includes('c') ? (
            <div style={{ float: 'left' }}>
              <Button type='link' icon='plus' onClick={this.onAddBtn}>
                新增車籍資料
              </Button>

              <Upload
                {...{
                  accept: '.csv',
                  beforeUpload: file => {
                    this.handleImport(file);

                    return false;
                  },
                  fileList: [],
                }}
              >
                <Button type='link'>
                  <Icon type='import' />
                  匯入
                </Button>
              </Upload>
            </div>
          ) : (
            <div></div>
          )}

          <div style={{ float: 'right' }}>
            <Select
              defaultValue='plate'
              onChange={this.onSearchTypeChange}
              style={{ width: 120 }}
            >
              <Option value='name'>名稱搜尋</Option>
              <Option value='plate'>車牌搜尋</Option>
              <Option value='identity_number'>身分證字號搜尋</Option>
              <Option value='phone_number'>手機號搜尋</Option>
              <Option value='tel_number'>電話號碼搜尋</Option>
            </Select>

            <Search
              placeholder={'請輸入搜尋字串'}
              onSearch={this.onSearch}
              onChange={evt => {
                let value = evt.target.value;
                if (value == null) return;

                this.onSearch(value);
              }}
              style={{ width: 200, float: 'right', marginLeft: 14 }}
            />
          </div>
        </Content>

        <Content
          style={{
            margin: '12px 0px 16px 0px',
            padding: '24px',
            background: '#fff',
            minHeight: 280,
          }}
        >
          <Table
            columns={totalColumns}
            dataSource={this.state.regs}
            loading={this.state.loading}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </Content>

        <div>
          <Modal
            visible={this.state.importModal.display}
            title='導入'
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key='back'>Return</Button>,
              <Button key='submit' type='primary'>
                Submit
              </Button>,
            ]}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal>
        </div>
      </Content>
    );
  }
}

function mapProps(s) {
  return {
    user: s.user,
    route: s.route,
  };
}

export default connect(mapProps)(CarRegistrations);
